import { dataSources } from '@configs/dataSources/config';

import DataStudioAddActivityDataStepForm from './DataStudioAddActivityDataStepForm';
import MetabaseAddActivityDataStepForm from './MetabaseAddActivityDataStepForm';
import QuickSightAddActivityDataStepForm from './QuickSightAddActivityDataStepForm';
import TableauAddActivityDataStepForm from './TableauAddActivityDataStepForm';
import { DataSourceAddActivityDataStepProps } from './types';

const forms: { [key: string]: React.FC<DataSourceAddActivityDataStepProps> } = {
  data_studio: DataStudioAddActivityDataStepForm,
  metabase: MetabaseAddActivityDataStepForm,
  [dataSources.quicksight.value]: QuickSightAddActivityDataStepForm,
  tableau: TableauAddActivityDataStepForm,
};

export default forms;
