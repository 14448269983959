import React from 'react';

import Alert from '@components/Alert';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import CircularLoader from '@components/CircularLoader';
import Modal, { ModalContent, ModalFooter, ModalHeader } from '@components/UI/Modal';

export interface DeleteConfirmationModalProps {
  children?: React.ReactNode;
  deleteButtonText?: string;
  error?: string;
  loading?: boolean;
  onClose?: () => void;
  onRemove?: () => void;
  title?: string;
}

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
  children,
  deleteButtonText = 'Delete',
  error,
  loading,
  onClose,
  onRemove,
  title,
}) => {
  return (
    <Modal onClose={onClose}>
      <ModalHeader onClose={onClose} title={title} />
      <ModalContent alignItems="center" color="gray.700" compDisplay="flex" minHeight="auto" py={2}>
        {loading ? (
          <CircularLoader aria-label="loader" centered compSize={4} />
        ) : (
          <Box compDisplay="flex" compWidth="100%" flexDirection="column" gap={1}>
            {children}
            {error && (
              <Alert title="Error" type="error">
                {error}
              </Alert>
            )}
          </Box>
        )}
      </ModalContent>
      <ModalFooter>
        <Button disabled={loading} onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button disabled={loading} onClick={onRemove}>
          {deleteButtonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteConfirmationModal;
