import React from 'react';

import CreateDocMenu from '@components/AppMainSidebar/Hierarchy/DocumentsHierarchy/CreateDocMenu';
import Box from '@components/Box';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import theme from '@styles/theme';

import NoContentMessage from '../NoContentMessage';

import { StyledAddNewDoc } from './AddNewDoc.styles';

const AddNewDoc: React.FC = () => {
  const { isEditable } = useObjectPermissionsContext({ id: 'all-docs' });

  return (
    <StyledAddNewDoc
      alignItems="center"
      compDisplay="flex"
      compWidth="500px"
      flexDirection="column"
      justifyContent="start"
    >
      <NoContentMessage />
      {isEditable && (
        <Box maxWidth={theme.space(24.5)}>
          <CreateDocMenu />
        </Box>
      )}
    </StyledAddNewDoc>
  );
};

export default AddNewDoc;
