import React from 'react';

import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import theme from '@styles/theme';

import { colors, StyledButton } from './ExpandCollapseButton.styles';

export type ExpandAllState = 'collapsed' | 'expanded';
interface ExpandCollapseButtonProps {
  direction: 'right' | 'left';
  disabled?: boolean;
  isColumn?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  state?: ExpandAllState;
}

const ExpandCollapseButton: React.FC<ExpandCollapseButtonProps> = ({
  direction,
  disabled,
  isColumn,
  isLoading,
  onClick,
  state = 'collapsed',
}) => {
  const isExpanded = state === 'expanded';
  const directionLabel = direction === 'left' ? 'upstream' : 'downstream';

  return (
    <Tooltip
      content={
        disabled &&
        `Too many nodes to open all ${directionLabel}.${
          isColumn ? '' : ' Download CSV to get the lineage list.'
        }`
      }
    >
      <Box>
        <StyledButton
          backgroundColor={colors[state].default.background}
          borderColor={colors[state].default.border}
          borderRadius="10px"
          color={colors[state].default.color}
          compSize="xxs"
          disabled={disabled}
          onClick={() => {
            if (!isLoading) {
              onClick?.();
            }
          }}
          spacing={0.5}
          startIcon={
            isLoading ? (
              <CircularLoader
                bgColor={theme.colors.v1.primary[100]}
                borderWidth={1}
                color={theme.colors.v1.primary[500]}
                compSize={1.5}
              />
            ) : (
              <Icon
                color={colors[state].default.icon}
                name={isExpanded ? 'chevron-up-double' : 'chevron-down-double'}
                size="16px"
              />
            )
          }
          state={state}
          variant="outlined"
        >
          {isExpanded ? 'Close' : 'Open'} all {directionLabel}
        </StyledButton>
      </Box>
    </Tooltip>
  );
};

export default ExpandCollapseButton;
