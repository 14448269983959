import { Editor, Element, Node, Transforms } from 'slate';

import { CustomElement, FormattedText } from '../RichTextEditor.types';

const LIST_TYPES = ['ol', 'ul'];

/**
 * Slate does normalization under the hood that enforces a constraint where text nodes and block nodes
 * cannot live next to eachother as siblings. This causes issues with nested lists (that live under a list item
 * next to the list item's text). In order for nested lists to display properly, we must lift up nested lists to be
 * direct children of parent list ('ul' or 'ol') rather than list items.
 *
 * Another condition to check for is parentless list items. If a list item doesn't have a 'ul' or 'ol' as parent
 * we should convert it to a paragraph node. This fixes bugs around deleting lists where list items would remain
 * without the proper parent.
 *
 * For more details on normalization rules and contraints in slate see:
 * https://docs.slatejs.org/concepts/11-normalizing
 */
const withListsPlugin = (editor: Editor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;
    if (Element.isElement(node) && node.type === 'li') {
      // If an 'li' element is ever without a parent 'ul' or 'ol' element convert it to a paragraph
      const [parentNode] = Editor.parent(editor, path);
      if (
        Editor.isEditor(parentNode) ||
        (Element.isElement(parentNode) && !LIST_TYPES.includes(parentNode.type))
      ) {
        Transforms.setNodes(editor, { type: 'paragraph' }, { at: path });
        return;
      }

      // Check for ul and ol types as children of list items and raise up if needed.
      const listItemChildren = Array.from(Node.children(editor, path));
      for (let i = 0; i < listItemChildren.length; i += 1) {
        const [child, childPath] = listItemChildren[i];
        if (Element.isElement(child) && LIST_TYPES.includes(child.type)) {
          Transforms.liftNodes(editor, { at: childPath });
          return;
        }
      }

      // When creating a new item at the end of the list, if the previous item is empty, exit from list styles
      const previousNode = Editor.previous(editor, { at: path });
      const previousNodeElement = previousNode?.[0];
      const previousNodePath = previousNode?.[1];

      const nextNode = Editor.next(editor, { at: path });
      const nextNodeElement = nextNode?.[0] as CustomElement;

      if (
        Element.isElement(previousNodeElement) &&
        previousNodeElement.type === 'li' &&
        nextNodeElement?.type !== 'li'
      ) {
        const currentItemHasContent = node.children.some((child) => {
          return (
            (Element.isElement(child) && child.type === 'mention') || (child as FormattedText).text
          );
        });
        const previousItemHasContent = previousNodeElement.children.some((child) => {
          return (
            (Element.isElement(child) && child.type === 'mention') || (child as FormattedText).text
          );
        });

        if (!currentItemHasContent && !previousItemHasContent) {
          Transforms.setNodes(editor, { type: 'paragraph' }, { at: previousNodePath });
          Transforms.delete(editor, { at: path });

          // Move created paragraph to outside from list parent ('ul' or 'ol')
          Transforms.unwrapNodes(editor, {
            at: previousNodePath,
            match: (n) =>
              !Editor.isEditor(n) && Element.isElement(n) && LIST_TYPES.includes(n.type),
            split: true,
          });

          return;
        }
      }
    }
    normalizeNode(entry);
  };

  return editor;
};
export default withListsPlugin;
