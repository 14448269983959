import React, { useMemo } from 'react';

import invalidateCache from '@api/invalidateCache';
import { usePatchTag } from '@api/tags';
import { TagModel } from '@api/tags/TagModel';
import { renderInfoToast } from '@components/Toast';
import Select, { SelectValue } from '@components/UI/Select';
import { useTagContext } from '@context/Tag';

interface DbtTagsSetupSelectProps {
  tag: TagModel;
}

const DbtTagsSetupSelect = ({ tag }: DbtTagsSetupSelectProps) => {
  const tagContext = useTagContext();

  const { isLoading, mutate } = usePatchTag(tag.guid, {
    onSuccess: (response) => {
      const { linksTo } = response;

      if (linksTo) {
        const linkedTag = tagContext.findTag?.(linksTo)?.name;
        renderInfoToast(`${linkedTag} has been linked to dbt tag ${tag.name}`);
      } else if (tag.linksTo !== undefined) {
        const linkedTag = tagContext.findTag?.(tag.linksTo)?.name;
        renderInfoToast(`${linkedTag} has been unlinked from dbt tag ${tag.name}`);
      }
      invalidateCache((keys) => [keys.tags.all]);
    },
  });

  const selectStarTags = useMemo(
    () => ({
      ...tagContext.tags?.category,
      ...tagContext.tags?.status,
    }),
    [tagContext],
  );

  const linksToTagOptions = useMemo(
    () =>
      Object.values(selectStarTags).map((item) => ({
        icon: item.icon,
        iconColor: item.color,
        text: item.name ?? 'Tag',
        value: item.guid,
      })),
    [selectStarTags],
  );

  const onTagChange = (newValue: SelectValue) => {
    const [selectedOption] = newValue ?? [];

    const linkedTagGuid = selectedOption?.value;

    mutate({
      links_to: linkedTagGuid ?? null,
    });
  };

  const selectedValue = linksToTagOptions.find((option) => option.value === tag.linksTo);

  return (
    <Select
      isLoading={isLoading}
      onChange={onTagChange}
      options={linksToTagOptions}
      placeholder="No tag"
      shouldBlockOnLoading={false}
      showClearButton
      value={selectedValue ? [selectedValue] : undefined}
    />
  );
};

export default DbtTagsSetupSelect;
