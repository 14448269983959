import React from 'react';

import { commentCacheKeys, useDeleteComment } from '@api/comments';
import { CommentModel } from '@api/comments/CommentModel';
import invalidateCache from '@api/invalidateCache';
import fetchClient from '@lib/fetchClient';

import { renderInfoToast } from '../Toast';

import DeleteConfirmationModal from './DeleteConfirmationModal';

export interface DeleteCommentModalProps {
  item: CommentModel;
  onClose?: () => void;
  reloadData?: () => void;
}

const DeleteCommentModal: React.FC<DeleteCommentModalProps> = (props) => {
  const { item, onClose, reloadData } = props;

  const { error, isLoading, mutate } = useDeleteComment(item.guid, {
    onSuccess: () => {
      fetchClient.invalidateQueries(commentCacheKeys.comments);
      invalidateCache((keys) => [keys.metadata.metadataComments]);

      renderInfoToast('Comment Deleted');
      reloadData?.();
      onClose?.();
    },
  });

  return (
    <DeleteConfirmationModal
      error={error?.data?.detail || error?.data?.non_field_errors || error?.message}
      loading={isLoading}
      onClose={onClose}
      onRemove={() => mutate(null)}
      title="Delete Comment"
    >
      Are you sure you want to delete this comment?
    </DeleteConfirmationModal>
  );
};

export default DeleteCommentModal;
