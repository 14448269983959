import { dataSources } from '../dataSources/config';
import type { DataTypesConfig, ObjectTypeConfig } from '../dataTypes/types';

const columTooltips = {
  boolean: 'Boolean',
  date: 'Date',
  default: 'Column',
  geo: 'Geography',
  number: 'Number',
  object: 'Object',
  string: 'String',
} as const;

const TOOLTIP_CONFIG_BY_OBJECT: ObjectTypeConfig<string> = {
  bifolder: { default: 'Folder' },
  column: columTooltips,
  columngroup: columTooltips,
  comment: { default: 'Comment' },
  dashboard: { default: 'Dashboard' },
  database: { default: 'Database' },
  datasource: { default: 'Datasource' },
  default: '',
  dsuser: { default: 'User' },
  folder: { default: 'Folder' },
  schema: { default: 'Schema' },
  table: { default: 'Table', implicit: 'Temporary table (inferred)', view: 'View' },
  tablegroup: { default: 'Grouped Tables' },
  tag: { default: 'Tag' },
  team: { default: 'Team' },
  user: { default: 'User' },
};

const tooltipConfig: DataTypesConfig<string> = {
  bigquery: {
    column: columTooltips,
    columngroup: columTooltips,
    default: dataSources.bigquery.text,
    tablegroup: { default: 'Grouped Tables' },
  },
  databricks: {
    bifolder: { default: 'Folder' },
    column: columTooltips,
    columngroup: columTooltips,
    databricks_notebooks_container: { default: 'folder' },
    default: dataSources.databricks.text,
    notebook: { default: 'Notebook' },
    tablegroup: { default: 'Grouped Tables' },
  },
  db2: {
    ...TOOLTIP_CONFIG_BY_OBJECT,
    default: dataSources.db2.text,
  },
  dbt: {
    bifolder: { default: 'Folder' },
    column: columTooltips,
    comment: { default: 'Comment' },
    database: { default: 'Database' },
    datasource: { default: 'Datasource' },
    dbt_test_container: { default: 'dbt Tests' },
    dbtproject: { default: 'dbt Project' },
    dbttest: { default: 'dbt Test' },
    default: dataSources.dbt.text,
    dsuser: { default: 'User' },
    folder: { default: 'Folder' },
    schema: { default: 'Schema' },
    table: {
      default: 'Table',
      ephemeral: 'Ephemeral',
      implicit: 'Implicit',
      incremental: 'Incremental',
      seed: 'Seed',
      view: 'View',
    },
  },
  glue: {
    column: columTooltips,
    columngroup: columTooltips,
    default: dataSources.glue.text,
    tablegroup: { default: 'Grouped Tables' },
  },
  mssql: {
    column: columTooltips,
    columngroup: columTooltips,
    default: dataSources.mssql.text,
    tablegroup: { default: 'Grouped Tables' },
  },
  mysql: {
    column: columTooltips,
    columngroup: columTooltips,
    default: dataSources.mysql.text,
    tablegroup: { default: 'Grouped Tables' },
  },
  oracle: {
    column: columTooltips,
    columngroup: columTooltips,
    default: dataSources.oracle.text,
    tablegroup: { default: 'Grouped Tables' },
  },
  postgres: {
    column: columTooltips,
    columngroup: columTooltips,
    default: dataSources.postgres.text,
    tablegroup: { default: 'Grouped Tables' },
  },
  power_bi: {
    bifolder: { default: 'Folder' },
    comment: { default: 'Comment' },
    dashboard: { default: 'Dashboard', power_bi_report: 'Report' },
    datasource: { default: 'Datasource' },
    default: dataSources.power_bi.text,
    dsuser: { default: 'User' },
    power_bi_folders_container: { default: 'Folders Container' },
    powerbicolumn: {
      binary: 'binary',
      boolean: 'boolean',
      datetime: 'datetime',
      decimal: 'decimal',
      default: 'column',
      double: 'double',
      int64: 'int64',
      measure: 'measure',
      string: 'string',
      unknown: 'unknown',
      variant: 'variant',
    },
    powerbidataset: {
      default: 'Dataset',
      powerbidataflow: 'Dataflow',
    },
    powerbitable: { default: 'Table', implicit: 'Temporary table (inferred)' },
    reportquery: { default: 'Report Query', page: 'Page', tile: 'Tile' },
  },
  quicksight: {
    bicolumn: {
      BIT: 'BIT',
      DATETIME: 'DATETIME',
      DECIMAL: 'DECIMAL',
      INTEGER: 'INTEGER',
      STRING: 'STRING',
      default: 'Field',
    },
    bidashboardelement: {
      default: 'Visual',
    },
    bidataset: { default: 'Dataset' },
    bifolder: { default: 'Folder' },
    comment: { default: 'Comment' },
    dashboard: { dashboard: 'Dashboard', default: '', report: 'Analysis' },
    datasource: { default: 'Datasource' },
    default: dataSources.quicksight.text,
    dsuser: { default: 'User' },
  },
  redshift: {
    column: columTooltips,
    columngroup: columTooltips,
    default: dataSources.redshift.text,
    tablegroup: { default: 'Grouped Tables' },
  },
  salesforce: {
    column: columTooltips,
    columngroup: columTooltips,
    default: dataSources.salesforce.text,
    tablegroup: { default: 'Grouped Tables' },
  },
  snowflake: {
    column: columTooltips,
    columngroup: columTooltips,
    default: dataSources.snowflake.text,
    tablegroup: { default: 'Grouped Tables' },
  },
  tableau: {
    bifolder: { default: 'Folder', project: '' },
    comment: { default: 'Comment' },
    dashboard: { default: 'Dashboard', workbook: 'Workbook' },
    data_sources_container: { default: 'Data Sources Container' },
    datasource: { default: 'Datasource' },
    default: dataSources.tableau.text,
    dsuser: { default: 'User' },
    projects_container: { default: 'Projects Container' },
    tableaucolumn: { ...columTooltips, default: 'Tableau Column' },
    tableaudatasource: {
      default: 'Tableau Datasource',
      published: 'Tableau Datasource - Published',
    },
    tableaufield: { ...columTooltips, default: 'Tableau Field' },
    tableautable: {
      'custom-sql': 'Custom SQL Query',
      default: 'Tableau Table',
      'excel-direct': 'Excel direct',
    },
    tableauview: {
      dashboard: 'Tableau Dashboard',
      default: 'Tableau View',
      sheet: 'Tableau Sheet',
    },
  },
  thoughtspot: {
    bifolder: {
      answer: '',
      default: 'Folder',
      liveboard: '',
      one_to_one_logical: '',
      sql_view: '',
      worksheet: '',
    },
    dashboard: {
      answer: 'Answer',
      default: 'Dashboard',
      liveboard: 'Liveboard',
    },
    datasource: { default: 'Datasource' },
    default: dataSources.thoughtspot.text,
    dsuser: { default: 'User' },
    reportquery: {
      column: 'Column',
      default: 'Report Query',
      visual: 'Chart',
    },
    thoughtspot_folders_container: { default: 'Folders Container' },
    thoughtspotcolumn: {
      BOOL: 'BOOL',
      DATE: 'DATE',
      DATE_TIME: 'DATE_TIME',
      DOUBLE: 'DOUBLE',
      FLOAT: 'FLOAT',
      INT64: 'INT64',
      TIME: 'TIME',
      TIMESTAMP: 'TIMESTAMP',
      VARCHAR: 'VARCHAR',
      default: 'Column',
    },
    thoughtspottable: {
      default: 'Table',
      one_to_one_logical: 'One To One Logical',
      sql_view: 'View',
      worksheet: 'Worksheet',
    },
  },
};

export default tooltipConfig;
