import React from 'react';

import { IconButtonProps } from '@components/IconButton';
import Icon from '@components/UI/Icon';
import theme from '@styles/theme';

import { StyledCloseButton } from './CloseButton.styles';

interface CloseButtonProps extends IconButtonProps {
  onClose: () => void;
}

const CloseButton: React.FC<CloseButtonProps> = ({ onClose, ...other }) => {
  return (
    <StyledCloseButton aria-label="close" onClick={onClose} onKeyPress={onClose} {...other}>
      <Icon color="gray.500" name="close" size={theme.space(1.5)} />
    </StyledCloseButton>
  );
};

export default CloseButton;
