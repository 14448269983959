import styled from '@emotion/styled';

import Box from '@components/Box';
import Icon from '@components/UI/Icon';
import rootTheme from '@styles/theme';

export const StyledIconSelect = styled(Box)``;

StyledIconSelect.defaultProps = {
  alignItems: 'center',
  backgroundColor: 'white',
  border: 'solid 1px',
  borderColor: 'gray.300',
  borderRadius: 'default',
  compDisplay: 'flex',
  compHeight: rootTheme.space(4.5),
  compWidth: rootTheme.space(4.5),
  cursor: 'pointer',
  justifyContent: 'center',
};

export const StyledIconSelectOptionsContainer = styled(Box)``;

StyledIconSelectOptionsContainer.defaultProps = {
  backgroundColor: 'white',
  border: 'solid 1px',
  borderColor: 'gray.300',
  borderRadius: 'default',
  boxShadow: 'md',
  compDisplay: 'grid',
  compWidth: 'fit-content',
  gridTemplateColumns: 'repeat(6, 44px)',
};

export const StyledIconSelectOptionIcon = styled(Icon)`
  border: solid 2px;
  border-radius: 50%;
  border-color: white;
  transition: all 300ms;
`;

export const StyledIconSelectOption = styled(Box)`
  &:hover {
    & ${StyledIconSelectOptionIcon} {
      border-color: ${({ theme }) => theme.colors.v1.gray[300]};
    }
  }
`;

StyledIconSelectOption.defaultProps = {
  alignItems: 'center',
  backgroundColor: 'transparent',
  compDisplay: 'flex',
  compHeight: rootTheme.space(5.5),
  compWidth: rootTheme.space(5.5),
  cursor: 'pointer',
  justifyContent: 'center',
};
