import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { UserModel } from '@api/user/UserModel';

export interface SSODomain {
  domain: string;
}

export class OrganizationSSOModel {
  static objectType: string = 'organizationsso';

  objectType: string = OrganizationSSOModel.objectType;

  static typeDisplay: string = 'OrganizationSSO';

  typeDisplay: string = OrganizationSSOModel.typeDisplay;

  guid!: string;

  name!: string;

  org!: string;

  @Expose({ name: 'connection_type' })
  connectionType!: string;

  @Expose({ name: 'connection_id' })
  connectionId!: string;

  @Expose({ name: 'connection_state' })
  connectionState!: string;

  @Expose({ name: 'updated_on' })
  @Transform((value) => value && moment(value))
  updatedOn?: moment.Moment;

  @Expose({ name: 'user_created_by' })
  @Type(() => UserModel)
  userCreatedBy?: UserModel;

  @Expose()
  domains!: SSODomain[];

  get fullName() {
    return this.name;
  }
}
