import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import invalidateCache from '@api/invalidateCache';
import rawTransform from '@api/rawTransform';
import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import { usePostTerms } from '@api/terms';
import { TermModel } from '@api/terms/TermModel';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import RichTextDescriptionEditor from '@components/RichTextDescriptionEditor';
import { RichTextEditorElement } from '@components/RichTextEditor/RichTextEditor.types';
import TaggedItems from '@components/TaggedItems';
import { renderErrorToast, renderInfoToast } from '@components/Toast';
import InputLabel from '@components/UI/Form/InputLabel';
import Icon from '@components/UI/Icon';
import Modal, { ModalContent, ModalFooter, ModalHeader } from '@components/UI/Modal';
import type { Option } from '@components/UI/Select';
import useBulkEditSelected from '@hooks/useBulkEditSelected';
import theme from '@styles/theme';

import { StyledButton } from './CreateTermModal.styles';

export const TERM_DESCRIPTION_ELEMENTS: RichTextEditorElement[] = [
  'bold',
  'italic',
  'underline',
  'mention',
  'li',
  'ul',
  'ol',
  'link',
];
interface CreateTermModalProps {
  onClose: () => void;
}

interface TermValues {
  description: string;
  name: string;
  richTextDescription: string;
  tags: string[];
}

interface TermValuesError {
  description?: string;
  name?: string;
  richTextDescription?: string;
}

const CreateTermModal: React.FC<CreateTermModalProps> = ({ onClose }) => {
  const [error, setError] = useState<TermValuesError | null>(null);
  const { selected, setSelected, tagsCounts } = useBulkEditSelected<TermModel>();

  const history = useHistory();

  const { isLoading, mutate: createTerm } = usePostTerms({
    onError: (e) => {
      renderErrorToast(e?.data?.name ?? e?.data?.detail ?? 'There was an error saving the term.');
    },
    onSuccess: (data) => {
      invalidateCache((keys) => [keys.documents.all, keys.terms.all]);
      renderInfoToast(`${data.name} term added.`);
      history.push('/docs/tabs/glossary');
      onClose();
    },
  });

  const validateForm = (values: TermValues) => {
    if (!values.name) {
      setError({ name: 'Name is required' });
      return false;
    }
    setError(null);
    return true;
  };

  const { handleChange, handleSubmit, setValues, values } = useForm<TermValues>({
    initialValues: {
      description: '',
      name: '',
      richTextDescription: '',
      tags: [],
    },
    onSubmit: (v) => {
      const isValid = validateForm(v);
      if (isValid) {
        createTerm({
          description: v.description,
          name: v.name,
          richtext_description: v.richTextDescription,
          tags: v.tags,
        });
      }
    },
  });

  const handleDescriptionChange = (richTextDescription: string, description?: string) => {
    setValues((prevValues) => ({
      ...prevValues,
      description: description || '',
      richTextDescription: richTextDescription || '',
    }));
  };

  const handleTagsChange = (selectedTags: Option[]) => {
    const taggedItems: TaggedItemModel[] = selectedTags.map((tag) =>
      rawTransform(TaggedItemModel)({
        guid: tag.value,
        kind: 'user-defined',
        tag: {
          color: tag.iconColor,
          guid: tag.value,
          icon: tag.icon,
          name: tag.text,
        },
      }),
    );
    setSelected([{ guid: 'new_term', objectType: 'term', taggedItems }] as TermModel[]);
    setValues((prevValues) => ({
      ...prevValues,
      tags: selectedTags.map((tag) => tag.value as string),
    }));
  };

  const handleOnSave = (e: React.MouseEvent) => {
    e.preventDefault();
    handleSubmit();
  };

  return (
    <Modal onClose={onClose}>
      <ModalHeader onClose={onClose} title="Create a Term" />
      <Form isLoading={isLoading}>
        <ModalContent maxHeight={theme.space(80)}>
          <Box compDisplay="flex" flexDirection="column" my={2}>
            <InputLabel color="gray.700" compDisplay="grid" fontWeight="medium" mb={1}>
              Name
              <Input
                autoFocus
                error={Boolean(error?.name)}
                helperText={error?.name}
                name="name"
                onChange={handleChange}
                placeholder="Enter name here"
                type="text"
                value={values.name}
              />
            </InputLabel>
            <InputLabel color="gray.700" cursor="default" fontWeight="medium" mb={1} mt={1.25}>
              Description
            </InputLabel>
            <RichTextDescriptionEditor
              allowedElements={TERM_DESCRIPTION_ELEMENTS}
              descriptions={{
                description: values.description,
                richTextDescription: values.richTextDescription,
              }}
              disableSaveOnEnter
              editIconVariant="always"
              editMode
              fontSize="body2"
              isEditable
              maxHeight={theme.space(45)}
              onDescriptionChange={handleDescriptionChange}
              placeholder="Enter the description here"
              shouldFocusOnEdit={false}
              shouldStartFocused={false}
              title=""
              variant="inline"
            />
            <Box alignItems="center" compDisplay="flex" gap={1} mt={2.7}>
              <Box compWidth="max-content">
                <TaggedItems
                  disableTagsUpdate
                  isEditable
                  objects={selected.items}
                  onSave={handleTagsChange}
                  renderCustomAnchor={({ anchorProps, anchorRef }) => (
                    <Box ref={anchorRef} mr={1}>
                      <StyledButton
                        {...anchorProps}
                        color="gray.700"
                        disabled={isLoading}
                        startIcon={<Icon color="gray.700" name="tag-outline" size="16px" />}
                        type="button"
                        variant="text"
                      >
                        Add a tag
                      </StyledButton>
                    </Box>
                  )}
                  taggedItemsCounts={tagsCounts}
                />
              </Box>
            </Box>
          </Box>
        </ModalContent>
        <ModalFooter>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleOnSave}>Save</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CreateTermModal;
