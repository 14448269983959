import React from 'react';

import { useFetchColumn } from '@api/columns';
import Box from '@components/Box';
import PageColumnInfo from '@components/PageColumnInfo';
import PageColumnTemplate from '@components/PageColumnTemplate';
import QueriesJoinsTab from '@components/TabContent/QueriesJoinsTab';
import type { TabRoutesConfig } from '@components/TabsRouter/TabsRouter';
import Icon from '@components/UI/Icon';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import stripSpaces from '@utils/stripSpaces';

import ColumnExploreTab from './ColumnExploreTab';
import ColumnLineageTab from './tabs/ColumnLineageTab';

const query = stripSpaces(`{
  guid,
  name,
  search_name,
  description,
  data_types,
  richtext_description,
  suggested_description,
  suggested_description_source_object,
  data_type,
  external_type,
  popularity,
  tagged_items,
  is_join_key,
  is_foreign_key,
  is_metric,
  is_mention,
  is_primary_key,
  is_nullable,
  is_unique,
  foreign_keys,
  extra_data,
  table {
    guid,
    name,
    database {
      guid,data_source {
        guid,
        type
      }
    }
  },
  upstream_objects_counts,
  downstream_objects_counts,
  breadcrumbs,
  description_source,
  ai_description,
  ingested_description,
  suggested_description_source,
  user_description
}`);

interface ColumnPageProps {
  dsGuid?: string;
  guid: string;
  hideCategoryTags?: boolean;
  isDataSourceEditable: boolean;
  onClose: () => void;
}

const ColumnPage: React.FC<ColumnPageProps> = ({
  dsGuid,
  guid,
  hideCategoryTags,
  isDataSourceEditable,
  onClose,
}) => {
  const { isEditable: isObjectEditable } = useObjectPermissionsContext({ id: guid });

  const { data, error, isError, isLoading } = useFetchColumn(guid, {
    enabled: Boolean(guid),
    params: { force_showing_suggested_description: true, query },
  });

  const upstreamCount = data?.upstreamObjectsCounts?.total;
  const downstreamCount = data?.downstreamObjectsCounts?.total;
  const downstreamChartsCount = data?.downstreamObjectsCounts?.chart;
  const isEditable = isDataSourceEditable && isObjectEditable;

  const tabsRouterConfig: TabRoutesConfig[] = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        <ColumnLineageTab
          assetName={data?.name ?? ''}
          downstreamChartsCount={downstreamChartsCount}
          downstreamCount={downstreamCount}
          guid={guid}
          nodeKey={`${data?.table?.guid}/${data?.guid}`}
          upstreamCount={upstreamCount}
          upstreamTotalAllDepths={data?.upstreamObjectsCounts?.totalAllDepths}
        />
      ),
      contentOffset: 0.8,
      default: true,
      label: 'Lineage',
      route: '/lineage',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        <QueriesJoinsTab
          dataSourceGuid={dsGuid ?? ''}
          guid={guid}
          itemMetadata={data?.table!}
          tableGuid={data?.table?.guid ?? ''}
        />
      ),
      contentOffset: 0.8,
      label: 'Queries & Joins',
      route: '/queries',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        <ColumnExploreTab
          dataSourceType={data?.table?.database?.dataSource?.type}
          downstreamCount={downstreamCount}
          startingColumnId={guid}
          startingTableId={data?.table?.guid ?? ''}
          upstreamCount={upstreamCount}
        />
      ),
      label: (
        <Box alignItems="center" compDisplay="flex" gap={0.5}>
          Explore <Icon color="currentColor" name="open-new-tab" size="14px" />
        </Box>
      ),
      openInNewTab: Boolean(process.env.REACT_APP_CHROME_EXTENSION_BUILD),
      route: '/lineage-explore',
      variant: 'filled',
    },
  ];

  return (
    <PageColumnTemplate
      header={
        <PageColumnInfo
          guid={guid}
          hideCategoryTags={hideCategoryTags}
          isEditable={isEditable}
          item={data}
        />
      }
      isAuthorized={!(error && error?.status === 403)}
      isError={isError || !guid}
      isLoading={isLoading}
      onClose={onClose}
      tabsRouterConfig={tabsRouterConfig}
      title={data?.name}
    />
  );
};

export default ColumnPage;
