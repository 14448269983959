import moment from 'moment';

import { ColumnModel } from '@api/columns/ColumnModel';
import { DataQualityTest } from '@api/openapi.generated';
import { TableModel } from '@api/tables/TableModel';
import { rawClassMap } from '@api/utils';
import mapDataTypesModel from '@models/DataTypesModel/DataTypesModel.v1';
import { Reference } from '@models/Reference';

import DbtTestModel from '../dbt/DbtTestModel';
import { MonitorModel } from '../monitors/MonitorsModel';

const objectType: string = 'dataqualitytest';

type DataQualityObject = MonitorModel | DbtTestModel;

const mapDataQualityTestModel = (data: DataQualityTest) => {
  const dataQualityObject = rawClassMap<Reference<DataQualityObject>>(
    Reference,
    data.data_quality_object,
  );
  return {
    column: rawClassMap(ColumnModel, data.column),
    dataQualityObject,
    dataTypes: mapDataTypesModel(data.data_types),
    distance: data.distance,
    guid: dataQualityObject.obj?.guid,
    latestRunAt: data.last_run_at ? moment(data.last_run_at) : undefined,
    sourceType: data.source_type,
    status: data.status,
    table: rawClassMap(TableModel, data.table),
  };
};

export default mapDataQualityTestModel;

mapDataQualityTestModel.objectType = objectType;

export type DataQualityTestModel = ReturnType<typeof mapDataQualityTestModel>;
