import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import type { UseMutationOptions } from '@hooks/useMutation';
import useMutation from '@hooks/useMutation';
import fetchClient from '@lib/fetchClient';
import { HierarchyData, HierarchyModel } from '@models/HierarchyModel';
import { PaginatedResponse } from '@models/PaginatedResponse';
import { WorkspaceItemModel } from '@models/WorkspaceItemModel';

import paginatedTransform from './paginatedTransform';
import rawTransform from './rawTransform';

export const workspacesCacheKeys = {
  hierarchy: ['workspaces', 'hierarchy'],
  teamWorkspaces: (id: string) => ['teamWorkspaces', id],
  workspace: (id: string) => ['workspaces', id],
  workspaces: ['workspaces'],
};

const fetchWorkspacesHierarchySelect = rawTransform(HierarchyModel);
export const useFetchWorkspacesHierarchy = (options?: UseFetchOptions<HierarchyData>) => {
  return useFetch<HierarchyData>({
    ...options,
    queryKey: [...workspacesCacheKeys.hierarchy],
    select: fetchWorkspacesHierarchySelect,
    url: '/workspaces/default/hierarchy/',
  });
};

const fetchWorkspacesSelect = paginatedTransform(WorkspaceItemModel);
export const useFetchWorkspaces = (
  id: string,
  options?: UseFetchOptions<PaginatedResponse<WorkspaceItemModel>>,
) => {
  return useFetch<PaginatedResponse<WorkspaceItemModel>>({
    ...options,
    queryKey: [...workspacesCacheKeys.workspaces, options?.params],
    select: fetchWorkspacesSelect,
    url: `/workspaces/items/${id}/`,
  });
};

const fetchTeamWorkspacesSelect = paginatedTransform(WorkspaceItemModel);
export const useFetchTeamWorkspaces = (
  id: string,
  options?: UseFetchOptions<PaginatedResponse<WorkspaceItemModel>>,
) => {
  return useFetch<PaginatedResponse<WorkspaceItemModel>>({
    ...options,
    queryKey: [...workspacesCacheKeys.teamWorkspaces(id), options?.params],
    select: fetchTeamWorkspacesSelect,
    url: `/teams/workspaces/items/${id}/`,
  });
};

export const usePostWorkspaces = (id: string, options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'POST',
    onSuccess: (data, variables, context) => {
      fetchClient.invalidateQueries(workspacesCacheKeys.hierarchy);
      options?.onSuccess?.(data, variables, context);
    },
    url: `/workspaces/items/${id}/`,
  });
};

export const useDeleteWorkspace = (id: string, itemId: string, options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'DELETE',
    onSuccess: (data, variables, context) => {
      fetchClient.invalidateQueries(workspacesCacheKeys.hierarchy);
      options?.onSuccess?.(data, variables, context);
    },
    url: `/workspaces/items/${id}/${itemId}/`,
  });
};
