import React from 'react';
import getDebug from 'debug';

import { usePatchMetadataShowHide } from '@api/metadata';
import Button from '@components/Button/Button';
import TableList from '@components/TableList';
import Text from '@components/Text';
import { renderErrorToast, renderInfoToast } from '@components/Toast';
import Modal, { ModalContent, ModalFooter, ModalHeader } from '@components/UI/Modal';
import { MetadataData } from '@models/MetadataModel';

const debug = getDebug('selectstar:hide-items');
interface ContentConfig {
  buttonText: string;
  text: string;
  title: string;
  toastErrorText: string;
  toastSuccessText: string;
}

const contentMap: Record<'hide' | 'show', ContentConfig> = {
  hide: {
    buttonText: 'Hide',
    text: 'Are you sure you want to hide the following from Select Star? Once hidden, they will not appear in the search results or any list pages by default.',
    title: 'Hide from Select Star',
    toastErrorText: 'There was a problem hiding these elements',
    toastSuccessText: 'Data objects hidden',
  },
  show: {
    buttonText: 'Unhide',
    text: 'Are you sure you want to show the following in Select Star?',
    title: 'Unhide from Select Star',
    toastErrorText: 'There was a problem unhiding these elements',
    toastSuccessText: 'Data objects unhidden',
  },
};

interface ToggleHideItemsModalProps {
  isShowItemsModal?: boolean;
  onClose?: () => void;
  reloadData?: () => void;
  selectedItems: MetadataData[];
}

const ToggleHideItemsModal: React.FC<ToggleHideItemsModalProps> = ({
  isShowItemsModal,
  onClose,
  reloadData,
  selectedItems,
}) => {
  const action = isShowItemsModal ? 'show' : 'hide';
  const { buttonText, text, title, toastErrorText, toastSuccessText } = contentMap[action];

  const { isLoading, mutate } = usePatchMetadataShowHide(action, {
    onError: (err) => {
      renderErrorToast(err?.data?.detail ?? toastErrorText);
      onClose?.();
    },
    onSuccess: () => {
      debug('Got Valid response');
      renderInfoToast(toastSuccessText);
      reloadData?.();
      onClose?.();
    },
  });

  const handleSubmit = () => {
    debug('Hide Metadata:submit');
    const guids = selectedItems.map((item) => item.guid);
    const data = { items: guids };
    mutate(data);
  };

  return (
    <Modal onClose={onClose}>
      <ModalHeader onClose={onClose} title={title} />
      <ModalContent py={2}>
        <Text color="gray.700" fontSize="body2" lineHeight={1.5} mb={1}>
          {text}
        </Text>
        <TableList items={selectedItems} />
      </ModalContent>
      <ModalFooter>
        <Button disabled={isLoading} onClick={handleSubmit}>
          {buttonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ToggleHideItemsModal;
