import React from 'react';
import { useHistory } from 'react-router-dom';

import { syncBackCacheKeys, useDeleteSyncBackRepository } from '@api/syncBack';
import { RepositoryModel } from '@api/syncBack/RepositoryModel';
import DeleteConfirmationModal from '@components/Modal/DeleteConfirmationModal/DeleteConfirmationModal';
import { renderInfoToast } from '@components/Toast';
import fetchClient from '@lib/fetchClient';

export interface DeleteRepositoryConfirmationModalProps {
  onClose?: () => void;
  redirectOnDelete?: boolean;
  repository: RepositoryModel;
}

const DeleteRepositoryConfirmationModal: React.FC<DeleteRepositoryConfirmationModalProps> = ({
  onClose,
  redirectOnDelete,
  repository,
}) => {
  const history = useHistory();

  const {
    error,
    isLoading,
    mutate: deleteRepository,
  } = useDeleteSyncBackRepository(repository.guid, {
    onSuccess: () => {
      renderInfoToast(`Git Repo ${repository.name} is deleted`);
      fetchClient.invalidateQueries(syncBackCacheKeys.syncBack);
      if (redirectOnDelete) {
        history.replace('/admin/apps');
      } else {
        onClose?.();
      }
    },
  });

  return (
    <DeleteConfirmationModal
      error={error?.data?.detail || error?.data?.non_field_errors || error?.message}
      loading={isLoading}
      onClose={onClose}
      onRemove={() => deleteRepository(null)}
      title="Remove Git Repo"
    >
      Are you sure you want to delete this Git Repo?
    </DeleteConfirmationModal>
  );
};

export default DeleteRepositoryConfirmationModal;
