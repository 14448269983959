import styled from '@emotion/styled';
import { Resizable, ResizableProps } from 're-resizable';

import Box from '@components/Box';
import rootTheme from '@styles/theme';

import { StyledCloseButton } from '@components/CloseButton/CloseButton.styles';

export const StyledSidebar = styled(Box)``;

StyledSidebar.defaultProps = {
  compDisplay: 'flex',
  compHeight: '100%',
};

export const StyledSidebarOptionsContainer = styled(Box)`
  border-right: 1px solid ${({ theme }) => theme.colors.v1.gray[200]};
`;

StyledSidebarOptionsContainer.defaultProps = {
  alignItems: 'center',
  backgroundColor: 'white',
  compDisplay: 'flex',
  compHeight: '100%',
  compWidth: rootTheme.space(6.5),
  flexDirection: 'column',
  gap: 1,
  py: 1.5,
};

interface StyledSidebarOptionProps {
  isSelected?: boolean;
}

export const StyledSidebarOption = styled(StyledCloseButton)<StyledSidebarOptionProps>`
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.v1.primary[50] : theme.colors.white};

  &:hover {
    background-color: ${({ isSelected, theme }) =>
      isSelected ? theme.colors.v1.primary[50] : theme.colors.v1.gray[100]};
  }
`;

export const StyledSidebarTabContainer = styled(Resizable as React.ComponentClass)<ResizableProps>`
  border-right: 1px solid ${({ theme }) => theme.colors.v1.gray[300]};
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  min-width: ${({ theme }) => theme.space(40)};
`;
