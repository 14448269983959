import { Expose, Transform, Type } from 'class-transformer';

import { BIFolderModel } from '@api/bifolder/BIFolderModel';
import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import type { DescriptionSource } from '@api/description/description.types';
import { LookMLModel, LookMLModelData } from '@api/lookML/LookMLModel';
import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import { TeamModel } from '@api/teams/TeamModel';
import { ObjectType } from '@api/types';
import { UserModel } from '@api/user/UserModel';
import { MetadataObjectType } from '@atoms';
import { breadcrumbsToLabelList, breadcrumbsToList } from '@components/Breadcrumbs';
import isEmptyRichText from '@components/RichTextEditor/helpers/isEmptyRichText';
import type { DataSourceTypesType } from '@models/DataSourceCredentials';
import DataTypesModel from '@models/DataTypesModel';
import { PopularityData, PopularityModel } from '@models/PopularityModel';
import { Reference } from '@models/Reference';
import RelatedObjectsCountsModel from '@models/RelatedObjectsCountsModel';
import { urlFor } from '@utils/routing';

export interface LookerExploreData {
  description?: string;
  externalUrl?: string;
  groupLabel?: string;
  guid: string;
  isHidden?: boolean;
  lookMLModel?: LookMLModelData;
  name: string;
  popularity?: PopularityData;
  projectName: string;
  rawName: string;
}

export class LookerExploreModel {
  static objectType: ObjectType = 'explore';

  objectType: ObjectType = LookerExploreModel.objectType;

  objectTypeV1: MetadataObjectType = 'explores';

  static typeDisplay: string = 'Explore';

  typeDisplay: string = LookerExploreModel.typeDisplay;

  guid!: string;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel = new DataTypesModel();

  @Expose({ name: 'label' })
  name!: string;

  @Expose({ name: 'search_name' })
  fullName?: string;

  @Expose({ name: 'name' })
  rawName!: string;

  description?: string;

  // TODO: Remove transform https://app.shortcut.com/select-star/story/55620
  @Expose({ name: 'richtext_description' })
  @Transform((richtextDescription) =>
    isEmptyRichText(richtextDescription) ? '' : richtextDescription,
  )
  richtextDescription?: string;

  suggestedDescriptionSource?: undefined;

  suggestedDescriptionSourceObject?: undefined;

  @Expose({ name: 'url' })
  externalUrl?: string;

  @Expose({ name: 'lookml_model' })
  @Type(() => LookMLModel)
  lookMLModel?: LookMLModel;

  @Expose({ name: 'group_label' })
  groupLabel?: string;

  @Type(() => BIFolderModel)
  group?: BIFolderModel;

  @Expose({ name: 'project_name' })
  projectName!: string;

  @Expose({ name: 'technical_owner' })
  @Type(() => Reference)
  technicalOwner?: Reference<UserModel | TeamModel>;

  @Expose({ name: 'business_owner' })
  @Type(() => Reference)
  businessOwner?: Reference<UserModel | TeamModel>;

  /** @todo Architecture. Why for some reason dataType is undefined. */
  @Expose({ name: 'data_type' })
  dType?: string;

  @Expose({ name: 'data_source_type' })
  dataSourceType?: DataSourceTypesType;

  /** @todo Architecture. Why dataType for looker is undefined. Same for TableauDataSourceModel. */
  get dataType() {
    return this.dType || ('explore' as const);
  }

  @Expose({ name: 'is_hidden' })
  isHidden?: boolean;

  @Expose({ name: 'downstream_objects_counts' })
  @Type(() => RelatedObjectsCountsModel)
  downstreamObjectsCounts?: RelatedObjectsCountsModel;

  @Expose({ name: 'upstream_objects_counts' })
  @Type(() => RelatedObjectsCountsModel)
  upstreamObjectsCounts?: RelatedObjectsCountsModel;

  @Expose({ name: 'tagged_items' })
  @Type(() => TaggedItemModel)
  taggedItems?: TaggedItemModel[];

  @Type(() => PopularityModel)
  popularity?: PopularityModel;

  get supTitle() {
    return this?.groupLabel;
  }

  get dataSourceGuid() {
    return this.lookMLModel?.dataSource?.guid;
  }

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  get breadcrumbList() {
    return breadcrumbsToList(this.breadcrumbs);
  }

  get breadcrumbLabelList() {
    const url = urlFor(this, false, '');
    return breadcrumbsToLabelList(this.name, url, this.breadcrumbs);
  }

  owner = undefined;

  itemsType = 'explores' as const;

  bytes = undefined;

  rowCount = undefined;

  lastRefreshed = undefined;

  materialization = undefined;

  showLinksTo = undefined;

  linksToObjects = undefined;

  showLinkedFrom = false;

  linkedFromObjects = undefined;

  showMentionedBy = true;

  showTableLastQuery = undefined;

  loadingStatus = undefined;

  lastUpdated = undefined;

  created = undefined;

  suggestedDescription = undefined;

  connections = undefined;

  lastRun = undefined;

  color = undefined;

  showEditTags = true;

  showCustomAttributes = true;

  cloudObject = undefined;

  rawSql = undefined;

  showDescriptionSelector = true;

  @Expose({ name: 'description_source' })
  descriptionSource?: DescriptionSource;

  @Expose({ name: 'ai_description' })
  aiDescription?: string;

  @Expose({ name: 'ingested_description' })
  ingestedDescription?: string;

  @Expose({ name: 'user_description' })
  userDescription?: string;

  type = undefined;
}
