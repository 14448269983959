import { Expose, Type } from 'class-transformer';

import { TableModel } from '@api/tables/TableModel';

export class RelatedTableModel {
  static objectType: string = 'relatedtable';

  objectType: string = RelatedTableModel.objectType;

  static typeDisplay: string = 'Related Table';

  typeDisplay: string = RelatedTableModel.typeDisplay;

  @Expose({ name: 'related_table' })
  @Type(() => TableModel)
  relatedTable!: TableModel;

  count!: number;
}
