import React, { useEffect } from 'react';

import { usePostSSOSetup } from '@api/sso';
import { SSODomain } from '@api/sso/OrganizationSSOModel';
import { UserModel } from '@api/user/UserModel';
import Alert from '@components/Alert';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import Form from '@components/Form';
import { FormHorizontalLabelGrid } from '@components/Form/Form.styles';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import { renderErrorToast } from '@components/Toast';
import InputLabel from '@components/UI/Form/InputLabel';
import Modal, { ModalFooter, ModalHeader } from '@components/UI/Modal';
import { useUserContext } from '@context/User';

interface CreateSSOModalProps {
  onClose: () => void;
  targetDomains?: SSODomain[];
}

const getEmailDomain = (user: UserModel) => {
  if (!user.email) return '';
  return user.email.split('@')[1];
};

const CreateSSOModal: React.FC<CreateSSOModalProps> = ({ onClose, targetDomains }) => {
  const { user } = useUserContext();
  const { error, isLoading, mutate } = usePostSSOSetup({
    onError: () => {
      renderErrorToast('Operation failed');
    },
    onSuccess: (d) => {
      if (d) {
        window.location.assign(d);
      } else {
        renderErrorToast('Operation failed');
      }
    },
  });

  const { handleChange, handleSubmit, setValues, values } = useForm({
    initialValues: {
      domains: '' as string | undefined,
    },
    onSubmit: ({ domains }) => {
      // Split the domains into a list
      const domainList: string[] | undefined = domains
        ? domains?.split(',').map((item: string) => item.trim())
        : undefined;

      if (domainList) {
        mutate({ domains: domainList });
      }
    },
  });

  useEffect(() => {
    if (user) {
      setValues({
        domains: targetDomains?.map((e) => e.domain).join(', ') || getEmailDomain(user),
      });
    }
  }, [user, targetDomains, setValues]);

  return (
    <Modal onClose={onClose} size="medium">
      <ModalHeader onClose={onClose} title="Complete SSO Connection" />
      <Form isLoading={isLoading} onSubmit={handleSubmit}>
        <FormHorizontalLabelGrid gridTemplateColumns="auto 1fr">
          {targetDomains === undefined ? (
            <InputLabel compDisplay="contents" variantColor="modalForm">
              Domain
              <Input
                error={error?.data?.domains}
                helperText={error?.data?.domains}
                onChange={handleChange}
                placeholder="Domain for SSO, if multiple use comma"
                type="text"
                value={values.domains}
              />
            </InputLabel>
          ) : (
            <InputLabel compDisplay="contents" variantColor="modalForm">
              Domain (set by Organization)
              <Input
                disabled
                error={error?.data?.domains}
                helperText={error?.data?.domains}
                type="text"
                value={values.domains}
              />
            </InputLabel>
          )}
          {error?.message && (
            <Box gridColumn="1/3">
              <Alert title="Error" type="error">
                {error.message}
              </Alert>
            </Box>
          )}
        </FormHorizontalLabelGrid>
        <ModalFooter>
          <Button disabled={isLoading} type="submit">
            Connect via WorkOS
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CreateSSOModal;
