import React from 'react';

import { RBACPolicyModel, useDeleteRbacPolicy } from '@api/rbacPolicy';
import Box from '@components/Box';
import { renderInfoToast } from '@components/Toast';

import DeleteConfirmationModal from './DeleteConfirmationModal';

export interface DeletePolicyConfirmationModalProps {
  onClose?: () => void;
  onSuccess?: () => void;
  policy: RBACPolicyModel;
}

const DeletePolicyConfirmationModal: React.FC<DeletePolicyConfirmationModalProps> = ({
  onClose,
  onSuccess,
  policy,
}) => {
  const { error, isLoading, mutate } = useDeleteRbacPolicy(policy.guid, {
    onSuccess: () => {
      renderInfoToast('Policy Deleted');
      onSuccess?.();
      onClose?.();
    },
  });

  return (
    <DeleteConfirmationModal
      error={error?.data?.detail || error?.data?.non_field_errors || error?.message}
      loading={isLoading}
      onClose={onClose}
      onRemove={() => mutate(null)}
      title="Remove Policy"
    >
      <Box as="span">
        Are you sure you want to remove
        <Box as="span" fontWeight="bold">{` ${policy.name} `}</Box>
        from your organization?
      </Box>
    </DeleteConfirmationModal>
  );
};

export default DeletePolicyConfirmationModal;
