import { isTransformerType, isWarehouseType } from '@configs/dataSources/getType';
import { MarkerType } from 'reactflow';

import { NewLineageItem } from '@api/openapi.generated';
import { DATABASE_COLLAPSE_SCHEMA_MAX_TABLES_NUMBER } from '@components/Explore.v1/Explore.constants';
import { EdgeType, ExploreNode, InputNodesById } from '@components/Explore.v1/Explore.types';
import { OneDirectionParseExceptions } from '@components/Explore.v1/useExplore/Explore.context.types';
import { StackIndex } from '@components/ExploreTree/types';
import theme from '@styles/theme';

import { EdgesById, NodesById } from '../../algorithm/types';
import { createRedirectedEdgesToCollapsedNode } from '../createRedirectedEdges';
import traversal from '../traversal';

import { GroupNodeType, GroupNodeTypeConfigItem, NODE_TYPE_CONFIG } from './createStacks.constants';
import { biGroupParser, dwhGroupParser } from './parsers/groupParsers';
import { CreateStacksOptions, GroupParser } from './types';

const markerEnd = { color: theme.colors.v1.gray[400], type: MarkerType.Arrow };
const createWalkedEdgeId = (parentId?: string, id?: string) => [parentId, id].sort().join('-');

interface ActionArgs {
  direction?: 'left' | 'right';
  id: string;
  isInitialNode?: boolean;
  parentId?: string;
  parentStackId?: number;
  stackId: number;
  type?: EdgeType;
}

interface CreateStackArgs {
  initialStackId?: number;
  inputNodesById: InputNodesById;
  oneDirectionParseExceptions?: OneDirectionParseExceptions;
  options?: CreateStacksOptions;
  startingColumnGuid?: string;
  startingTableId: string;
}

const createStacks = ({
  initialStackId,
  inputNodesById,
  oneDirectionParseExceptions = {},
  options = {
    enableColumnEdges: false,
    enableHorizontalGroups: true,
    enableTableEdges: true,
    openAll: false,
    pivotToSkipCollapsing: undefined,
    shouldHideFilterLineage: false,
  },
  startingColumnGuid,
  startingTableId,
}: CreateStackArgs) => {
  const stackedAt: StackIndex = {};
  let nodesById: NodesById = {};
  let stackGroups: Record<number, Set<string>> = {};
  const dbNodes: Array<string> = [];
  const edgesById: EdgesById = {};
  const tableColumnEdges: string[] = [];
  const linkedObjects: Array<string> = [];
  const transformerTablesToCheck: Set<string> = new Set();
  const initiallyCollapsedTableNodes: Array<string> = [];
  let schemaKeyToSkipCollapsing: string | undefined;

  const { enableColumnEdges, enableTableEdges, openAll, pivotToSkipCollapsing } = options;

  const tryToStackIt = ({ id, stackId }: ActionArgs) => {
    // only loaded tables and only stack once
    if (inputNodesById?.[id] && typeof stackedAt[id] === 'undefined') {
      stackedAt[id] = stackId;

      const inputNode = inputNodesById[id];
      const metadata = inputNode?.metadata;
      const { dataTypes } = metadata;
      const { dataSourceType, findConfig } = dataTypes ?? {};
      const nodeConfig = findConfig?.<GroupNodeTypeConfigItem>(NODE_TYPE_CONFIG);

      if (dataSourceType && isTransformerType(dataSourceType)) {
        transformerTablesToCheck.add(id);
      }

      const nodeParserMap: Record<GroupNodeType, GroupParser> = {
        bi: biGroupParser,
        dwh: dwhGroupParser,
      };

      const nodeType =
        nodeConfig?.type ?? isWarehouseType(dataSourceType) ? GroupNodeType.dwh : GroupNodeType.bi;

      if (nodeType) {
        const parser = nodeParserMap[nodeType];
        const {
          dbNodes: parsedDbNodes = [],
          initiallyCollapsedTableNodes: parsedInitiallyCollapsedTableNodes = [],
          linkedObjects: parsedLinkedObjects,
          nodesById: parsedNodesById,
          schemaKeyToSkipCollapsing: parsedSchemaKeyToSkipCollapsing,
          stackGroups: parsedStackGroups,
        } = parser({
          currentNodesById: nodesById,
          currentStackGroups: stackGroups,
          id,
          inputNodesById,
          options,
          pivotToSkipCollapsing,
          stackId,
          startingColumnGuid,
          startingTableId,
        });

        initiallyCollapsedTableNodes.push(...parsedInitiallyCollapsedTableNodes);
        nodesById = { ...nodesById, ...parsedNodesById };
        stackGroups = parsedStackGroups;
        schemaKeyToSkipCollapsing = parsedSchemaKeyToSkipCollapsing;
        linkedObjects.push(...parsedLinkedObjects);
        dbNodes.push(...parsedDbNodes);
      }
    }
  };

  const createEdge = ({
    direction,
    id,
    parentId,
    parentStackId,
    stackId,
    type,
  }: Required<ActionArgs>) => {
    // Remove self references
    if (id === parentId) {
      return;
    }

    const edgeKey = createWalkedEdgeId(parentId, id);

    let sourceHandle = 'right';
    let targetHandle = 'left';
    let source = parentId;
    let target = id;
    const sourceTargetSameStack = parentStackId === stackId;
    const data = { sourceTargetSameStack, type };

    /**
     * Display edges on the right side when the target and object are in the same stack:
     * Target ^
     *        |
     * Source |
     */
    if (sourceTargetSameStack) {
      sourceHandle = 'right';
      targetHandle = 'right';
    }

    /*
     * When walking to the left and the source is at the left of the target, we need to switch the handles position to make them closer,
     * avoiding the edge from being overlapped by the nodes.
     * We don't want this:
     *         source (edge starts on the right side and goes to the left)
     *               /
     *              /
     *             /
     *            /
     *           /
     * target <-/ (edges finishes on the right side)
     * We want this:
     *              source  (edge starts on the left side and goes to the left)
     *             /
     *            /
     *           /
     * target <-/ (edge finishes on the right side)
     *
     * The same is valid when walking to the right, but it's the opposite: switch the handles position when the source is at the right of the target.
     */
    if (direction === 'left' && stackId > parentStackId) {
      sourceHandle = 'left';
      targetHandle = 'right';
    } else if (direction === 'right' && parentStackId > stackId) {
      sourceHandle = 'left';
      targetHandle = 'right';
    }

    /**
     * If algorithms move from right to left when parsing sources, we should still
     * show the direction pointing from left/upstream to right/downstream.
     * To achieve that, we can switch the source and target IDs.
     * Parsing: Left   <---- Starting ID ----> Right
     * Edges:   Source ----> Starting ID ----> Target
     */
    if (direction === 'left') {
      source = id;
      target = parentId;
    }

    edgesById[edgeKey] = {
      data,
      id: edgeKey,
      markerEnd,
      source,
      sourceHandle,
      target,
      targetHandle,
    };

    /*
     * When creating edges between a table and a column, there may be cases where the table stack has not been created yet.
     * This is not an issue for table-to-table edges because we recursively walk through all the tables. So, for an edge from table A to table B,
     * if we don't have the stack when creating the edges from table A, we will have it when we walk through table B.
     * However, for columns, we do not have this recursive walk. To handle this, we need to store the edge and handle it after having all the stacks created.
     */
    if (
      (inputNodesById[source].node_type === 'table' &&
        inputNodesById[target].node_type === 'column') ||
      (inputNodesById[target].node_type === 'table' &&
        inputNodesById[source].node_type === 'column')
    ) {
      tableColumnEdges.push(edgeKey);
    }
  };

  const action = (args: ActionArgs, currentNode: NewLineageItem) => {
    const { isInitialNode, parentId, parentStackId, stackId } = args;
    tryToStackIt(args);

    if (isInitialNode || (parentId !== undefined && parentStackId !== undefined)) {
      if (enableTableEdges && parentId) {
        const edge = currentNode?.target_edges?.[parentId] ?? currentNode?.source_edges?.[parentId];
        const edgeType = edge?.edge_type === 'manual' ? 'manual' : 'table';

        createEdge({
          ...args,
          type: edgeType,
        } as Required<ActionArgs>);
      }

      if (enableColumnEdges) {
        currentNode.columns?.forEach((columnId: string) => {
          const column = inputNodesById[columnId];

          if (column && !column?.shouldSkipEdgesCalculation) {
            Object.keys(column?.source_edges ?? {}).forEach((columnSourceId) => {
              if (
                inputNodesById[columnSourceId] &&
                !inputNodesById[columnSourceId]?.shouldSkipEdgesCalculation
              ) {
                const columnSourceParentId = inputNodesById[columnSourceId].metadata.parent_guid;
                const columnSourcePos = stackedAt[columnSourceParentId ?? ''];
                const edgeType =
                  column?.source_edges?.[columnSourceId] &&
                  column.source_edges[columnSourceId].edge_type === 'manual'
                    ? 'manual'
                    : 'table';

                if (args.id !== args.parentId) {
                  createEdge({
                    ...args,
                    id: columnId,
                    parentId: columnSourceId,
                    parentStackId: isInitialNode ? 0 : columnSourcePos,
                    stackId: isInitialNode ? 0 : stackId,
                    type: edgeType,
                  } as Required<ActionArgs>);
                }
              }
            });

            Object.keys(column?.target_edges ?? {}).forEach((columnTargetId) => {
              if (
                inputNodesById[columnTargetId] &&
                !inputNodesById[columnTargetId]?.shouldSkipEdgesCalculation
              ) {
                const columnTargetParentId = inputNodesById[columnTargetId].metadata.parent_guid;
                const columnTargetPos = stackedAt[columnTargetParentId ?? ''];
                const edgeType =
                  column?.target_edges?.[columnTargetId] &&
                  column.target_edges[columnTargetId].edge_type === 'manual'
                    ? 'manual'
                    : 'table';

                if (args.id !== args.parentId) {
                  createEdge({
                    ...args,
                    direction: args.direction ?? 'left',
                    id: columnId,
                    parentId: columnTargetId,
                    parentStackId: isInitialNode ? 0 : columnTargetPos,
                    stackId: isInitialNode ? 0 : stackId,
                    type: edgeType,
                  } as Required<ActionArgs>);
                }
              }
            });
          }
        });
      }
    }
  };

  const walkedNodes = traversal({
    action,
    initialNodeId: startingTableId,
    initialStackId,
    nodesById: inputNodesById,
    oneDirectionParseExceptions,
  });

  const uniqueDbNodes = new Set(dbNodes);

  /*
   * Remove transformer types linked tables (e.g. dbt linked tables)
   * Transformer types tables can be linked to other DWH tables. When the link exists, we want to show only the DWH table.
   * If all the dbt table nodes from a parent are removed, we also need to remove the parent nodes.
   */
  Array.from(transformerTablesToCheck).forEach((transformerTableId) => {
    if (linkedObjects.includes(transformerTableId)) {
      const node = nodesById[transformerTableId];

      const getParentsToRemove = (currentNode: ExploreNode): Array<string> => {
        const schemaParent = nodesById[currentNode?.data?.parent ?? ''];
        const databaseParent = nodesById[schemaParent?.data?.parent ?? ''];
        // If the parent has only one child, remove the parent
        if (schemaParent?.data?.children?.size === 1) {
          stackGroups[schemaParent.stackedAt!]?.delete(schemaParent.id);
          uniqueDbNodes.delete(schemaParent.id);
          return [schemaParent.id, ...getParentsToRemove(schemaParent)];
        }

        // If the parent has more then one child, remove the current node from the parent children and update the counts
        if (schemaParent?.data) {
          schemaParent.data.children?.delete(currentNode.id);
          schemaParent.data.tablesCount = schemaParent.data.tablesCount
            ? schemaParent.data.tablesCount - 1
            : 0;
          schemaParent.data.childrenCount = schemaParent.data.childrenCount
            ? schemaParent.data.childrenCount - 1
            : 0;
        }
        if (databaseParent?.data) {
          databaseParent.data.tablesCount = databaseParent.data.tablesCount
            ? databaseParent.data.tablesCount - 1
            : 0;
        }

        return [];
      };

      const parentsToRemove = getParentsToRemove(node);
      const relatedNodesToRemove = Object.keys(nodesById).filter((key) =>
        key.includes(transformerTableId),
      );
      const nodesToRemove = [transformerTableId, ...parentsToRemove, ...relatedNodesToRemove];
      const edgesToRemove = Object.keys(edgesById).filter((key) =>
        key.includes(transformerTableId),
      );

      nodesToRemove.forEach((nodeId) => {
        delete nodesById[nodeId];
      });

      edgesToRemove.forEach((edgeId) => {
        delete edgesById[edgeId];
      });
    }
  });

  let collapsedEdges: EdgesById = {};

  // Create redirected edges to collapsed DWH nodes
  if (!openAll) {
    Array.from(uniqueDbNodes).forEach((dbNodeKey) => {
      const node = nodesById[dbNodeKey];
      const dbTableCounts = node.data.tablesCount ?? 0;

      if (dbTableCounts > DATABASE_COLLAPSE_SCHEMA_MAX_TABLES_NUMBER) {
        Array.from(node.data.children ?? []).forEach((schemaNodeKey) => {
          if (schemaNodeKey !== schemaKeyToSkipCollapsing && nodesById[schemaNodeKey]) {
            nodesById[schemaNodeKey].data.isOpen = false;
            const { edges: collapsedSchemaEdges } = createRedirectedEdgesToCollapsedNode({
              edgesById,
              inputNodeId: schemaNodeKey,
              nodesById,
            });

            collapsedEdges = { ...collapsedEdges, ...collapsedSchemaEdges };
          }
        });
      }
    });
  }

  // Create redirected edges to collapsed tables
  const uniqueInitiallyCollapsedTableNodes = Array.from(new Set(initiallyCollapsedTableNodes));
  for (let i = 0; i < uniqueInitiallyCollapsedTableNodes.length; i += 1) {
    const collapsedTableKey = uniqueInitiallyCollapsedTableNodes[i];

    nodesById[collapsedTableKey].data.isOpen = false;
    const { edges: collapsedTableEdges } = createRedirectedEdgesToCollapsedNode({
      edgesById,
      inputNodeId: collapsedTableKey,
      nodesById,
    });

    collapsedEdges = { ...collapsedEdges, ...collapsedTableEdges };
  }

  // Update source and target handles for table-column edges, based on the stack position
  tableColumnEdges.forEach((edgeId) => {
    const { source, target } = edgesById[edgeId];

    const sourceParent =
      inputNodesById[source].node_type === 'column'
        ? inputNodesById[source].metadata.parent_guid
        : undefined;

    const targetParent =
      inputNodesById[target].node_type === 'column'
        ? inputNodesById[target].metadata.parent_guid
        : undefined;

    const sourceStackId = sourceParent ? stackedAt[sourceParent] : stackedAt[source];
    const targetStackId = targetParent ? stackedAt[targetParent] : stackedAt[target];

    let sourceHandle = 'right';
    let targetHandle = 'right';
    if (sourceStackId < targetStackId) {
      targetHandle = 'left';
    } else if (targetStackId > sourceStackId) {
      sourceHandle = 'left';
    }

    edgesById[edgeId] = {
      ...edgesById[edgeId],
      data: {
        ...edgesById[edgeId].data,
        sourceTargetSameStack: sourceStackId === targetStackId,
      },
      sourceHandle,
      targetHandle,
    };
  });

  return {
    edgesById: { ...edgesById, ...collapsedEdges },
    nodesById,
    stackGroups,
    stackedAt,
    startingTableId,
    walkedNodes,
  };
};

export default createStacks;
