import React from 'react';

import Box, { StyledBoxProps } from '@components/Box';
import CloseButton from '@components/CloseButton';

import { StyledModalHeader, StyledModalSubTitle, StyledModalTitle } from './Modal.styles';

export interface ModalHeaderProps extends StyledBoxProps {
  onClose?: () => void;
  showCloseButtonHover?: boolean;
  subtitle?: string;
  title?: React.ReactNode;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({
  children,
  onClose,
  showCloseButtonHover,
  subtitle,
  title,
  ...others
}) => {
  return (
    <StyledModalHeader {...others}>
      <Box compDisplay="flex" flexDirection="column" flexGrow={1}>
        <Box alignItems="center" compDisplay="flex" flexGrow={1}>
          {title && <StyledModalTitle>{title}</StyledModalTitle>}
        </Box>
        {subtitle && <StyledModalSubTitle>{subtitle}</StyledModalSubTitle>}
      </Box>
      {onClose && <CloseButton my={-2} onClose={onClose} />}
      {children}
    </StyledModalHeader>
  );
};

export default ModalHeader;
