import React, { useEffect } from 'react';

import useUserTracking from '@components/Explore.v1/useUserTracking';
import { GridContainer } from '@components/Grid';
import Text from '@components/Text';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import MetadataDecorator from '@utils/MetadataDecorator';

const ExtensionSignIn: React.FC = () => {
  const { track } = useUserTracking();

  useEffect(() => {
    track(SegmentTrackEventName.ExtensionSignedIn);
  }, [track]);

  return (
    <>
      <MetadataDecorator title="Extension Sign In" />
      <GridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <Text as="span" fontSize="20px" fontWeight="medium">
          Successfully signed in to the extension. You can use it now by clicking on the Select Star
          extension icon in the Chrome extensions toolbar.
        </Text>
      </GridContainer>
    </>
  );
};

export default ExtensionSignIn;
