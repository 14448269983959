import { camelCase, capitalize } from 'lodash';

const filterDisabledIntegrations = (option: { value: string }, settings: Record<string, any>) => {
  const settingKey = `use${capitalize(camelCase(option.value))}`; // ex: useSnowflake
  const settingValue = settings?.[settingKey];

  if (settingKey in settings && settingValue !== undefined) return settingValue;
  if (option.value === 'monte_carlo') return false;
  return true;
};

export default filterDisabledIntegrations;
