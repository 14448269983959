import useFetch, { UseFetchOptions } from '@hooks/useFetch';

import rawTransform from '../rawTransform';

import { UserListModel } from './UserListModel';

export const userGroupsCacheKeys = {
  itemUserLists: (itemId: string) => ['userLists', itemId],
  userLists: ['userLists'],
};

export const fetchItemUserListsSelect = rawTransform(UserListModel);
export const useFetchItemUserLists = (
  itemId: string,
  options?: UseFetchOptions<UserListModel[]>,
) => {
  return useFetch<UserListModel[]>({
    ...options,
    queryKey: [...userGroupsCacheKeys.itemUserLists(itemId), options?.params],
    select: fetchItemUserListsSelect,
    url: `/user-lists/users/${itemId}/`,
  });
};
