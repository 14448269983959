import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';

import { UsageTypeType } from '@api/lineage/types';
import Box from '@components/Box';
import UnexpectedError from '@components/Error/UnexpectedError';
import ExploreLoader from '@components/Explore.v1/components/ExploreLoader';
import ExploreSidebar from '@components/Explore.v1/components/ExploreSidebar';
import ExploreTree from '@components/Explore.v1/components/ExploreTree';
import LineageInteractionsWrapper from '@components/Explore.v1/components/LineageInteractionsWrapper';
import useCreateNodesEdges from '@components/Explore.v1/useCreateNodesEdges';
import {
  DEFAULT_INITIAL_POSITION,
  ExploreContextProvider,
  useExplore,
} from '@components/Explore.v1/useExplore';
import Text from '@components/Text';
import Modal from '@components/UI/Modal';
import { useModal } from '@context/Modal';
import theme from '@styles/theme';

import ControlBar from './components/ControlBar';
import ExploreErrorMessage from './components/ExploreErrorMessage';
import { LineageInteractions } from './Explore.types';
import { getColumnIdFromKey, getNewUsageTypes } from './Explore.v1.utils';
import useColumnLevelLineage from './useColumnLevelLineage';
import useRemoveUsageType from './useRemoveUsageType';
import { UndoRedoContextProvider } from './useUndoRedo/UndoRedo.context';

interface ExploreProps {
  isError: boolean;
  isLoading: boolean;
  prevRoute: string;
}

const Explore: React.FC<ExploreProps> = ({ isError, isLoading, prevRoute }) => {
  const history = useHistory();
  const location = useLocation<any>();

  const { loadingState, nodesById, setLoadingState } = useExplore();

  const { MODAL_IDS, closeModal, openModal } = useModal();

  const handleClose = (_?: any, isUnmount?: boolean) => {
    closeModal(MODAL_IDS.explore);
    if (isUnmount) return;
    history.replace(
      `${prevRoute}${location.search.replace('?version=v0', '').replace('&version=v0', '')}`,
      {
        ...location.state,
        scrollToTop: false,
      },
    );
  };

  useEffect(() => {
    openModal(MODAL_IDS.explore);
  }, [MODAL_IDS.explore, openModal]);

  useEffect(() => {
    setLoadingState({ enabled: isLoading, type: 'initial' });
  }, [isLoading, setLoadingState]);

  let mainContent = null;

  if (isError) {
    mainContent = <ExploreErrorMessage />;
  } else if (Object.keys(nodesById).length === 0 && !loadingState.enabled) {
    mainContent = (
      <Box compDisplay="flex" compWidth="100%" justifyContent="center" mt={35}>
        <Text fontSize={theme.typography.fontSizes.h2}>No lineage detected.</Text>
      </Box>
    );
  } else {
    mainContent = <ExploreTree />;
  }

  return (
    <Modal
      borderRadius={0}
      onClose={handleClose}
      renderContent={({ modalHandleClose }) => <ControlBar onClose={modalHandleClose} />}
      size="full"
    >
      <Box compDisplay="flex" compHeight="100vh">
        <ExploreSidebar />
        {mainContent}
        <ExploreLoader state={loadingState} />
      </Box>
    </Modal>
  );
};

export const ExploreContextsWrapper: React.FC<{
  initialColumnGuid?: string;
  initialTableGuid: string;
}> = ({ children, initialColumnGuid, initialTableGuid }) => {
  return (
    <ReactFlowProvider>
      <ExploreContextProvider
        initialColumnGuid={initialColumnGuid}
        initialTableGuid={initialTableGuid}
      >
        <UndoRedoContextProvider>
          <LineageInteractionsWrapper>{children}</LineageInteractionsWrapper>
        </UndoRedoContextProvider>
      </ExploreContextProvider>
    </ReactFlowProvider>
  );
};

const ExploreDataRequestWrapper: React.FC<{
  columnId?: string;
  prevRoute: string;
  tableId: string;
}> = ({ columnId: propsColumnId, prevRoute, tableId }) => {
  const [shouldRefreshCurrentLineage, setShouldRefreshCurrentLineage] = useState(false);
  const isInitialColumnDataCreated = useRef(false);
  const isDataSaved = useRef(false);
  const currentUsageTypes = useRef<Set<UsageTypeType>>(new Set());
  const { createColumnNodes } = useColumnLevelLineage();
  const { removeUsageType } = useRemoveUsageType();

  const {
    columnLevelLineageRootKey,
    selectedUsageTypesState,
    setBiggestConflictEndPerStack,
    setEdgesById,
    setInitialPosition,
    setInputNodesById,
    setIsCollapseAllButtonEnabled,
    setIsColumnLevelLineage,
    setIsColumnLevelLoading,
    setNextUserInteraction,
    setNodesById,
    setNodesByStack,
    setStacksData,
    stackData,
  } = useExplore();

  const columnId = columnLevelLineageRootKey
    ? getColumnIdFromKey(columnLevelLineageRootKey)
    : propsColumnId;
  const columnKey = columnLevelLineageRootKey ?? (columnId ? `${tableId}/${columnId}` : undefined);

  const newUsageTypes = useMemo(
    () => getNewUsageTypes(currentUsageTypes.current, selectedUsageTypesState.usageTypes),
    [selectedUsageTypesState],
  );

  const {
    biggestConflictEndPerStack,
    columnInputNodesById,
    edgesById: initialEdgesById,
    inputNodesById,
    isFetchColumnLevelLoading,
    isFetchLineageError,
    isFetchLineageLoading,
    nodesById: initialNodesById,
    nodesByStack: initialNodesByStack,
    stacksData: initialStacksData,
  } = useCreateNodesEdges({
    columnGuid: propsColumnId,
    enabled: newUsageTypes.length > 0,
    tableGuid: tableId,
    usageTypes: selectedUsageTypesState.usageTypes,
  });

  useEffect(() => {
    if (!selectedUsageTypesState.shouldNotRecalculate) {
      if (newUsageTypes.length > 0) {
        setIsColumnLevelLineage(false);
        isDataSaved.current = false;
        isInitialColumnDataCreated.current = false;
        currentUsageTypes.current = new Set(selectedUsageTypesState.usageTypes);

        if (newUsageTypes.length === 1 && newUsageTypes[0] === 'none') {
          setShouldRefreshCurrentLineage((current) => !current);
        }
      } else if (isDataSaved.current) {
        setIsCollapseAllButtonEnabled(true);
        removeUsageType({
          columnKey,
          tableGuid: tableId,
          usageTypes: selectedUsageTypesState.usageTypes,
        });
        currentUsageTypes.current = new Set(selectedUsageTypesState.usageTypes);
      }
    }
  }, [selectedUsageTypesState, newUsageTypes.length]);

  useEffect(() => {
    if (!isDataSaved.current && !isFetchLineageLoading) {
      setInitialPosition(DEFAULT_INITIAL_POSITION);
      setInputNodesById(inputNodesById);
      setNodesByStack(initialNodesByStack);
      setStacksData(initialStacksData, { fitView: !columnKey });
      setNodesById(initialNodesById);
      setEdgesById(initialEdgesById);
      setBiggestConflictEndPerStack(biggestConflictEndPerStack);
      setIsCollapseAllButtonEnabled(true);

      isDataSaved.current = true;

      if (columnKey) {
        setNextUserInteraction({
          payload: {
            nodeKey: columnKey,
          },
          type: LineageInteractions.ToggleColumnLevelLineage,
        });
      }
    }
  }, [
    shouldRefreshCurrentLineage,
    setIsCollapseAllButtonEnabled,
    setInitialPosition,
    initialNodesByStack,
    setNextUserInteraction,
    inputNodesById,
    isFetchLineageLoading,
    isFetchColumnLevelLoading,
    setIsColumnLevelLoading,
    initialStacksData,
    initialNodesById,
    initialEdgesById,
    biggestConflictEndPerStack,
    setInputNodesById,
    setNodesByStack,
    setStacksData,
    setNodesById,
    setEdgesById,
    setBiggestConflictEndPerStack,
    columnKey,
    stackData,
    columnLevelLineageRootKey,
  ]);

  useEffect(() => {
    /*
     * Calculates columns only if there is data in the stackData.
     * It means the table lineage has been loaded.
     */
    if (
      isDataSaved.current &&
      stackData &&
      !isInitialColumnDataCreated.current &&
      columnInputNodesById &&
      tableId
    ) {
      isInitialColumnDataCreated.current = true;
      createColumnNodes({
        columnInputNodesById,
        fitView: !columnLevelLineageRootKey,
        removeEdgesWithNoUsages: selectedUsageTypesState.usageTypes.every(
          (usage) => usage !== 'none',
        ),
        stacksData: stackData,
        startingTableId: tableId,
      });
      setIsColumnLevelLoading(false);

      if (columnLevelLineageRootKey) {
        setNextUserInteraction({
          payload: {
            nodeKey: columnLevelLineageRootKey,
          },
          type: LineageInteractions.ToggleColumnLevelLineage,
        });
      }
    }
  }, [
    stackData,
    columnInputNodesById,
    createColumnNodes,
    tableId,
    columnLevelLineageRootKey,
    setNextUserInteraction,
    shouldRefreshCurrentLineage,
    selectedUsageTypesState.usageTypes,
    setIsColumnLevelLoading,
  ]);

  return (
    <Explore
      isError={isFetchLineageError}
      isLoading={isFetchLineageLoading}
      prevRoute={prevRoute}
    />
  );
};

const ExploreWithContexts: React.FC<{ prevRoute: string }> = ({ prevRoute }) => {
  const {
    columnGuid, // BI column page
    guid: tableGuid,
    itemId, // DWH column page
  } = useParams<{ columnGuid?: string; guid: string; itemId?: string }>();

  const columnId = itemId ?? columnGuid;

  return (
    <ExploreContextsWrapper initialColumnGuid={columnId} initialTableGuid={tableGuid}>
      <ExploreDataRequestWrapper columnId={columnId} prevRoute={prevRoute} tableId={tableGuid} />
    </ExploreContextsWrapper>
  );
};

const ExploreErrorBoundary: React.FC<{ prevRoute: string }> = ({ prevRoute }) => {
  return (
    <ErrorBoundary fallback={UnexpectedError}>
      <ExploreWithContexts prevRoute={prevRoute} />
    </ErrorBoundary>
  );
};

export default ExploreErrorBoundary;
