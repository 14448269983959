import React from 'react';
import styled from '@emotion/styled';

import rootTheme from '@styles/theme';

const levelStyleMap: { [key: string]: string } = {
  0: 'decimal',
  1: 'lower-alpha',
  2: 'lower-roman',
  3: 'decimal',
  4: 'lower-alpha',
};

const getOlPadding = (children: React.ReactNodeArray | any[]) => {
  const listItemChildren = children.filter((child) => child instanceof Object);

  const childrenCount = listItemChildren.length;
  if (childrenCount === 0) {
    return '0';
  }

  const numberOfDigits = Math.floor(Math.log10(childrenCount)) + 1;

  return rootTheme.space(numberOfDigits + 1);
};

export const OLElement = styled.ol<{ level?: number }>`
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  list-style: ${({ level }) => (level ? levelStyleMap[level] : 'decimal')};
  padding-left: ${({ children }) =>
    Array.isArray(children) && children.length > 0 ? getOlPadding(children) : '0'};

  li {
    margin-left: ${({ level, theme }) => theme.space(((level ?? 0) + 1) * 2.65)};
    padding-left: ${({ theme }) => theme.space(0.35)};
  }
`;
