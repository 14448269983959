import React, { useCallback, useEffect, useState } from 'react';
import { LOADING_TEXT } from '@constants';
import createDebug from 'debug';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { FetchLineageParams, useFetchLineage } from '@api/lineage';
import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import useMergeLineageData from '@components/Explore/useMergeLineageData';
import ExploreSidebar from '@components/ExploreSidebar';
import ExploreTree from '@components/ExploreTree';
import { exploreOptions } from '@components/ExploreTree/atoms';
import { ExploreOptionItem, ExploreOptionKey } from '@components/ExploreTree/ExploreOptions';
import DeleteConfirmationModal from '@components/Modal/DeleteConfirmationModal';
import Tooltip from '@components/Tooltip';
import Checkbox from '@components/UI/Form/Checkbox';
import InputLabel from '@components/UI/Form/InputLabel';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';
import flags from '@features';
import { DataSourceTypesType } from '@models/DataSourceCredentials';
import useLocalStorage from '@utils/useLocalStorage';

import {
  LineageOption,
  LineageOptionsPane,
  LoadingText,
  SideBarContainer,
  StyledExploreModal,
  StyledExploreModalHeader,
  ToggleSidebarButton,
} from './Explore.styles';
import ExploreLegend from './ExploreLegend';

const debug = createDebug('selectstar:explore:loader');

const BIG_LINEAGE_COUNT_DEFINITION = 1000;

interface ExploreProps {
  dataSourceType?: DataSourceTypesType;
  downstreamCount?: number;
  mode?: FetchLineageParams['mode'];
  prevRoute: string;
  renderContext?: string;
  showModeLineage?: boolean;
  startingColumnId?: string | null;
  startingTableId: string;
  upstreamCount?: number;
}

interface SidebarState {
  sidebarKey: string | null;
  sidebarType: 'table' | 'column';
}

const Explore: React.FC<ExploreProps> = ({
  dataSourceType,
  downstreamCount = 0,
  mode = 'table',
  prevRoute,
  renderContext = '',
  showModeLineage = true,
  startingColumnId = null,
  startingTableId,
  upstreamCount = 0,
}) => {
  const history = useHistory();
  const location = useLocation<any>();
  const { MODAL_IDS, checkModalOpened, closeModal, openModal } = useModal();
  const [sideBarWidth, setSideBarWidth] = useLocalStorage('lineageSideBarWidth', 300);
  const [options, setOptions] = useRecoilState(exploreOptions);
  const [isSidebarVisible, setVisible] = useState<boolean>(false);
  const [tableId, setTableId] = useState(startingTableId);
  const [loadedLineageGuids, setLoadedLineageGuids] = useState([tableId]);

  useEffect(() => {
    if (downstreamCount + upstreamCount > BIG_LINEAGE_COUNT_DEFINITION) {
      setOptions((prev) => ({
        ...prev,
        /** Disables filter usage for bigger lineage */
        showFieldUsage: {
          ...prev.showFieldUsage,
          value: false,
        },
      }));
    }
  }, [downstreamCount, upstreamCount]);

  const usageTypeParam = options.showFieldUsage.value ? undefined : '-filter';
  const initialRequestParams = {
    dbt_links: options.showModels?.value,
    group_by_data_source: true,
    include_borderline_edges: false,
    include_table_borderline_edges: true,
    looker_view_lineage: flags.looker_view_lineage,
    mode,
    mode_lineage: showModeLineage,
  };

  const [requestParams, setRequestParams] = useState<FetchLineageParams>(initialRequestParams);

  const [{ sidebarKey, sidebarType }, setSidebar] = useState<SidebarState>({
    sidebarKey: startingColumnId ? `${startingTableId}/${startingColumnId}` : startingTableId,
    sidebarType: startingColumnId ? 'column' : 'table',
  });

  const { data, isLoading: isDataLoading } = useFetchLineage(tableId, {
    params: {
      ...requestParams,
      usage_type: usageTypeParam,
    },
  });

  const { data: tableRelatedColumnsData, isLoading: isTableRelatedColumnsDataLoading } =
    useFetchLineage(tableId, {
      enabled: Boolean(tableId) && mode === 'table',
      /**
       * In current frontend implementation we need tables and its attribute "columns"
       * for each of them which is provided only in mode "all".
       */
      params: {
        ...requestParams,
        mode: 'all',
        usage_type: usageTypeParam,
      },
    });

  const isLoading = isDataLoading || isTableRelatedColumnsDataLoading;
  const lineageDataMerged = useMergeLineageData(
    {
      columns: [...(data?.columns ?? []), ...(tableRelatedColumnsData?.columns ?? [])],
      tables: [...(data?.tables ?? []), ...(tableRelatedColumnsData?.tables ?? [])],
    },
    { deepMerge: loadedLineageGuids.length > 1 },
  );

  /**
   * Sets showDashboards option default value to false when viewing Table lineage.
   * Sets showTables option default value to false when showing dbt lineage
   */
  useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      showDashboards: {
        ...prev.showDashboards,
        value: !startingTableId.startsWith('ta_'),
      },
      showTables: {
        ...prev.showTables,
        value: dataSourceType !== 'dbt',
      },
    }));

    /** Reset to default on Explore close. */
    return () => {
      setOptions((prev) => ({
        ...prev,
        showDashboards: {
          ...prev.showDashboards,
          value: true,
        },
      }));
    };
  }, [startingTableId, setOptions, dataSourceType]);

  const loadLineage = useCallback(
    ({ direction, guid }) => {
      setTableId(guid);
      setLoadedLineageGuids((prev) => [...prev, guid]);
      setRequestParams((prevReq) => {
        const newRequest = { ...prevReq, mode };
        delete newRequest.direction;

        if (direction) {
          newRequest.direction = direction;
        }

        return newRequest;
      });
    },
    [mode, setRequestParams, setTableId],
  );

  const closeSidebar = useCallback(() => {
    setVisible(false);
    setSidebar({ sidebarKey: null, sidebarType: 'table' });
  }, [setVisible, setSidebar]);

  const openSidebar = useCallback(
    (type: 'table' | 'column', key: string) => {
      setSidebar({ sidebarKey: key, sidebarType: type });
    },
    [setSidebar],
  );

  const toggleExploreOption = (key: ExploreOptionKey) => {
    setOptions((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        value: !prev[key]?.value,
      },
    }));
  };

  const toggleLineageOption = useCallback(
    (key: ExploreOptionKey) => {
      debug('toggle lineage option', key);

      if (key === 'showFieldUsage' && loadedLineageGuids.length > 1) {
        openModal(MODAL_IDS.confirmLoadFilterUsage);
      } else {
        toggleExploreOption(key);
      }
    },
    [setOptions, loadedLineageGuids],
  );

  const handleRequestClose = (_?: any, isUnmount?: boolean) => {
    closeModal(MODAL_IDS.exploreLegacy);
    if (isUnmount) return;
    history.replace(`${prevRoute}${location.search}`, {
      ...location.state,
      scrollToTop: false,
    });
  };

  useEffect(() => {
    openModal(MODAL_IDS.exploreLegacy);
  }, [MODAL_IDS.exploreLegacy, openModal]);

  return (
    <StyledExploreModal
      onClose={handleRequestClose}
      renderContent={({ modalHandleClose }) => (
        <>
          <Box alignItems="stretch" compDisplay="flex" compHeight="100%" compWidth="100%">
            <SideBarContainer
              defaultSize={{ height: '100%', width: sideBarWidth }}
              enable={{ right: isSidebarVisible }}
              isVisible={isSidebarVisible}
              maxWidth={isSidebarVisible ? 500 : 65}
              minWidth={isSidebarVisible ? 300 : 65}
              onResizeStop={(_e, _direction, ref) =>
                ref.offsetWidth < 300 ? setSideBarWidth(300) : setSideBarWidth(ref.offsetWidth)
              }
            >
              <ToggleSidebarButton
                onClick={() => setVisible((prev) => !prev)}
                onKeyDown={() => setVisible((prev) => !prev)}
                role="button"
                tabIndex={-1}
              >
                <Icon
                  color="#0C3476"
                  name={isSidebarVisible ? 'left-chevron' : 'right-chevron'}
                  size="20px"
                />
              </ToggleSidebarButton>
              {isSidebarVisible && sidebarKey && (
                <ExploreSidebar
                  closeSidebar={closeSidebar}
                  columns={lineageDataMerged.columns}
                  downstreamCount={downstreamCount}
                  loadLineage={loadLineage}
                  nodeKey={sidebarKey}
                  showLineageTree={lineageDataMerged.tables.length > 0}
                  tables={lineageDataMerged.tables}
                  type={sidebarType}
                  upstreamCount={upstreamCount}
                />
              )}
            </SideBarContainer>
            <Box compDisplay="flex" compWidth="100%" flexDirection="column">
              <StyledExploreModalHeader
                onClose={modalHandleClose}
                title={
                  <>
                    Data Lineage
                    {isLoading && <CircularLoader borderWidth={2} compSize={2.5} />}
                    <LineageOptionsPane>
                      {Object.entries(options)
                        .filter(([, value]) => value !== undefined)
                        .map(([key, value]) => [key, value!] as [string, ExploreOptionItem])
                        .map(([key, { label, tooltip, value }]) => (
                          <LineageOption key={key}>
                            <Tooltip content={tooltip || label}>
                              <InputLabel fontSize="default">
                                <Checkbox
                                  checked={value}
                                  disabled={isDataLoading}
                                  onClick={() => toggleLineageOption(key as ExploreOptionKey)}
                                />
                                {label}
                              </InputLabel>
                            </Tooltip>
                          </LineageOption>
                        ))}
                    </LineageOptionsPane>
                  </>
                }
              />
              {isDataLoading && lineageDataMerged.tables.length === 0 && (
                <LoadingText>{LOADING_TEXT}</LoadingText>
              )}
              {!isDataLoading && lineageDataMerged.tables.length === 0 && (
                <LoadingText>No lineage detected.</LoadingText>
              )}
              {lineageDataMerged.tables.length > 0 && (
                <ExploreTree
                  columns={lineageDataMerged.columns}
                  loadLineage={loadLineage}
                  openSidebar={openSidebar}
                  renderContext={renderContext}
                  startingColumnId={startingColumnId}
                  startingTablesIds={[startingTableId]}
                  tables={lineageDataMerged.tables}
                />
              )}
            </Box>
          </Box>
          {lineageDataMerged.tables.length > 0 && <ExploreLegend />}
          {checkModalOpened(MODAL_IDS.confirmLoadFilterUsage) && (
            <DeleteConfirmationModal
              deleteButtonText="Continue"
              onClose={() => closeModal(MODAL_IDS.confirmLoadFilterUsage)}
              onRemove={() => {
                toggleExploreOption('showFieldUsage');
                setLoadedLineageGuids([startingTableId]);
                setRequestParams(initialRequestParams);
                setTableId(startingTableId);
                closeModal(MODAL_IDS.confirmLoadFilterUsage);
              }}
              title="Load Filter Usage"
            >
              <Box as="span">
                We're refreshing the lineage exploration for a different usage type due to
                additional additional levels explored, ensuring accuracy. This action will remove
                any extra data you've loaded, and you should begin exploring from scratch. Would you
                like to continue?
              </Box>
            </DeleteConfirmationModal>
          )}
        </>
      )}
    />
  );
};

export default Explore;
