/* eslint-disable import/prefer-default-export */
import { SearchModel } from '@api/search/SearchModel';
import { Option } from '@components/UI/Select';

const mapSearchToRecipients = (
  searchResults: SearchModel[],
  currentOptions: Option[],
): Option[] => {
  const currentOptionsValues = currentOptions.map((option) => option.value);

  const nonExistingSearchResults = searchResults.filter(
    (result) => !currentOptionsValues.includes(result.guid),
  );

  return nonExistingSearchResults.map((result) => ({
    avatar: {
      guid: result.guid,
      isActive: result.isActive,
      isDeleted: false,
      name: result.name,
      src: result.avatar,
    },
    props: {
      rank: result.indexPosition,
    },
    text: result.name,
    value: result.guid,
  }));
};

export default mapSearchToRecipients;
