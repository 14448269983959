import { DataTypesConfig } from '@configs/dataTypes/types';

export enum GroupNodeType {
  bi = 'bi',
  dwh = 'dwh',
}

export interface GroupNodeTypeConfigItem {
  hideColumns?: boolean;
  type: GroupNodeType;
}

export const NODE_TYPE_CONFIG: DataTypesConfig<GroupNodeTypeConfigItem> = {
  databricks: {
    default: { type: GroupNodeType.dwh },
    notebook: { default: { hideColumns: true, type: GroupNodeType.bi } },
  },
  dbt: { default: { type: GroupNodeType.dwh } },
};
