import React from 'react';
import { FEDataSourceType } from '@configs/dataTypes/types';
import { Link as RouterLink } from 'react-router-dom';

import { DataType, ObjectType } from '@api/types';
import Box from '@components/Box/Box.styles';
import Link from '@components/Link';
import getUrl from '@components/Tree/getUrl';
import Icon from '@components/UI/Icon';
import DataTypesModel from '@models/DataTypesModel';

export const getHierarchyUrl = ({
  dataSourceGuid,
  dataSourceType,
  dataType,
  dataTypes,
  guid,
  objectType,
  rawDataSourceGuid,
  showSchemataPage,
}: Omit<DataSourceHierarchyItemProps, 'name' | 'isCarrotVisible' | 'level'>) => {
  return (
    getUrl({
      dataType,
      dataTypes,
      dsGuid: dataSourceGuid,
      guid,
      objectType,
      rawDataSourceGuid,
      showSchemataPage,
      type: dataSourceType,
    }) ?? '#'
  );
};

interface DataSourceHierarchyItemProps {
  dataSourceGuid: string;
  dataSourceType: FEDataSourceType;
  dataType: DataType;
  dataTypes?: DataTypesModel;
  guid: string;
  isCarrotVisible?: boolean;
  level: number;
  name: string;
  objectType: ObjectType;
  rawDataSourceGuid?: string;
  showSchemataPage?: boolean;
}

const DataSourceHierarchyItem: React.FC<DataSourceHierarchyItemProps> = ({
  dataSourceGuid,
  dataSourceType,
  dataType,
  dataTypes,
  guid,
  isCarrotVisible,
  level,
  name,
  objectType,
  rawDataSourceGuid,
  showSchemataPage,
}) => {
  const url = getHierarchyUrl({
    dataSourceGuid,
    dataSourceType,
    dataType,
    dataTypes,
    guid,
    objectType,
    rawDataSourceGuid,
    showSchemataPage,
  });

  return (
    <Link
      as={RouterLink}
      color="inherit"
      compDisplay="block"
      compWidth="100%"
      fontSize="inherit"
      to={url}
    >
      <Box
        alignItems="center"
        as="span"
        compDisplay="flex"
        gap={0.5}
        pl={level === 0 && !isCarrotVisible ? 0.625 : 0}
        py={0.75}
      >
        <Icon color="gray.700" name={dataTypes?.icons.dataType!} size="14px" />
        {name}
      </Box>
    </Link>
  );
};

export default React.memo(DataSourceHierarchyItem);
