import { Expose, Transform } from 'class-transformer';
import moment from 'moment';

import { LookerExploreModel } from './LookerExploreModel';

export class ExploreUserQuerySummaryModel extends LookerExploreModel {
  @Expose({ name: 'last_queried' })
  @Transform((value) => moment(value))
  lastQueried?: moment.Moment;

  count!: number;
}
