import React from 'react';

import Tooltip from '@components/Tooltip';
import Avatar from '@components/UI/Avatar';
import Icon from '@components/UI/Icon';
import { useTagContext } from '@context/Tag';
import type DataTypesModel from '@models/DataTypesModel';
import { MapObjectToAvatarReturnType } from '@models/mapObjectToAvatar';

export interface SearchResultIconProps {
  dataTypes?: Omit<DataTypesModel, 'findConfig'>;
  guid?: string;
  mappedAvatar?: MapObjectToAvatarReturnType;
  mr?: number;
  name?: string;
  showDataTypeTooltip?: boolean;
  size?: string;
}

const SearchResultIcon: React.FC<SearchResultIconProps> = ({
  dataTypes,
  guid,
  mappedAvatar,
  mr = 0.5,
  name,
  showDataTypeTooltip,
  size = '16px',
}) => {
  const isDataSource =
    dataTypes && dataTypes.dataSourceType && dataTypes.objectType === 'datasource';

  const { findTag } = useTagContext();

  if (mappedAvatar) {
    return <Avatar {...mappedAvatar} mr={mr} size={size} />;
  }

  if (dataTypes?.objectType === 'team') {
    return <Avatar guid={guid} mr={mr} name={name} size={size} />;
  }

  if (dataTypes?.objectType === 'tag') {
    const tag = findTag?.(guid!);
    return <Icon color={tag?.color} mr={mr} name={tag?.icon ?? 'tag-outline'} size={size} />;
  }

  if (isDataSource) {
    return <Icon mr={mr} name={dataTypes?.icons.dataSource} size={size} />;
  }

  return (
    <Tooltip content={showDataTypeTooltip && dataTypes?.tooltips.dataType}>
      <Icon mr={mr} name={dataTypes?.icons.dataType!} size={size} />
    </Tooltip>
  );
};

export default SearchResultIcon;
