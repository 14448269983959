import React, { useState } from 'react';
import moment from 'moment';
import { ViewCallbackProperties } from 'react-calendar';

import Box from '@components/Box';
import Button from '@components/Button/Button';
import Calendar from '@components/Calendar';
import { Column, Row } from '@components/Grid';
import Text from '@components/Text';
import Icon from '@components/UI/Icon';
import Modal from '@components/UI/Modal';
import theme from '@styles/theme';

import { RangeSelectButton } from './DateRangePicker.styles';

const initialPresetDateOptions = [
  {
    days: 0,
    label: 'Today',
  },
  {
    days: 6,
    label: 'Past 7 days',
  },
  {
    days: 29,
    label: 'Past 30 days',
  },
  {
    days: 89,
    label: 'Past 90 days',
  },
];

export interface CalendarModalProps {
  close?: () => void;
  initialRange: [moment.Moment, moment.Moment];
  maxRangeDays?: number;
  onChange: (range: [moment.Moment, moment.Moment]) => void;
  presetDateOptions?: Array<{ days: number; label: string }>;
}

const CalendarModal: React.FC<CalendarModalProps> = ({
  close,
  initialRange,
  maxRangeDays = 90,
  onChange,
  presetDateOptions = initialPresetDateOptions,
}) => {
  const [activeStartDate, setActiveStartDate] = useState<moment.Moment>(initialRange[0]);
  const [rangeValue, setRangeValue] = useState<[moment.Moment] | [moment.Moment, moment.Moment]>(
    initialRange,
  );
  const [rangeLimitDateMax, setRangeLimitDateMax] = useState<Date>(moment().toDate());
  const [rangeLimitDateMin, setRangeLimitDateMin] = useState<Date>();

  const handlePresetClick = (days: number) => {
    setActiveStartDate(moment().subtract(days, 'days'));
    setRangeValue([moment().subtract(days, 'days').startOf('day'), moment().endOf('day')]);
  };

  const handleChange = (values: Date[]) => {
    setRangeValue([moment(values[0]), moment(values[1])]);
    setRangeLimitDateMax(moment().toDate());
    setRangeLimitDateMin(undefined);
  };

  const handleClick = (value: Date) => {
    if (rangeValue[1]) {
      setRangeValue([moment(value)]);

      if (moment(value).add(maxRangeDays, 'days').isAfter(moment())) {
        setRangeLimitDateMax(moment().toDate());
      } else {
        setRangeLimitDateMax(moment(value).add(maxRangeDays, 'days').toDate());
      }

      setRangeLimitDateMin(moment(value).subtract(maxRangeDays, 'days').toDate());
    }
  };

  const handleSave = () => {
    onChange(rangeValue as [moment.Moment, moment.Moment]);
    close?.();
  };

  const handleCancel = () => {
    setRangeValue(initialRange);
    setRangeLimitDateMax(moment().toDate());
    setRangeLimitDateMin(undefined);
    close?.();
  };

  const handleActiveDateChange = (props: ViewCallbackProperties) => {
    if (!moment(props.activeStartDate).isSame(activeStartDate, 'day'))
      setActiveStartDate(moment(props.activeStartDate));
  };

  const calendarValue: [Date, Date | null] = [
    rangeValue[0].toDate(),
    rangeValue[1]?.toDate() || null,
  ];

  return (
    <Modal borderRadius="default" fontSize="h4" onClose={handleCancel} p={1.5} size="tiny">
      <Row>
        <Column xs={3}>
          <Box compDisplay="flex" flexDirection="column" gap={1} ml={-1.5} mr={-1}>
            {presetDateOptions.map((el) => (
              <RangeSelectButton
                key={el.days}
                color="gray.800"
                fontWeight="regular"
                justifyContent="flex-start"
                onClick={() => {
                  handlePresetClick(el.days);
                }}
                variant="text"
              >
                {el.label}
              </RangeSelectButton>
            ))}
          </Box>
        </Column>
        <Column xs={9}>
          <Calendar
            activeStartDate={activeStartDate?.toDate()}
            formatLongDate={(_, date) => moment(date).format('MMMM D YYYY')}
            formatMonth={(_, date) => moment(date).format('MMMM')}
            formatMonthYear={(_, date) => moment(date).format('MMMM YYYY')}
            formatShortWeekday={(_, date) => moment(date).format('ddd')}
            formatWeekday={(_, date) => moment(date).format('dddd')}
            maxDate={rangeLimitDateMax}
            minDate={rangeLimitDateMin}
            minDetail="year"
            next2Label={<Icon name="right-chevrons" />}
            nextLabel={<Icon name="right-chevron" />}
            onActiveStartDateChange={handleActiveDateChange}
            onChange={handleChange}
            onClickDay={handleClick}
            prev2Label={<Icon name="left-chevrons" />}
            prevLabel={<Icon name="left-chevron" />}
            selectRange
            value={calendarValue}
          />
          <Box alignItems="center" compDisplay="flex" gap={0.5} justifyContent="center" mt={1.5}>
            <Text as="span" color={theme.colors.gray[800]} fontSize="14px" fontWeight="medium">
              {rangeValue[0].format('MMM D YYYY')}
            </Text>
            <Text as="span" color={theme.colors.gray[600]} fontSize="14px">
              to
            </Text>
            <Text as="span" color={theme.colors.gray[800]} fontSize="14px" fontWeight="medium">
              {rangeValue?.[1]?.format('MMM D YYYY')}
            </Text>
          </Box>
        </Column>
      </Row>
      <Box compDisplay="flex" gap={1} justifyContent="end" mt={2} noDefault>
        <Button onClick={handleCancel} variant="outlined">
          Cancel
        </Button>
        <Button disabled={!rangeValue[1]} onClick={handleSave}>
          Confirm
        </Button>
      </Box>
    </Modal>
  );
};

export default CalendarModal;
