import React from 'react';

import { dsUsersCacheKeys, usePatchDsUser } from '@api/dsusers';
import { DsUserModel } from '@api/dsusers/DsUserModel';
import { usersCacheKeys } from '@api/users';
import BaseOwnerSelect from '@components/OwnerSelect/BaseOwnerSelect';
import { renderErrorToast, renderInfoToast } from '@components/Toast';
import { SelectValue } from '@components/UI/Select';
import fetchClient from '@lib/fetchClient';

import { mapDsUserToOption } from './DSUsersTable.utils';

interface OrganizationUsersDropdownCellProps {
  dsUser: DsUserModel;
}

const OrganizationUsersDropdownCell: React.FC<OrganizationUsersDropdownCellProps> = ({
  dsUser,
}) => {
  const { isLoading, mutate: updateServiceAccount } = usePatchDsUser(dsUser.guid, {
    onError: (err) => {
      renderErrorToast(err?.data?.user);
    },
    onSuccess: (d) => {
      const title = d?.user
        ? `${d.user.fullName} is connected to ${d.fullName}`
        : `Disconnected from ${d.fullName}`;

      renderInfoToast(title);
      fetchClient.invalidateQueries(usersCacheKeys.userDSUsersBase);
      fetchClient.invalidateQueries(dsUsersCacheKeys.dsUsers);
    },
  });

  const handleChange = (value: SelectValue) => {
    const [selectedOption] = value ?? [];

    if (!selectedOption) {
      updateServiceAccount({ user: null });
    } else {
      updateServiceAccount({
        is_service_account: false,
        user: selectedOption.value,
      });
    }
  };

  if (dsUser.isServiceAccount) {
    return null;
  }

  const selectedUserValue = mapDsUserToOption(dsUser);

  return (
    <BaseOwnerSelect
      fitAnchorWidth
      hideCaret
      isLoading={isLoading}
      label="organizationUserSelect"
      onChange={handleChange}
      ownerType="user"
      placeholder="Search for users"
      showClearButton
      showClearSelection
      value={selectedUserValue}
    />
  );
};

export default React.memo(OrganizationUsersDropdownCell);
