import React, { useState } from 'react';

import Input from '@components/Input/Input.v1';
import Checkbox from '@components/UI/Form/Checkbox';
import InputLabel from '@components/UI/Form/InputLabel';
import Icon from '@components/UI/Icon';

interface SidebarTreeSearchInputProps {
  onExcludeValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SidebarTreeSearchInput: React.FC<SidebarTreeSearchInputProps> = ({
  onExcludeValueChange,
  onSearchValueChange,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [excludeValue, setExcludeValue] = useState(false);

  return (
    <Input
      aria-label="search"
      compWidth="396px"
      endIcon={
        <InputLabel alignItems="center" compDisplay="flex" fontSize="body1" verticalAlign="middle">
          <Checkbox
            checked={excludeValue}
            onChange={(e) => {
              onExcludeValueChange(e);
              setExcludeValue(e.target.checked);
            }}
          />
          Exclude
        </InputLabel>
      }
      onChange={(e) => {
        setSearchValue(e.target.value);
        onSearchValueChange(e);
      }}
      placeholder="Search"
      startIcon={<Icon color="gray.800" name="search" size="20px" />}
      value={searchValue}
    />
  );
};

export default SidebarTreeSearchInput;
