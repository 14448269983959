import React from 'react';

import CloseButton from '@components/CloseButton';
import { useExplore } from '@components/Explore.v1/useExplore';

import CollapseAllNodesButton from '../CollapseAllNodesButton';
import UndoRedoControls from '../UndoRedoControls';
import UsageFieldFilter from '../UsageFieldFilter';

import { StyledControlBar } from './ControlBar.styles';

interface ControlBarProps {
  onClose: () => void;
}

const ControlBar = ({ onClose }: ControlBarProps) => {
  const { selectedUsageTypesState, setSelectedUsageTypesState } = useExplore();
  return (
    <StyledControlBar className="lineage-control-bar">
      <UndoRedoControls />
      <CollapseAllNodesButton />
      <UsageFieldFilter
        selectedUsageTypes={selectedUsageTypesState.usageTypes}
        setSelectedUsageTypesState={setSelectedUsageTypesState}
      />
      <CloseButton onClose={onClose} />
    </StyledControlBar>
  );
};

export default ControlBar;
