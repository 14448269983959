import React from 'react';

import Icon, { IconProps } from '@components/UI/Icon';
import theme from '@styles/theme';
import useLocalStorage from '@utils/useLocalStorage';

import { LineageSidebarTabs } from '../../Explore.types';
import { useExplore } from '../../useExplore';

import {
  StyledSidebar,
  StyledSidebarOption,
  StyledSidebarOptionsContainer,
  StyledSidebarTabContainer,
} from './ExploreSidebar.styles';
import TabHeader from './TabHeader';
import LineageTreeTab from './Tabs/LineageTreeTab';
import OverviewTab from './Tabs/OverviewTab';

interface Tab {
  component: () => JSX.Element;
  icon: IconProps['name'];
  id: LineageSidebarTabs;
  name: string;
}

const ExploreSidebar = () => {
  const { selectedSidebarTab, setSidebarTab } = useExplore();
  const [sideBarWidth, setSideBarWidth] = useLocalStorage('lineageSideBarWidth', '20vw');

  const tabs: Tab[] = [
    {
      component: OverviewTab,
      icon: 'info-outline',
      id: LineageSidebarTabs.Overview,
      name: 'Overview',
    },
    {
      component: LineageTreeTab,
      icon: 'lineage',
      id: LineageSidebarTabs.LineageTree,
      name: 'Lineage Tree',
    },
  ];

  const handleClose = () => {
    setSidebarTab(undefined);
  };

  const CurrentTab = tabs.find((tab) => tab.id === selectedSidebarTab)?.component;

  return (
    <StyledSidebar>
      <StyledSidebarOptionsContainer>
        {tabs.map(({ icon, id, name }) => {
          const isOptionSelected = selectedSidebarTab === id;
          const iconColor = isOptionSelected ? 'primary.500' : 'gray.500';
          return (
            <StyledSidebarOption
              key={id}
              aria-label={name}
              isSelected={isOptionSelected}
              onClick={() => setSidebarTab(id)}
            >
              <Icon color={iconColor} name={icon} size={theme.space(2.5)} />
            </StyledSidebarOption>
          );
        })}
      </StyledSidebarOptionsContainer>
      {selectedSidebarTab && (
        <StyledSidebarTabContainer
          defaultSize={{ height: 'auto', width: sideBarWidth }}
          enable={{ right: true }}
          maxWidth={500}
          minWidth={300}
          onResizeStop={(_e, _direction, ref) =>
            ref.offsetWidth < 300
              ? setSideBarWidth('300px')
              : setSideBarWidth(`${ref.offsetWidth}px`)
          }
        >
          <TabHeader onClose={handleClose} />
          {CurrentTab && <CurrentTab />}
        </StyledSidebarTabContainer>
      )}
    </StyledSidebar>
  );
};

export default ExploreSidebar;
