import styled from '@emotion/styled';

import Box from '@components/Box';

export const TreeContainer = styled.div`
  flex-shrink: 1;
  flex-grow: 1;
  min-height: 3em;
  padding: ${({ theme }) => theme.space(0, 0, 1, 0)};

  * {
    outline: none;
  }

  &.keyUser li.focused {
    outline: 1px dotted gray;
  }
`;

export const SortByPanel = styled(Box)`
  display: flex;
  flex-grow: 1;
  align-items: center;
  border-bottom: 1px #d8dbe0 solid;
  margin: ${({ theme }) => theme.space(0, 0, 1, 0)};
  width: 100%;
`;
