import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { RepositoryModel } from './RepositoryModel';

export class PullRequestModel {
  @Expose({ name: 'remote_title' })
  remoteTitle!: string;

  @Expose({ name: 'local_body' })
  localBody!: string;

  @Expose({ name: 'remote_id' })
  remoteId!: string;

  url!: string;

  @Type(() => RepositoryModel)
  repository!: RepositoryModel;

  @Expose({ name: 'created_on' })
  @Transform((value) => value && moment(value))
  createdOn!: moment.Moment;

  get editedFiles() {
    return this.localBody.split('\n');
  }
}
