import React, { useEffect } from 'react';
import { DEFAULT_DOCS_URL } from '@constants';

import Box from '@components/Box';
import Button from '@components/Button/Button';
import Link from '@components/Link';
import BaseModalHeader from '@components/Modal/BaseModal/BaseModalHeader';
import Text, { defaultParagraphStyles } from '@components/Text';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import theme from '@styles/theme';

import AddDSHeader from './AddDSHeader';

interface InformationScreenProps {
  goBack: () => void;
  onChange: () => void;
  showCalendly?: boolean;
}

const InformationScreen: React.FC<InformationScreenProps> = ({
  goBack,
  onChange,
  showCalendly,
}) => {
  const segment = useSegmentContext();

  useEffect(() => {
    // code from https://stackoverflow.com/questions/53891698/embed-calendly-into-react
    if (showCalendly) {
      const head = document.querySelector('head');
      const script = document.createElement('script');
      script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
      head?.appendChild(script);
    }
  }, [showCalendly]);

  const handleOnClick = () => {
    segment?.track(SegmentTrackEventName.CreateServiceAccountDocsLinkClicked);
  };

  return (
    <Box
      backgroundColor="white"
      borderRadius="8px"
      m="auto"
      maxWidth="820px"
      p={5}
      position="relative"
    >
      <BaseModalHeader title={<AddDSHeader fontSize="1.5rem" fontWeight="medium" pt={0.9} />} />
      {showCalendly ? (
        <Box compDisplay="flex" flexDirection="column">
          <Text {...defaultParagraphStyles}>
            Looks like we may not have integrations for your data stack yet. Please find time with
            our team to discuss:
          </Text>
          <Box
            className="calendly-inline-widget"
            data-url="https://calendly.com/selectstar/selectstar-demo"
            style={{ height: '350px', minWidth: '320px' }}
          />
        </Box>
      ) : (
        <Box compDisplay="flex" compHeight="350px" flexDirection="column">
          <Text {...defaultParagraphStyles} lineHeight={1.5}>
            Great!
          </Text>
          <Text {...defaultParagraphStyles} display="inline" lineHeight={1.5} mb={3}>
            Please create a service account for Select Star following the instructions:&nbsp;
          </Text>
          <Text {...defaultParagraphStyles} display="inline" lineHeight={1.5} mb={3}>
            <Link
              href={`${DEFAULT_DOCS_URL}/integrations`}
              onClick={handleOnClick}
              rel="noopener noreferrer"
              target="_blank"
              underline
            >
              See how to create a service account
            </Link>
          </Text>
          <Text {...defaultParagraphStyles} mb={3}>
            When you have the service accounts created, click Next.
          </Text>
        </Box>
      )}
      <Box compDisplay="flex" gap={1} justifyContent="flex-end" mt={3}>
        <Button onClick={goBack} variant="outlined">
          <Text
            as="span"
            {...defaultParagraphStyles}
            fontSize="14px"
            fontWeight="medium"
            textAlign="center"
          >
            Back
          </Text>
        </Button>
        {!showCalendly && (
          <Button onClick={onChange}>
            <Text
              as="span"
              color={theme.colors.white}
              fontSize="14px"
              fontWeight="medium"
              textAlign="center"
            >
              Next
            </Text>
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default InformationScreen;
