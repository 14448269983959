import React from 'react';

import { useFetchDbtWarehouseLink } from '@api/dbt';
import Box from '@components/Box';
import Mention from '@components/Mention';

import { StyledDetailsListItemLabel } from '../DetailsSection.styles';

export interface DbtLinksToProps {
  guid: string;
}

const DbtLinksTo: React.FC<DbtLinksToProps> = ({ guid }) => {
  const { data } = useFetchDbtWarehouseLink(guid, {
    enabled: Boolean(guid),
    params: {
      types: 'table,view,incremental,seed,dynamic_table',
    },
  });

  if (!data || data.results.length === 0) {
    return null;
  }

  return (
    <>
      <StyledDetailsListItemLabel>dbt Model</StyledDetailsListItemLabel>
      <Box compDisplay="flex" flexWrap="wrap" gap={0.25}>
        {data.results.map(({ dbtModel }) => (
          <Mention
            key={dbtModel.guid}
            dataTypes={dbtModel?.dataTypes}
            guid={dbtModel?.guid}
            link={dbtModel?.routePath}
            name={dbtModel?.name}
          />
        ))}
      </Box>
    </>
  );
};

export default DbtLinksTo;
