import React from 'react';

import { customAttributesCacheKeys, useDeleteCustomAttribute } from '@api/customAttributes';
import { CustomAttributeModel } from '@api/customAttributes/CustomAttributeModel';
import Box from '@components/Box';
import DeleteConfirmationModal from '@components/Modal/DeleteConfirmationModal';
import { renderInfoToast } from '@components/Toast';
import fetchClient from '@lib/fetchClient';

interface AddCustomAttributeModalProps {
  customAttribute?: CustomAttributeModel;
  onClose: () => void;
}

const AddCustomAttributeModal: React.FC<AddCustomAttributeModalProps> = ({
  customAttribute,
  onClose,
}) => {
  const { error, isLoading, mutate } = useDeleteCustomAttribute(customAttribute?.guid || '', {
    onSuccess: () => {
      fetchClient.invalidateQueries(customAttributesCacheKeys.customAttributes);
      renderInfoToast('Custom attribute has been deleted.');
      onClose();
    },
  });

  return (
    <DeleteConfirmationModal
      error={error?.message}
      loading={isLoading}
      onClose={onClose}
      onRemove={() => mutate(null)}
      title="Delete Custom Attribute"
    >
      <Box as="span">
        Are you ure you want to delete{' '}
        <Box as="span" fontWeight="bold">
          {customAttribute?.name}
        </Box>
        ? All values of{' '}
        <Box as="span" fontWeight="bold">
          {customAttribute?.name}
        </Box>{' '}
        will be deleted. This change cannot be reverted.
      </Box>
    </DeleteConfirmationModal>
  );
};

export default AddCustomAttributeModal;
