import React from 'react';

import { StatusType } from '@api/types';
import { StyledBoxProps } from '@components/Box';
import formatNumber from '@utils/formatNumber';

import { StyledBadge } from './NumberBadge.styles';

export type StatusCountsType = Record<StatusType, number> | null;

interface NumberBadgeProps extends StyledBoxProps {
  role?: string;
  value?: number;
}

const NumberBadge: React.FC<NumberBadgeProps> = ({ role, value = 0, ...rest }) => {
  return (
    <StyledBadge backgroundColor="gray.100" color="gray.700" role={role} {...rest}>
      {formatNumber(value)}
    </StyledBadge>
  );
};

export default NumberBadge;
