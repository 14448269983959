import { useMemo } from 'react';

import { useFetchTableFrequentDSUsers } from '@api/tables';
import type { OwnerType } from '@components/Owners/OwnerItem';
import { Option } from '@components/UI/Select';
import { useMetadataObjectContext } from '@context/MetadataObject';
import { useUserContext } from '@context/User';
import stripSpaces from '@utils/stripSpaces';

import { OwnerSelectItem } from './types';
import { mapOwnerToOption } from './utils';

interface UseDefaultOptionsProps {
  currentOwner?: OwnerType | null;
  items: OwnerSelectItem[];
}

const TOP_USER_PARAMS = {
  order: '-query_count',
  page: 1,
  page_size: 1,
  query: stripSpaces(`{
    guid,
    name,
    display_name,
    user {
      guid,
      email,
      first_name,
      last_name,
      is_active,
      avatar,
      team
    },
    data_source {
      guid,
      name,
      type
    }
  }`),
};

const useDefaultOptions = ({ currentOwner, items }: UseDefaultOptionsProps) => {
  const { user } = useUserContext();
  const { dataSourceGuid } = useMetadataObjectContext();

  const { data: topUsers, isLoading } = useFetchTableFrequentDSUsers(items?.[0]?.guid, {
    enabled: items?.[0]?.dataTypes?.objectType === 'table',
    keepPreviousData: true,
    params: {
      ...TOP_USER_PARAMS,
      datasources: dataSourceGuid,
    },
  });

  const currentOwnerOption = currentOwner ? mapOwnerToOption(currentOwner) : undefined;

  const options: Option[] = useMemo(() => {
    const mappedDefaultUsers = [user, topUsers?.results?.[0]?.user, user?.team];

    const filteredUsers = mappedDefaultUsers.filter((mappedUser) =>
      Boolean(mappedUser),
    ) as OwnerType[];

    const validUsers = filteredUsers.filter((mappedUser) => mappedUser.guid !== currentOwner?.guid);

    const mappedOptions = validUsers.map((mappedUser) => mapOwnerToOption(mappedUser));

    return [...(currentOwnerOption ? [currentOwnerOption] : []), ...mappedOptions];
  }, [currentOwnerOption, topUsers, user, currentOwner]);
  return { defaultValue: currentOwnerOption, isLoading, options };
};

export default useDefaultOptions;
