import React, { useEffect, useMemo, useRef, useState } from 'react';
import TreeMenu, { TreeNodeInArray } from 'react-simple-tree-menu';
import { RecoilState, useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useDebouncedCallback } from 'use-debounce';

import Box from '@components/Box';
import getTreeColumnsConfig from '@components/ExploreSidebar/getTreeColumnsConfig';
import type { SearchOptions } from '@components/ExploreTree/atoms';
import { desiredZoomTable, exploreOptions, searchOptions } from '@components/ExploreTree/atoms';
import OrderByButton from '@components/OrderByButton';
import { OrderBy } from '@components/OrderByButton/OrderByButton.styles';
import { useModal } from '@context/Modal';
import useOverrideCmdCtrlKey from '@hooks/useOverrideCmdCtrlKey';
import ExportToCsvButton from '@pages/DocumentsPage/GlossaryTab/ExportToCsvButton';
import { MakeSpaceProps } from '@styles/mixins/makeSpace';
import wrapString from '@utils/wrapString';

import getTree from '../getTree';
import sidebarInit from '../sidebarInit';
import SidebarTreeItem from '../SidebarTreeItem';
import type { ClickedGuidAtLevel, SidebarProps } from '../types';

import { SortByPanel, TreeContainer } from './SidebarTree.styles';
import SidebarTreeSearchInput from './SidebarTreeSearchInput';

const DEFAULT_EMPTY_TREE = { key: '0', label: 'No results found.', nodes: [] };
const EXPAND_ALL_COUNT_LIMIT = 700;

export type SidebarTreeProps = SidebarProps & {
  counts?: {
    downstream: number | undefined;
    upstream: number | undefined;
  };
  customSearchOptions?: RecoilState<SearchOptions>;
  direction: 'right' | 'left';
  filterLevelFrom?: number;
  isExpandingAll?: boolean;
  isLoading?: boolean;
  isLoadingExportCsv?: boolean;
  onExportCsvClick?: () => void;
  showDescriptions?: boolean;
  showExpandAll?: boolean;
  showUsage?: boolean;
  startingGuid?: string;
  topBarSpacing?: MakeSpaceProps;
};

const SidebarTree: React.FC<SidebarTreeProps> = (props) => {
  const { customSearchOptions } = props;
  const treeSearchOptions = customSearchOptions ?? searchOptions;

  const [search, setSearch] = useRecoilState(treeSearchOptions);
  const resetSearch = useResetRecoilState(treeSearchOptions);
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [expandAllState, setExpandAllState] = useState<'collapsed' | 'expanded'>('collapsed');
  const searchInputRef = useRef<HTMLInputElement>(null);
  const resetExploreOptions = useResetRecoilState(exploreOptions);
  const {
    counts,
    direction,
    filterLevelFrom = 0,
    isExpandingAll,
    isLoading,
    isLoadingExportCsv,
    loadLineage,
    nodeKey: startingKey,
    onExportCsvClick,
    onItemClick,
    showDescriptions,
    showExpandAll = false,
    showUsage,
    startingGuid,
    tables,
    topBarSpacing,
    type,
    zoomOnItemClick = true,
  } = props;
  const [clickedGuidAtLevel, setClickedGuid] = useState<ClickedGuidAtLevel>({ '0': 0 });
  const propIndex = ['left', 'right'].indexOf(direction);
  const [keyboardUser, setKeyboardUser] = useState(false);
  const { allNodes, traversalProps } = sidebarInit(props);
  const setZoomToTableId = useSetRecoilState(desiredZoomTable);
  const { MODAL_IDS, openModal } = useModal();
  const setSearchDebounced = useDebouncedCallback((val: SearchOptions) => {
    setSearch(val);
  }, 500);

  const allNodeIds = useMemo(() => {
    return new Set<string | undefined>([...allNodes.map((t) => t.guid)]);
  }, [allNodes]);

  const startingNode = useMemo(() => {
    if (startingKey?.includes('/co_')) {
      const [, columnGuid] = startingKey.split('/');
      return allNodes.find((node) => node.guid === columnGuid);
    }
    return allNodes.find((node) => node.guid === startingKey);
  }, [startingKey, allNodes]);

  const startingDataSourceType = startingNode?.dataSourceType;

  const handleShowInputToggle = useDebouncedCallback(() => {
    setIsInputVisible(!isInputVisible);

    if (!isInputVisible) {
      searchInputRef?.current?.focus();
    }
  }, 50);

  useOverrideCmdCtrlKey({ onPress: handleShowInputToggle });

  useEffect(() => {
    return () => {
      resetSearch();
      resetExploreOptions();
    };
  }, [resetExploreOptions, resetSearch]);

  const updateSearch = (sortBy: SearchOptions['sortBy'], orderBy: OrderBy) => {
    setSearch((prev) => ({
      ...prev,
      orderBy,
      sortBy,
    }));
  };

  const onSqlButtonClick = (query?: string) => {
    openModal(MODAL_IDS.query, { codeString: query });
  };

  const handleExpandAllClick = () => {
    setExpandAllState((prev) => (prev === 'collapsed' ? 'expanded' : 'collapsed'));
    loadLineage?.(startingGuid ?? '', direction, { maxDepth: 'max' });
  };

  const treeData = useMemo<{
    data: TreeNodeInArray[];
    expandAllKeys: string[] | undefined;
  }>(() => {
    if (!startingKey) {
      return {
        data: [DEFAULT_EMPTY_TREE],
        expandAllKeys: [],
      };
    }

    const expandAllKeys: string[] = [];
    const treeResult = getTree(
      {
        allNodeIds,
        allNodes,
        expandAllKeys,
        maxLevel: isExpandingAll ? undefined : Math.max(...Object.values(clickedGuidAtLevel)) + 1,
        propIndex,
        search,
        startingDataSourceType,
        startingKey,
        tables,
        traversalProps,
        type,
      },
      startingKey,
    );

    return {
      data: [treeResult ?? DEFAULT_EMPTY_TREE],
      expandAllKeys,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allNodes, startingKey, propIndex, search]);

  if (!startingKey) {
    return null;
  }

  const treeColumnsConfig = getTreeColumnsConfig({ showDescriptions, showUsage });

  const disableExpandAll = () => {
    if (direction === 'right') {
      return (counts?.downstream ?? 0) > EXPAND_ALL_COUNT_LIMIT;
    }
    return (counts?.upstream ?? 0) > EXPAND_ALL_COUNT_LIMIT;
  };

  return (
    <Box>
      <Box
        alignItems="center"
        compDisplay="flex"
        justifyContent="space-between"
        mb={0.75}
        mt={2.25}
        {...topBarSpacing}
      >
        <SidebarTreeSearchInput
          onExcludeValueChange={(e) => setSearch({ ...search, exclude: Boolean(e.target.checked) })}
          onSearchValueChange={({ target }) => {
            setSearchDebounced({ ...search, keyword: target.value });
          }}
        />
        {onExportCsvClick && (
          <ExportToCsvButton isLoading={isLoadingExportCsv} onClick={onExportCsvClick} />
        )}
      </Box>
      <TreeContainer
        className={keyboardUser ? 'keyUser' : ''}
        onClick={() => setKeyboardUser(false)}
        onKeyUp={() => setKeyboardUser(true)}
      >
        <TreeMenu
          key={
            expandAllState === 'expanded' && isExpandingAll
              ? treeData.expandAllKeys?.join()
              : startingKey
          }
          data={treeData.data}
          hasSearch={false}
          initialOpenNodes={
            expandAllState === 'expanded' && isExpandingAll ? treeData.expandAllKeys : [startingKey]
          }
        >
          {({ items }) => {
            const filteredItems = search?.keyword
              ? items?.filter(({ description, label, level }) => {
                  const searchString = search.keyword?.toLocaleLowerCase()?.toLocaleLowerCase();
                  const stringToMatch = `${label ?? ''}${description ?? ''}`?.toLocaleLowerCase();

                  if (level > filterLevelFrom) {
                    const match = stringToMatch?.includes(searchString);

                    if (search.exclude) {
                      return !match;
                    }

                    return match;
                  }

                  return true;
                })
              : items;

            return (
              <>
                <SortByPanel>
                  <Box compDisplay="flex " compWidth="100%" py={1.5}>
                    {treeColumnsConfig[0] !== null && (
                      <Box gap={1} pl={2} pr={2} {...treeColumnsConfig[0]}>
                        <OrderByButton
                          onClick={(orderBy) => {
                            updateSearch('name', orderBy);
                          }}
                          orderBy={search.sortBy === 'name' ? search.orderBy : 'default'}
                        >
                          Name {wrapString((filteredItems?.length ?? 1) - 1)}
                        </OrderByButton>
                      </Box>
                    )}
                    {treeColumnsConfig[1] !== null && (
                      <Box {...treeColumnsConfig[1]}>
                        <OrderByButton
                          onClick={(orderBy) => {
                            updateSearch('description', orderBy);
                          }}
                          orderBy={search.sortBy === 'description' ? search.orderBy : 'default'}
                        >
                          Description
                        </OrderByButton>
                      </Box>
                    )}
                    {treeColumnsConfig[2] !== null && (
                      <Box compDisplay="flex" {...treeColumnsConfig[2]}>
                        <OrderByButton
                          onClick={(orderBy) => {
                            updateSearch('usage', orderBy);
                          }}
                          orderBy={search.sortBy === 'usage' ? search.orderBy : 'default'}
                        >
                          Usage
                        </OrderByButton>
                      </Box>
                    )}
                    {treeColumnsConfig[3] !== null && (
                      <Box {...treeColumnsConfig[3]}>
                        <OrderByButton
                          onClick={(orderBy) => {
                            updateSearch('popularity', orderBy);
                          }}
                          orderBy={search.sortBy === 'popularity' ? search.orderBy : 'default'}
                        >
                          Popularity
                        </OrderByButton>
                      </Box>
                    )}
                  </Box>
                </SortByPanel>
                <Box as="ul">
                  {filteredItems?.map((item) => (
                    <SidebarTreeItem
                      {...item}
                      key={item.key}
                      direction={direction}
                      disableExpandAll={disableExpandAll()}
                      enableHighlight={!search.exclude}
                      expandAllState={expandAllState}
                      isExpandingAll={isExpandingAll}
                      isLoading={isLoading}
                      loadLineage={loadLineage}
                      onClick={() => {
                        if (zoomOnItemClick) {
                          setZoomToTableId(item.tableId);
                        }
                        onItemClick?.(item);

                        setClickedGuid((prev) => ({ ...prev, [item.id]: item.level }));
                      }}
                      onExpandAllClick={handleExpandAllClick}
                      onSqlButtonClick={onSqlButtonClick}
                      searchKeyword={search.keyword}
                      showExpandAll={showExpandAll && item.level === 0}
                      treeColumnsConfig={treeColumnsConfig}
                      type={type}
                    />
                  ))}
                </Box>
              </>
            );
          }}
        </TreeMenu>
      </TreeContainer>
    </Box>
  );
};

export default React.memo(SidebarTree);
