import React, { memo, useMemo, useState } from 'react';
import { Cell } from 'react-table';

import { RelatedDashboardModel } from '@api/dashboards/RelatedDashboardModel';
import DateTime from '@components/DateTime';
import PopularityCellHeader from '@components/Table/Cells/PopularityCell/PopularityCellHeader';
import type { ColumnConfig } from '@components/Table/Table/types';
import { Filter } from '@utils';
import { getPopularityNormalized } from '@utils/popularity';

import LineageDistanceCell from '../Cells/LineageDistanceCell';
import LinkedCell from '../Cells/LinkedCell';
import PopularityCell from '../Cells/PopularityCell';
import SearchHeader from '../Cells/SearchHeader';
import UserCell from '../Cells/UserCell';
import LineageDistanceHeader from '../Headers/LineageDistanceHeader';
import Table, { TableProps } from '../Table';
import TableStyled from '../TableStyled';

const ALL_COLUMNS = [
  'search',
  'name',
  'createdBy',
  'topUser',
  'distance',
  'popularity',
  'updatedAt',
  'lastRun',
] as const;

export type ColumnKey = (typeof ALL_COLUMNS)[number];

export const RELATED_DASHBOARDS_TABLE_SEARCH_CONFIG: PartialRecord<
  ColumnKey,
  keyof Filter.FilterOptions
> = {
  createdBy: 'search_dsuser_created_by',
  distance: 'distance',
  name: 'search_name',
};

export const RELATED_DASHBOARDS_TABLE_SORT_CONFIG: PartialRecord<ColumnKey, string> = {
  createdBy: 'dsuser_created_by',
  distance: 'distance,-popularity',
  lastRun: 'last_run_or_viewed_at',
  name: 'name',
  popularity: 'popularity',
  updatedAt: 'dashboard_updated_at',
};

export interface RelatedDashboardsTableProps extends Pick<TableProps, 'customTopBarContent'> {
  customColumProps?: PartialRecord<ColumnKey, { Header?: string }>;
  data?: RelatedDashboardModel[];
  filterService: Filter.FilterServiceInterface;
  itemCount?: number;
  loading?: boolean;
  reloadData: () => void;
  stickyHeader?: boolean;
  visibleColumns?: Array<ColumnKey>;
}

const RelatedDashboardsTable: React.FC<RelatedDashboardsTableProps> = ({
  customColumProps,
  customTopBarContent,
  data,
  filterService,
  itemCount = 0,
  loading,
  reloadData,
  stickyHeader,
  visibleColumns = [
    'name',
    'createdBy',
    'topUser',
    'distance',
    'updatedAt',
    'lastRun',
    'popularity',
  ],
}) => {
  const [isShowFilter, setShowFilter] = useState(false);

  const { changePage, filter, globalSearch, initialTableSortState, search, sort } = filterService;

  const totalPages = data && filter.page_size ? Math.ceil(itemCount / filter.page_size) : 1;

  const columns: ColumnConfig<RelatedDashboardModel>[] = useMemo(() => {
    const all: Record<ColumnKey, ColumnConfig<RelatedDashboardModel>> = {
      createdBy: {
        Cell: (props: Cell<RelatedDashboardModel>) => {
          const reldashboard = props.row.original;
          return (
            <UserCell
              {...props}
              user={
                reldashboard.dashboard.dsuserCreatedBy?.user?.fullName
                  ? reldashboard.dashboard.dsuserCreatedBy?.user
                  : reldashboard.dashboard.dsuserCreatedBy
              }
            />
          );
        },
        Header: 'Created By',
        accessor: (d) =>
          d?.dashboard?.dsuserCreatedBy?.user?.fullName
            ? d?.dashboard?.dsuserCreatedBy?.user.fullName
            : d?.dashboard?.dsuserCreatedBy?.fullName,
        id: 'createdBy',
        width: '120%',
      },
      distance: {
        Cell: (props: Cell<RelatedDashboardModel>) => {
          const {
            row: { original },
          } = props;
          return <LineageDistanceCell {...props} distance={original.distance} />;
        },
        Header: () => <LineageDistanceHeader />,
        accessor: (d) => d.distance,
        dropdownCheckboxLabel: 'Lineage Distance',
        id: 'distance',
        width: 165,
      },
      lastRun: {
        Cell: ({
          row: {
            original: { dashboard },
          },
        }: Cell<RelatedDashboardModel>) => <DateTime datetime={dashboard.lastRunAt} />,
        Header: customColumProps?.lastRun?.Header ?? 'Last Run / Viewed',
        accessor: (d) => d.dashboard?.lastRunAt?.toDate(),
        disableFilters: true,
        id: 'lastRun',
        width: '118%',
      },
      name: {
        Cell: (props: Cell<RelatedDashboardModel>) => {
          const reldashboard = props.row.original;
          return (
            <LinkedCell {...props} item={reldashboard.dashboard} showDataSourceIcon showIcon />
          );
        },
        Header: `Dashboard (${itemCount})`,
        accessor: (d) => d.dashboard?.name,
        disableHiding: true,
        id: 'name',
        width: '150%',
      },
      popularity: {
        Cell: (props: Cell<RelatedDashboardModel>) => {
          const reldashboard = props.row.original;
          return (
            <PopularityCell {...props} isDashboard popularity={reldashboard.dashboard.popularity} />
          );
        },
        Header: PopularityCellHeader,
        accessor: (d) => getPopularityNormalized(d?.dashboard?.popularity?.popularity),
        disableFilters: true,
        disableResizing: true,
        dropdownCheckboxLabel: 'Popularity',
        id: 'popularity',
        width: 120,
      },
      search: {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      topUser: {
        Cell: (props: Cell<RelatedDashboardModel>) => {
          const reldashboard = props.row.original;
          return (
            <UserCell
              {...props}
              user={
                reldashboard.dashboard?.topDSUser?.user?.fullName
                  ? reldashboard.dashboard?.topDSUser?.user
                  : reldashboard.dashboard.topDSUser
              }
            />
          );
        },
        Header: 'Top User',
        accessor: (d) => {
          return d?.dashboard?.topDSUser?.user?.fullName
            ? d?.dashboard?.topDSUser?.user.fullName
            : d?.dashboard?.topDSUser?.fullName;
        },
        disableFilters: true,
        disableSortBy: true,
        id: 'topUser',
        width: '120%',
      },
      updatedAt: {
        Cell: (props: Cell<RelatedDashboardModel>) => {
          const reldashboard = props.row.original;
          return <DateTime datetime={reldashboard.dashboard.updatedAt} />;
        },
        Header: 'Last Modified',
        accessor: (d) => d?.dashboard?.updatedAt.toDate(),
        disableFilters: true,
        id: 'updatedAt',
        sortType: 'datetime',
        width: '118%',
      },
    };

    return visibleColumns.map((col) => all[col]);
  }, [customColumProps, itemCount, visibleColumns]);

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        className="table-full"
        columns={columns}
        compact
        customTopBarContent={customTopBarContent}
        data={data ?? []}
        disableRowSelect
        initialState={{
          hiddenColumns: ['updatedAt', 'lastRun'],
          pageIndex: filter.page ? filter.page - 1 : 0,
          sortBy: initialTableSortState,
        }}
        loading={loading}
        manualGlobalFilter
        manualPagination
        manualSortBy
        reloadData={reloadData}
        setFilters={search}
        setGlobalFilter={globalSearch}
        setSortBy={sort}
        showFilter={isShowFilter}
        showGlobalFilterV1
        singleLine
        sortable
        stickyHeader={stickyHeader}
        toggleFilter={() => setShowFilter((prev) => !prev)}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default memo<RelatedDashboardsTableProps>(RelatedDashboardsTable);
