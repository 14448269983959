import { fetchColumnsSelect } from '@api/columns/columns';

export const fetchColumnsMock = fetchColumnsSelect({
  count: 2,
  model_count: 1,
  next: null,
  previous: null,
  results: [
    {
      ai_description: 'Unique identifier of the migration.',
      extra_data: {
        formula_display: 'lorem ipsum',
      },
      breadcrumbs: [
        {
          guid: 'br_aF2o28bYhUWMUcfsiHmrLE',
          ordinal: 0,
          target_data_source_guid: 'ds_hwRe6u6w99Hu4oRc885seK',
          target_data_source_type: 'postgres',
          target_data_type: 'database',
          target_guid: 'db_njinRVkZb4Shk7DqzNFoyP',
          target_name: 'metadata',
          target_object_type: 'database',
        },
        {
          guid: 'br_HtV8tNdp8pkrKpvED3wjLn',
          ordinal: 1,
          target_data_source_guid: 'ds_hwRe6u6w99Hu4oRc885seK',
          target_data_source_type: 'postgres',
          target_data_type: 'schema',
          target_guid: 'cl_ad6nXNYsxfWF9Gb76hG9bo',
          target_name: 'public',
          target_object_type: 'schema',
        },
        {
          guid: 'br_GLTPtWFa55NsvWXuMhL7dW',
          ordinal: 2,
          target_data_source_guid: 'ds_hwRe6u6w99Hu4oRc885seK',
          target_data_source_type: 'postgres',
          target_data_type: 'table',
          target_guid: 'ta_JA6LJuTAnC873kWF8JALdQ',
          target_name: 'django_migrations',
          target_object_type: 'table',
        },
      ],
      data_type: 'number',
      data_types: {
        data_source_type: 'postgres',
        data_type: 'number',
        object_type: 'column',
      },
      deactivation_scheduled_on: null,
      description: 'Unique identifier of the migration.',
      description_source: 'ai',
      external_type: 'int4',
      foreign_keys: [],
      guid: 'co_ii4Vfwk6ZeGC3JPM7V8s2Y',
      ingested_description: '',
      is_foreign_key: false,
      is_join_key: false,
      is_mention: false,
      is_metric: false,
      is_primary_key: true,
      name: 'id',
      popularity: null,
      richtext_description:
        '[{"type":"paragraph","children":[{"text":"Unique identifier of the migration."}]}]',
      sample_join_query: null,
      search_name: 'metadata.public.django_migrations.id',
      suggested_description: null,
      suggested_description_source: 'unknown',
      suggested_description_source_object: null,
      table: {
        guid: 'ta_JA6LJuTAnC873kWF8JALdQ',
        name: 'django_migrations',
      },
      tagged_items: [
        {
          kind: 'suggestion',
          suggested_source_kind: 'pii_from_name',
          suggestion_source: null,
          suggestion_source_object: {
            obj: null,
            object_type: 'piirule',
          },
          tag: {
            breadcrumbs: [],
            color: '#f38816',
            guid: 'tg_58DHZq47yRU6ToLxrQAZ4F',
            icon: 'pii',
            links_to: null,
            name: 'PII',
            type: 'status',
            visible: true,
          },
        },
      ],
      user_description:
        '[{"type":"paragraph","children":[{"text":"Unique identifier of the migration.1"}]}]',
    },
    {
      ai_description: 'Name of the application associated with the migration.',
      breadcrumbs: [
        {
          guid: 'br_XLH9C328oevWaKpp2YEE3B',
          ordinal: 0,
          target_data_source_guid: 'ds_hwRe6u6w99Hu4oRc885seK',
          target_data_source_type: 'postgres',
          target_data_type: 'database',
          target_guid: 'db_njinRVkZb4Shk7DqzNFoyP',
          target_name: 'metadata',
          target_object_type: 'database',
        },
        {
          guid: 'br_fKfvJgVBCEpTJy4CMbhVqb',
          ordinal: 1,
          target_data_source_guid: 'ds_hwRe6u6w99Hu4oRc885seK',
          target_data_source_type: 'postgres',
          target_data_type: 'schema',
          target_guid: 'cl_ad6nXNYsxfWF9Gb76hG9bo',
          target_name: 'public',
          target_object_type: 'schema',
        },
        {
          guid: 'br_LCufY8Xuzh4DmGu2DYT4Tq',
          ordinal: 2,
          target_data_source_guid: 'ds_hwRe6u6w99Hu4oRc885seK',
          target_data_source_type: 'postgres',
          target_data_type: 'table',
          target_guid: 'ta_JA6LJuTAnC873kWF8JALdQ',
          target_name: 'django_migrations',
          target_object_type: 'table',
        },
      ],
      data_type: 'string',
      data_types: {
        data_source_type: 'postgres',
        data_type: 'string',
        object_type: 'column',
      },
      deactivation_scheduled_on: null,
      description: 'Name of the application associated with the migration.',
      description_source: 'ai',
      external_type: 'varchar',
      foreign_keys: [],
      guid: 'co_VYBXmtKwS55s8RvixMxWVi',
      ingested_description: '',
      is_foreign_key: false,
      is_join_key: false,
      is_mention: false,
      is_metric: false,
      is_primary_key: false,
      name: 'app',
      popularity: null,
      richtext_description: '',
      sample_join_query: null,
      search_name: 'metadata.public.django_migrations.app',
      suggested_description: null,
      suggested_description_source: 'unknown',
      suggested_description_source_object: null,
      table: {
        guid: 'ta_JA6LJuTAnC873kWF8JALdQ',
        name: 'django_migrations',
      },
      tagged_items: [
        {
          kind: 'suggestion',
          suggested_source_kind: 'pii_from_name',
          suggestion_source: null,
          suggestion_source_object: {
            obj: null,
            object_type: 'piirule',
          },
          tag: {
            breadcrumbs: [],
            color: '#f38816',
            guid: 'tg_58DHZq47yRU6ToLxrQAZ4F',
            icon: 'pii',
            links_to: null,
            name: 'PII',
            type: 'status',
            visible: true,
          },
        },
      ],
      user_description: null,
    },
  ],
  schema_count: 1,
  table_count: 1,
});
