import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { usePostUser } from '@api/user';
import Alert from '@components/Alert';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import CenterAlignedBox from '@components/CenterAlignedBox';
import Input from '@components/Input/Input.v1';
import TermsOfService from '@components/TermsOfService';
import Text from '@components/Text';
import Title from '@components/Title/Title';
import Checkbox from '@components/UI/Form/Checkbox';
import InputLabel from '@components/UI/Form/InputLabel';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import useUrlSearchParams from '@hooks/useUrlSearchParams';
import theme from '@styles/theme';
import { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

import { FormComponentProps, FormState } from '../types';

export const SignUpFields: FormComponentProps[] = [
  {
    component: Input,
    id: 'email',
    key: 'email',
    placeholder: 'Email',
    required: true,
    type: 'text',
  },
  {
    component: Input,
    id: 'firstName',
    key: 'first_name',
    placeholder: 'First Name',
    required: true,
    type: 'text',
  },
  {
    component: Input,
    id: 'lastName',
    key: 'last_name',
    placeholder: 'Last Name',
    required: true,
    type: 'text',
  },
  {
    component: Input,
    extra: (
      <Text color={theme.colors.gray[500]} display="block" fontSize="10px">
        Minimum 12 characters
      </Text>
    ),
    id: 'password',
    key: 'password',
    placeholder: 'Password',
    required: true,
    type: 'password',
  },
  {
    component: Input,
    id: 'confirmPassword',
    key: 'confirm_password',
    placeholder: 'Confirm Password',
    required: true,
    type: 'password',
  },
  {
    component: () => null,
    fill: true,
    id: 'isAgreeToTerms',
    key: 'is_agreed_to_terms',
    label: { children: <TermsOfService /> },
    required: true,
    type: 'boolean',
  },
];

const SignUpFieldsDefault = ['email', 'first_name', 'last_name', 'password', 'confirm_password'];

export const renderComponent = (
  field: FormComponentProps,
  setSignUpState: React.Dispatch<React.SetStateAction<FormState>>,
  error?: EnhancedErrorResult | null,
  defaultValue?: string | number | boolean | null,
  signUpState?: FormState,
) => {
  const { component: Component, extra, id, key, type, ...rest } = field;

  const compOnType =
    type === 'boolean' ? (
      <InputLabel fontSize="body1">
        <Checkbox
          checked={Boolean(signUpState?.[key])}
          error={Boolean(error?.data[key])}
          onChange={({ target: { checked } }) => {
            setSignUpState((prev) => ({
              ...prev,
              [key]: checked,
            }));
          }}
          required
        />
        {rest.label?.children}
      </InputLabel>
    ) : (
      <Component
        error={Boolean(error?.data?.[key])}
        helperText={error?.data?.[key]?.[0]}
        id={id}
        name={key}
        type={type}
        {...rest}
        defaultValue={defaultValue}
        disabled={Boolean(defaultValue)}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          e.persist();

          setSignUpState((prev) => ({
            ...prev,
            [key]: e.target.value,
          }));
        }}
      />
    );

  return (
    <Box key={key} compWidth="100%" mt={1}>
      {compOnType}
      {extra}
    </Box>
  );
};

interface SignUpComponentProps {
  onSuccess: (email: string) => void;
}

const SignUp: React.FC<SignUpComponentProps> = ({ onSuccess }) => {
  const segment = useSegmentContext();
  const SignUpFormFields = SignUpFields.filter((field) => SignUpFieldsDefault.includes(field.key));

  const query = useUrlSearchParams();
  const utmCampaign = query.get('utm_campaign');

  const [signUpState, setSignUpState] = useState<FormState>(() =>
    SignUpFormFields.reduce(
      (dict, field) => Object.assign(dict, { [field.key]: field.type === 'boolean' ? false : '' }),
      { is_agreed_to_terms: true },
    ),
  );
  const history = useHistory();
  const {
    error,
    isLoading,
    mutate: signUp,
  } = usePostUser({
    onSuccess: (data) => {
      onSuccess(data.email);
      segment?.track(SegmentTrackEventName.SignedUp, {
        email: data.email,
        name: data.fullName,
        userId: data.guid,
      });
    },
  });

  const handleOnSubmit = () => {
    if (!isLoading) {
      signUp({ ...signUpState, utm_campaign: utmCampaign });
    }
  };

  if (error?.data?.invite_link) {
    return (
      <Redirect
        to={{
          pathname: '/request-invite',
          state: { inviteLink: error.data?.invite_link },
        }}
      />
    );
  }

  return (
    <CenterAlignedBox maxWidth="460px" minWidth="360px">
      <Box alignItems="flex-start" compWidth="100%" mb={1.5}>
        <Title showLogo>Create a new account</Title>
      </Box>
      {SignUpFormFields.map((field) => {
        const { key } = field;
        return (
          <React.Fragment key={key}>
            {renderComponent(field, setSignUpState, error, null, signUpState)}
          </React.Fragment>
        );
      })}
      {error?.data?.organization && <Alert type="error">{error?.data?.organization}</Alert>}
      <Button disabled={isLoading} fluid mt={2} onClick={handleOnSubmit}>
        Sign up
      </Button>
      <Box compDisplay="inline" p={4} textAlign="center">
        <Text color={theme.colors.gray[600]} fontSize="13px" fontWeight="regular">
          Already have an account?&nbsp;
        </Text>
        <Text
          color={theme.colors.gray[600]}
          cursor="pointer"
          fontSize="13px"
          fontWeight="regular"
          onClick={() => history.push('/login')}
          textDecoration="underline"
        >
          Sign in
        </Text>
      </Box>
    </CenterAlignedBox>
  );
};

export default SignUp;
