import React from 'react';

import { usePatchDataSource } from '@api/dataSources';
import Alert from '@components/Alert';
import Box from '@components/Box';
import NextStepButton from '@components/DataSourceSetup/components/Buttons/NextStepButton';
import PreviousStepButton from '@components/DataSourceSetup/components/Buttons/PreviousStepButton';
import ErrorCredentialAlert from '@components/DataSourceSetup/components/ErrorCredentialAlert/ErrorCredentialAlert';
import { StyledText } from '@components/DataSourceSetup/components/LookMLProjectSetup/LookMLProjectSetup';
import {
  StyledFormHorizontalLabelGrid,
  StyledLabel,
} from '@components/DataSourceSetup/DataSourceSetup.styles';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import Link from '@components/Link';
import Text from '@components/Text';
import { ModalFooter } from '@components/UI/Modal';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import { ModeCredentials } from '@models/DataSourceCredentials';
import { DataSourceModel } from '@models/DataSourceModel';
import theme from '@styles/theme';

import ErrorDescriptionMarkdown from '../ErrorDescriptionMarkdown';

const helpText = (
  <>
    <StyledText display="inline">
      This is optional, but we recommend it in order to include Personal Collection Reports, and to
      calculate popularity score based on the last 90 day’s user activities.&nbsp;
    </StyledText>
    <Box compDisplay="inline" mt={1}>
      <StyledText as="span">To enable this step,&nbsp;</StyledText>
      <Text
        as="span"
        color={theme.colors.black}
        display="inline"
        fontSize="14px"
        fontWeight="bold"
        m={0}
      >
        Mode’s Snowflake Reader account is required.&nbsp;
      </Text>
      <StyledText>
        Learn more&nbsp;
        <Link
          fontSize="inherit"
          href="https://mode.com/developer/discovery-database/introduction/"
          rel="noopener noreferrer"
          target="_blank"
          underline
        >
          here
        </Link>
        .
      </StyledText>
    </Box>
  </>
);
interface DataSourceAddActivityDataStepProps {
  dataSource: DataSourceModel;
  onDataSourceAdded: (dataSource: DataSourceModel) => void;
  prevStep: () => void;
}

const AddDiscoveryDB: React.FC<DataSourceAddActivityDataStepProps> = ({
  dataSource,
  onDataSourceAdded,
  prevStep,
}) => {
  const segment = useSegmentContext();
  const onResponse = (ds: DataSourceModel) => {
    onDataSourceAdded(ds);
  };

  const {
    error,
    isLoading,
    mutate: updateDataSource,
  } = usePatchDataSource(dataSource.guid, { onSuccess: onResponse });

  const { handleChange, handleSubmit, values } = useForm({
    initialValues: {
      account: '',
      database: '',
      password: '',
      role: '',
      schema: '',
      user: '',
      warehouse: '',
    },
    onSubmit: (val) => {
      const areSomeFieldsFilled = Object.values(val).some((element) => element !== '');

      if (areSomeFieldsFilled) {
        const payload = {
          credentials: {
            discovery_db: {
              account: val.account,
              database: val.database,
              password: val.password,
              role: val.role,
              schema: val.schema,
              user: val.user,
              warehouse: val.warehouse,
            },
          } as ModeCredentials,
          guid: dataSource.guid,
          name: dataSource.name,
          type: dataSource.type,
        };
        updateDataSource(payload);
        segment?.track(SegmentTrackEventName.ImportData_ModeDiscoveryConnectNextButtonClicked);
      } else {
        onDataSourceAdded(dataSource);
      }
    },
  });

  return (
    <Form isLoading={isLoading} onSubmit={handleSubmit}>
      <StyledFormHorizontalLabelGrid>
        <Box gridColumn="1/3">
          <Alert type="info">{helpText}</Alert>
        </Box>
        <StyledLabel>
          Account
          <Input
            error={error?.data?.account}
            helperText={error?.data?.account}
            name="account"
            onChange={handleChange}
            placeholder="account.us-east-1"
            type="text"
            value={values.account}
          />
        </StyledLabel>
        <StyledLabel>
          Username
          <Input
            error={error?.data?.user}
            helperText={error?.data?.user}
            name="user"
            onChange={handleChange}
            placeholder="Username"
            type="text"
            value={values.user}
          />
        </StyledLabel>
        <StyledLabel>
          Password
          <Input
            error={error?.data?.password}
            helperText={error?.data?.password}
            name="password"
            onChange={handleChange}
            placeholder="Password"
            type="password"
            value={values.password}
          />
        </StyledLabel>
        <StyledLabel>
          Role
          <Input
            error={error?.data?.role}
            helperText={error?.data?.role}
            name="role"
            onChange={handleChange}
            placeholder="'selectstar_role' by default"
            type="text"
            value={values.role}
          />
        </StyledLabel>
        <StyledLabel>
          Warehouse
          <Input
            error={error?.data?.warehouse}
            helperText={error?.data?.warehouse}
            name="warehouse"
            onChange={handleChange}
            placeholder="Warehouse"
            type="text"
            value={values.warehouse}
          />
        </StyledLabel>
        <StyledLabel>
          Database
          <Input
            error={error?.data?.database}
            helperText={error?.data?.database}
            name="database"
            onChange={handleChange}
            placeholder="Database"
            type="text"
            value={values.database}
          />
        </StyledLabel>
        <StyledLabel>
          Schema
          <Input
            error={error?.data?.schema}
            helperText={error?.data?.schema}
            name="schema"
            onChange={handleChange}
            placeholder="Schema"
            type="text"
            value={values.schema}
          />
        </StyledLabel>
        <Box gridColumn="1/3">
          {error && error.status === 500 && (
            <Alert title="Couldn't store credentials." type="error">
              Please try again later.
            </Alert>
          )}
          <ErrorCredentialAlert error={error?.data.credentials} />
          <ErrorDescriptionMarkdown error={error} />
        </Box>
      </StyledFormHorizontalLabelGrid>
      <ModalFooter>
        <PreviousStepButton
          disabled={isLoading}
          onClick={() => {
            segment?.track(SegmentTrackEventName.ImportData_ModeDiscoveryConnectPrevButtonClicked);
            prevStep();
          }}
        />
        <NextStepButton disabled={isLoading} text="Save" />
      </ModalFooter>
    </Form>
  );
};

export default AddDiscoveryDB;
