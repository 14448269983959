import styled from '@emotion/styled';

import Box from '@components/Box';
import type { StyledBoxProps } from '@components/Box/Box.styles';

interface SidebarSectionProps {
  grow?: boolean;
}

export const SidebarSection = styled.div<SidebarSectionProps>`
  flex-grow: ${({ grow }) => (grow ? '1' : '0')};
  flex-shrink: ${({ grow }) => (grow ? '1' : '0')};
  overflow: auto;
  margin: 0 0 0 1.25rem;
  padding-right: 0.5rem;
  &:first-of-type {
    margin-top: 1.25rem;
  }
  &:last-of-type {
    margin-bottom: 1.25rem;
  }
`;

export const SectionTitle = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  font-size: 0.8125rem;
  margin-bottom: 0.5rem;
`;

interface SubsectionProps {
  gutterBottom?: boolean;
}

export const Subsection = styled(Box)<SubsectionProps & StyledBoxProps>`
  display: flex;
  justify-content: start;
  font-size: 0.8125rem;
  margin: 0.25rem 0;
  margin-bottom: ${({ gutterBottom }) => (gutterBottom ? '0.5rem' : '0')};
`;

export const SubsectionNested = styled(Subsection)`
  margin-left: 1.25rem;
`;

export const SectionInfo = styled.span`
  display: inline-flex;
  margin-right: ${({ theme }) => theme.space(0.5)};
  flex-shrink: 0;
  color: #6f747c;
  &::after {
    content: ' ';
  }
`;

export const SectionValue = styled.span`
  display: inline-flex;
  flex-grow: 0;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;
