import React from 'react';

import invalidateCache from '@api/invalidateCache';
import { usePostTeams } from '@api/teams';
import Button from '@components/Button/Button';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import { renderInfoToast } from '@components/Toast';
import InputLabel from '@components/UI/Form/InputLabel/InputLabel';
import Modal, { ModalContent, ModalFooter, ModalHeader } from '@components/UI/Modal';

interface Props {
  onClose: () => void;
}

const CreateTeamModal: React.FC<Props> = ({ onClose }) => {
  const {
    error,
    isLoading,
    mutate: createOrganizationTeam,
  } = usePostTeams({
    onSuccess: () => {
      invalidateCache((keys) => [keys.teams.all]);
      renderInfoToast('Team Created.');
      onClose();
    },
  });

  const { handleChange, handleSubmit, values } = useForm({
    initialValues: {
      name: '',
    },
    onSubmit: ({ name }) => {
      createOrganizationTeam({ name });
    },
  });

  return (
    <Modal onClose={onClose}>
      <ModalHeader onClose={onClose} title="Create Team" />
      <Form isLoading={isLoading} onSubmit={handleSubmit}>
        <ModalContent alignItems="center" compDisplay="flex" minHeight="auto" pb={2.5} pt={2.5}>
          <InputLabel
            alignItems="baseline"
            as="div"
            color="gray.700"
            compDisplay="grid"
            compWidth="100%"
            cursor="default"
            fontWeight="bold"
            gap={2}
            gridTemplateColumns="max-content 1fr"
          >
            Team Name
            <Input
              autoFocus
              error={error?.data?.name}
              helperText={error?.data?.name}
              name="name"
              onChange={handleChange}
              placeholder="Team Name"
              type="text"
              value={values.name}
            />
          </InputLabel>
        </ModalContent>
        <ModalFooter>
          <Button disabled={isLoading} type="submit">
            Create
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CreateTeamModal;
