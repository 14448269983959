import React, { memo, useEffect, useState } from 'react';

import { UsageTypeType } from '@api/lineage/types';
import Button from '@components/Button/Button';
import NotApplicable from '@components/NotApplicable';
import Tooltip from '@components/Tooltip.v1/Tooltip';
import Select, { Option, SelectValue } from '@components/UI/Select';
import { StyledCaretButtonIcon } from '@components/UI/Select/CaretButton/CaretButton.styles';

import { UsageTypesState } from '../../useExplore/Explore.context.types';
import UsageTypeBadge from '../Common/UsageTypeBadge';

import {
  StyledUsageFieldFilterActionContainer,
  StyledUsageFieldFilterAnchor,
  StyledUsageFieldFilterOverlay,
} from './UsageFieldFilter.styles';

const TOOLTIP_TEXT_MAP: Record<UsageTypeType, string> = {
  aggregated: 'Field is aggregated',
  asis: 'Field has been replicated as is',
  filter: 'Field has been used as a filter',
  none: 'Field usage not detected or available',
  transformed: 'Field has been transformed',
};

const OPTIONS_MAP: Record<UsageTypeType, Option> = {
  aggregated: {
    customLabel: <UsageTypeBadge tooltipText={TOOLTIP_TEXT_MAP.aggregated} type="aggregated" />,
    text: 'Aggr',
    value: 'aggregated',
  },
  asis: {
    customLabel: <UsageTypeBadge tooltipText={TOOLTIP_TEXT_MAP.asis} type="asis" />,
    text: 'As is',
    value: 'asis',
  },
  filter: {
    customLabel: (
      <UsageTypeBadge showFilterFullText tooltipText={TOOLTIP_TEXT_MAP.filter} type="filter" />
    ),
    text: 'Filter',
    value: 'filter',
  },
  none: {
    customLabel: (
      <Tooltip content={TOOLTIP_TEXT_MAP.none}>
        <NotApplicable aria-label="na" data-testid="column-usage" fontSize="body2" />
      </Tooltip>
    ),
    text: 'None',
    value: 'none',
  },
  transformed: {
    customLabel: <UsageTypeBadge tooltipText={TOOLTIP_TEXT_MAP.transformed} type="transformed" />,
    text: 'Transformed',
    value: 'transformed',
  },
};

const SORTED_OPTIONS_KEYS: UsageTypeType[] = [
  'asis',
  'aggregated',
  'transformed',
  'none',
  'filter',
];

const OPTIONS = SORTED_OPTIONS_KEYS.map((usageType) => OPTIONS_MAP[usageType]);

interface UsageFieldFilterProps {
  selectedUsageTypes: UsageTypeType[];
  setSelectedUsageTypesState: (usageTypes: UsageTypesState) => void;
}

const UsageFieldFilter = ({
  selectedUsageTypes,
  setSelectedUsageTypesState,
}: UsageFieldFilterProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  const handleFilterChange = (newValue: SelectValue) => {
    setSelectedOptions(newValue as Option[]);
  };

  useEffect(() => {
    if (isOpen) {
      setSelectedOptions(selectedUsageTypes.map((usageType) => OPTIONS_MAP[usageType]));
    }
  }, [selectedUsageTypes, isOpen]);

  const handleRedrawLineage = () => {
    const newUsageTypes = selectedOptions.map((option) => option.value as UsageTypeType);
    setSelectedUsageTypesState({ usageTypes: newUsageTypes });
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && <StyledUsageFieldFilterOverlay />}
      <Select
        isMulti
        isOpen={isOpen}
        onChange={handleFilterChange}
        options={OPTIONS}
        optionsFitAnchorWidth={false}
        optionsListFooterElement={
          <StyledUsageFieldFilterActionContainer>
            <Button compSize="xxs" onClick={handleCancel} variant="outlined">
              Cancel
            </Button>
            <Button compSize="xxs" onClick={handleRedrawLineage}>
              Apply
            </Button>
          </StyledUsageFieldFilterActionContainer>
        }
        popperConfigProps={{
          customPopperStyles: {
            width: '177px',
          },
          placement: 'bottom-end',
        }}
        renderCustomAnchor={({ anchorProps }) => {
          return (
            <StyledUsageFieldFilterAnchor as="button" {...anchorProps}>
              Field Usage
              <StyledCaretButtonIcon color="currentColor" isOpen={isOpen} name="down" size="16px" />
            </StyledUsageFieldFilterAnchor>
          );
        }}
        setIsOpen={(newIsOpen) => setIsOpen(Boolean(newIsOpen))}
        showSelectAllCount={false}
        value={selectedOptions}
      />
    </>
  );
};

export default memo(UsageFieldFilter);
