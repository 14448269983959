import React, { useMemo } from 'react';

import { biConnectionsCacheKeys, usePatchBIConnections } from '@api/biconnections';
import { BiConnectionModel } from '@api/biconnections/BiConnectionModel';
import Skeleton from '@components/Skeleton';
import Select, { Option, SelectValue } from '@components/UI/Select';
import fetchClient from '@lib/fetchClient';
import { DataSourceModel } from '@models/DataSourceModel';
import theme from '@styles/theme';

export interface EnhancedOption extends Option {
  type: string;
}

interface BIConnectionsSetupTableSelectProps {
  connection: BiConnectionModel;
  dataSources?: DataSourceModel[];
  isLoadingOptions: boolean;
}

export const getDWHOptions = (items?: DataSourceModel[]): EnhancedOption[] =>
  (items ?? []).map((ds) => ({
    icon: ds.dataTypes?.icons.dataSource,
    text: ds.name,
    type: ds.type,
    value: ds.guid,
  }));

const BIConnectionsSetupTableSelect = ({
  connection,
  dataSources,
  isLoadingOptions,
}: BIConnectionsSetupTableSelectProps) => {
  const { isLoading: isSavingConnection, mutate: updateBIConnection } = usePatchBIConnections({
    onSuccess: () => {
      fetchClient.invalidateQueries(biConnectionsCacheKeys.biConnections);
    },
  });

  const options = useMemo(() => {
    return getDWHOptions(dataSources).filter((elem) => {
      return connection?.connectionType?.includes(elem.type);
    });
  }, [connection?.connectionType, dataSources]);

  const handleSelection = (newValue: SelectValue, connectionGuid: string) => {
    const [selectedOption] = newValue as Option[];
    const { value } = selectedOption ?? {};
    updateBIConnection({
      data: {
        targetDataSourceGuid: value === '' || !value ? null : value,
      },
      httpClientUrl: `/bi/bi-connections/${connectionGuid}/`,
    });
  };

  const currentValue = options.find((option) => option.value === connection.targetDataSource?.guid);

  if (isLoadingOptions) {
    return (
      <Skeleton compHeight={theme.space(4.5)} compWidth="100%" grayShade={200} variant="text" />
    );
  }

  return options?.length === 0 ? null : (
    <Select
      isLoading={isSavingConnection}
      onChange={(newValue) => handleSelection(newValue, connection.guid)}
      options={options}
      showClearButton
      value={currentValue ? ([currentValue] as SelectValue) : undefined}
    />
  );
};

export default BIConnectionsSetupTableSelect;
