import React, { MouseEvent } from 'react';

import Icon from '@components/UI/Icon';

import type { StyledCheckboxProps } from './Checkbox.styles';
import { StyledCheckbox } from './Checkbox.styles';

export interface CheckboxProps extends Omit<StyledCheckboxProps, 'variantState'> {
  checked?: boolean;
  error?: boolean;
  indeterminate?: boolean;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  role?: string;
  title?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  checked = false,
  disabled = false,
  error = false,
  indeterminate = false,
  onClick,
  title,
  variantColor,
  variantRadius,
  ...other
}) => {
  const isChecked = checked || indeterminate;
  const state = isChecked ? 'checked' : 'default';

  return (
    <StyledCheckbox
      aria-checked={isChecked}
      aria-disabled={disabled}
      disabled={disabled}
      onClick={onClick}
      state={error ? 'error' : state}
      title={title}
      variantColor={variantColor}
      variantRadius={variantRadius}
      {...other}
    >
      {isChecked && (
        <Icon
          color="currentColor"
          name={indeterminate ? 'minus' : 'check'}
          size={indeterminate ? '16px' : '12px'}
        />
      )}
    </StyledCheckbox>
  );
};

export default Checkbox;
