import { Type } from 'class-transformer';

import { DashboardModel } from '@api/dashboards/DashboardModel';
import { Reference } from '@models/Reference';

export type DisplayedInItemType = DashboardModel;

export class DisplayedInItemModel {
  static objectType: string = 'displayedinitem';

  objectType: string = DisplayedInItemModel.objectType;

  static typeDisplay: string = 'Displayed In Item';

  typeDisplay: string = DisplayedInItemModel.typeDisplay;

  @Type(() => Reference)
  item!: Reference<DisplayedInItemType>;
}
