import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { ActivityMessageModel } from '@api/activity/ActivityMessageModel';
import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { CommentModel } from '@api/comments/CommentModel';
import { UserModel } from '@api/user/UserModel';
import { breadcrumbsToList } from '@components/Breadcrumbs';
import { DataSourceModel } from '@models/DataSourceModel';
import { Reference } from '@models/Reference';

import type { ActivityType, GeneratedByType } from './types';
import { activityTypeToUserAction } from './utils';

export class ActivityModel {
  static objectType: string = 'activity';

  objectType: string = 'activity';

  guid?: string;

  field?: string;

  @Type(() => ActivityMessageModel)
  message!: ActivityMessageModel;

  data?: string;

  @Expose({ name: 'generated_by' })
  generatedBy?: GeneratedByType;

  @Expose({ name: 'activity_type' })
  activityType?: ActivityType;

  @Type(() => UserModel)
  user?: UserModel;

  @Type(() => Reference)
  target!: Reference<any>;

  @Expose({ name: 'cached_target_name' })
  cachedTargetName!: string;

  @Expose({ name: 'cached_target_data_type' })
  cachedTargetDataType!: string;

  @Type(() => CommentModel)
  comment?: CommentModel;

  @Type(() => DataSourceModel)
  @Expose({ name: 'target_data_source' })
  targetDataSource?: DataSourceModel;

  @Expose({ name: 'performed_on' })
  @Transform((value) => moment(value))
  performedOn!: moment.Moment;

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  get breadcrumbList() {
    return breadcrumbsToList(this.breadcrumbs);
  }

  get action() {
    return activityTypeToUserAction(this.activityType);
  }

  get targetObjectType() {
    return this.target.objectType;
  }
}
