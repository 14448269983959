import React, { useState } from 'react';
import styled from '@emotion/styled';
import { DropzoneState, useDropzone } from 'react-dropzone';

import Alert from '@components/Alert';
import Box from '@components/Box';
import Text from '@components/Text';
import Icon from '@components/UI/Icon';
import theme from '@styles/theme';

interface DropZoneComponentProps {
  isActive?: boolean;
  isComplete?: boolean;
  isDragReject?: boolean;
}

const getBorderColor = (props: DropZoneComponentProps) => {
  if (props.isComplete) {
    return theme.colors.v1.gray[100];
  }
  if (props.isActive) {
    return theme.colors.primary;
  }
  return '#eeeeee';
};

const getBackgroundColor = (props: DropZoneComponentProps) => {
  if (props.isActive) {
    return theme.colors.v1.gray[100];
  }
  return theme.colors.white;
};

const getTextColor = (props: DropZoneComponentProps) => {
  if (props.isActive) {
    return theme.colors.primary;
  }
  return theme.colors.gray[600];
};

const DropzoneContainer = styled.div<DropZoneComponentProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-width: 1px;
  border-radius: 2px;
  border-color: ${(props) => getBorderColor(props)};
  border-style: ${(props) => (props.isComplete ? 'solid' : 'dashed')};
  background-color: ${(props) => getBackgroundColor(props)};
  color: #bdbdbd;
  outline: none;
  transition: border, background 0.14s ease-in;
`;

interface SingleFileDropzoneProps<T> {
  disabled?: boolean;
  format?: 'string' | 'json';
  update: (fileContents?: T) => void;
}

export const useSingleFileDropzone = <T,>({
  disabled,
  format,
  update,
}: SingleFileDropzoneProps<T>): [DropzoneState, string] => {
  const [error, setError] = useState('');

  const dropzone = useDropzone({
    accept: ['application/json'],
    disabled,
    maxFiles: 1,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length !== 0) {
        setError(`Too many files provided, expected 1 but found: ${rejectedFiles.length}`);
        update(undefined);
      } else if (acceptedFiles.length === 1) {
        setError('');
        const reader = new FileReader();
        reader.onload = () => {
          let result;
          if (format === 'string') {
            result = reader.result?.toString();
          }
          if (format === 'json') {
            result = JSON.parse(reader.result as any);
          }

          if (result !== undefined) {
            update(result);
          }
        };
        reader.readAsText(acceptedFiles[0]);
      } else {
        setError(`No file provided`);
        update(undefined);
      }
    },
  });

  return [dropzone, error];
};
interface DropzoneFormElementProps<T> extends Pick<SingleFileDropzoneProps<T>, 'format'> {
  disabled?: boolean;
  error?: string;
  isComplete: boolean;
  label?: string;
  onFileAccept: SingleFileDropzoneProps<T>['update'];
  text: string;
}

const DropzoneFormElement = <T = string,>({
  disabled,
  error,
  format = 'string',
  isComplete,
  label,
  onFileAccept,
  text,
}: DropzoneFormElementProps<T>) => {
  const [
    { acceptedFiles, getInputProps, getRootProps, isDragAccept, isDragActive, isFileDialogActive },
    dropzoneError,
  ] = useSingleFileDropzone<T>({
    disabled,
    format,
    update: onFileAccept,
  });

  const dropZoneComponentProps = {
    ...getRootProps({ isDragAccept, isDragActive, isFileDialogActive }),
    isActive: isDragAccept || isComplete || isFileDialogActive || isDragActive,
    isComplete,
  };

  return (
    <Box
      alignItems="baseline"
      className="field StyledDropzoneFormElement"
      compDisplay="flex"
      flexDirection="row"
      mb={1}
      opacity={disabled ? 0.5 : 1}
    >
      {label && <label>{label}</label>}
      <Box className="ui input" compDisplay="block !important">
        <DropzoneContainer {...dropZoneComponentProps}>
          <input {...getInputProps({ disabled })} />
          <Text color={getTextColor(dropZoneComponentProps)} fontSize="13px" fontWeight="medium">
            {isComplete ? (
              <Box alignItems="center" compDisplay="flex">
                <Icon color="#21ba45" name="check" size="13px" /> {acceptedFiles[0]?.name}
              </Box>
            ) : (
              text
            )}
          </Text>
        </DropzoneContainer>
        {dropzoneError && (
          <Alert mt={0.5} type="error">
            {dropzoneError}
          </Alert>
        )}
        {error && (
          <Alert mt={0.5} type="error">
            {error}
          </Alert>
        )}
      </Box>
    </Box>
  );
};

export { DropzoneFormElement };

export default DropzoneContainer;
