import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Resizable } from 're-resizable';

export interface StyledRightSidebarProps {
  isActive: boolean;
  noPadding: boolean;
  top?: number;
}

const left = keyframes`
  from {transform: translateX(0.4rem)}
  to {transform: translateX(0rem)}
`;

const right = keyframes`
  from {transform: translateX(-0.4rem)}
  to {transform: translateX(0rem)}
`;

export const StyledRightSidebar = styled(Resizable as any)<StyledRightSidebarProps>`
  border-left: 2px solid #f2f5f9;
  position: relative;
  background-color: ${({ isActive }) => (isActive ? '#ffffff' : '#f2f5f9')};
  display: ${({ isActive }) => (isActive ? 'block' : 'inherit')};

  .right-sidebar-content-active {
    padding: ${({ noPadding }) => (noPadding ? 0 : '1.25rem')};
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  ${({ isActive, theme, top }) => theme.media.down('md')`
    position: ${isActive ? 'fixed !important' : 'relative'};
    top: ${isActive ? `${top}px` : 'unset'};
    right: 0;
    z-index: ${theme.zIndex.sidebar};

    .right-sidebar-content-active {
      overflow-y: scroll;
      height: 100vh;
    }
  `};

  .right-sidebar-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 0;
    transition: all 1s;
  }
`;

interface StyledRightSidebarButtonProps {
  animate: boolean;
  isActive: boolean;
}

export const StyledRightSidebarButton = styled.div<StyledRightSidebarButtonProps>`
  width: 1.75rem;
  height: 1.75rem;
  position: absolute;
  top: 2rem;
  left: -0.875rem;
  z-index: 0;
  background: #fff;
  border-radius: 50%;
  z-index: ${({ theme }) => theme.zIndex.stickyElement};
  cursor: pointer;
  box-shadow: 0 0 3px 0 #bbb;
  color: ${({ theme }) => theme.colors.primary};
  animation: ${({ animate, isActive }) =>
    animate
      ? css`
          ${isActive ? left : right} 0.5s forwards
        `
      : 'none'};

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(${({ isActive }) => (isActive ? 0 : 180)}deg);
  }
`;

export interface RightSidebarContentWrapperProps {
  isActive: boolean;
}
