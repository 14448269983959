import React, { useCallback, useMemo, useRef, useState } from 'react';
import { NO_DATA_TEXT } from '@constants';
import { useHistory, useParams } from 'react-router-dom';
import { Cell } from 'react-table';

import { useFetchDbtTests } from '@api/dbt';
import DbtTestModel from '@api/dbt/DbtTestModel';
import DateTime from '@components/DateTime';
import EmptyContent from '@components/EmptyContent';
import type { FiltersListFilterType } from '@components/FiltersList/FiltersList';
import Status from '@components/Status';
import ColumnNameTableCell from '@components/TabContent/ColumnsTab/ColumnTable/ColumnNameTableCell';
import TabError from '@components/TabContent/TabError';
import { TableItem } from '@components/Table/Cells/LinkedCell';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import Table from '@components/Table/Table';
import { sortDates } from '@components/Table/Table/sortDates';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { useMetadataObjectContext } from '@context/MetadataObject';
import { useModal } from '@context/Modal';
import { useUserContext } from '@context/User';
import ColumnPage from '@pages/ColumnPage';
import DbtFiltersList, { type DbtTestsCountsType } from '@pages/DbtTestsPage/DbtFiltersList';
import { Filter } from '@utils';
import wrapString from '@utils/wrapString';

import { MONITORS_EMPTY_CONTENT_MESSAGE } from './MonitorsTable';

export const defaultFiltersConfig: Filter.FilterOptions = {
  order: 'completed_at',
  page: 1,
  page_size: 100,
  sortColumn: 'completed_at',
  sortDirection: 'ascending',
};

export const searchConfig: { [key: string]: keyof Filter.FilterOptions } = {
  name: 'search_name',
};

export const sortConfig: { [key: string]: string } = {
  completedAt: 'completed_at',
  name: 'name',
  status: 'status',
};

export const FILTER_CONFIG = {
  defaultFiltersConfig,
  defaultOrder: 'completed_at',
  searchConfig,
  sortConfig,
};

interface DbtTestTableProps {
  tableGuid: string;
}

const MonitorsDbtTestsTable: React.FC<DbtTestTableProps> = ({ tableGuid }) => {
  const { changePage, filter, globalSearch, sort } = Filter.useUpdateFilters(
    FILTER_CONFIG.defaultFiltersConfig,
    FILTER_CONFIG.searchConfig,
    FILTER_CONFIG.sortConfig,
    FILTER_CONFIG.defaultOrder,
  );
  const history = useHistory();
  const { guid, itemId } = useParams<{ guid: string; itemId: string }>();
  const [currColumn, setCurrColumn] = useState<string | undefined>(itemId);
  const [searchVisible, setSearchVisible] = useState(false);
  const { MODAL_IDS, openModal } = useModal();
  const [selectedFilter, setSelectedFilter] = useState<FiltersListFilterType | null>(null);
  const initialStatusCounts = useRef<DbtTestsCountsType>(null);
  const initialResultsLengthRef = useRef<number | null>(null);
  const { dataSourceGuid } = useMetadataObjectContext();
  const { dataSources, organization } = useUserContext();
  const isDataSourceEditable = Boolean(dataSources?.[dataSourceGuid ?? '']?.settings?.isEditable);
  const showColumnCategoryTags = organization?.settings?.showColumnCategoryTags ?? false;

  const { data, isError, isLoading } = useFetchDbtTests({
    enabled: Boolean(tableGuid),
    params: {
      ...Filter.setParams(filter),
      status: selectedFilter?.name !== 'all' ? selectedFilter?.name : undefined,
      table: tableGuid,
    },
  });

  const columns: ColumnConfig<DbtTestModel>[] = [
    {
      Header: SearchHeader,
      disableFilters: true,
      disableResizing: true,
      disableSortBy: true,
      id: 'search',
      width: 32,
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (props: Cell<DbtTestModel>) => {
        const { row } = props;

        return (
          <TableItem
            {...props}
            item={row.original}
            name={row.original.name}
            onClick={() => {
              openModal(MODAL_IDS.query, {
                codeString: row.original.latestRun?.compiledSql ?? NO_DATA_TEXT,
                hideSidebar: true,
              });
            }}
            showIcon
          />
        );
      },
      Header: `Test ${wrapString(data?.count)}`,
      accessor: 'name',
      disableHiding: true,
      id: 'name',
      width: '280%',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (props: Cell<DbtTestModel>) => {
        const { row } = props;
        const columnData = row?.original?.column;

        return (
          <ColumnNameTableCell
            columnItem={columnData}
            customUrl={`/tables/${guid}/data-quality/dbt-test/${columnData?.guid}`}
            isDataSourceEditable={isDataSourceEditable}
            openColumnsPage={() => {
              setCurrColumn(columnData?.guid);
            }}
            showConstraint={false}
            showCopyFullPathButton={false}
          />
        );
      },
      Header: 'Column',
      disableFilters: true,
      disableSortBy: true,
      id: 'column',
      width: '120%',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ row }: Cell<DbtTestModel>) => <Status status={row.original.latestRun?.status} />,
      Header: 'Status',
      accessor: 'status',
      disableFilters: true,
      id: 'status',
      width: 130,
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ row }: Cell<DbtTestModel>) => (
        <DateTime datetime={row.original.latestRun?.completedAt} format="fullDateFormat" />
      ),
      Header: 'Last Run',
      accessor: (d) => d?.latestRun?.completedAt?.toDate(),
      disableFilters: true,
      id: 'completedAt',
      sortType: sortDates,
      width: 160,
    },
  ];

  const statusCounts = useMemo(() => {
    if (data?.status_counts && initialStatusCounts.current === null) {
      initialStatusCounts.current = data?.status_counts;
    }

    return initialStatusCounts.current;
  }, [data?.status_counts]);

  const initialResultsLength = useMemo(() => {
    if (data?.results && initialResultsLengthRef.current === null) {
      initialResultsLengthRef.current = data?.results.length;
    }
    return initialResultsLengthRef.current;
  }, [data?.results]);

  const hiddenColumns = useMemo(() => {
    const columnIds = ['description', 'tags'];

    if (tableGuid) {
      columnIds.push('dbt_table');
    }

    return columnIds;
  }, [tableGuid]);

  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;
  const getRowId = useCallback((row: Partial<DbtTestModel>) => row.guid!, []);

  if (isError) {
    return <TabError />;
  }

  return !tableGuid || initialResultsLength === 0 ? (
    <EmptyContent message={MONITORS_EMPTY_CONTENT_MESSAGE} />
  ) : (
    <TableStyled>
      <DbtFiltersList
        counts={statusCounts}
        onClick={(value) => {
          changePage(1);
          setSelectedFilter(value);
        }}
        selectedFilter={selectedFilter}
      />
      <Table
        basic="very"
        changePage={changePage}
        className="table-full"
        columns={columns}
        compact
        data={data?.results ?? []}
        disableRowSelect
        getRowId={getRowId}
        initialState={{
          hiddenColumns,
          sortBy: [
            {
              desc: false,
              id: 'completedAt',
            },
          ],
        }}
        loading={isLoading}
        manualGlobalFilter
        manualPagination
        selectable
        setGlobalFilter={globalSearch}
        setSortBy={sort}
        showFilter={searchVisible}
        sortable
        stickyHeader
        toggleFilter={() => setSearchVisible((prev) => !prev)}
        totalPages={totalPages}
        unstackable
      />
      {currColumn && (
        <ColumnPage
          dsGuid={dataSourceGuid}
          guid={currColumn}
          hideCategoryTags={!showColumnCategoryTags}
          isDataSourceEditable={isDataSourceEditable}
          onClose={() => {
            history.push(`/tables/${guid}/data-quality/dbt-test`);
            setCurrColumn(undefined);
          }}
        />
      )}
    </TableStyled>
  );
};

export default MonitorsDbtTestsTable;
