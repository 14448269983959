import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { Cell } from 'react-table';

import DateTime from '@components/DateTime';
import IconButton from '@components/IconButton';
import UserOwnershipMigrationModal from '@components/Modal/UserOwnershipMigrationModal';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import UserCell from '@components/Table/Cells/UserCell';
import Table from '@components/Table/Table';
import { sortDates } from '@components/Table/Table/sortDates';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';
import { useUserContext } from '@context/User';
import { OrganizationTeamModel } from '@models/OrganizationTeamModel';
import { OrganizationUserModel } from '@models/OrganizationUserModel';
import { ROLE_OPTIONS } from '@models/OrganizationUserRole';
import { FilterServiceInterface } from '@utils/filters';

import UserUpdateSelect from './UserUpdateSelect';

const initialSortState = [
  {
    desc: false,
    id: 'lastActivity',
  },
];

interface Props {
  filterService: FilterServiceInterface;
  itemCount?: number;
  teams: OrganizationTeamModel[];
  totalPages: number;
  users?: OrganizationUserModel[];
}

const StyledAdminUsersTable = styled(TableStyled)`
  font-size: 0.8125rem;

  .ui.table tr td {
    &.team,
    &.role {
      overflow: visible !important;
    }
    &.name {
      h4.ui.header {
        display: flex;
        align-items: center;
      }
    }
  }
`;

const AdminUsersTable: React.FC<Props> = ({
  filterService,
  itemCount,
  teams,
  totalPages,
  users,
}) => {
  const [isShowFilter, setShowFilter] = React.useState(false);
  const { organization } = useUserContext();
  const { MODAL_IDS, checkModalOpened, closeModal, getModalContent, openModal } = useModal();
  const { useRbac } = organization?.settings ?? {};

  const toggleFilter = useCallback(() => setShowFilter((prev) => !prev), [setShowFilter]);

  const { changePage, filter, search, sort } = filterService;

  const teamOptions = React.useMemo(
    () => [...teams.map((t) => ({ key: t.guid, text: t.name ?? '', value: t.guid ?? '' }))],
    [teams],
  );

  const columns: ColumnConfig<OrganizationUserModel>[] = React.useMemo(
    () => [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        Cell: (props: Cell<OrganizationUserModel>) => {
          const { row } = props;
          const item = row.original;
          return <UserCell {...props} user={item.user} />;
        },
        Header: `User (${itemCount || 0})`,
        accessor: (d) => d?.user?.fullName,
        disableHiding: true,
        id: 'name',
        width: '14%',
      },
      {
        Cell: ({ row }: Cell<OrganizationUserModel>) => {
          const item = row.original;
          return (
            <UserUpdateSelect
              options={teamOptions}
              type="team"
              userGuid={item.user.guid}
              value={item.team?.guid ?? ''}
            />
          );
        },
        Header: 'Team',
        accessor: (d) => d.team?.fullName,
        disableFilters: true,
        id: 'team',
        width: '16%',
      },
      {
        Header: 'Email',
        accessor: (d) => d.user?.email,
        id: 'email',
        width: '20%',
      },
      {
        Cell: ({ row }: Cell<OrganizationUserModel>) => {
          const item = row.original;

          return (
            <UserUpdateSelect
              options={ROLE_OPTIONS}
              type="role"
              userGuid={item.user.guid}
              value={item.role ?? ''}
            />
          );
        },
        Header: () => (useRbac ? 'Organization Role' : 'Role'),
        accessor: (d) => d?.role,
        disableFilters: true,
        id: 'role',
        width: '16%',
      },
      {
        Cell: ({ row }: Cell<OrganizationUserModel>) => {
          const d = row.original;
          return d?.user?.lastActivity ? (
            <DateTime datetime={d?.user?.lastActivity} format="fullDateFormat" />
          ) : (
            'never'
          );
        },
        Header: 'Last Activity',
        accessor: (d) => d?.user?.lastActivity?.toDate(),
        disableFilters: true,
        id: 'lastActivity',
        sortType: sortDates,
        width: '16%',
      },
      {
        Cell: ({ row }: Cell<OrganizationUserModel>) => {
          const item = row.original;
          return item.lastSSOUse ? <Icon color="#21ba45" name="check" size="14px" /> : '';
        },
        Header: 'SSO',
        accessor: (d) => (d?.lastSSOUse ? 'True' : 'False'),
        disableFilters: true,
        id: 'sso',
        width: '8%',
      },
      {
        Cell: ({ row }: Cell<OrganizationUserModel>) => {
          const item = row.original;
          return (
            <IconButton
              iconColor="gray.600"
              iconName="trash"
              iconSize="20px"
              onClick={() => openModal(MODAL_IDS.userOwnershipMigration, { user: item.user })}
              variant="clear"
              verticalAlign="middle"
            />
          );
        },
        disableFilters: true,
        disableSortBy: true,
        id: 'delete',
        width: '2rem',
      },
    ],
    [itemCount, teamOptions, useRbac, openModal, MODAL_IDS],
  );

  const getRowId = React.useCallback((row: Partial<OrganizationUserModel>) => row.user?.guid!, []);
  const userOwnershipMigrationModalProps = getModalContent(MODAL_IDS.userOwnershipMigration);

  return (
    <>
      <StyledAdminUsersTable>
        <Table
          basic="very"
          changePage={changePage}
          className="table-full"
          columns={columns}
          compact
          data={users || []}
          disableColumnFiltering
          disableGlobalFilter
          disableRowSelect
          getRowId={getRowId}
          initialState={{
            pageIndex: filter.page ? filter.page - 1 : 0,
            sortBy: initialSortState,
          }}
          loading={users === undefined}
          manualFilters
          manualPagination
          manualSortBy
          selectable
          setFilters={search}
          setSortBy={sort}
          showFilter={isShowFilter}
          singleLine
          sortable
          stickyHeader
          toggleFilter={toggleFilter}
          totalPages={totalPages}
          unstackable
        />
      </StyledAdminUsersTable>
      {checkModalOpened(MODAL_IDS.userOwnershipMigration) && userOwnershipMigrationModalProps && (
        <UserOwnershipMigrationModal
          onClose={() => {
            closeModal(MODAL_IDS.userOwnershipMigration);
          }}
          user={userOwnershipMigrationModalProps.user}
        />
      )}
    </>
  );
};

export default AdminUsersTable;
