import { dataSources } from '@configs/dataSources/config';
import { isWarehouseType } from '@configs/dataSources/getType';

import { DataSourceModel } from '@models/DataSourceModel';

export interface Step<KeyType> {
  hidden?: boolean;
  key: KeyType;
  name: string;
  title?: string;
}

export type StepKey =
  | 'add-ds'
  | 'authorize-aws-rds'
  | 'add-usage-ds'
  | 'connect-query-log'
  | 'select-dbs'
  | 'select-schemas'
  | 'select-bi-folders'
  | 'init-config'
  | 'select-lookml-projects'
  | 'add-discovery-db'
  | 'load'
  | 'finish';

type DBStep = Step<StepKey>;

export const STEPS: { [key: string]: DBStep } = {
  AWSAuthorize: {
    key: 'authorize-aws-rds',
    name: 'Authorize',
    title: 'Authorize',
  },
  AddDS: { key: 'add-ds', name: 'Add Data Source', title: 'Add Data Source' },
  AddDiscoveryDB: {
    key: 'add-discovery-db',
    name: 'Connect Mode Discovery DB',
    title: 'Connect Mode Discovery DB',
  },
  AddUsageDS: {
    key: 'add-usage-ds',
    name: 'Connect Activity Data',
    title: 'Connect Activity Data',
  },
  ConnectQueryLog: {
    key: 'connect-query-log',
    name: 'Connect Query Log',
    title: 'Connect Query Log',
  },
  Finish: { key: 'finish', name: 'Finish', title: 'Finished' },

  InitConfig: {
    key: 'init-config',
    name: 'Detect Databases/Schemas',
    title: 'Detecting Databases/Schemas',
  },

  /*
   * For AWS Glue, we are going to use Catalog as Database, and Database as schemas
   * Since there would always be one Database/Catalog, we skip that step
   */
  InitConfigAwsGlue: {
    key: 'init-config',
    name: 'Ingest Catalog',
    title: 'Ingesting Catalog Metadata',
  },

  Load: { key: 'load', name: 'Load Data', title: 'Loading Data' },

  LookerBIFolders: { key: 'select-bi-folders', name: 'Select Folders', title: 'Select Folders' },

  MetabaseSelectBIFolders: {
    key: 'select-bi-folders',
    name: 'Select Collections',
    title: 'Select Collections',
  },

  ModeSelectBIFolders: {
    key: 'select-bi-folders',
    name: 'Select Collections',
    title: 'Select Collections',
  },

  PeriscopeSelectBIFolders: { key: 'select-bi-folders', name: 'Select Tags', title: 'Select Tags' },

  PowerBISelectBIFolders: {
    key: 'select-bi-folders',
    name: 'Select Workspaces',
    title: 'Select Workspaces',
  },

  SelectAwsGlueDatabases: {
    key: 'select-schemas',
    name: 'Select Databases',
    title: 'Select Databases',
  },

  SelectDBs: { key: 'select-dbs', name: 'Select Databases', title: 'Select Databases' },
  SelectLookMLProjects: {
    key: 'select-lookml-projects',
    name: 'Select LookML Projects',
    title: 'Select LookML Projects',
  },
  SelectSchemas: { key: 'select-schemas', name: 'Select Schemas', title: 'Select Schemas' },
  TableauSelectBIFolders: {
    key: 'select-bi-folders',
    name: 'Select Projects',
    title: 'Select Projects',
  },
};

const BI_FOLDER_EDIT_DS_TYPES: string[] = [
  dataSources.looker.value,
  dataSources.metabase.value,
  dataSources.mode.value,
  dataSources.periscope.value,
  dataSources.power_bi.value,
  dataSources.tableau.value,
];

const DEFAULT_STEPS: DBStep[] = [STEPS.AddDS, STEPS.Load, STEPS.Finish];

const DWH_EXTERNAL_QUERY_LOG_STEPS: DBStep[] = [
  STEPS.AddDS,
  STEPS.ConnectQueryLog,
  STEPS.SelectDBs,
  STEPS.SelectSchemas,
  STEPS.Load,
  STEPS.Finish,
];

export const CONFIGURE_STEP_INDEX: { [key: string]: number } = {
  [dataSources.data_studio.value]: 1,
  [dataSources.dbt.value]: 0,
  [dataSources.glue.value]: 2,
  [dataSources.looker.value]: 2,
  [dataSources.metabase.value]: 2,
  [dataSources.mode.value]: 1,
  [dataSources.periscope.value]: 1,
  [dataSources.postgres.value]: 2,
  [dataSources.power_bi.value]: 1,
  [dataSources.redshift.value]: 2,
  [dataSources.salesforce.value]: 0,
  [dataSources.sigma.value]: 0,
  [dataSources.tableau.value]: 2,
  [dataSources.thoughtspot.value]: 0,
};

export const getConfigureStepIndex = (dsType: any) => {
  if (dsType in CONFIGURE_STEP_INDEX) return CONFIGURE_STEP_INDEX[dsType];
  if (isWarehouseType(dsType)) return 1;
  return undefined;
};

export function getStepStartIndex(
  dataSource?: DataSourceModel,
  isIngestionComplete: boolean = false,
) {
  const dsType = dataSource?.type;
  // if we don't have a data source, start at the beginning
  if (dsType === undefined) {
    return 0;
  }

  // if we have a data source, but it's credentials are incomplete, start at step 1
  if (
    (
      [dataSources.glue.value, dataSources.postgres.value, dataSources.redshift.value] as string[]
    ).includes(dsType) &&
    dataSource?.isCredentialsSetupIncomplete
  ) {
    return 1;
  }

  let step = getConfigureStepIndex(dsType) ?? -1;
  /*
   * if we have a data source, which supports editing BI folders,
   * and ingestion is not complete, start at previous step
   */
  if (BI_FOLDER_EDIT_DS_TYPES.includes(dsType) && !isIngestionComplete) {
    step -= 1;
  }
  return step;
}

const BI_FOLDER_EDIT_STEPS: DBStep[] = [
  STEPS.LookerBIFolders,
  STEPS.PeriscopeSelectBIFolders,
  STEPS.MetabaseSelectBIFolders,
  STEPS.ModeSelectBIFolders,
  STEPS.PowerBISelectBIFolders,
  STEPS.TableauSelectBIFolders,
];

// Steps for databases without external query log (eg. native query log) support
const DB_STEPS: DBStep[] = [
  STEPS.AddDS,
  STEPS.SelectDBs,
  STEPS.SelectSchemas,
  STEPS.Load,
  STEPS.Finish,
];

const DS_STEP_CONFIG: Record<string, DBStep[]> = {
  [dataSources.data_studio.value]: [STEPS.AddDS, STEPS.AddUsageDS, STEPS.Load, STEPS.Finish],
  [dataSources.dbt.value]: [STEPS.AddDS, STEPS.InitConfig, STEPS.Load, STEPS.Finish],
  [dataSources.glue.value]: [
    STEPS.AddDS,
    STEPS.AWSAuthorize,
    STEPS.InitConfigAwsGlue,
    STEPS.SelectAwsGlueDatabases,
    STEPS.Load,
    STEPS.Finish,
  ],
  [dataSources.looker.value]: [
    STEPS.AddDS,
    STEPS.SelectLookMLProjects,
    STEPS.LookerBIFolders,
    STEPS.Load,
    STEPS.Finish,
  ],
  [dataSources.metabase.value]: [
    STEPS.AddDS,
    STEPS.AddUsageDS,
    STEPS.MetabaseSelectBIFolders,
    STEPS.Load,
    STEPS.Finish,
  ],
  [dataSources.mode.value]: [
    STEPS.AddDS,
    STEPS.AddDiscoveryDB,
    STEPS.ModeSelectBIFolders,
    STEPS.Load,
    STEPS.Finish,
  ],
  [dataSources.periscope.value]: [
    STEPS.AddDS,
    STEPS.PeriscopeSelectBIFolders,
    STEPS.Load,
    STEPS.Finish,
  ],
  [dataSources.mysql.value]: DWH_EXTERNAL_QUERY_LOG_STEPS,
  [dataSources.mssql.value]: DWH_EXTERNAL_QUERY_LOG_STEPS,
  [dataSources.oracle.value]: DWH_EXTERNAL_QUERY_LOG_STEPS,
  [dataSources.postgres.value]: DWH_EXTERNAL_QUERY_LOG_STEPS,
  [dataSources.power_bi.value]: [
    STEPS.AddDS,
    STEPS.PowerBISelectBIFolders,
    STEPS.Load,
    STEPS.Finish,
  ],
  [dataSources.quicksight.value]: [
    STEPS.AddDS,
    STEPS.AWSAuthorize,
    STEPS.AddUsageDS,
    STEPS.Load,
    STEPS.Finish,
  ],
  [dataSources.redshift.value]: [
    STEPS.AddDS,
    STEPS.AWSAuthorize,
    STEPS.SelectDBs,
    STEPS.SelectSchemas,
    STEPS.Load,
    STEPS.Finish,
  ],
  [dataSources.tableau.value]: [
    STEPS.AddDS,
    STEPS.AddUsageDS,
    STEPS.MetabaseSelectBIFolders,
    STEPS.Load,
    STEPS.Finish,
  ],
  [dataSources.salesforce.value]: [STEPS.AddDS, STEPS.Load, STEPS.Finish],
};

export function getStepsForDS(
  dsType: string = '',
  showOnlyIngestStep: boolean = false,
  isIngestionComplete: boolean = false,
) {
  const getSteps = () => {
    if (dsType in DS_STEP_CONFIG) return DS_STEP_CONFIG[dsType];
    if (isWarehouseType(dsType)) return DB_STEPS;
    return DEFAULT_STEPS;
  };

  let steps = getSteps();
  if (showOnlyIngestStep) {
    steps = steps.filter((step) => step !== STEPS.Finish);
  }
  if (!isIngestionComplete) {
    steps = steps.filter((step) => !BI_FOLDER_EDIT_STEPS.includes(step));
  }
  return steps;
}
