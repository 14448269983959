import { Expose, Type } from 'class-transformer';

import { TableModel } from '@api/tables/TableModel';
import DataTypesModel from '@models/DataTypesModel';

import ConditionModel from './ConditionModel';

const objectType = 'index' as const;

class IndexModel {
  static objectType = objectType;

  objectType = objectType;

  @Type(() => ConditionModel)
  conditions?: Array<ConditionModel>;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel;

  definition?: string;

  guid!: string;

  name?: string;

  @Type(() => TableModel)
  table?: TableModel;
}

export default IndexModel;
