import styled from '@emotion/styled';

import rem from '@styles/mixins/rem';

/**
 * @deprecated. Use Tree.v2 instead.
 */
export const StyledTree = styled.div`
  .tree-treenode {
    align-items: center;
    display: flex;
    outline: none;
    min-height: fit-content;
    /* padding: 4px 0; */
    position: relative;
    font-size: ${rem(14)};
    padding-left: ${({ theme }) => theme.space(2)};
    padding-right: 0;

    &.dragging {
      opacity: 0.75;
      user-select: none;
    }

    &.drag-over {
      background: #e2e9f0;
    }

    &.tree-treenode-selected,
    &:hover {
      font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
      background-color: #e2e9f0;
      color: ${({ theme }) => theme.colors.primary};
    }

    .tree-node-content-wrapper {
      width: 100%;
      align-items: stretch;
      color: inherit;
      display: flex;
      line-height: ${({ theme }) => theme.typography.lineHeights.default};
    }

    .tree-switcher {
      cursor: pointer;
      display: flex;
      justify-content: center;
      flex-shrink: 0;
      width: 16px;
      &_open {
        svg {
          transform: rotate(90deg);
        }
      }
    }

    .tree-iconEle {
      flex-shrink: 0;

      a {
        height: 100%;
        display: flex;
        align-items: center;
        color: inherit;
      }
    }

    .tree-title {
      width: 100%;
      margin: 0;

      a {
        color: inherit;
        display: block;
        padding-left: ${({ theme }) => theme.space(0.5)};
        padding-right: ${({ theme }) => theme.space(2)};
        padding-top: ${({ theme }) => theme.space(0.6)};
        padding-bottom: ${({ theme }) => theme.space(0.6)};

        &:hover {
          color: inherit;
        }
      }
    }

    .tree-indent {
      display: inline-block;
      user-select: none;
      white-space: nowrap;

      .tree-indent-unit {
        display: inline-block;
        width: 0;
      }
    }
  }
`;

export const TreeDropIndicator = styled.div`
  position: absolute;
  height: 4px;
  background: #7490be;
  pointer-events: none;
  left: 0;
  right: 0;
`;
