import { useMemo } from 'react';

import { useFetchOrganizationUsers } from '@api/organizationUser';
import { UserModel } from '@api/user/UserModel';
import { mapOwnerToOption } from '@components/OwnerSelect/utils';
import { Option } from '@components/UI/Select';
import stripSpaces from '@utils/stripSpaces';

const TEAM_USERS_PARAMS = {
  is_active: true,
  order: 'role,name',
  page: 1,
  page_size: 4,
  query: stripSpaces(`{
    role,
    user{
      guid,
      avatar,
      first_name,
      last_name,
      is_active,
      team
    }
  }`),
  roles: 'admin,data_manager',
};

const useDefaultOptions = (deletedUser: UserModel) => {
  const { data: organizationUsers, isLoading } = useFetchOrganizationUsers({
    enabled: Boolean(deletedUser?.team?.guid),
    params: {
      teams: deletedUser?.team?.guid,
      ...TEAM_USERS_PARAMS,
    },
  });

  const options: Option[] = useMemo(() => {
    if (!deletedUser?.team?.guid) return [];

    if (organizationUsers?.results && organizationUsers?.results.length > 0) {
      const teamUsers = organizationUsers.results
        .map((orgUser) => orgUser.user)
        .filter((user) => user.guid !== deletedUser.guid);
      const formattedOptions = [deletedUser.team, ...teamUsers].map((owner) =>
        mapOwnerToOption(owner),
      );

      return formattedOptions;
    }

    return [];
  }, [deletedUser, organizationUsers]);
  return { isLoading, options };
};

export default useDefaultOptions;
