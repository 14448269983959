import React, { useState } from 'react';
import AvatarEdit from 'react-avatar-edit';

import invalidateCache from '@api/invalidateCache';
import { usePostSettingsAvatar } from '@api/settings';
import Alert from '@components/Alert';
import Button from '@components/Button/Button';
import Form from '@components/Form';
import { renderInfoToast } from '@components/Toast';
import Modal, { ModalContent, ModalFooter, ModalHeader } from '@components/UI/Modal';
import convertDataURLtoFile from '@utils/convertDataURLtoFile';

interface ChangeProfilePictureModalProps {
  onClose?: () => void;
}

const ChangeProfilePictureModal: React.FC<ChangeProfilePictureModalProps> = ({ onClose }) => {
  const [picture, setPicture] = useState<string | undefined>();

  const { isError, isLoading, mutate } = usePostSettingsAvatar({
    onSuccess: () => {
      invalidateCache((keys) => [
        keys.organizationUser.all,
        keys.organizationUser.organizationUser('me'),
        keys.user.me,
      ]);
      onClose?.();
      renderInfoToast('Profile picture changed.');
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (picture) {
      const file = convertDataURLtoFile(picture, 'avatar.png');
      const formData = new FormData();
      formData.append('file', file);
      mutate(formData);
    }
  };

  return (
    <Modal onClose={onClose}>
      <ModalHeader onClose={onClose} title="Change Profile Picture" />
      <Form isLoading={isLoading} onSubmit={handleSubmit}>
        <ModalContent
          alignItems="center"
          compDisplay="flex"
          flexDirection="column"
          gap={2}
          maxHeight="fit-content"
          py={2}
        >
          <AvatarEdit
            height={300}
            imageHeight={300}
            imageWidth={400}
            onCrop={setPicture}
            width={400}
          />
          {isError && <Alert title="Couldn't save the image." type="error" />}
        </ModalContent>
        <ModalFooter>
          <Button disabled={!picture} type="submit">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ChangeProfilePictureModal;
