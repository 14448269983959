import React from 'react';

import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import { TagModel } from '@api/tags/TagModel';
import AppTag from '@components/AppTag';
import Box from '@components/Box';
import Ellipsis from '@components/Ellipsis';
import TagsSelect, { TagsSelectProps } from '@components/TagsSelect/TagsSelect';
import Tooltip from '@components/Tooltip';
import type { Option } from '@components/UI/Select';
import { useTagContext } from '@context/Tag';
import { GetColorProps } from '@styles/mixins/getColor';
import { alphabetically } from '@utils/sort';

interface TaggedItemsProps extends TagsSelectProps {
  showEllipsis?: boolean;
  tagTextColor?: GetColorProps;
}

const sortTagsByName = (tags: TagModel[]) => {
  return tags.sort((a, b) => {
    return alphabetically(true)(a.name, b.name);
  });
};

const WrappedBox: React.FC = ({ children }) => (
  <Box alignItems="center" compDisplay="flex" flexWrap="wrap" gap={0.1}>
    {children}
  </Box>
);

const TaggedItems: React.FC<TaggedItemsProps> = ({
  showEllipsis = false,
  tagTextColor,
  ...rest
}) => {
  const { findTag } = useTagContext();

  const getTooltipText = (selectedItems: TagModel[]) => {
    return selectedItems
      ?.map((tag) => {
        const breadcrumbs = tag?.breadcrumbLabelList
          ?.map((breadcrumb) => breadcrumb.label)
          .join(' / ');

        return breadcrumbs;
      })
      .join(', ');
  };

  const renderSelectedItems = (selectedItems: Option[]) => {
    let selectedTags: TagModel[] = [];
    const categoryTags: TagModel[] = [];
    const statusTags: TagModel[] = [];
    const dbtTags: TagModel[] = [];
    const taggedItems: {
      [tagGuid: string]: TaggedItemModel;
    } = {};

    selectedItems.forEach((item) => {
      const tag = findTag?.(item.value as string);
      taggedItems[item.value] = item.original;

      if (tag) {
        const array = {
          category: categoryTags,
          dbt: dbtTags,
          status: statusTags,
        };

        array[tag?.type].push(tag);
      }
    });

    selectedTags = [
      ...sortTagsByName(statusTags),
      ...sortTagsByName(categoryTags),
      ...sortTagsByName(dbtTags),
    ];

    if (selectedItems.length === 0) {
      return null;
    }

    const DynamicWrapper = showEllipsis ? Ellipsis : WrappedBox;

    return (
      <Tooltip content={showEllipsis ? getTooltipText(selectedTags) : ''}>
        <DynamicWrapper>
          {selectedTags.map((tag) => (
            <AppTag
              key={tag.guid}
              item={taggedItems[tag.guid]}
              showBreadcrumbs
              showBreadcrumbsTooltip={!showEllipsis}
              tag={tag}
              tagTextColor={tagTextColor}
              variant="outlined"
            />
          ))}
        </DynamicWrapper>
      </Tooltip>
    );
  };

  return <TagsSelect {...rest} renderSelectedItems={renderSelectedItems} />;
};

export default TaggedItems;
