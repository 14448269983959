import { Expose, Transform, Type } from 'class-transformer';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { breadcrumbsToLabelList, breadcrumbsToList } from '@components/Breadcrumbs';
import isEmptyRichText from '@components/RichTextEditor/helpers/isEmptyRichText';
import { DataSourceModel } from '@models/DataSourceModel';
import DataTypesModel from '@models/DataTypesModel';
import { urlFor } from '@utils/routing';

export class FolderModel {
  static objectType: string = 'folder';

  objectType: string = FolderModel.objectType;

  static typeDisplay: string = 'folder';

  typeDisplay: string = FolderModel.typeDisplay;

  guid!: string;

  name!: string;

  description?: string;

  // TODO: Remove transform https://app.shortcut.com/select-star/story/55620
  @Expose({ name: 'richtext_description' })
  @Transform((richtextDescription) =>
    isEmptyRichText(richtextDescription) ? '' : richtextDescription,
  )
  richtextDescription?: string;

  @Expose({ name: 'search_name' })
  fullName?: string;

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  @Expose({ name: 'data_source' })
  @Type(() => DataSourceModel)
  dataSource!: DataSourceModel;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel;

  get breadcrumbList() {
    return breadcrumbsToList(this.breadcrumbs);
  }

  get breadcrumbLabelList() {
    const url = urlFor(this, false, '');
    return breadcrumbsToLabelList(this.name, url, this.breadcrumbs);
  }
}
