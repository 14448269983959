import React from 'react';

import Modal from '@components/UI/Modal';

import DataSourceSetupContent, { DataSourceSetupContentProps } from './DataSourceSetupContent';

interface DataSourceSetupModalProps extends DataSourceSetupContentProps {}

const DataSourceSetupModal: React.FC<DataSourceSetupModalProps> = ({ onClose, ...props }) => {
  return (
    <Modal onClose={onClose} overflow="hidden" size="large">
      <DataSourceSetupContent {...props} onClose={onClose} />
    </Modal>
  );
};

export default DataSourceSetupModal;
