import styled from '@emotion/styled';

import type { StyledBoxProps } from '@components/Box';
import Box from '@components/Box';
import rootTheme from '@styles/theme';

export const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${({ theme }) => theme.zIndex.floatingElement};
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
`;

export interface StyledModalOverlayProps extends StyledBoxProps {
  transparent?: boolean;
}

export const StyledModalOverlay = styled.div<StyledModalOverlayProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ transparent }) => (transparent ? 'transparent' : 'rgba(0, 0, 0, 0.5)')};
`;

export interface StyledModalCardProps extends StyledBoxProps {
  size?: 'tiny' | 'medium' | 'full' | 'unset' | 'large';
}

const sizes = {
  full: {
    'max-height': '100%',
    'max-width': '100%',
    width: '100%',
  },
  large: {
    'max-height': 'calc(100% - 2rem)',
    'max-width': '900px',
    width: 'calc(100% - 2rem)',
  },
  medium: {
    'max-height': 'calc(100% - 2rem)',
    'max-width': '800px',
    width: 'calc(100% - 2rem)',
  },
  tiny: {
    'max-height': 'calc(100% - 2rem)',
    'max-width': '540px',
    width: 'calc(100% - 2rem)',
  },
  unset: {
    'max-height': '',
    'max-width': '',
    width: 'unset',
  },
};

export const StyledModalCard = styled(Box)<StyledModalCardProps>`
  z-index: 1;
  box-shadow: 1px 3px 3px 0 rgba(0, 0, 0, 0.2), 1px 3px 15px 2px rgba(0, 0, 0, 0.2);
  width: ${({ compWidth, size = 'tiny' }) => compWidth ?? sizes[size].width};
  max-height: ${({ size = 'tiny' }) => sizes[size]['max-height']};
  background: ${({ theme }) => theme.colors.white};
  max-width: ${({ size = 'tiny' }) => sizes[size]['max-width']};
  display: flex;
  position: relative;
`;

StyledModalCard.defaultProps = {
  'aria-labelledby': 'dialog-header',
  flexDirection: 'column',
  role: 'dialog',
};

export const StyledModalHeader = styled(Box)`
  height: ${({ compHeight = 'auto' }) => compHeight};
`;

StyledModalHeader.defaultProps = {
  alignItems: 'center',
  borderBottom: `1px solid ${rootTheme.colors.v1.gray[200]}`,
  compDisplay: 'flex',
  gap: 0.5,
  justifyContent: 'space-between',
  minHeight: '65px',
  p: 3,
  pb: 2,
};

export const StyledModalTitle = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.typography.fontSizes.h3};
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  line-height: ${({ theme }) => theme.space(3)};
  gap: ${({ theme }) => theme.space(0.5)};
  display: flex;
  align-items: center;
`;

StyledModalTitle.defaultProps = {
  id: 'dialog-header',
};

export const StyledModalSubTitle = styled.div`
  color: ${({ theme }) => theme.colors.v1.gray[600]};
  font-size: ${({ theme }) => theme.typography.fontSizes.body2};
`;

export const StyledModalContent = styled(Box)`
  position: relative;
  font-size: ${({ theme }) => theme.typography.fontSizes.body2};
`;

StyledModalContent.defaultProps = {
  maxHeight: '18rem',
  minHeight: '10rem',
  noDefault: true,
  overflow: 'auto',
  px: 3,
};

export const StyledModalFooter = styled(Box)`
  position: relative;
  border-top: 1px solid ${({ theme }) => theme.colors.v1.gray[200]};
`;

StyledModalFooter.defaultProps = {
  alignItems: 'center',
  compDisplay: 'flex',
  gap: 1,
  justifyContent: 'flex-end',
  minHeight: '77px',
  pb: 3,
  pt: 2,
  px: 3,
};
