import { COLUMNS_PAGE_DEFAULT_HIDDEN_COLUMNS } from './ColumnsPage';
import { TABLES_TABLE_DEFAULT_HIDDEN_COLUMNS } from './TablesTable';
import { TablePageProps } from './types';

// eslint-disable-next-line import/prefer-default-export
export const TABLES_PAGE_ROUTED_CONFIG: Record<
  string,
  PartialRecord<string, Partial<TablePageProps>>
> = {
  dbt: {
    columns: {
      countItemsConfig: [{ key: 'model_count', text: 'model' }],
      customColumnProps: { popularity: { dropdownCheckboxLabel: 'Popularity' } },
      hiddenColumns: [...COLUMNS_PAGE_DEFAULT_HIDDEN_COLUMNS, 'popularity'],
    },
    tables: {
      countItemsConfig: [{ key: 'model_count', text: 'model' }],
      customColumnProps: { popularity: { dropdownCheckboxLabel: 'Popularity' } },
      hiddenColumns: [...TABLES_TABLE_DEFAULT_HIDDEN_COLUMNS, 'popularity'],
    },
  },
  salesforce: {
    columns: {
      countItemsConfig: [
        { key: 'schema_count', text: 'schema' },
        { key: 'model_count', text: 'object' },
        { key: 'count', text: 'field' },
      ],
      customColumnProps: {
        name: { Header: 'Field' },
        popularity: { dropdownCheckboxLabel: 'Popularity' },
      },
      hiddenColumns: [...COLUMNS_PAGE_DEFAULT_HIDDEN_COLUMNS, 'label', 'dataType', 'popularity'],
      visibleColumns: [
        'search',
        'name',
        'label',
        'formula',
        'dataType',
        'salesforceDataType',
        'description',
        'tags',
        'popularity',
        'downstreamObjectsCounts',
        'downstreamChartsCounts',
        'upstreamObjectsCounts',
      ],
    },
    tables: {
      countItemsConfig: [
        { key: 'schema_count', text: 'schema' },
        { key: 'count', text: 'object' },
      ],
      customColumnProps: { popularity: { dropdownCheckboxLabel: 'Popularity' } },
      hiddenColumns: [...TABLES_TABLE_DEFAULT_HIDDEN_COLUMNS, 'popularity'],
    },
  },
};

export const getTablesPageRoutedConfig = (dataSourceType?: string, objectType?: string) =>
  TABLES_PAGE_ROUTED_CONFIG[dataSourceType!]?.[objectType!] as Partial<TablePageProps> | undefined;
