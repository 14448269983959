import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { DataSourceModel } from '@models/DataSourceModel';

import { UserModel } from '../user/UserModel';

export type Provider = 'bitbucket' | 'github';

export class ExtraConfigModel {
  @Expose({ name: 'dbt_project_filepath' })
  dbtProjectFilepath!: string;

  @Expose({ name: 'package_name' })
  packageName!: string;
}

export class RepositoryModel {
  guid!: string;

  @Expose({ name: 'scm_provider' })
  provider!: Provider;

  url!: string;

  owner!: string;

  @Expose({ name: 'repository' })
  name!: string;

  @Type(() => DataSourceModel)
  @Expose({ name: 'data_source' })
  dataSource!: DataSourceModel;

  @Expose({ name: 'last_successful_sync' })
  @Transform((value) => value && moment(value))
  lastSuccessfulSync!: moment.Moment;

  @Expose({ name: 'files_path_prefix' })
  filePathPrefix!: string;

  @Type(() => ExtraConfigModel)
  @Expose({ name: 'extra_conf' })
  extraConfig!: ExtraConfigModel;

  @Type(() => UserModel)
  @Expose({ name: 'created_by' })
  creator!: UserModel;
}

export class RepositoryNextExecutionModel {
  @Expose({ name: 'next_execution' })
  @Transform((value) => value && moment(value))
  nextExecution!: moment.Moment;
}
