import React from 'react';

import { dsUsersCacheKeys, usePatchDsUsersModeServiceAccount } from '@api/dsusers';
import Button from '@components/Button/Button';
import CircularLoader from '@components/CircularLoader';
import TableList from '@components/TableList';
import { renderErrorToast, renderInfoToast } from '@components/Toast';
import Modal, { ModalContent, ModalFooter, ModalHeader } from '@components/UI/Modal';
import fetchClient from '@lib/fetchClient';
import { MetadataData } from '@models/MetadataModel';

interface RemoveModeServiceAccountModalProps {
  onClose: () => void;
  onSuccess: () => void;
  selectedItems: MetadataData[];
}

const RemoveModeServiceAccountModal: React.FC<RemoveModeServiceAccountModalProps> = ({
  onClose,
  onSuccess,
  selectedItems,
}) => {
  const { isLoading, mutate: updateDsUsers } = usePatchDsUsersModeServiceAccount({
    onError: () => {
      renderErrorToast('There was a problem removing the connections.');
      onClose();
    },
    onSuccess: () => {
      renderInfoToast('Connections successfully removed.');
      fetchClient.invalidateQueries(dsUsersCacheKeys.dsUsers);
      onClose();
      onSuccess();
    },
  });

  const handleSubmit = () => {
    updateDsUsers({ items: selectedItems.map((item) => item.guid) });
  };

  return (
    <Modal onClose={onClose}>
      <ModalHeader onClose={onClose} title="Remove Data Source User Connections" />
      <ModalContent compDisplay="flex" flexDirection="column" gap={1} minHeight="unset" py={3}>
        Are you sure you want to remove these connections?
        <TableList items={selectedItems} />
      </ModalContent>
      <ModalFooter>
        <Button
          disabled={isLoading}
          onClick={handleSubmit}
          startIcon={isLoading && <CircularLoader compSize={1.5} />}
          type="submit"
        >
          Remove
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RemoveModeServiceAccountModal;
