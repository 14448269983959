import React from 'react';

import { usePatchDbtProject, usePostDbtProject } from '@api/dbt';
import { useFetchDbtProjects } from '@api/dbt/dbt';
import { DbtTypes } from '@api/dbt/types';
import invalidateCache from '@api/invalidateCache';
import NextStepButton from '@components/DataSourceSetup/components/Buttons/NextStepButton';
import DataSourceAddStepError from '@components/DataSourceSetup/components/DataSourceAddStep/DataSourceAddStepError';
import useForm from '@components/Form/useForm';
import type { FormValues } from '@components/Forms/CreateDbtProjectForm';
import CreateDbtProjectForm, { FieldKey } from '@components/Forms/CreateDbtProjectForm';
import {
  DEFAULT_URL,
  getCredentialsByType,
  getDatasourceOptions,
  REQUIRED_FIELDS,
} from '@components/Forms/CreateDbtProjectForm/utils';
import { renderErrorToast, renderInfoToast } from '@components/Toast';
import Modal, { ModalFooter, ModalHeader } from '@components/UI/Modal';
import { useModal } from '@context/Modal';
import type { DataSourceModel } from '@models/DataSourceModel';
import stripSpaces from '@utils/stripSpaces';

interface CreateDbtProjectModalProps {
  dataSource: DataSourceModel;
}

const CreateDbtProjectModal: React.FC<CreateDbtProjectModalProps> = ({ dataSource }) => {
  const { MODAL_IDS, closeModal, getModalContent } = useModal();
  const dbtProject = getModalContent(MODAL_IDS.createDbtProject);
  const isPatch = Boolean(dbtProject?.guid);

  // [edge case] if its a new project fetch an existing one to use as default value [dialect, target_data_source]
  const { data: defaultDbtProjectResponse, isLoading: defaultDbtProjectIsLoading } =
    useFetchDbtProjects({
      enabled: Boolean(!isPatch),
      params: {
        data_source: dataSource?.guid,
        page_size: 1,
        query: stripSpaces(`{
        dialect,
        target_data_source{
          guid,
          type,
          data_types,
          name,
        }
      }`),
      },
    });

  const onSuccess = () => {
    renderInfoToast('Changes saved.');
    invalidateCache((keys) => [keys.dbt.projects]);
    closeModal(MODAL_IDS.createDbtProject);
  };

  const onError = () => {
    renderErrorToast('There was an error saving your changes.');
  };

  const patchQuery = usePatchDbtProject(dbtProject?.guid ?? '', { onError, onSuccess });
  const postQuery = usePostDbtProject({ onError, onSuccess });
  const defaultDbtProject = defaultDbtProjectResponse?.results?.[0];

  const { error, isLoading, mutate } = isPatch ? patchQuery : postQuery;

  const { handleChange, handleSubmit, setValues, values } = useForm<FormValues>({
    initialValues: {
      cloudBaseUrl: isPatch ? undefined : DEFAULT_URL,
      cloudJobId: undefined,
      cloudToken: undefined,
      coreCatalogJson: undefined,
      coreManifestJson: undefined,
      coreRunResultsJson: undefined,
      dbtType: isPatch ? dbtProject?.dbtType! : DbtTypes.cloud,
      dialect: isPatch ? dbtProject?.dialect : defaultDbtProject?.dialect,
      name: dbtProject?.name,
      targetDataSource: isPatch
        ? dbtProject?.targetDataSource
        : defaultDbtProject?.targetDataSource,
    },
    onSubmit: (val) => {
      mutate({
        data_source: dataSource,
        dbt_type: val.dbtType,
        dialect: val.dialect,
        name: val.name,
        ...getCredentialsByType(val),
      });
    },
    syncInitialValues: true,
  });

  const handleClose = () => {
    closeModal(MODAL_IDS.createDbtProject);
  };

  const customFieldProps = {
    [FieldKey.name]: { label: 'Project Name' },
    [FieldKey.dialect]: { disabled: true },
    [FieldKey.targetDataSource]: { disabled: true },
  };

  const isInvalid = isPatch
    ? false
    : REQUIRED_FIELDS[values.dbtType].some((key) => Boolean(values[key]) === false);

  const userDWHOptions = values.targetDataSource
    ? getDatasourceOptions({ [values.targetDataSource.guid]: values.targetDataSource })
    : [];

  return (
    <Modal compWidth="600px" onClose={handleClose} size="unset">
      <ModalHeader onClose={handleClose} title="Add a New Project" />
      <CreateDbtProjectForm
        customFieldProps={customFieldProps}
        error={error}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        isLoading={isLoading || defaultDbtProjectIsLoading}
        setValues={setValues}
        showEditMsg={isPatch}
        userDWHOptions={userDWHOptions}
        values={values}
      >
        <DataSourceAddStepError error={error} />;
        <ModalFooter>
          <NextStepButton disabled={isInvalid} text="Save" />
        </ModalFooter>
      </CreateDbtProjectForm>
    </Modal>
  );
};

export default CreateDbtProjectModal;
