import React, { memo, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useFetchLineageNodes } from '@api/lineage';
import Box from '@components/Box';
import { ExploreNode } from '@components/Explore.v1/Explore.types';
import useExpandExploreNode from '@components/Explore.v1/useExpandExploreNode';
import { useExplore } from '@components/Explore.v1/useExplore';
import {
  LineageNodeOpeningState,
  LineageNodeOptionsMenuState,
  SidebarOpeningOrigin,
} from '@components/Explore.v1/useExplore/Explore.context.types';
import useSearchChildren from '@components/Explore.v1/useSearchChildren';
import useUserTracking from '@components/Explore.v1/useUserTracking';
import Icon from '@components/UI/Icon';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import flags from '@features';
import theme from '@styles/theme';
import { SPACE } from '@styles/theme/grid';
import useComponentHover from '@utils/useComponentHover';

import ColumnsSeachInput from '../../Common/ColumnsSeachInput';
import NoMatchedDataBadge from '../../Common/NoMatchedDataBadge';
import NodeHandles from '../../EdgeHandle/NodeHandles';
import { COLORS } from '../../ExploreTree/ExploreTree.colors';
import LineageTooltip from '../../LineageTooltip';
import { SIZES } from '../config';
import { getColorsBasedOnDataTypes } from '../Nodes.utils';
import UpstreamDownstreamButton from '../UpstreamDownstreamButton';

import {
  StyledTableNode,
  StyledTableNodeContent,
  StyledTableNodeIconContainer,
  StyledTableNodeSearchContainer,
  StyledTableNodeTitle,
} from './TableNode.styles';
import TableNodeOptions from './TableNodeOptions';

const TableNode: React.FC<ExploreNode> = ({ children, data }) => {
  const {
    dataTypes,
    downstreamObjectsCount,
    guid = '',
    hideColumns,
    hideDownstreamButton,
    hideUpstreamButton,
    isBITable = false,
    isOpen = false,
    isSearchEnabled = false,
    isUnfocused,
    key,
    linkedObjs,
    name,
    noMatchedChildren = false,
    onExpandNode,
    searchName,
    upstreamObjectsCount,
  } = data;

  const trackingData = {
    dataTypes: {
      dataSourceType: dataTypes?.dataSourceType,
      dataType: dataTypes?.dataType,
      objectType: dataTypes?.objectType,
    },
    downstreamObjectsCount,
    guid,
    isBITable,
    key,
    name,
    upstreamObjectsCount,
  };
  const areColumnsLoaded = useRef(false);
  const shouldColumnLoadExpandTable = useRef<boolean>(false);
  const { track } = useUserTracking();
  const [searchText, setSearchText] = useState<string | undefined>();
  const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const { selectedEdge, selectedNode, setSelectedNode } = useExplore();
  const {
    centeredTableKey,
    focusedNodeKey,
    initialSelectedNodeGuid,
    initialSelectedNodeParentGuid,
    inputNodesById,
    isColumnLevelLineage,
    isColumnLevelLoading,
    openOverviewTab,
    setSelectedEdge,
  } = useExplore();

  const { mergeTableNodeColumns, updateNodesOnExpand } = useExpandExploreNode();
  const { updateNodesOnSearch } = useSearchChildren(key);
  const shouldRequestTableColumns =
    isColumnLevelLoading && !isOpen && !isColumnLevelLineage && !areColumnsLoaded.current;

  const {
    data: columnsData,
    isLoading: isLoadingColumnsData,
    refetch: requestTableColumns,
  } = useFetchLineageNodes({
    enabled: guid === initialSelectedNodeGuid,
    params: {
      // @ts-expect-error
      order: '-popularity,name',
      parents: guid,
    },
  });

  useEffect(() => {
    if (!areColumnsLoaded.current && !isLoadingColumnsData && columnsData && key) {
      mergeTableNodeColumns({
        newColumns: columnsData.results,
        nodeKey: key,
        shouldExpandTableNode: shouldColumnLoadExpandTable.current,
      });
      areColumnsLoaded.current = true;
    }
  }, [columnsData, isLoadingColumnsData, key, mergeTableNodeColumns]);

  const isFocused = Boolean(focusedNodeKey) && !isUnfocused;

  const { isHovered } = useComponentHover(containerRef);

  const colors = getColorsBasedOnDataTypes(dataTypes);

  const showOpenDownstreamLevelButton = () => {
    if (hideDownstreamButton === undefined && initialSelectedNodeParentGuid === guid) {
      return false;
    }

    if (initialSelectedNodeGuid === guid) {
      if (hideDownstreamButton === undefined) {
        return false;
      }
      if (hideDownstreamButton === false) {
        return true;
      }
    }
    return !hideDownstreamButton && (downstreamObjectsCount ?? 0) > 0;
  };

  const showOpenUpstreamLevelButton = () => {
    if (hideUpstreamButton === undefined && initialSelectedNodeParentGuid === guid) {
      return false;
    }

    if (initialSelectedNodeGuid === guid) {
      if (hideUpstreamButton === undefined) {
        return false;
      }
      if (hideUpstreamButton === false) {
        return true;
      }
    }
    return !hideUpstreamButton && (upstreamObjectsCount ?? 0) > 0;
  };

  const shouldShowOpenLevelButton = {
    downstream: showOpenDownstreamLevelButton(),
    upstream: showOpenUpstreamLevelButton(),
  };

  const debouncedSearch = useDebouncedCallback((query: string) => {
    updateNodesOnSearch(query);
  }, 300);

  const nodeClickAction = () => {
    onExpandNode?.();
    if (key) {
      openOverviewTab(SidebarOpeningOrigin.Table);
      setSelectedNode({
        guid,
        key,
        metadata: selectedNode?.guid === guid ? selectedNode?.metadata : undefined,
        type: 'table',
      });

      if (!hideColumns) {
        if (shouldRequestTableColumns) {
          shouldColumnLoadExpandTable.current = true;
          requestTableColumns();
        } else {
          updateNodesOnExpand({
            nodeKey: key,
            shouldEnableFocusedLineage: !isColumnLevelLineage,
          });
        }
      }
    }
  };

  const menuClickAction = (menuIsOpen: boolean) => {
    track(SegmentTrackEventName.LineageTableOptionMenuToggled, {
      ...trackingData,
      state: menuIsOpen ? LineageNodeOptionsMenuState.Open : LineageNodeOptionsMenuState.Closed,
    });
    setIsOptionsMenuOpen(menuIsOpen);
  };

  const handleOnClick = (event: React.MouseEvent) => {
    track(SegmentTrackEventName.LineageTableNodeClicked, {
      ...trackingData,
      stateWhenClicked: isOpen
        ? LineageNodeOpeningState.Expanded
        : LineageNodeOpeningState.Collapsed,
    });
    event.preventDefault();
    const content = contentRef.current;

    if (!(content === event.target || content?.contains(event.target as Node))) {
      nodeClickAction();
    }
  };

  const handleOpenMenu = (menuIsOpen: boolean) => {
    if (shouldRequestTableColumns) {
      requestTableColumns();
    }

    setSelectedEdge(undefined);
    menuClickAction(menuIsOpen);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchText(value);
  };

  const isConnectedToSelectedEdge = selectedEdge?.target === key || selectedEdge?.source === key;

  const getDynamicBorderColor = () => {
    if (isConnectedToSelectedEdge) {
      return COLORS.node.connectedToSelectedEdge;
    }

    if ((isFocused || selectedNode?.guid === guid) && !isColumnLevelLineage) {
      return colors.primary;
    }

    return theme.colors.v1.gray[300];
  };

  useEffect(() => {
    if (searchText !== undefined) {
      debouncedSearch(searchText);
    }
  }, [debouncedSearch, searchText]);

  const iconName = dataTypes?.icons.dataType ?? 'table';
  const isImplicit = dataTypes?.dataType === 'implicit';
  const hasDbtLinkedObjs =
    dataTypes?.dataSourceType === 'dbt' ? false : linkedObjs && linkedObjs?.length > 0;

  const tableInputData = inputNodesById?.[guid];
  const isDownstreamLeaf =
    Object.keys(tableInputData?.target_edges ?? {}).length === 0 &&
    !shouldShowOpenLevelButton.downstream;
  const isUpstreamLeaf =
    Object.keys(tableInputData?.source_edges ?? {}).length === 0 &&
    !shouldShowOpenLevelButton.upstream;
  const isSelectedNode = selectedNode?.guid === guid;
  const tooltipText = isBITable ? searchName : name;

  return (
    <StyledTableNode
      ref={containerRef}
      backgroundColor="gray.200"
      borderColor={getDynamicBorderColor()}
      data-testid={`table-node-${key}`}
      isBITable={isBITable}
      isColumnLevelLineage={isColumnLevelLineage}
      isDownstreamLeaf={isDownstreamLeaf}
      isHoverAnimationActive={centeredTableKey === key}
      isImplicit={isImplicit}
      isOpen={isOpen}
      isSelectedNode={isSelectedNode}
      isUnfocused={isUnfocused}
      isUpstreamLeaf={isUpstreamLeaf}
      onClick={handleOnClick}
    >
      <NodeHandles height="table" />
      {shouldShowOpenLevelButton.upstream && (
        <UpstreamDownstreamButton
          direction="upstream"
          guid={guid}
          left="14px"
          nodeKey={key ?? ''}
          nodeType="table"
        />
      )}
      <Box compDisplay="flex" compHeight="100%" flexDirection="column">
        <Box alignItems="center" compDisplay="flex" compWidth="100%" gap={0.75}>
          <Box alignItems="center" compDisplay="flex" gap={0.5}>
            <LineageTooltip
              content={isImplicit ? 'Temporary table (inferred)' : ''}
              offset={[8, 15]}
            >
              <StyledTableNodeIconContainer backgroundColor={colors.secondary}>
                <Icon color={colors.primary} name={iconName} size="16px" />
              </StyledTableNodeIconContainer>
            </LineageTooltip>
            {hasDbtLinkedObjs && (
              <LineageTooltip content={dataTypes?.tooltips?.dataType}>
                <Icon name="dbt" size="15px" />
              </LineageTooltip>
            )}
          </Box>
          <LineageTooltip content={tooltipText} offset={[8, 15]} placement="bottom-start">
            <StyledTableNodeTitle>{flags.new_lineage_debug_mode ? key : name}</StyledTableNodeTitle>
          </LineageTooltip>
          <Box mr={isHovered && shouldShowOpenLevelButton.downstream ? 3.25 : 0}>
            <TableNodeOptions
              dataSourceType={dataTypes?.dataSourceType}
              disableSearch={hideColumns}
              downstreamObjectsCount={downstreamObjectsCount}
              guid={guid}
              isBITable={isBITable}
              isOpen={isOptionsMenuOpen}
              nodeKey={key ?? ''}
              setIsOpen={handleOpenMenu}
              show={isHovered}
              trackingData={trackingData}
              upstreamObjectsCount={upstreamObjectsCount}
            />
          </Box>
        </Box>
        {isOpen && (
          <StyledTableNodeContent
            ref={contentRef}
            data-testid="table-node-content"
            gap={SIZES.rowGap.column / SPACE}
          >
            {isSearchEnabled && (
              <StyledTableNodeSearchContainer>
                <ColumnsSeachInput onChange={handleSearchChange} value={searchText} />
              </StyledTableNodeSearchContainer>
            )}
            {noMatchedChildren ? <NoMatchedDataBadge /> : children}
          </StyledTableNodeContent>
        )}
      </Box>
      {shouldShowOpenLevelButton.downstream && (
        <UpstreamDownstreamButton
          direction="downstream"
          guid={guid}
          nodeKey={key ?? ''}
          nodeType="table"
          right="14px"
        />
      )}
    </StyledTableNode>
  );
};

const MemoizedTableNode = memo<typeof TableNode>(TableNode);

export default MemoizedTableNode;
