import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

import { VariantSize } from '@components/Button/Button.styles.variants';
import rootTheme from '@styles/theme';

export type { VariantSize };

export type VariantColor = 'white' | 'gray';
export type VariantState = 'default' | 'error' | 'disabled' | 'focused';
type VariantColorStyle = Record<VariantState, SerializedStyles>;

const white: VariantColorStyle = {
  default: css`
    background-color: ${rootTheme.colors.white};
    border-color: ${rootTheme.colors.v1.gray[300]};
  `,
  disabled: css`
    background-color: ${rootTheme.colors.white};
    border-color: ${rootTheme.colors.v1.gray[300]};
    opacity: 0.3;
  `,
  error: css`
    background-color: ${rootTheme.colors.white};
    border-color: ${rootTheme.colors.error};
  `,
  focused: css`
    background-color: ${rootTheme.colors.white};
    border-color: ${rootTheme.colors.v1.primary[400]};
    border-width: 2px;
  `,
};

const gray: VariantColorStyle = {
  default: css`
    background-color: ${rootTheme.colors.v1.gray[50]};
    border-color: ${rootTheme.colors.v1.gray[200]};
  `,
  disabled: css`
    background-color: ${rootTheme.colors.v1.gray[50]};
    border-color: ${rootTheme.colors.v1.gray[200]};
    opacity: 0.3;
  `,
  error: css`
    background-color: ${rootTheme.colors.v1.gray[50]};
    border-color: ${rootTheme.colors.error};
  `,
  focused: css``,
};

export const colorVariants: Record<VariantColor, VariantColorStyle> = {
  gray,
  white,
};

export const sizeVariants: Record<VariantSize, SerializedStyles> = {
  default: css`
    height: 36px;
  `,
  lg: css`
    height: 42px;
  `,
  sm: css`
    height: 32px;
  `,
  xs: css`
    height: 28px;
  `,
  xxs: css`
    height: 20px;
  `,
};
