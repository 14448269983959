import { Type } from 'class-transformer';

import { ColumnModel } from '@api/columns/ColumnModel';
import { LookerExploreFieldModel } from '@api/explores/LookerExploreFieldModel';
import { TableauFieldModel } from '@api/tableau/TableauFieldModel';
import { Reference } from '@models/Reference';

export type DimensionItemType = LookerExploreFieldModel | TableauFieldModel | ColumnModel;

export class DimensionItemModel {
  static objectType: string = 'dimensionitem';

  objectType: string = DimensionItemModel.objectType;

  static typeDisplay: string = 'Dimension Item';

  typeDisplay: string = DimensionItemModel.typeDisplay;

  @Type(() => Reference)
  field!: Reference<DimensionItemType>;
}
