import React from 'react';
import { UNTITLED_GUID } from '@constants';
import { useHistory } from 'react-router-dom';

import CreateMetricModal from '@components/Modal/CreateMetricModal';
import Select, { Option, SelectValue } from '@components/UI/Select';
import { useModal } from '@context/Modal';
import { urlFor } from '@utils/routing';

import CreateTermModal from './CreateTermModal/CreateTermModal';

const OPTIONS: Option[] = [
  {
    icon: 'document',
    text: 'Page',
    value: 'document',
  },
  { icon: 'metric', text: 'Metric', value: 'metric' },
  { icon: 'term', text: 'Glossary', value: 'glossary' },
];

const CreateDocMenu: React.FC = () => {
  const { MODAL_IDS, checkModalOpened, closeModal, openModal } = useModal();
  const history = useHistory();

  const handleOptionClick = (option: SelectValue) => {
    const [selected] = option as Option[];

    switch (selected.value) {
      case 'metric':
        openModal(MODAL_IDS.metric);
        break;
      case 'document':
        history.push(urlFor({ guid: UNTITLED_GUID, objectType: 'docs' }));
        break;
      case 'glossary':
        openModal(MODAL_IDS.glossary);
        break;
      default:
    }
  };

  return (
    <>
      <Select
        isDropdown
        leftIcon="plus"
        onChange={handleOptionClick}
        options={OPTIONS}
        placeholder="Add a New Doc"
      />
      {checkModalOpened(MODAL_IDS.metric) && (
        <CreateMetricModal
          onClose={() => {
            closeModal(MODAL_IDS.metric);
          }}
        />
      )}
      {checkModalOpened(MODAL_IDS.glossary) && (
        <CreateTermModal
          onClose={() => {
            closeModal(MODAL_IDS.glossary);
          }}
        />
      )}
    </>
  );
};

export default CreateDocMenu;
