import React, { useEffect, useRef, useState } from 'react';

import Box from '@components/Box';
import usePopper from '@components/Popper/usePopper';
import Portal from '@components/Portal';
import Icon, { IconProps } from '@components/UI/Icon';
import useComponentHover from '@utils/useComponentHover';

import {
  StyledIconSelect,
  StyledIconSelectOption,
  StyledIconSelectOptionIcon,
  StyledIconSelectOptionsContainer,
} from './IconSelect.styles';

interface IconValue {
  color: string;
  icon: IconProps['name'];
}

interface IconSelectProps {
  onChange: (newValue: IconValue) => void;
  options: Array<{
    key: string;
    text: React.ReactNode;
    value: IconValue;
  }>;
  value: IconValue;
}

const IconSelect = ({ onChange, options, value }: IconSelectProps) => {
  const [isPopperHovered, setIsPopperHovered] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const anchorContainerRef = useRef(null);
  const { isHovered: isAnchorHovered } = useComponentHover(anchorContainerRef);

  const { anchorProps, popperProps } = usePopper({
    fallbackPlacements: ['bottom-end'],
    offset: [0, 0],
    placement: 'bottom-start',
    strategy: 'fixed',
  });

  const { color, icon } = value;

  useEffect(() => {
    setShowOptions(isPopperHovered || isAnchorHovered);
  }, [isPopperHovered, isAnchorHovered]);

  const handleOptionClick = (optionValue: IconValue) => {
    onChange(optionValue);
    setShowOptions(false);
  };

  return (
    <>
      <Box ref={anchorContainerRef}>
        <StyledIconSelect role="listbox" {...anchorProps}>
          <Icon color={color} name={icon} size="20px" />
        </StyledIconSelect>
      </Box>
      {showOptions && (
        <Portal>
          <StyledIconSelectOptionsContainer
            onMouseEnter={() => setIsPopperHovered(true)}
            onMouseLeave={() => setIsPopperHovered(false)}
            {...popperProps}
          >
            {options.map(({ key, value: optionValue }) => (
              <StyledIconSelectOption
                key={key}
                onClick={() => handleOptionClick(optionValue)}
                role="option"
              >
                <StyledIconSelectOptionIcon
                  color={optionValue.color}
                  name={optionValue.icon}
                  size="25px"
                />
              </StyledIconSelectOption>
            ))}
          </StyledIconSelectOptionsContainer>
        </Portal>
      )}
    </>
  );
};

export default IconSelect;
