import { RelatedDashboardModel } from '@api/dashboards/RelatedDashboardModel';
import { LookerExploreModel } from '@api/explores/LookerExploreModel';
import { LookerExplorePaginatedResponse } from '@api/explores/LookerExplorePaginatedResponse';
import paginatedTransform from '@api/paginatedTransform';
import rawTransform from '@api/rawTransform';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import type { UseMutationOptions } from '@hooks/useMutation';
import useMutation from '@hooks/useMutation';
import { FrequentDSUserModel } from '@models/FrequentDSUserModel';
import type { OwnersUpdateData } from '@models/Owners';
import { PaginatedResponse } from '@models/PaginatedResponse';
import type { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

import {
  GetBiExploresRelatedDashboardsCsvRetrievePathParams,
  GetBiExploresRelatedDashboardsListQueryParams,
} from '../openapi.generated';

import * as cacheKeys from './cacheKeys';
import { LookerExploreFieldModel } from './LookerExploreFieldModel';

export const exploresCacheKeys = {
  explore: cacheKeys.explore,
  exploreFields: cacheKeys.exploreFields,
  exploreFrequentDsUsers: cacheKeys.exploreFrequentDsUsers,
  exploreRelatedDashboards: cacheKeys.exploreRelatedDashboards,
  exploreRelatedDashboardsCsv: cacheKeys.exploreRelatedDashboardsCsv,
  explores: cacheKeys.all,
};

const fetchExploresSelect = paginatedTransform(LookerExploreModel);
export const useFetchExplores = (
  options?: UseFetchOptions<LookerExplorePaginatedResponse<LookerExploreModel>>,
) => {
  return useFetch<LookerExplorePaginatedResponse<LookerExploreModel>>({
    ...options,
    queryKey: [...cacheKeys.all, options?.params],
    select: fetchExploresSelect,
    url: '/bi/explores/',
  });
};

export const fetchExploreSelect = rawTransform(LookerExploreModel);
export const useFetchExplore = (id: string, options: UseFetchOptions<LookerExploreModel>) => {
  return useFetch<LookerExploreModel>({
    ...options,
    queryKey: cacheKeys.explore(id),
    select: fetchExploreSelect,
    url: `/bi/explores/${id}/`,
  });
};

const fetchExploreFieldsSelect = paginatedTransform(LookerExploreFieldModel);
export const useFetchExploreFields = (
  id: string,
  options?: UseFetchOptions<PaginatedResponse<LookerExploreFieldModel>>,
) => {
  return useFetch<PaginatedResponse<LookerExploreFieldModel>>({
    ...options,
    queryKey: [...cacheKeys.exploreFields(id), options?.params],
    select: fetchExploreFieldsSelect,
    url: `/bi/explores/${id}/fields/`,
  });
};

const fetchExploreRelatedDashboardsSelect = paginatedTransform(RelatedDashboardModel);
export const useFetchExploreRelatedDashboards = (
  id: string,
  options?: UseFetchOptions<
    PaginatedResponse<RelatedDashboardModel>,
    GetBiExploresRelatedDashboardsListQueryParams
  >,
) => {
  return useFetch<
    PaginatedResponse<RelatedDashboardModel>,
    GetBiExploresRelatedDashboardsListQueryParams
  >({
    ...options,
    queryKey: [...cacheKeys.exploreRelatedDashboards(id), options?.params],
    select: fetchExploreRelatedDashboardsSelect,
    url: `/bi/explores/${id}/related-dashboards/`,
  });
};

const fetchExploreFrequentDSUsersSelect = paginatedTransform(FrequentDSUserModel);
export const useFetchExploreFrequentDSUsers = (id: string, options?: UseFetchOptions) => {
  return useFetch<PaginatedResponse<FrequentDSUserModel>>({
    ...options,
    queryKey: [...cacheKeys.exploreFrequentDsUsers(id), options?.params],
    select: fetchExploreFrequentDSUsersSelect,
    url: `bi/explores/${id}/frequent-dsusers/`,
  });
};

export const usePatchExploresOwners = (
  options?: UseMutationOptions<never, EnhancedErrorResult, OwnersUpdateData>,
) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    url: `/bi/explores/owners/`,
  });
};

export const useFetchExploreRelatedDashboardsCsv = (
  id: string,
  options?: UseFetchOptions<any, GetBiExploresRelatedDashboardsCsvRetrievePathParams>,
) => {
  return useFetch({
    ...options,
    queryKey: [...exploresCacheKeys.exploreRelatedDashboardsCsv(id), options?.params],
    url: `/bi/explores/${id}/related-dashboards/csv/`,
  });
};
