import React from 'react';
import * as Sentry from '@sentry/react';

import { ColumnModel } from '@api/columns/ColumnModel';
import { exploresCacheKeys } from '@api/explores';
import { fieldsCacheKeys } from '@api/fields';
import invalidateCache from '@api/invalidateCache';
import { usePatchMetadataById } from '@api/metadata';
import BILink from '@components/BILink';
import Box from '@components/Box';
import Breadcrumbs from '@components/Breadcrumbs';
import OpenInDjango from '@components/OpenInDjango';
import PageHeaderBar from '@components/PageHeaderBar';
import RichTextDescriptionEditor from '@components/RichTextDescriptionEditor';
import SearchResultIcon from '@components/SearchBar/SearchResult/SearchResultIcon';
import SearchTopIcon from '@components/SearchBar/SearchResult/SearchTopIcon';
import ColumnConstraint from '@components/TabContent/ColumnsTab/ColumnTable/ColumnConstraint';
import { SIMPLIFIED_DESCRIPTION_ALLOWED_ELEMENTS } from '@components/Table/Cells/DescriptionCell/DescriptionCell';
import PopularityCell from '@components/Table/Cells/PopularityCell';
import TaggedItems from '@components/TaggedItems';
import Text from '@components/Text';
import { renderErrorToast } from '@components/Toast';
import Icon from '@components/UI/Icon';
import useBulkEditSelected from '@hooks/useBulkEditSelected';
import fetchClient from '@lib/fetchClient';
import { MetadataModel } from '@models/MetadataModel';
import capitalizeFirstChar from '@utils/capitalizeFirstChar';

import PageColumnInfoCustomAttributeValues from './PageColumnInfoCustomAttributeValues';

export interface PageColumnInfoProps {
  guid: string;
  hideCategoryTags?: boolean;
  isEditable: boolean;
  item?: ColumnModel | MetadataModel;
}

const PageColumnInfo: React.FC<PageColumnInfoProps> = ({
  guid,
  hideCategoryTags,
  isEditable,
  item,
}) => {
  const { selected, tagsCounts } = useBulkEditSelected<ColumnModel | MetadataModel>({
    defaultSelected: item ? [item] : undefined,
  });

  const { isSuccess, mutate } = usePatchMetadataById(guid, {
    onError: (err) => {
      renderErrorToast('Update failed');
      Sentry.captureException(err, {
        extra: {
          guid,
        },
      });
    },
    onSuccess: () => {
      const parentGuid = item?.parentGuid || '';

      invalidateCache((keys) => [
        keys.columns.all,
        keys.dashboards.elements(parentGuid),
        keys.metadata.metadata(guid),
        keys.dbt.tests,
        keys.activity.all,
      ]);
      fetchClient.invalidateQueries(fieldsCacheKeys.fields);
      fetchClient.invalidateQueries(exploresCacheKeys.exploreFields(parentGuid));
    },
  });

  if (!item) {
    return null;
  }

  const getDisplayTitle = () => {
    if (item.objectType === 'lookmlfield') {
      if (item.name) {
        return `${item.shortName} (${item.name})`;
      }
      return item.shortName;
    }

    return item.name;
  };

  return (
    <>
      <PageHeaderBar
        icon={
          <>
            {item.objectType === 'column' && (item as ColumnModel).isJoinKey && (
              <Icon mr={0.5} name="join-link" size="24px" />
            )}
            <SearchResultIcon dataTypes={item.dataTypes} mr={0} showDataTypeTooltip size="24px" />
          </>
        }
        mb={0}
        overwriteIconsSizes={false}
        sticky={false}
        supIcon={<SearchTopIcon dataTypes={item.dataTypes} mr={0} size="16px" />}
        supTitle={<Breadcrumbs fontSize="14px" items={item?.breadcrumbList} />}
        title={getDisplayTitle()}
        titleFontSize="h2"
        titleToolBox={
          <>
            <Box alignItems="center" compDisplay="flex" flexShrink={0} gap={1}>
              {!['periscope', 'sigma'].includes(item?.dataSourceType ?? '') && (
                <Text color="gray.500" fontSize="15px" fontWeight="regular" mb={0}>
                  {capitalizeFirstChar(item?.externalType || item?.dataType || '')}
                </Text>
              )}
              <ColumnConstraint columnItem={item as ColumnModel} gap={1} showUsageBadges />
              {item?.popularity && <PopularityCell popularity={item?.popularity} />}
              {item?.externalUrl && <BILink dataTypes={item?.dataTypes} href={item?.externalUrl} />}
            </Box>
            <OpenInDjango guid={item.guid} size="14px" />
          </>
        }
      />
      <Box alignItems="center" as="span" compDisplay="flex" flexWrap="wrap" mb={1}>
        <TaggedItems
          hideCategoryTags={hideCategoryTags}
          iconButtonProps={{ backgroundColor: 'transparent', mr: 0.5, size: 'xs' }}
          isEditable={isEditable}
          objects={selected.items}
          popperConfigProps={{
            offset: [0, 5],
          }}
          taggedItemsCounts={tagsCounts}
        />
      </Box>
      <Box mb={1}>
        <RichTextDescriptionEditor
          allowedElements={SIMPLIFIED_DESCRIPTION_ALLOWED_ELEMENTS}
          dataSourceType={item.dataTypes?.dataSourceType}
          descriptions={{
            aiDescription: item?.aiDescription,
            description: item?.description,
            ingestedDescription: item?.ingestedDescription,
            richTextDescription: item?.richtextDescription,
            suggestedDescription: item?.suggestedDescription,
            userDescription: item.userDescription,
          }}
          descriptionSource={item?.descriptionSource}
          editIconVariant="always"
          guid={guid}
          isEditable={isEditable}
          isSuccess={isSuccess}
          onDescriptionSave={(richtextDesc, plainTextDesc, descriptionSource) =>
            mutate({
              description: plainTextDesc,
              description_source: descriptionSource,
              richtext_description: richtextDesc,
            })
          }
          parentGuid={item.parentGuid}
          placeholder="Add description"
          showDescriptionSelector
          suggestedDescriptionSource={item?.suggestedDescriptionSource}
          suggestedDescriptionSourceObject={item?.suggestedDescriptionSourceObject?.obj}
          toastTitle={`${item.name} description has been updated.`}
        />
      </Box>
      <PageColumnInfoCustomAttributeValues guid={item?.guid} isEditable={isEditable} />
    </>
  );
};

export default PageColumnInfo;
