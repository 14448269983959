import React from 'react';
import { useParams } from 'react-router-dom';

import { GridContainer } from '@components/Grid';
import PageHeaderBar from '@components/PageHeaderBar';
import Icon from '@components/UI/Icon';
import { useUserContext } from '@context/User';
import DbtTestsTable from '@pages/DbtTestsPage/DbtTestsTable';
import MetadataDecorator from '@utils/MetadataDecorator';

const DbtTestsPage: React.FC = () => {
  const { dsGuid, fGuid } = useParams<{
    containerId: string;
    dsGuid: string;
    fGuid?: string;
  }>();
  const { dataSources, organization } = useUserContext();
  const isDataSourceEditable = Boolean(dataSources?.[dsGuid ?? '']?.settings?.isEditable);
  const showColumnCategoryTags = organization?.settings?.showColumnCategoryTags ?? false;

  return (
    <>
      <MetadataDecorator title="All Tests" />
      <GridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <PageHeaderBar icon={<Icon name="dbt-test" />} title="All Tests" />
        <DbtTestsTable
          dsGuid={dsGuid}
          fGuid={fGuid}
          isDataSourceEditable={isDataSourceEditable}
          parentUrl={`/dbt-tests/${dsGuid}${fGuid ? `/${fGuid}` : ''}`}
          showColumnCategoryTags={showColumnCategoryTags}
        />
      </GridContainer>
    </>
  );
};

export default DbtTestsPage;
