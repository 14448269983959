import React from 'react';

import { UsageTypeType } from '@api/lineage/types';
import Box from '@components/Box';
import Tooltip from '@components/Tooltip.v1';
import Icon from '@components/UI/Icon';

import { StyledFilterUsageBadge, StyledUsageTypeBadge } from './UsageTypeBadge.styles';

interface UsageTypeBadgeProps {
  hideFilterIcon?: boolean;
  showFilterFullText?: boolean;
  tooltipText?: string;
  type: UsageTypeType;
}

const USAGE_TYPE_LABEL: PartialRecord<UsageTypeType, string> = {
  aggregated: 'Aggr',
  asis: 'As Is',
  filter: 'Filter',
  transformed: 'Transformed',
};

const UsageTypeBadge = ({
  hideFilterIcon,
  showFilterFullText,
  tooltipText,
  type,
}: UsageTypeBadgeProps) => {
  const content =
    type === 'filter' ? (
      <Box alignItems="center" compDisplay="flex">
        {showFilterFullText && (
          <StyledFilterUsageBadge aria-label={type} data-testid="column-usage" mr={0.5}>
            {USAGE_TYPE_LABEL.filter}
          </StyledFilterUsageBadge>
        )}
        {!hideFilterIcon && (
          <Tooltip content={tooltipText ?? USAGE_TYPE_LABEL.filter}>
            <Icon color="primary.400" name="filter" size="18px" />
          </Tooltip>
        )}
      </Box>
    ) : (
      <StyledUsageTypeBadge aria-label={type} data-testid="column-usage">
        {USAGE_TYPE_LABEL[type]}
      </StyledUsageTypeBadge>
    );

  return tooltipText ? <Tooltip content={tooltipText}>{content}</Tooltip> : content;
};

export default UsageTypeBadge;
