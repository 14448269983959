import React, { useState } from 'react';
import { Cell } from 'react-table';

import { useFetchIngestionBiLogs, useFetchIngestionLogs } from '@api/ingestion';
import IngestionLogModel from '@api/ingestion/IngestionLogModel';
import DateTime from '@components/DateTime';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import Modal, { ModalContent, ModalHeader } from '@components/UI/Modal';
import { DataSourceModel } from '@models/DataSourceModel';
import theme from '@styles/theme';

export interface DataSourceIngestionLogModalProps {
  dataSource: DataSourceModel;
  onClose?: () => void;
}

const DataSourceIngestionLogModal: React.FC<DataSourceIngestionLogModalProps> = ({
  dataSource,
  onClose,
}) => {
  const isBi = ['mode', 'looker'].includes(dataSource.type);
  const [showFilter, setShowFilter] = useState(false);
  const logsQuery = useFetchIngestionLogs({
    enabled: !isBi,
    params: { data_sources: dataSource.guid },
  });
  const biLogsQuery = useFetchIngestionBiLogs({
    enabled: isBi,
    params: { data_sources: dataSource.guid },
  });
  const { data, isLoading } = isBi ? biLogsQuery : logsQuery;

  const ingestionLogs = data?.results;

  const columns: ColumnConfig<IngestionLogModel>[] = React.useMemo(
    () => [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        Header: 'Data Source',
        accessor: (log) => log?.dataSource?.name,
        disableFilters: true,
        disableHiding: true,
        disableSortBy: true,
        id: 'name',
        width: '120%',
      },
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (props: Cell<IngestionLogModel>) => {
          const {
            row: { original: log },
          } = props;

          return <DateTime datetime={log.createdOn} format="fullDateFormat" />;
        },
        Header: 'Update Time',
        accessor: (log) => log.createdOn,
        disableFilters: true,
        disableHiding: true,
        id: 'updateTime',
        width: '120%',
      },
      {
        Header: 'Job Type',
        accessor: (log) => log.jobType,
        disableHiding: true,
        id: 'jobType',
        width: '160%',
      },
    ],
    [],
  );

  const toggleFilter = () => setShowFilter((prev) => !prev);

  return (
    <Modal onClose={onClose} size="medium">
      <ModalHeader onClose={onClose} title={`${dataSource.name} update history`} />
      <ModalContent maxHeight={theme.space(50)} py={2.5}>
        <TableStyled>
          <Table
            basic="very"
            className="table-full"
            columns={columns}
            compact
            data={ingestionLogs || []}
            disableColumnFiltering
            disablePagination
            disableRowSelect
            initialState={{
              sortBy: [
                {
                  desc: false,
                  id: 'updateTime',
                },
              ],
            }}
            loading={isLoading}
            selectable
            showFilter={showFilter}
            singleLine
            sortable
            toggleFilter={toggleFilter}
            unstackable
          />
        </TableStyled>
      </ModalContent>
    </Modal>
  );
};

export default DataSourceIngestionLogModal;
