import styled from '@emotion/styled';
import { Accordion } from 'semantic-ui-react';

export const AccordionItemTitle = styled(Accordion.Title)`
  display: flex;
  align-items: center;
  font-size: 0.8125rem !important;
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  color: #44464a !important;
  height: 0.9735rem;
  margin: 0.06rem 0.3rem 0.3rem 0;
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary} !important;
  padding: 0 !important;
  margin-top: 2rem;
`;

export const AccordionItemContent = styled(Accordion.Content)``;
