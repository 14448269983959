import { UsageTypeType } from '@api/lineage/types';
import { InputNodesById } from '@components/Explore.v1/Explore.types';

import { NodesById } from '../useCreateNodesEdges/algorithm/types';
import traversal from '../useCreateNodesEdges/utils/traversal';

export const getAllInputNodeParents = (
  nodeKey: string,
  inputNodesById: InputNodesById,
): InputNodesById => {
  const findParentNodes = (key: string, parents: InputNodesById): InputNodesById => {
    const parentNode = inputNodesById[key];
    if (!parentNode || !parentNode.metadata.parent_guid) {
      return parents;
    }

    const parent = inputNodesById?.[parentNode.metadata.parent_guid];
    if (!parent) {
      return parents;
    }

    return findParentNodes(parentNode.metadata.parent_guid, { ...parents, [parent.key]: parent });
  };

  return findParentNodes(nodeKey, {});
};

interface MergeRelevantColumnLineageStateParams {
  newNodes: NodesById;
  previousNodes: NodesById;
}

export const mergeRelevantColumnLineageState = ({
  newNodes,
  previousNodes,
}: MergeRelevantColumnLineageStateParams) => {
  const nodesById: NodesById = {};

  Object.entries(newNodes).forEach(([key, value]) => {
    const currentPreviousNode = previousNodes[key];

    if (currentPreviousNode) {
      nodesById[key] = {
        ...value,
        data: {
          ...value.data,
          childrenSearchText: currentPreviousNode?.data?.childrenSearchText,
          isSearchEnabled: currentPreviousNode?.data?.isSearchEnabled,
          shownChildrenCount: Math.max(
            currentPreviousNode?.data?.shownChildrenCount ?? 0,
            value.data.shownChildrenCount ?? 0,
          ),
        },
      };
    } else {
      nodesById[key] = value;
    }
  });

  return nodesById;
};

export const createColumnLevelInput = ({
  columnId,
  inputNodesById,
  parentKey,
}: {
  columnId: string;
  inputNodesById: InputNodesById;
  parentKey: string;
}) => {
  const newInput: InputNodesById = {};
  const visitedNodes = new Set<string>([]);

  traversal({
    action: ({ direction, id, isInitialNode, parentId }) => {
      if (isInitialNode || direction) {
        const column = inputNodesById[id];
        newInput[id] = column;
        visitedNodes.add(id);

        if (!isInitialNode && parentId && id !== parentId) {
          const { source_edges: sourceEdges = {}, target_edges: targetEdges = {} } = column;

          const edgesToGetUsage = direction === 'right' ? sourceEdges : targetEdges;

          const usages: Array<UsageTypeType> = edgesToGetUsage[parentId]?.usage_type ?? [];
          const validUsages = usages.filter((usage) => usage);

          newInput[id].usage = validUsages;
        }

        const allParents = getAllInputNodeParents(id, inputNodesById);
        Object.keys(allParents).forEach((key) => {
          if (!newInput[key]) {
            newInput[key] = { ...allParents[key], columns: [] };
          }
        });
      }
    },
    calculateStraightPath: true,
    initialNodeId: columnId,
    nodesById: inputNodesById,
    skipColumns: false,
  });

  Array.from(visitedNodes).forEach((nodeKey) => {
    const node = inputNodesById[nodeKey];
    const { metadata } = node;
    const parentGuid = metadata?.parent_guid;

    if (node.node_type === 'table') {
      newInput[nodeKey].columns = [];
    } else if (parentGuid && newInput[parentGuid]) {
      newInput[parentGuid].columns = [...(newInput[parentGuid]?.columns ?? []), nodeKey];
    }
  });

  const originalParentNode = inputNodesById[parentKey];
  const originalParentNodeColumns = originalParentNode?.columns ?? [];
  originalParentNodeColumns.forEach((columnKey) => {
    const isVisited = visitedNodes.has(columnKey);
    if (!isVisited) {
      newInput[columnKey] = { ...inputNodesById[columnKey], shouldSkipEdgesCalculation: true };
    }
  });
  if (!newInput[parentKey]) {
    newInput[parentKey] = originalParentNode;
  }
  newInput[parentKey].columns = originalParentNodeColumns;

  return newInput;
};
