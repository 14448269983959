import React, { useEffect } from 'react';

import { usePatchMetadata } from '@api/metadata';
import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import { Step } from '@components/DataSourceSetup/steps';
import { ModalFooter, ModalHeader } from '@components/UI/Modal';
import { Option } from '@components/UI/Select';

import DragAndDrop from './DragAndDrop';
import Finish from './Finish';

import { ModalContainer, ModalSidebar, ModalWithSidebar, SidebarSteps } from '..';

interface Props {
  onClose: () => void;
  options?: Option[];
}

type CsvKeys = 'upload' | 'load' | 'finish';

const CSV_UPLOAD: Step<CsvKeys>[] = [
  { key: 'upload', name: 'Upload CSV', title: 'Upload CSV' },
  { key: 'load', name: 'Load Data Source', title: 'Load Data Source' },
  { key: 'finish', name: 'Finish', title: 'Finish' },
];

const CSVUploadModal: React.FC<Props> = ({ onClose, options }) => {
  const [step, setStep] = React.useState(CSV_UPLOAD[0]);

  const { data, error, isError, isLoading, isSuccess, mutate } = usePatchMetadata({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  useEffect(() => {
    if (!isLoading && (isSuccess || isError)) {
      setStep(CSV_UPLOAD[2]);
    }
  }, [isError, isLoading, isSuccess]);

  const onAccept = (currentDS?: string, files?: File[]) => {
    if (currentDS && files) {
      const formData = new FormData();
      formData.append('file', files[0]);
      formData.append('data_source', currentDS);

      mutate(formData);
      setStep(CSV_UPLOAD[1]);
    }
  };

  const goBack = () => setStep(CSV_UPLOAD[0]);

  return (
    <ModalWithSidebar onClose={onClose}>
      <ModalSidebar>
        <ModalHeader title="Metadata Upload" />
        <SidebarSteps activeStep={step} steps={CSV_UPLOAD} />
        <ModalFooter mt="auto" />
      </ModalSidebar>
      <ModalContainer>
        <ModalHeader onClose={onClose} title={step.title} />
        {step.key === 'upload' && <DragAndDrop onAccept={onAccept} options={options} />}
        {step.key === 'load' && isLoading && (
          <Box
            alignItems="center"
            compDisplay="flex"
            compHeight="100%"
            compWidth="100%"
            flexDirection="column"
            fontSize="h2"
            fontWeight="medium"
            gap={1.5}
            justifyContent="center"
          >
            <CircularLoader />
            Uploading Data and making updates
          </Box>
        )}
        {step.key === 'finish' && (isSuccess || isError) && (
          <Finish
            error={error}
            goBack={goBack}
            notFound={data?.fields_not_found}
            onClose={onClose}
            uploadedCount={data?.updated_field_count || 0}
          />
        )}
      </ModalContainer>
    </ModalWithSidebar>
  );
};

export default CSVUploadModal;
