import type { GetDbtProjectListQueryParams } from '@api/openapi.generated';
import paginatedTransform from '@api/paginatedTransform';
import { paginatedSelect } from '@api/utils';
import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';
import useMutation, { UseMutationOptions } from '@hooks/useMutation';
import { PaginatedResponse } from '@models/PaginatedResponse';

import * as cacheKeys from './cacheKeys';
import type { DbtProjectModelV1 } from './DbtProjectModel.v1';
import mapDbtProjectModel from './DbtProjectModel.v1';
import DbtTestModel from './DbtTestModel';
import DbtWarehouseLinkModel from './DbtWarehouseLinkModel';

export const fetchDbtProjectsSelect = paginatedSelect(mapDbtProjectModel);
export const useFetchDbtProjects = (
  options?: UseFetchOptions<
    PaginatedResponse<DbtProjectModelV1>,
    GetDbtProjectListQueryParams & { query?: string }
  >,
) => {
  return useFetch({
    ...options,
    queryKey: [...cacheKeys.projects, options?.params],
    select: fetchDbtProjectsSelect,
    url: `/dbt/project/`,
  });
};

export const usePatchDbtProject = (id: string, options?: UseMutationOptions) => {
  return useMutation<DbtTestModel>({
    ...options,
    method: 'PATCH',
    url: `/dbt/project/${id}/`,
  });
};

export const usePostDbtProject = (options?: UseMutationOptions) => {
  return useMutation<DbtTestModel>({
    ...options,
    method: 'POST',
    url: `/dbt/project/`,
  });
};

export const useDeleteDbtProject = (id: string, options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'DELETE',
    url: `/dbt/project/${id}/`,
  });
};

const fetchDbtWarehouseLinkSelect = paginatedTransform(DbtWarehouseLinkModel);
export const useFetchDbtWarehouseLink = (
  guid?: string,
  options?: UseFetchOptions<PaginatedResponse<DbtWarehouseLinkModel>>,
) => {
  return useFetch<PaginatedResponse<DbtWarehouseLinkModel>>({
    ...options,
    queryKey: [...cacheKeys.warehouseLink(guid!), options?.params],
    select: fetchDbtWarehouseLinkSelect,
    url: `/dbt/warehouse-link/${guid}/`,
  });
};

export const fetchDbtTestsSelect = paginatedTransform(DbtTestModel);
export const useFetchDbtTests = (options?: UseFetchOptions<PaginatedResponse<DbtTestModel>>) => {
  return useFetch<PaginatedResponse<DbtTestModel>>({
    ...options,
    queryKey: [...cacheKeys.tests, options?.params],
    select: fetchDbtTestsSelect,
    url: `/dbt/tests/`,
  });
};
