import styled from '@emotion/styled';

import IconButton from '@components/IconButton';

export const StyledCloseButton = styled(IconButton)`
  background-color: white;
  border-radius: ${({ theme }) => theme.radius.md};
  height: ${({ theme }) => theme.space(4.5)};
  width: ${({ theme }) => theme.space(4.5)};

  background-color: ${({ theme }) => theme.colors.white};

  &:hover {
    background-color: ${({ theme }) => theme.colors.v1.gray[100]};
  }
`;
