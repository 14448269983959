import { Option } from '@components/UI/Select';

import { defaultIconForType, TAG_TYPE_OPTIONS } from './constants';
import { CreateTagModalPayload } from './types';

export const getInitialState = (owner: Option[], initialState?: CreateTagModalPayload) => {
  return {
    color: initialState?.color ?? defaultIconForType[initialState?.type ?? 'category'].color,
    description: initialState?.description ?? '',
    icon: initialState?.icon ?? defaultIconForType[initialState?.type ?? 'category'].icon,
    name: initialState?.name ?? '',
    owner,
    richtextDescription: initialState?.richtextDescription ?? '',
    type: [
      TAG_TYPE_OPTIONS.find((opt) => opt.value === initialState?.type) ?? TAG_TYPE_OPTIONS[1],
    ] as Option[],
  };
};
