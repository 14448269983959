import React from 'react';
import styled from '@emotion/styled';
import getDebug from 'debug';
import { DropzoneRootProps, useDropzone } from 'react-dropzone';

import Alert from '@components/Alert';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import DropzoneContainer from '@components/Dropzone';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Text from '@components/Text';
import { ModalContent, ModalFooter } from '@components/UI/Modal';
import Select, { Option, SelectValue } from '@components/UI/Select';

const debug = getDebug('selectstar:upload-csv');

interface Props {
  isDragAccept?: boolean;
  isDragActive?: boolean;
  isDragReject?: boolean;
}

const getColor = (props: Props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
};

const StyledDropzoneContainer = styled(DropzoneContainer)<DropzoneRootProps & Props>`
  border-radius: 2px;
  border: 2px dashed #cbcbd0;
  transition: border 0.24s ease-in-out;
  display: flex;
  justify-content: center;
  padding: 20px;
  border-color: ${(props) => getColor(props)};
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  max-height: 85%;
`;

const StyledButton = styled.p`
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  font-size: 1.5rem;
  user-select: none;
  cursor: pointer;

  &:hover {
    color: #00000091;
  }
`;

const List = styled.ul`
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary} !important;
`;

const ListItem = styled.li`
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary} !important;
`;

interface FormValues {
  dataSource: SelectValue;
  files?: File[];
}

interface DragAndDropProps {
  onAccept: (currentDS?: string, files?: File[]) => void;
  options?: Option[];
}

const DragAndDrop: React.FC<DragAndDropProps> = ({ onAccept, options }) => {
  const [error, setError] = React.useState<string>();

  const { handleSubmit, setValues, values } = useForm<FormValues>({
    initialValues: {
      dataSource: [],
      files: undefined,
    },
    onSubmit: (v) => {
      const { dataSource, files } = v;
      const [selected] = dataSource as Option[];
      if (!selected) {
        setError('Please Select Data Source');
      } else if (!files) {
        setError('Please Select File');
      } else {
        onAccept(String(selected.value), files);
      }
    },
  });

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      debug('file dropped');
      if (!acceptedFiles) {
        setError('Only CSV files are accepted.');
      } else {
        setValues((prevValues) => ({ ...prevValues, files: acceptedFiles }));
      }
    },
    [setValues],
  );

  const { acceptedFiles, getInputProps, getRootProps, isDragAccept, isDragActive, isDragReject } =
    useDropzone({
      accept: '.csv',
      maxFiles: 1,
      onDrop,
    });

  const handleDataSourceChange = (newValue: SelectValue) => {
    setValues((prevValues) => ({ ...prevValues, dataSource: newValue }));
  };

  const acceptedFileItems = acceptedFiles.map((file: File) => (
    <ListItem key={file.name}>
      {file.name} -{file.size} bytes
    </ListItem>
  ));

  return (
    <Form compDisplay="flex" compHeight="100%" flexDirection="column" onSubmit={handleSubmit}>
      <ModalContent compDisplay="flex" flexDirection="column" flexGrow={1} maxHeight="unset" py={3}>
        <Select
          onChange={handleDataSourceChange}
          options={options}
          placeholder="Select Data Source"
        />
        <Box compDisplay="flex" flexDirection="column" flexGrow="1" my={1}>
          <StyledDropzoneContainer
            {...getRootProps({ isDragAccept, isDragActive, isDragReject })}
            isDragAccept={isDragAccept}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
          >
            <input {...getInputProps()} />
            <StyledButton>Choose a File</StyledButton>
          </StyledDropzoneContainer>
          {acceptedFiles.length > 0 && (
            <Box mt={2}>
              <Text color="black" fontSize="1.25rem" fontWeight="bold" mt={1}>
                Accepted files
              </Text>
              <List>{acceptedFileItems}</List>
            </Box>
          )}
          {error && (
            <Alert mt={2} title="Required" type="error">
              {error}
            </Alert>
          )}
        </Box>
      </ModalContent>
      <ModalFooter>
        <Button
          disabled={acceptedFiles.length === 0 || !values.dataSource?.[0]?.value}
          type="submit"
        >
          Save
        </Button>
      </ModalFooter>
    </Form>
  );
};
export default DragAndDrop;
