import { LOADING_TEXT } from '@constants';
import type { TreeNodeInArray } from 'react-simple-tree-menu';

import TableLineageModel from '@api/lineage/TableLineageModel';
import type { NodeSource } from '@components/ExploreSidebar/types';
import type { SearchOptions } from '@components/ExploreTree/atoms';
import { getPopularityNormalized } from '@utils/popularity';
import sortByType from '@utils/sortByType';

import type { NodeExtraMeta } from '../types';

import sortByUsageAndPopularity from './sortByUsageAndPopularity';

type Node = TreeNodeInArray & {
  icon?: string;
};

export interface GetTreeConfig {
  allNodeIds: Set<string | undefined>;
  allNodes: NodeSource[];
  expandAllKeys?: string[];
  maxLevel?: number;
  propIndex: number;
  search: SearchOptions;
  startingDataSourceType?: any;
  startingKey: string;
  tables: TableLineageModel[];
  traversalProps: string[];
  type: 'column' | 'table';
}

const getTree = (
  config: GetTreeConfig,
  id: string,
  parentTableId?: string,
  visitedParentNodes = new Set<any>(),
  { usageType }: NodeExtraMeta = {},
  level = 0,
): Node | undefined => {
  visitedParentNodes.add(id); // A set of visited parent nodes. Used to avoid cycles A -> B -> C -> A.
  const nodeKey = Array.from(visitedParentNodes).join('/');
  const obj: NodeSource | undefined = config.allNodes.find((node) => node.key === id);

  if (!obj) {
    let tableId = parentTableId ?? id;

    /**
     * Prevents passing :tableId/:columnId calling loading more which crashes the lineage request.
     * Instead, it has to load more using the only :columnId in request.
     */
    if (config.startingKey?.includes('/')) {
      const [, columnGuid] = config.startingKey.split('/');
      tableId = parentTableId ?? columnGuid;
    }

    return { id, key: id, label: LOADING_TEXT, loadMore: true, tableId };
  }

  if (config.maxLevel && level > config.maxLevel + 1) {
    return undefined;
  }

  /**
   * If we started in a warehouse model/column
   * and a dbt table/column is linked to another table/column that is visible, then hide the dbt table/column
   * NOTE: we can assume that dbt will only be linked to not-dbt data sources because and vice versa
   * backend guarantees it. Which means we won't be hiding all references to a linked table.
   */
  if (
    config.startingKey !== id &&
    config.startingDataSourceType !== 'dbt' &&
    obj.dataSourceType === 'dbt' &&
    obj.linkedObjs?.some((linkedObj) => config.allNodeIds.has(linkedObj))
  ) {
    return undefined;
  }

  /**
   * If we started in a dbt model/column
   * and a warehouse table/column is linked to a dbt model/column, then hide the warehouse table/column
   * NOTE: we can assume that dbt will only be linked to not-dbt data sources because and vice versa
   * backend guarantees it. Which means we won't be hiding all references to a linked table.
   */
  if (
    config.startingKey !== id &&
    config.startingDataSourceType === 'dbt' &&
    obj.dataSourceType !== 'dbt' &&
    obj.linkedObjs?.some((linkedObj) => config.allNodeIds.has(linkedObj))
  ) {
    return undefined;
  }

  const {
    dataSourceType,
    dataType,
    dataTypes,
    description,
    fullName,
    guid,
    isHidden,
    linkedObjs,
    name,
    objectType,
    popularity,
    query,
    routePath,
  } = obj;
  const priority = getPopularityNormalized(obj.popularity?.popularity);
  let tableId = id;
  let label = name || id;

  /**
   * If the table has any linked objects that are dbt models, we should also show the dbt icon
   * NOTE: Currently we assume that linkedObjs only contain information about dbt <-> DWH connections
   * NOTE: this is related to ExploreTree
   */
  const hasDbtLinkedObjs = dataSourceType === 'dbt' ? false : Boolean(linkedObjs);

  if (config.type === 'column') {
    tableId = obj.tableGuid!;
    label = `${fullName}`;
  }

  const prop = config.traversalProps[config.propIndex] as keyof NodeSource;
  const sourcesOrTargets = obj?.[prop] ?? {};

  const nodes: Node[] = (
    Array.isArray(sourcesOrTargets) ? sourcesOrTargets : Object.keys(sourcesOrTargets)
  )
    .filter((sourceOrTargetId) => !visitedParentNodes.has(sourceOrTargetId))
    .map((sourceOrTargetId) => {
      return getTree(
        config,
        sourceOrTargetId,
        tableId,
        new Set(visitedParentNodes),
        {
          usageType: sourcesOrTargets?.[sourceOrTargetId]?.usage_type ?? '',
        },
        level + 1,
      );
    })
    .filter((n): n is Node => Boolean(n))
    .sort((a, b) => {
      const valuesMap = {
        description: { a: a?.description, b: b?.description },
        name: { a: a?.label, b: b?.label },
        popularity: { a: a?.popularity?.popularity ?? 0, b: b?.popularity?.popularity ?? 0 },
        usage: { a: a?.usageType?.join?.(''), b: b?.usageType?.join?.('') },
      };

      if (config.search.sortBy === 'usage') {
        return sortByUsageAndPopularity(valuesMap, { order: config.search.orderBy });
      }

      const sortByTypeResult = sortByType({
        ...valuesMap[config.search.sortBy],
        orderBy: config.search.orderBy,
      });

      if (sortByTypeResult === 0) {
        const sortByName = sortByType({
          ...valuesMap.name,
          orderBy: 'asc',
        });

        if (sortByName === 0) {
          return sortByType({
            a: a?.guid,
            b: b?.guid,
            orderBy: 'asc',
          });
        }

        return sortByName;
      }

      return sortByTypeResult;
    });

  if (nodes.length > 0) {
    config?.expandAllKeys?.push(nodeKey);
  }

  return {
    dataSourceType,
    dataType,
    dataTypes,
    description,
    fullName,
    guid,
    hasDbtDwhLink: hasDbtLinkedObjs,
    id,
    isHidden,
    key: id,
    label,
    level,
    nodes,
    objectType,
    popularity,
    priority,
    query,
    routePath,
    tableId,
    usageType,
  };
};

export default getTree;
