import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import Box from '@components/Box';
import Breadcrumbs from '@components/Breadcrumbs';

export const StyledTabHeader = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.v1.gray[200]};
  display: flex;
`;

StyledTabHeader.defaultProps = {
  compDisplay: 'flex',
  flexDirection: 'column',
  pb: 1,
  pt: 1.5,
  px: 2.5,
};

export const StyledTabHeaderRowContainer = styled(Box)``;

StyledTabHeaderRowContainer.defaultProps = {
  alignItems: 'center',
  compDisplay: 'flex',
  overflow: 'hidden',
};

export const StyledTabHeaderTitle = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.v1.gray[800]};
  font-size: ${({ theme }) => theme.typography.fontSizes.h3};
  font-weight: ${({ theme }) => theme.typography.fontWeights.semibold};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: ${({ to }) => (to ? 'auto' : 'none')};

  &:hover {
    color: ${({ theme }) => theme.colors.v1.gray[800]};
  }
`;

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  color: ${({ theme }) => theme.colors.v1.gray[500]};
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
`;
