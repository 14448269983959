import React from 'react';
import { DEFAULT_DOCS_URL } from '@constants';

import { usePatchDataSource } from '@api/dataSources';
import Alert from '@components/Alert';
import Box from '@components/Box';
import NextStepButton from '@components/DataSourceSetup/components/Buttons/NextStepButton';
import PreviousStepButton from '@components/DataSourceSetup/components/Buttons/PreviousStepButton';
import ErrorCredentialAlert from '@components/DataSourceSetup/components/ErrorCredentialAlert/ErrorCredentialAlert';
import {
  StyledFormHorizontalLabelGrid,
  StyledLabel,
} from '@components/DataSourceSetup/DataSourceSetup.styles';
import Dropdown from '@components/Dropdown';
import Form from '@components/Form';
import Input from '@components/Input/Input.v1';
import Link from '@components/Link';
import { ModalFooter } from '@components/UI/Modal';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import {
  PostgresCredentials,
  SnowflakeCredentials,
  TableauActivityDataTypesType,
  TableauCredentials,
} from '@models/DataSourceCredentials';
import {
  TableauActivityDataSourceOptions,
  TableauTypeOptions,
  TableauTypeType,
} from '@models/DataSourceOptions';

import ErrorDescriptionMarkdown from '../../ErrorDescriptionMarkdown';
import CompleteInfoText from '../../SetupCompleteStep/CompleteInfoText';

import type { DataSourceAddActivityDataStepProps } from './types';

const TableauAddActivityDataStepForm: React.FC<DataSourceAddActivityDataStepProps> = ({
  dataSource,
  onDataSourceAdded,
  prevStep,
}) => {
  const segment = useSegmentContext();
  const [tableauType, setTableauType] = React.useState<TableauTypeType>();
  const [dataType, setDataType] = React.useState<TableauActivityDataTypesType>('postgres');
  const [account, setAccount] = React.useState('');
  const [host, setHost] = React.useState('');
  const [port, setPort] = React.useState(5432);
  const [user, setUser] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [role, setRole] = React.useState('');
  const [warehouse, setWarehouse] = React.useState('');
  const [database, setDatabase] = React.useState('');
  const [schema, setSchema] = React.useState('');

  const {
    error,
    isLoading,
    mutate: updateDataSource,
  } = usePatchDataSource(dataSource.guid, {
    onSuccess: onDataSourceAdded,
  });

  const onConnect = () => {
    if (!isLoading) {
      const payload = {
        credentials: dataSource.credentials ?? {},
        guid: dataSource.guid,
        type: dataSource.type,
      };

      if (tableauType === 'server') {
        if (dataType === 'snowflake') {
          const usageCredentials: {
            tableau_type: TableauTypeType;
            type: string;
          } & SnowflakeCredentials = {
            account,
            database,
            password,
            schema,
            tableau_type: tableauType,
            type: dataType,
            user,
            warehouse,
          };
          if (role) {
            usageCredentials.role = role;
          }
          (payload.credentials as TableauCredentials).data_store_params = usageCredentials;
        } else if (dataType === 'postgres') {
          const usageCredentials: {
            tableau_type: TableauTypeType;
            type: string;
          } & PostgresCredentials = {
            database,
            host,
            password,
            port,
            schema,
            tableau_type: tableauType,
            type: dataType,
            user,
          };
          (payload.credentials as TableauCredentials).data_store_params = usageCredentials;
        }
      }
      if (tableauType === 'online') {
        const usageCredentials: {
          tableau_type: TableauTypeType;
        } = { tableau_type: tableauType };
        (payload.credentials as TableauCredentials).data_store_params = usageCredentials;
      }
      updateDataSource(payload);
    }
  };

  return (
    <Form isLoading={isLoading} onSubmit={onConnect}>
      <StyledFormHorizontalLabelGrid>
        <StyledLabel>
          Tableau Type
          <Dropdown
            as="div"
            error={error?.data && error.data?.type && 'Select type.'}
            icon="dropdown"
            onChange={(_, d) => setTableauType(d.value as TableauTypeType)}
            options={TableauTypeOptions}
            placeholder="Select Type"
            selection
            value={tableauType}
          />
        </StyledLabel>
        {tableauType === 'online' && (
          <Box gridColumn="1/3">
            <>
              <CompleteInfoText>
                1.{' '}
                <Link
                  fontSize="inherit"
                  href={`${DEFAULT_DOCS_URL}/integrations/tableau-server/tableau-online#3.-create-a-custom-sql-view-tableau-online`}
                  rel="noopener noreferrer"
                  target="_blank"
                  underline
                >
                  Please follow these instructions
                </Link>{' '}
                to download and upload a custom Tableau Workbook in Admin Insights.
              </CompleteInfoText>
              <CompleteInfoText>
                2. Click "Next" when the Workbook has been successfully uploaded.
              </CompleteInfoText>
            </>
          </Box>
        )}
        {tableauType === 'server' && (
          <>
            <StyledLabel as="div">
              Source Type
              <Dropdown
                error={error?.data && error.data?.type && 'Select type.'}
                icon="dropdown"
                onChange={(_, d) => setDataType(d.value as TableauActivityDataTypesType)}
                options={TableauActivityDataSourceOptions}
                placeholder="Source Type"
                selection
                value={dataType}
              />
            </StyledLabel>
            {dataType === 'postgres' && (
              <StyledLabel>
                Host
                <Input
                  error={error?.data?.host}
                  helperText={error?.data?.host}
                  onChange={(e) => setHost(e.target.value)}
                  placeholder="db.example.com"
                  type="text"
                  value={host}
                />
              </StyledLabel>
            )}
            {dataType === 'postgres' && (
              <StyledLabel>
                Port
                <Input
                  error={error?.data?.port}
                  helperText={error?.data?.port}
                  onChange={(e) => setPort(parseInt(e.target.value, 10))}
                  placeholder="5432"
                  type="number"
                  value={port}
                />
              </StyledLabel>
            )}
            {dataType === 'snowflake' && (
              <StyledLabel>
                Account
                <Input
                  error={error?.data?.account}
                  helperText={error?.data?.account}
                  onChange={(e) => setAccount(e.target.value)}
                  placeholder="account.us-east-1"
                  type="text"
                  value={account}
                />
              </StyledLabel>
            )}
            <StyledLabel>
              Username
              <Input
                error={error?.data?.username}
                helperText={error?.data?.username}
                onChange={(e) => setUser(e.target.value)}
                placeholder="Username"
                type="text"
                value={user}
              />
            </StyledLabel>
            <StyledLabel>
              Password
              <Input
                error={error?.data?.password}
                helperText={error?.data?.password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                type="password"
                value={password}
              />
            </StyledLabel>
            {dataType === 'snowflake' && (
              <>
                <StyledLabel>
                  Role
                  <Input
                    error={error?.data?.role}
                    helperText={error?.data?.role}
                    onChange={(e) => setRole(e.target.value)}
                    placeholder="'selectstar_role' by default"
                    type="text"
                    value={role}
                  />
                </StyledLabel>
                <StyledLabel>
                  Warehouse
                  <Input
                    error={error?.data?.warehouse}
                    helperText={error?.data?.warehouse}
                    onChange={(e) => setWarehouse(e.target.value)}
                    placeholder="Warehouse"
                    type="text"
                    value={warehouse}
                  />
                </StyledLabel>
              </>
            )}
            <StyledLabel>
              Database
              <Input
                error={error?.data?.database}
                helperText={error?.data?.database}
                onChange={(e) => setDatabase(e.target.value)}
                placeholder="Database"
                type="text"
                value={database}
              />
            </StyledLabel>
            <StyledLabel>
              Schema
              <Input
                error={error?.data?.schema}
                helperText={error?.data?.schema}
                onChange={(e) => setSchema(e.target.value)}
                placeholder="Schema"
                type="text"
                value={schema}
              />
            </StyledLabel>
          </>
        )}
        <Box gridColumn="1/3">
          {error && error.status === 500 && (
            <Alert title="Couldn't store credentials." type="error">
              Please try again later.
            </Alert>
          )}
          <ErrorCredentialAlert error={error?.data.credentials} />
          <ErrorDescriptionMarkdown error={error} />
        </Box>
      </StyledFormHorizontalLabelGrid>
      <ModalFooter>
        <PreviousStepButton
          disabled={isLoading}
          onClick={() => {
            segment?.track(SegmentTrackEventName.ImportData_TableauActivityDBPrevButtonClicked);
            prevStep();
          }}
        />
        <NextStepButton
          disabled={isLoading || !tableauType}
          onClick={() => {
            segment?.track(SegmentTrackEventName.ImportData_TableauActivityDBNextButtonClicked);
            onConnect();
          }}
          text="Save"
        />
      </ModalFooter>
    </Form>
  );
};

export default TableauAddActivityDataStepForm;
