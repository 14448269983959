import React, { useEffect } from 'react';
import { FullStory } from '@fullstory/browser';

import { useFetchDataSourcesInfo } from '@api/dataSources';
import { isIngestionComplete } from '@api/organizations/OrganizationModel';
import { useFetchDefaultOrganization } from '@api/organizations/organizations';
import { useFetchOrganizationUser } from '@api/organizationUser';
import { useFetchMyUser } from '@api/user';
import CircularLoader from '@components/CircularLoader';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import { DataSourceTypesType } from '@models/DataSourceCredentials';
import { DataSourceModel } from '@models/DataSourceModel';
import { isMasked } from '@pages/MaskPage/MaskPage.utils';
import stripSpaces from '@utils/stripSpaces';

import { UserContext, UserContextProps } from './User';
import { StyledLoaderWrapper } from './UserProvider.styles';

const orgQuery = stripSpaces(`{
  guid,
  name,
  trial_ends_at,
  subscription_ends_at,
  is_onboarding_complete,
  created_on,
  settings
}`);

const userQuery = stripSpaces(`{
  role
}`);

const UserProvider: React.FC = ({ children }) => {
  const segment = useSegmentContext();

  const { data: currUserData, isLoading: userDataLoading } = useFetchMyUser();

  const {
    data: orgData,
    error: orgError,
    isLoading: orgLoading,
  } = useFetchDefaultOrganization({
    params: { query: orgQuery },
  });

  const useRbac = orgData?.settings?.useRbac;

  const { data: dataSourcesData, isLoading: dataSourcesLoading } = useFetchDataSourcesInfo({
    enabled: !orgLoading && Boolean(orgData),
    params: { meta_types: 'bi,dwh,data_quality' },
  });

  const {
    data: orgUserData,
    error,
    isLoading,
  } = useFetchOrganizationUser('me', {
    enabled: !orgLoading && Boolean(orgData),
    params: { query: userQuery },
  });

  useEffect(() => {
    if (currUserData) {
      const userData = currUserData;
      const userVars = {
        created_at: userData.createdOn?.toISOString(),
        email: userData?.email,
        first_name: userData?.firstName,
        isMasked: isMasked(),
        name: userData.fullName,
        role: orgUserData?.role,
        source: process.env.REACT_APP_CHROME_EXTENSION_BUILD ? 'extension' : 'web-app',
      };

      segment?.identify(userData.guid, userVars);
      segment?.track(SegmentTrackEventName.SignedIn);

      if (orgData && dataSourcesData) {
        const dataSources = dataSourcesData?.results?.reduce(
          (acc, cur) => ({ ...acc, [cur.guid]: cur }),
          {},
        );

        const orgVars = {
          isDataSourceComplete: isIngestionComplete(orgData, dataSources),
          organizationName: orgData.name,
          team: userData.team?.name,
        };

        segment?.identify(userData.guid, {
          ...orgVars,
          company: {
            created_at: orgData.createdOn?.toISOString(),
            id: orgData.guid,
            name: orgData.name,
          },
        });

        if (!process.env.REACT_APP_CHROME_EXTENSION_BUILD && !isMasked()) {
          FullStory('setIdentity', {
            properties: {
              ...userVars,
              displayName: userData.email,
              organizationGuid: orgData.guid,
            },
            uid: userData.email,
          });
        }
      }
    }
  }, [orgUserData, orgData, dataSourcesData, currUserData, segment]);

  if (isLoading || orgLoading || userDataLoading || dataSourcesLoading) {
    return (
      <StyledLoaderWrapper>
        <CircularLoader centered cover />
      </StyledLoaderWrapper>
    );
  }

  const isOrgAdmin: boolean = orgUserData?.role === 'admin';

  const isOrgDataManager: boolean = orgUserData?.role === 'data_manager';

  const isOrgUser: boolean = orgUserData?.role === 'user';

  const hasEditPermissions: boolean = isOrgAdmin || isOrgDataManager;

  let dataSources: { [guid: string]: DataSourceModel } | undefined =
    dataSourcesData?.results?.reduce((acc, cur) => {
      return { ...acc, [cur.guid]: cur };
    }, {});

  /** A temporary fix. Will be removed once data sources will be fixed in pbac. */
  if (dataSourcesData?.results?.length === 0 && useRbac) {
    const alwaysTrue = new Proxy(
      {},
      {
        get: (target, name) => name === 'isEditable',
      },
    );

    dataSources = new Proxy(
      {},
      {
        get: () => ({ settings: alwaysTrue }),
      },
    );
  }

  const isTos = !userDataLoading && currUserData?.isAgreedToTerms;

  const isOnboarding = !orgLoading && Boolean(orgError) && orgError?.status === 404;

  const isOnboardingComplete = !orgLoading && orgData?.isOnboardingComplete;

  const appError =
    (Boolean(error) && error?.status !== 404) || (Boolean(orgError) && orgError?.status !== 404);

  const hasDataSource = ({ guid, type }: { guid?: string; type: DataSourceTypesType }) => {
    if (guid) return Object.keys(dataSources || {}).includes(guid);

    if (type) return Boolean(Object.values(dataSources || {}).find((ds) => ds.type === type));

    return false;
  };

  const getDataSourceSettings = (guid?: string) => {
    return dataSources?.[guid!]?.settings;
  };

  const state: UserContextProps = {
    dataSources,
    error: appError,
    getDataSourceSettings,
    hasDataSource,
    hasEditPermissions,
    isDataSourcesLoading: dataSourcesLoading,
    isOnboarding,
    isOnboardingComplete,
    isOrgAdmin,
    isOrgDataManager,
    isOrgUser,
    isTos,
    loading: isLoading || orgLoading || userDataLoading,
    organization: orgData,
    role: orgUserData?.role,
    settings: orgData?.settings,
    user: currUserData,
  };

  return <UserContext.Provider value={state}>{children}</UserContext.Provider>;
};

export default UserProvider;
