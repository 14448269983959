import React, { useMemo } from 'react';
import clsx from 'clsx';

import { TagModel } from '@api/tags/TagModel';
import AppTag from '@components/AppTag';
import Box from '@components/Box';
import Breadcrumbs, { BreadcrumbItem } from '@components/Breadcrumbs';
import Highlighter from '@components/Highlighter';
import SearchResultIcon from '@components/SearchBar/SearchResult/SearchResultIcon';
import SearchTopIcon from '@components/SearchBar/SearchResult/SearchTopIcon';
import Tooltip from '@components/Tooltip';
import DataTypesModel from '@models/DataTypesModel';
import type { MapObjectToAvatarReturnType } from '@models/mapObjectToAvatar';
import theme from '@styles/theme';
import { urlFor } from '@utils/routing';

import { LinkStyled, StyledText, TableItemLinkStyled } from './LinkedCell.styles';
import { TypedCell } from './types';

export interface LinkItem {
  breadcrumbLabelList?: BreadcrumbItem[];
  color?: string;
  dataTypes?: DataTypesModel;
  filterValue?: string;
  fullName?: string;
  fullPath?: string;
  guid?: string;
  icon?: string;
  isHidden?: boolean;
  label?: string;
  link?: string;
  mappedAvatar?: MapObjectToAvatarReturnType;
  name?: string;
  parentGuid?: string;
  tags?: TagModel[];
  type?: string;
}

/* eslint-disable react/no-unused-prop-types */
export interface ItemProps {
  alwaysBold?: boolean;
  breadcrumbFixedUrl?: string;
  clickableBreadcrumbs?: boolean;
  customUrl?: string;
  externalLink?: boolean;
  filterValue?: string;
  /**
   * If true will display `item.fullPath` name which is supposed to be in format:
   * - DATA_SOURCE_NAME.DATABASE_NAME.SCHEMA_NAME.TABLE_NAME (for table)
   * If false, will display this item's name only.
   * - TABLE_NAME (for table)
   */
  fullPath?: boolean;
  globalFilter?: string;
  iconsSize?: string;
  item: LinkItem;
  /**
   * Will contain the a generated string that will be used in place
   * of the item.name.
   * This will take precedence of FullPath if fullPath is true
   */
  itemName?: string;
  noBold?: boolean;
  noLink?: boolean;
  noLinkOnInvalidUrl?: boolean;
  onClick?: () => void;
  showBreadcrumbs?: boolean;
  showDataSourceIcon?: boolean;
  showDataSourceTooltip?: boolean;
  showDataTypeTooltip?: boolean;
  showIcon?: boolean;
  showNameTooltip?: boolean;
  showTags?: boolean;
  tooltipText?: string;
}

interface TableItemProps {
  /* Additional nodes to put in before tags */
  extra?: React.ReactNode;
  name: string;
  showDataSourceIcon?: boolean;
}

export const TableItem: React.FC<ItemProps & TableItemProps> = React.memo((props) => {
  const {
    alwaysBold,
    breadcrumbFixedUrl,
    clickableBreadcrumbs,
    extra,
    filterValue,
    globalFilter,
    iconsSize,
    item,
    name,
    noBold,
    noLink,
    onClick,
    showBreadcrumbs,
    showDataSourceIcon,
    showDataSourceTooltip,
    showDataTypeTooltip,
    showIcon,
    showNameTooltip,
    showTags,

    tooltipText,
  } = props;

  const textValue = (
    <StyledText
      className={clsx(
        'link',
        noBold && 'no-bold',
        alwaysBold && 'always-bold',
        noLink && 'no-link',
        item.dataTypes?.objectType === 'team' && 'icon-content',
      )}
      cursor={noLink ? 'initial' : 'pointer'}
      display="block"
      fontFamily="primary"
      fontSize="13px"
    >
      {showBreadcrumbs && item.breadcrumbLabelList ? (
        <Breadcrumbs
          clickable={clickableBreadcrumbs}
          color={theme.colors.text}
          filterValue={filterValue}
          fixedUrl={breadcrumbFixedUrl}
          fontSize={theme.typography.fontSizes.body1}
          fontWeight={400}
          globalFilter={globalFilter}
          items={item.breadcrumbLabelList}
          showTrailingSlash={false}
        />
      ) : (
        <Highlighter
          autoEscape
          searchWords={globalFilter?.split(/[.| ]+/) || filterValue?.split(/[.| ]+/) || []}
          textToHighlight={name}
        />
      )}
    </StyledText>
  );

  const getNameTooltipText = () => {
    if (tooltipText) {
      return tooltipText;
    }
    if (item.breadcrumbLabelList && item.breadcrumbLabelList.length > 0) {
      return (
        <Breadcrumbs
          color="#FFFFFF"
          fontSize="13px"
          fontWeight={400}
          items={item.breadcrumbLabelList}
          showTrailingSlash={false}
        />
      );
    }
    return name;
  };

  const hideWrapperNameTooltip = showDataTypeTooltip || showDataSourceTooltip;

  return (
    <Box
      alignItems="center"
      compDisplay="flex"
      justifyContent="flex-start"
      onClick={onClick}
      overflowX="hidden"
      overflowY="hidden"
      textOverflow="ellipsis"
    >
      <Tooltip
        content={showNameTooltip && !hideWrapperNameTooltip ? getNameTooltipText() : undefined}
        forceDefaultBackground
      >
        <Box alignItems="center" compDisplay="flex" justifyContent="flex-start">
          {showDataSourceIcon && (
            <SearchTopIcon
              dataTypes={item.dataTypes}
              showTooltip={showDataSourceTooltip}
              size={iconsSize}
            />
          )}
          {showIcon && (
            <SearchResultIcon
              dataTypes={item.dataTypes}
              guid={item.guid}
              mappedAvatar={item.mappedAvatar}
              name={item.name}
              showDataTypeTooltip={showDataTypeTooltip}
              size={iconsSize}
            />
          )}
          <Tooltip
            content={showNameTooltip && hideWrapperNameTooltip ? getNameTooltipText() : undefined}
            forceDefaultBackground
          >
            <Box
              alignItems="center"
              compDisplay="inline-flex"
              compWidth="100%"
              overflowX="hidden"
              overflowY="hidden"
              textOverflow="ellipsis"
            >
              {textValue}
            </Box>
          </Tooltip>
        </Box>
      </Tooltip>
      {extra && <Box>{extra}</Box>}
      {showTags && (
        <Box alignItems="center" compDisplay="flex">
          {item?.tags?.map((tag: TagModel) => (
            <AppTag
              key={tag.guid}
              searchTermsToHighlight={globalFilter || filterValue}
              tag={tag}
              variant="outlined"
            />
          ))}
        </Box>
      )}
    </Box>
  );
});

export interface LinkedCellProps
  extends Pick<
    ItemProps,
    | 'alwaysBold'
    | 'breadcrumbFixedUrl'
    | 'customUrl'
    | 'externalLink'
    | 'fullPath'
    | 'globalFilter'
    | 'iconsSize'
    | 'item'
    | 'itemName'
    | 'noBold'
    | 'noLink'
    | 'noLinkOnInvalidUrl'
    | 'onClick'
    | 'showBreadcrumbs'
    | 'showDataSourceIcon'
    | 'showDataTypeTooltip'
    | 'showIcon'
    | 'showNameTooltip'
    | 'showTags'
    | 'tooltipText'
  > {}

const LinkedCell = React.memo(
  ({
    alwaysBold,
    breadcrumbFixedUrl,
    column,
    customUrl,
    externalLink,
    fullPath,
    globalFilter,
    iconsSize,
    item,
    itemName,
    noBold,
    noLink,
    noLinkOnInvalidUrl,
    onClick,
    showBreadcrumbs,
    showDataSourceIcon,
    showDataTypeTooltip,
    showIcon,
    showNameTooltip,
    showTags,
    tooltipText,
  }: LinkedCellProps & Partial<TypedCell>) => {
    const name: string = useMemo(() => {
      if (itemName) {
        return itemName;
      }
      if (fullPath && item.fullPath) {
        return item.fullPath;
      }

      return item.name || item.fullName || '';
    }, [itemName, fullPath, item.fullPath, item.name, item.fullName]);

    const linkUrl = useMemo(() => customUrl || urlFor(item, true) || item.link || '#', []);

    const tableItem = (
      <TableItem
        alwaysBold={alwaysBold}
        breadcrumbFixedUrl={breadcrumbFixedUrl}
        filterValue={column?.filterValue}
        globalFilter={globalFilter}
        iconsSize={iconsSize}
        item={item}
        name={name}
        noBold={noBold}
        noLink={noLink || item.isHidden || (linkUrl === '#' && noLinkOnInvalidUrl)}
        onClick={onClick}
        showBreadcrumbs={showBreadcrumbs}
        showDataSourceIcon={showDataSourceIcon}
        showDataTypeTooltip={showDataTypeTooltip}
        showIcon={showIcon}
        showNameTooltip={showNameTooltip}
        showTags={showTags}
        tooltipText={tooltipText}
      />
    );

    if (noLink || item.isHidden) {
      return tableItem;
    }

    if (externalLink) {
      return (
        <LinkStyled
          href={customUrl || urlFor(item)}
          onClick={(e) => {
            e?.preventDefault();
            window.open(customUrl || urlFor(item), '_blank', 'noopener,noreferrer');
          }}
          rel="noopener noreferrer"
          target="_blank"
        >
          {tableItem}
        </LinkStyled>
      );
    }

    return (
      <TableItemLinkStyled
        component={linkUrl === '#' && noLinkOnInvalidUrl ? ('div' as any) : undefined}
        isHiddenItem={item.isHidden}
        to={linkUrl}
      >
        {tableItem}
      </TableItemLinkStyled>
    );
  },
);

export default LinkedCell;
