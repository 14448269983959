import React from 'react';

import { useFetchDatasets } from '@api/datasets';
import type { DatasetModel } from '@api/datasets/DatasetModel.v1';
import invalidateCache from '@api/invalidateCache';
import Box from '@components/Box';
import Breadcrumbs from '@components/Breadcrumbs';
import BulkButtons from '@components/BulkButtons/BulkButtons';
import { FiltersSidebar } from '@components/FiltersSidebar';
import { GridContainer } from '@components/Grid';
import { ToggleHideItemsModal } from '@components/Modal';
import PageHeaderBar from '@components/PageHeaderBar';
import DatasetTable, {
  DATASET_TABLE_SEARCH_CONFIG,
  DATASET_TABLE_SORT_CONFIG,
} from '@components/Table/DatasetTable';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import { useUserContext } from '@context/User';
import useBulkEditSelected from '@hooks/useBulkEditSelected';
import { StyledTablesPage } from '@pages/TablesPage/TablesPage.styles';
import { setParams, useUpdateFilters } from '@utils/filters';
import type { GetItemsCountParams } from '@utils/getItemsCount';
import getItemsCount from '@utils/getItemsCount';
import MetadataDecorator from '@utils/MetadataDecorator';

import type { TestViewProps } from './types';

const REQUEST_CONFIG = { order: '-popularity', page: 1, page_size: 100 };

const CONTENT_CONFIG: Record<string, { countItems?: GetItemsCountParams['countItems'] }> = {
  powerbidataflow: {
    countItems: [{ key: 'count', text: 'Dataflow' }],
  },
  powerbidataset: {
    countItems: [{ key: 'count', text: 'Dataset' }],
  },
};

const DatasetPage: React.FC<TestViewProps> = ({
  breadcrumbList,
  dataTypeFilter,
  dataTypeOptions,
  dataTypes,
  dsGuid,
  guid,
  onSelectDataType,
  title,
}) => {
  const config = CONTENT_CONFIG[dataTypeFilter];
  const { getDataSourceSettings } = useUserContext();
  const { MODAL_IDS, checkModalOpened } = useModal();
  const { selected, tagsCounts, toggleAll, toggleItem } = useBulkEditSelected<DatasetModel>();

  const filterService = useUpdateFilters(
    REQUEST_CONFIG,
    DATASET_TABLE_SEARCH_CONFIG,
    DATASET_TABLE_SORT_CONFIG,
  );

  const { data, isLoading } = useFetchDatasets({
    enabled: Boolean(guid ?? dsGuid),
    params: {
      ...setParams(filterService.filter),
      datasources: dsGuid,
      ...(dataTypeFilter && { type: dataTypeFilter }),
      ...(guid && { bifolders: guid }),
    },
  });

  const itemCount = getItemsCount({
    countItems: config?.countItems,
    ref: data,
  });

  const reload = () => {
    invalidateCache((keys) => [keys.datasets.all]);
  };
  const dataSourceSettings = getDataSourceSettings(dsGuid);
  const isDataSourceEditable = Boolean(dataSourceSettings?.isEditable);
  const { permissions } = useObjectPermissionsContext();

  const selectedEditableItems = selected.items.filter(
    (item: any) => permissions[item.guid]?.isEditable,
  );

  return (
    <Box compDisplay="flex">
      <GridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <MetadataDecorator title={title} />
        <StyledTablesPage>
          <PageHeaderBar
            icon={dataTypes && <Icon name={dataTypes.icons.dataType} />}
            sticky
            supIcon={dataTypes && <Icon name={dataTypes.icons.dataSource} />}
            supTitle={breadcrumbList && <Breadcrumbs items={breadcrumbList} />}
            title={title}
            titleSuffix={!isLoading && itemCount}
          />
          <BulkButtons
            canEditTags
            canRemove={!filterService.filter.is_hidden}
            canUnHide={filterService.filter.is_hidden}
            isDataSourceEditable={isDataSourceEditable}
            selectedEditableItems={selectedEditableItems}
            selectedItems={selected.items}
            showText
            tagsCounts={tagsCounts}
          />
          <Box mb={0.5}>
            <DatasetTable
              data={data}
              filterService={filterService}
              isDataSourceEditable={isDataSourceEditable}
              isLoading={isLoading}
              selectedRowIds={selected.ids}
              toggleAll={(checked) => {
                toggleAll(data?.results!, checked);
              }}
              toggleItem={toggleItem}
            />
          </Box>
        </StyledTablesPage>
      </GridContainer>
      {checkModalOpened(MODAL_IDS.hideItems) && (
        <ToggleHideItemsModal
          isShowItemsModal={filterService.filter.is_hidden}
          reloadData={reload}
          selectedItems={selectedEditableItems}
        />
      )}
      <FiltersSidebar
        dataSourceType={dataTypes?.dataSourceType}
        dataTypeInitialSelected={dataTypeFilter}
        dataTypeOptions={dataTypeOptions}
        filterService={filterService}
        isCategoryTags
        isCreatedBy
        isPopularity={false}
        isSelectStarDetected
        isStatusTags
        onSelectDataType={onSelectDataType}
        showHidden
      />
    </Box>
  );
};

export default DatasetPage;
