import { Option } from '@components/UI/Select';
import { removeDuplicatedOptions } from '@components/UI/Select/Select.utils';
import { useUserContext } from '@context/User';

import { CreateTagModalPayload } from './types';

interface UseDefaultOwnerOptionsParams {
  initialState?: CreateTagModalPayload;
  isEdit?: boolean;
}

const useDefaultOwnerOptions = (params?: UseDefaultOwnerOptionsParams) => {
  const { initialState, isEdit } = params ?? {};
  const { user } = useUserContext();

  const currentOwnerOption = initialState?.owner
    ? {
        avatar: initialState.owner?.mappedAvatar,
        text: initialState.owner?.fullName ?? '',
        value: initialState.owner?.guid ?? '',
      }
    : undefined;

  const loggedUserOption = {
    avatar: user?.mappedAvatar,
    text: user?.fullName ?? '',
    value: user?.guid ?? '',
  };

  const validOptions = [currentOwnerOption, loggedUserOption].filter((opt) => Boolean(opt));

  const options = removeDuplicatedOptions(validOptions as Option[]);

  const selectedOption = isEdit ? currentOwnerOption : currentOwnerOption ?? loggedUserOption;

  return { options, selectedOption: selectedOption ? [selectedOption] : [] };
};

export default useDefaultOwnerOptions;
