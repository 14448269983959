import React, { useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import pluralize from 'pluralize';

import invalidateCache from '@api/invalidateCache';
import { usePostTermsCSVUpload } from '@api/terms';
import Button from '@components/Button/Button';
import PreviewMarkdown from '@components/PreviewMarkdown';
import { renderErrorToast } from '@components/Toast';
import Icon from '@components/UI/Icon';
import Modal, { ModalContent, ModalFooter, ModalHeader } from '@components/UI/Modal';
import { useModal } from '@context/Modal';
import downloadCSV from '@utils/downloadCSV';

import { StyledUploadCSVFilePreviewMarkdownWrapper } from './UploadCSVFile.styles';

export const MAX_FILE_SIZE = 104857600;
export const MAX_FILE_SIZE_AS_MB_STRING = `${Math.floor(MAX_FILE_SIZE / 1024 / 1024)}MB`;
export const SUMMARY_UPLOAD_TITLE = 'CSV Upload Summary';
export const UPLOAD_ERROR_TITLE = 'CSV Upload Error';
export const NO_CHANGES_DETECTED = 'No changes detected.';
export const createNewGlossaryTermMessage = (val?: number) =>
  `${val} new glossary ${pluralize('term', val)}`;
export const createUpdateGlossaryTermMessage = (val?: number) =>
  `${val} updated glossary ${pluralize('term', val)}`;

export const CSV_UPLOAD_ERRORS_MAP = {
  duplicated_guid:
    'The following guids are repeated. Please ensure each guid only appears once and try again:',
  hierarchy_cycle: 'Hierarchy cycle detected. Please correct the issues and retry:',
  invalid_email:
    'Invalid owners detected in the CSV, owners must be defined by their email or Team name. Please ensure all owners already exist in Select Star and try again:',
  invalid_guid:
    'The following glossary term GUIDs do not exist or you do not have permission to edit them. Please correct the issues and retry:',
  invalid_parent_guid:
    'The following glossary term parent GUIDs do not exist or you do not have permission to edit them. Please correct the issues and retry:',
  invalid_tags:
    'Invalid tags detected in the CSV. Please ensure all tags already exist in Select Star and try again:',
  required: 'The following required fields are missing:',
};

export const CSV_EXPORT_ERROR_REPORT_FILE_NAME = 'glossary_terms_with_error_report.csv';

interface UploadCSVFileChildrenProps {
  handleSelectFile: () => void;
  isLoading: boolean;
}

interface UploadCSVFileProps {
  children?: (args: UploadCSVFileChildrenProps) => React.ReactNode;
}

const UploadCSVFile: React.FC<UploadCSVFileProps> = ({ children }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { MODAL_IDS, checkModalOpened, closeModal, openModal } = useModal();
  const [modalDetails, setModalDetails] = useState({ message: '', title: '' });

  const { error, isError, isLoading, mutate } = usePostTermsCSVUpload({
    onError: (err) => {
      const title = UPLOAD_ERROR_TITLE;
      let message = '';

      // eslint-disable-next-line no-restricted-syntax
      for (const key in err?.data) {
        if (key in CSV_UPLOAD_ERRORS_MAP) {
          message += `\n\n ${CSV_UPLOAD_ERRORS_MAP[key as keyof typeof CSV_UPLOAD_ERRORS_MAP]}`;

          // eslint-disable-next-line @typescript-eslint/no-loop-func
          err?.data?.[key].forEach((item: string) => {
            message += `\n- ${item}`;
          });
        } else {
          Sentry.captureMessage(`Unhandled CSV import key error ${key}.`);
        }
      }

      if (!message) {
        message = err?.data?.detail ?? 'Sorry, something went wrong.';
      }

      setModalDetails({ message, title });
      openModal(MODAL_IDS.uploadGlossaryCSV);
    },
    onSuccess: (data) => {
      const title = SUMMARY_UPLOAD_TITLE;
      let message = '';

      if (data?.created) {
        message += `\n- ${createNewGlossaryTermMessage(data.created)}`;
      }

      if (data?.updated) {
        message += `\n- ${createUpdateGlossaryTermMessage(data.updated)}`;
      }

      if (!data?.updated && !data?.created) {
        message = NO_CHANGES_DETECTED;
      }

      setModalDetails({ message, title });
      openModal(MODAL_IDS.uploadGlossaryCSV);
    },
  });

  const errorReport = error?.data?.error_report;

  const clearInput = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { files } = event.target;
    if (!files || files?.length === 0) {
      clearInput();
      return;
    }

    if (files.length > 1) {
      renderErrorToast('Please select only one file.');
      clearInput();
      return;
    }

    const csvFile = files[0];

    if (csvFile.size > MAX_FILE_SIZE) {
      renderErrorToast(
        `File size cannot exceed ${MAX_FILE_SIZE_AS_MB_STRING}. Please select another file.`,
      );
      clearInput();
      return;
    }

    const formData = new FormData();
    formData.append('file', csvFile);
    mutate(formData);

    clearInput();
  };

  const handleSelectFile = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleModalClose = () => {
    closeModal(MODAL_IDS.uploadGlossaryCSV);
    setModalDetails({ message: '', title: '' });

    if (!isError) {
      invalidateCache((keys) => [keys.terms.all, keys.documents.hierarchy]);
    }
  };

  const handleDownloadErrors = () => {
    if (errorReport) {
      downloadCSV(errorReport, CSV_EXPORT_ERROR_REPORT_FILE_NAME);
    }
  };

  return (
    <>
      <input
        ref={inputRef}
        accept=".csv"
        data-testid="csv-file-upload-input"
        onChange={handleUpload}
        style={{ display: 'none' }}
        title="image-upload-input"
        type="file"
      />
      {children?.({ handleSelectFile, isLoading })}
      {checkModalOpened(MODAL_IDS.uploadGlossaryCSV) && Boolean(modalDetails.title) && (
        <Modal onClose={() => closeModal(MODAL_IDS.uploadGlossaryCSV)} size="tiny">
          <ModalHeader onClose={handleModalClose} title={modalDetails.title} />
          <ModalContent minHeight="unset" py={2}>
            <StyledUploadCSVFilePreviewMarkdownWrapper>
              <PreviewMarkdown markdown={modalDetails.message} />
            </StyledUploadCSVFilePreviewMarkdownWrapper>
          </ModalContent>
          <ModalFooter>
            {errorReport && (
              <Button
                onClick={handleDownloadErrors}
                startIcon={<Icon color="currentcolor" name="export" size="16px" />}
                variant="outlined"
              >
                Download Errors
              </Button>
            )}
            <Button onClick={handleModalClose} variant="outlined">
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default UploadCSVFile;
