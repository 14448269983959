import React from 'react';

import { TeamModel } from '@api/teams/TeamModel';
import { UserModel } from '@api/user/UserModel';
import { UserListModel } from '@api/userLists/UserListModel';
import Box from '@components/Box';
import mapRecipientsToOptions from '@components/Modal/NotifyUsersModal/mapRecipientsToOptions';
import Text from '@components/Text';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import Option from '@components/UI/Select/Option';
import { Reference } from '@models/Reference';
import theme from '@styles/theme';

export interface RecipientsProps {
  recipients: Reference<UserModel | TeamModel | UserListModel>[];
}

const Recipients = ({ recipients }: RecipientsProps) => {
  const formattedRecipients = mapRecipientsToOptions(recipients);

  return (
    <Box
      backgroundColor="gray.100"
      borderRadius="lg"
      compDisplay="flex"
      flexDirection="column"
      gap={0.25}
      px={2}
      py={1.5}
    >
      <Box alignItems="center" compDisplay="flex" gap={0.5}>
        <Icon color={theme.colors.v1.gray[400]} name="visibility" size="16px" />
        <Text color="gray.400" fontSize={theme.typography.fontSizes.body2} fontWeight="medium">
          Only visible to you
        </Text>
      </Box>
      <Box alignItems="center" columnGap={0.5} compDisplay="flex" flexWrap="wrap">
        <Text as="span" color="gray.400" fontSize={theme.typography.fontSizes.body2}>
          This message was sent to
        </Text>
        {formattedRecipients.map((recipient) => (
          <Tooltip key={recipient.value} content={recipient.teamName}>
            <Box mr={1}>
              <Option
                {...recipient}
                fontColor={theme.colors.v1.gray[500]}
                iconColor={theme.colors.v1.gray[500]}
              />
            </Box>
          </Tooltip>
        ))}
      </Box>
    </Box>
  );
};

export default Recipients;
