import { camelCase, isArray, isObject, transform } from 'lodash';

const camelize = <T>(obj?: T) => {
  if (!obj) return undefined;

  return transform<any, any>(obj, (acc, value, key, target) => {
    const camelKey = isArray(target) ? key : camelCase(key);
    acc[camelKey] = isObject(value) ? camelize(value) : value;
  }) as ConvertKeysToCamelCase<T>;
};

export default camelize;
