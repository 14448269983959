import React from 'react';
import copy from 'copy-to-clipboard';

import { useFetchColumnExportData, useFetchColumns } from '@api/columns';
import { ColumnModel } from '@api/columns/ColumnModel';
import { useFetchMetadataCopyData } from '@api/metadata';
import BulkButtons from '@components/BulkButtons';
import TabError from '@components/TabContent/TabError';
import { TabContentProps } from '@components/Tabs/types';
import { renderInfoToast } from '@components/Toast';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import { useUserContext } from '@context/User';
import useBulkEditSelected from '@hooks/useBulkEditSelected';
import DataTypesModel from '@models/DataTypesModel';
import { DefaultDatabaseTableColumnOrderOptions } from '@models/UserSettingsModel';
import { TableViewTabProps } from '@pages/TabbedTableViewPage/TableViewTab';
import { Filter } from '@utils';
import downloadCSV from '@utils/downloadCSV';
import stripSpaces from '@utils/stripSpaces';

import ColumnTable, { COLUMN_TABLE_SEARCH_CONFIG, COLUMN_TABLE_SORT_CONFIG } from './ColumnTable';
import type { ColumnsTableConfigItem } from './ColumnTable/Config';
import COLUMNS_TABLE_CONFIG from './ColumnTable/Config';

const defaultColumnsConfig: Filter.FilterOptions = {
  page: 1,
  page_size: 100,
  query: stripSpaces(`{
    guid,
    name,
    search_name,
    description,
    data_types,
    richtext_description,
    suggested_description,
    suggested_description_source,
    suggested_description_source_object,
    deactivation_scheduled_on,
    data_type,
    external_type,
    extra_data,
    popularity,
    tagged_items,
    is_join_key,
    is_foreign_key,
    is_primary_key,
    foreign_keys,
    is_mention,
    is_metric,
    is_nullable,
    is_unique,
    parent_guid,
    table{
      guid,
      name
    },
    sample_join_query{
      guid,
      name,
      raw_sql,
      last_executed_on,
      created_by{
        guid,
        first_name,
        last_name,
        avatar
      },
      dsuser_created_by{
        name,
        display_name
      }
    },
    breadcrumbs,
    description_source,
    ai_description,
    ingested_description,
    user_description
  }`),
};

const DEFAULT_SORTING_MAP: Record<DefaultDatabaseTableColumnOrderOptions, string> = {
  alphabetical: 'name',
  column_ordinal_position: '',
  popularity: '-popularity,name',
};

export interface ColumnsTabProps extends TableViewTabProps, TabContentProps {
  dataTypes?: DataTypesModel;
}

const ColumnsTab: React.FC<ColumnsTabProps> = ({
  dataSourceType,
  dataTypes,
  fullTableName,
  guid,
  isDataSourceEditable = false,
}) => {
  const { user } = useUserContext();
  const { isEditable: isObjectEditable } = useObjectPermissionsContext({ id: guid });

  const userDefaultColumnOrder = user?.settings.defaultDatabaseTableColumnOrder;

  const columnsConfig = {
    ...defaultColumnsConfig,
    order: DEFAULT_SORTING_MAP[userDefaultColumnOrder ?? 'popularity'],
  };
  const FilterService = Filter.useUpdateFilters(
    columnsConfig,
    COLUMN_TABLE_SEARCH_CONFIG,
    COLUMN_TABLE_SORT_CONFIG,
  );
  const { filter } = FilterService;
  const {
    data: columnsResponse,
    isError,
    isLoading,
  } = useFetchColumns({
    params: {
      ...Filter.setParams({ ...filter, tables: [guid] }),
      force_showing_suggested_description: true,
    },
  });

  const { refetch: exportData } = useFetchColumnExportData({
    enabled: false,
    onSuccess: (data) => {
      downloadCSV(data, `${fullTableName}_columns.csv`);
    },
    params: Filter.setParams({ ...filter, tables: [guid] }),
  });

  const { refetch: copyData } = useFetchMetadataCopyData(guid, {
    enabled: false,
    onSuccess: (data) => {
      copy(data.items.join('\n'));
      renderInfoToast('Data copied');
    },
  });

  const columns = columnsResponse?.results;
  const totalPages =
    columnsResponse && filter.page_size ? Math.ceil(columnsResponse.count / filter.page_size) : 1;
  const config = dataTypes?.findConfig<ColumnsTableConfigItem>(COLUMNS_TABLE_CONFIG);

  const { selected } = useBulkEditSelected<ColumnModel>({
    defaultSelected: columns,
  });

  if (isError) return <TabError />;

  return (
    <>
      <BulkButtons
        canCopyData
        canExportCSV
        canUseBulkAssist={isObjectEditable}
        guid={guid}
        isDataSourceEditable
        onCopyDataClick={() => copyData()}
        onExportCSVClick={() => exportData()}
        selectedEditableItems={selected.items}
        selectedItems={selected.items}
      />
      <ColumnTable
        customColumnProps={config?.customColumnProps}
        data={columns}
        dataSourceType={dataSourceType}
        filterService={FilterService}
        hiddenColumns={config?.hiddenColumns}
        isDataSourceEditable={isDataSourceEditable}
        isLoading={isLoading}
        itemCount={columnsResponse?.count}
        totalPages={totalPages}
        visibleColumns={config?.visibleColumns}
      />
    </>
  );
};

export default React.memo(ColumnsTab);
