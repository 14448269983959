import { useCallback, useMemo, useRef } from 'react';

import ColumnLineageModel from '@api/lineage/ColumnLineageModel';
import TableLineageModel from '@api/lineage/TableLineageModel';
import { LineageType } from '@api/lineage/types';

interface UseMergeLineageDataOptions {
  deepMerge?: boolean;
}

export const useMergeLineageData = (
  newData?: Partial<LineageType>,
  options: UseMergeLineageDataOptions = {},
) => {
  const tablesRef = useRef<Record<string, TableLineageModel>>({});
  const columnsRef = useRef<Record<string, ColumnLineageModel>>({});

  const reset = useCallback(() => {
    tablesRef.current = {};
    columnsRef.current = {};
  }, []);

  const tables = useMemo(() => {
    newData?.tables?.forEach((table) => {
      let prevTable = tablesRef.current[table.key];

      if (prevTable && options.deepMerge) {
        prevTable.sourceEdges = { ...prevTable.sourceEdges, ...table.sourceEdges };
        prevTable.targetEdges = { ...prevTable.targetEdges, ...table.targetEdges };
        prevTable.columns = Array.from(new Set([...prevTable.columns, ...table.columns]));
        prevTable.sourceTableGuids = Array.from(
          new Set([...prevTable.sourceTableGuids, ...table.sourceTableGuids]),
        );
        prevTable.targetTableGuids = Array.from(
          new Set([...prevTable.targetTableGuids, ...table.targetTableGuids]),
        );
      } else {
        prevTable = table;
      }

      tablesRef.current[table.key] = prevTable;
    });

    return [...Object.values(tablesRef.current)];
  }, [newData?.tables, options.deepMerge]);

  const columns = useMemo(() => {
    newData?.columns?.forEach((column) => {
      let prevColumn = columnsRef.current[column.key];

      if (prevColumn && options.deepMerge) {
        prevColumn.sourceEdges = { ...prevColumn.sourceEdges, ...column.sourceEdges };
        prevColumn.targetEdges = { ...prevColumn.targetEdges, ...column.targetEdges };
        prevColumn.sourceTableGuids = Array.from(
          new Set([...prevColumn.sourceTableGuids, ...column.sourceTableGuids]),
        );
      } else {
        prevColumn = column;
      }

      columnsRef.current[column.key] = prevColumn;
    });

    return [...Object.values(columnsRef.current)];
  }, [newData?.columns, options.deepMerge]);

  return {
    columns: [...columns],
    reset,
    tables: [...tables],
  };
};

export default useMergeLineageData;
