import React from 'react';

import { usePostInvitation } from '@api/invitations';
import { TeamModel } from '@api/teams/TeamModel';
import Box from '@components/Box';
import DocsButton from '@components/Button/BIButtons/DocsButton';
import Button from '@components/Button/Button';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import { renderInfoToast } from '@components/Toast';
import Modal, { ModalContent, ModalFooter, ModalHeader } from '@components/UI/Modal';
import Select, { Option } from '@components/UI/Select';
import { ROLE_OPTIONS } from '@models/OrganizationUserRole';

import { StyledInviteUserModalFieldLabel } from './InviteUserModal.styles';

export interface InviteUserForm {
  email: string;
  role: Option[];
  team: Option[];
}

export interface InviteUserModalProps {
  onClose?: () => void;
  teams: TeamModel[];
}

const InviteUserModal: React.FC<InviteUserModalProps> = ({ onClose, teams }) => {
  const teamOptions = [...teams.map((t) => ({ text: t.name, value: t.guid }))];

  const onInviteUserResponse = () => {
    renderInfoToast('Invititation sent.');
    onClose?.();
  };

  const {
    error,
    isLoading,
    mutate: createInvitation,
  } = usePostInvitation({
    onSuccess: () => {
      onInviteUserResponse();
    },
  });

  const { handleChange, handleSubmit, setValues, values } = useForm<InviteUserForm>({
    initialValues: {
      email: '',
      role: [ROLE_OPTIONS[0]],
      team: [],
    },
    onSubmit: (payload) => {
      const { email, role, team } = payload;

      const [selectedRole] = role;
      const [selectedTeam] = team;

      createInvitation({ email, role: selectedRole?.value ?? '', team: selectedTeam?.value ?? '' });
    },
  });

  const handleSelectFieldChange = (newValue: Option[], field: 'team' | 'role') => {
    setValues((prevValues) => ({
      ...prevValues,
      [field]: newValue,
    }));
  };

  return (
    <Modal onClose={onClose} size="tiny">
      <ModalHeader
        onClose={onClose}
        title={
          <Box alignItems="center" compDisplay="flex">
            <Box mr={1}>Invite User</Box>
            <DocsButton
              href="https://docs.selectstar.com/user-management/user-roles"
              rel="noopener noreferrer"
              target="_blank"
            />
          </Box>
        }
      />
      <Form isLoading={isLoading} onSubmit={handleSubmit}>
        <ModalContent compDisplay="flex" flexDirection="column" gap={2} py={2.5}>
          <StyledInviteUserModalFieldLabel>
            Email
            <Input
              autoFocus
              error={error?.data && error.data?.email}
              helperText={error?.data && error.data?.email}
              name="email"
              onChange={handleChange}
              placeholder="john@yourdomain.com"
              type="email"
              value={values.email}
            />
          </StyledInviteUserModalFieldLabel>
          <StyledInviteUserModalFieldLabel>
            Team
            <Select
              error={Boolean(error?.data && error.data?.team)}
              helperText={error?.data && error.data?.team && 'Select a team'}
              onChange={(value) => handleSelectFieldChange(value as Option[], 'team')}
              options={teamOptions}
              value={values.team}
            />
          </StyledInviteUserModalFieldLabel>
          <StyledInviteUserModalFieldLabel>
            Role
            <Select
              onChange={(value) => handleSelectFieldChange(value as Option[], 'role')}
              options={ROLE_OPTIONS}
              value={values.role}
            />
          </StyledInviteUserModalFieldLabel>
        </ModalContent>
        <ModalFooter>
          <Button disabled={isLoading} type="submit">
            Invite
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default InviteUserModal;
