import styled from '@emotion/styled';

import type { StyledBoxProps } from '@components/Box';
import Box from '@components/Box';
import {
  ANIMATION_DURATION,
  getNodeOpacity,
} from '@components/Explore.v1/components/Nodes/TableNode/TableNode.styles';
import rootTheme from '@styles/theme';

import { COLORS } from '../../ExploreTree/ExploreTree.colors';
import { SIZES } from '../config';
import { StyledUpstreamDownstreamButton } from '../UpstreamDownstreamButton/UpstreamDownstreamButton.styles';

interface StyledColumnExploreNode extends StyledBoxProps {
  isAnimationActive?: boolean;
  isBITableColumn?: boolean;
  isColumnLevelLineage?: boolean;
  isConnectedToSelectedEdge: boolean;
  isDownstreamLeaf?: boolean;
  isHighlighted?: boolean;
  isParentImplicit?: boolean;
  isSelected?: boolean;
  isUnfocused?: boolean;
  isUpstreamLeaf?: boolean;
  nodeKey?: string;
  showUpstreamDownstreamButtons: boolean;
}

export const getNodeBorderWidth = ({
  isConnectedToSelectedEdge,
  isDownstreamLeaf,
  isHighlighted,
  isSelected,
  isUpstreamLeaf,
}: StyledColumnExploreNode) => {
  let left = 0;
  let right = 0;

  if (isSelected || isHighlighted || isConnectedToSelectedEdge) {
    right = SIZES.borderWidth.column;
    left = SIZES.borderWidth.column;
  }

  if (isDownstreamLeaf) {
    right = SIZES.borderWidth.columnLeaf;
  }

  if (isUpstreamLeaf) {
    left = SIZES.borderWidth.columnLeaf;
  }

  return `${SIZES.borderWidth.column}px ${right}px ${SIZES.borderWidth.column}px ${left}px`;
};

export const getBorderColor = ({
  borderColor,
  isConnectedToSelectedEdge,
  isDownstreamLeaf,
  isHighlighted,
  isSelected,
  isUpstreamLeaf,
}: StyledColumnExploreNode) => {
  const top: string = rootTheme.colors.v1.gray[200];
  const bottom: string = rootTheme.colors.v1.gray[200];
  let left: string = rootTheme.colors.white;
  let right: string = rootTheme.colors.white;

  if (isUpstreamLeaf && isSelected) {
    // eslint-disable-next-line prefer-destructuring
    left = rootTheme.colors.v1.primary[200];
  } else if (isUpstreamLeaf) {
    // eslint-disable-next-line prefer-destructuring
    left = rootTheme.colors.v1.gray[200];
  }

  if (isDownstreamLeaf && isSelected) {
    // eslint-disable-next-line prefer-destructuring
    right = rootTheme.colors.v1.primary[200];
  } else if (isDownstreamLeaf) {
    // eslint-disable-next-line prefer-destructuring
    right = rootTheme.colors.v1.gray[200];
  }

  if (isConnectedToSelectedEdge) {
    return COLORS.node.connectedToSelectedEdge;
  }

  if (isHighlighted) {
    return borderColor;
  }

  return `${top} ${right} ${bottom} ${left}`;
};

export const StyledColumnNode = styled(Box)<StyledColumnExploreNode>`
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.v1.primary[50] : theme.colors.white};
  opacity: ${({ isParentImplicit, isUnfocused }) =>
    getNodeOpacity({ isImplicit: isParentImplicit, isUnfocused })};
  transition: all 300ms linear;
  border-color: ${(props) => getBorderColor(props)};
  border-style: solid;
  border-radius: ${({ isHighlighted, isSelected, theme }) =>
    isSelected || isHighlighted ? theme.radius.default : 0};
  border-width: ${(props) => getNodeBorderWidth(props)};
  cursor: pointer;
  padding-right: ${({ isDownstreamLeaf }) =>
    isDownstreamLeaf
      ? `${SIZES.paddingRight.column}px`
      : `${SIZES.paddingRight.column + SIZES.borderWidth.columnLeaf}px`};
  padding-left: ${({ isUpstreamLeaf }) =>
    isUpstreamLeaf
      ? `${SIZES.paddingLeft.column}px`
      : `${SIZES.paddingLeft.column + SIZES.borderWidth.columnLeaf}px`};
  width: ${({ isBITableColumn }) =>
    isBITableColumn ? `${SIZES.width.biColumn}px` : `${SIZES.width.column}px`};

  ${StyledUpstreamDownstreamButton} {
    visibility: ${({ isColumnLevelLineage, showUpstreamDownstreamButtons }) =>
      showUpstreamDownstreamButtons && isColumnLevelLineage ? `visible` : `hidden`};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.v1.primary[50]};
    border: ${SIZES.borderWidth.column} solid ${({ theme }) => theme.colors.v1.primary[300]};

    ${StyledUpstreamDownstreamButton} {
      visibility: visible;
    }
  }

  ${({ isAnimationActive, theme }) =>
    isAnimationActive &&
    `
  @keyframes focused-effect {
    0% {
      background-color: ${theme.colors.v1.primary[50]}; 
    }

    50% {
      background-color: ${theme.colors.v1.primary[200]};
    }

    100% {
      background-color: ${theme.colors.v1.primary[50]};
    }
  }

  animation: ${ANIMATION_DURATION}ms linear focused-effect;

  `}
`;

StyledColumnNode.defaultProps = {
  alignItems: 'center',
  compDisplay: 'flex',
  compHeight: `${SIZES.height.column}px`,
  justifyContent: 'space-between',
  overflow: 'hidden',
};

export const StyledColumnNodeTitle = styled(Box)``;

StyledColumnNodeTitle.defaultProps = {
  color: 'gray.800',
  fontSize: 'sm',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};
