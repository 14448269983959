import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { QueryLogRecordModel } from '@models/QueryLogRecordModel';

export class TableEventModel {
  static objectType: string = 'event';

  objectType: string = TableEventModel.objectType;

  @Expose({ name: 'event_type' })
  eventType!: string;

  @Expose({ name: 'event_on' })
  @Transform((value) => moment(value))
  eventOn!: moment.Moment;

  @Expose({ name: 'query_record' })
  @Type(() => QueryLogRecordModel)
  queryRecord!: QueryLogRecordModel;
}
