import React, { forwardRef, memo } from 'react';
import type { ChangeEvent, MouseEvent } from 'react';

import type { CheckboxProps } from './Checkbox';
import Checkbox from './Checkbox';
import type { StyledCheckboxInputLabelProps } from './CheckboxInput.styles';
import { StyledCheckboxInputField, StyledCheckboxInputLabel } from './CheckboxInput.styles';

export interface CheckboxInputProps
  extends StyledCheckboxInputLabelProps,
    Pick<
      CheckboxProps,
      | 'checked'
      | 'error'
      | 'indeterminate'
      | 'title'
      | 'disabled'
      | 'variantColor'
      | 'variantRadius'
    > {
  name?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: MouseEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  required?: boolean;
}

const CheckboxInput = forwardRef<HTMLInputElement, CheckboxInputProps>(
  (
    {
      checked = false,
      disabled = false,
      error = false,
      indeterminate = false,
      name = '',
      onChange,
      onClick,
      readOnly,
      required,
      title,
      variantColor,
      variantRadius,
      ...other
    },
    ref,
  ) => {
    const isChecked = checked || indeterminate;

    return (
      <StyledCheckboxInputLabel {...other}>
        <StyledCheckboxInputField
          ref={ref}
          checked={isChecked}
          disabled={disabled}
          name={name}
          onChange={(e) => {
            onChange?.(e);
          }}
          onClick={onClick}
          readOnly={readOnly}
          required={required}
          role="none"
          type="checkbox"
        />
        <Checkbox
          aria-label={name}
          checked={checked}
          disabled={disabled}
          error={error}
          indeterminate={indeterminate}
          role="checkbox"
          title={title}
          variantColor={variantColor}
          variantRadius={variantRadius}
        />
      </StyledCheckboxInputLabel>
    );
  },
);

export default memo(CheckboxInput);
