import React, { forwardRef } from 'react';

import { GetColorProps } from '@styles/mixins/getColor';
import theme from '@styles/theme';

import type { StyledSVGIconProps } from './Icon.styles';
import { StyledSVGIcon } from './Icon.styles';
import type { IconName } from './sprites/icon.list.generated';
import Icons from './sprites/sprites.generated.svg';

export interface IconProps
  extends Omit<StyledSVGIconProps, 'color' | 'fontSize' | 'to' | 'fill'>,
    Pick<React.SVGAttributes<SVGElement>, 'onClick' | 'onKeyPress' | 'role' | 'tabIndex' | 'id'> {
  ariaLabel?: string;
  className?: string;
  color?: GetColorProps;
  name: IconName;
  size?: string;
  title?: string;
}

const Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ ariaLabel, color, name, size = '1em', title, ...other }, ref) => {
    return (
      <StyledSVGIcon
        aria-label={ariaLabel}
        compHeight={size}
        compWidth={size}
        fill={color || theme.colors.v1.gray[700]}
        id="icon"
        role="graphics-symbol"
        {...other}
        ref={ref}
      >
        {title && <title>{title}</title>}
        <use data-testid={`icon-use-${name}`} href={`${Icons}#${name}`} />
      </StyledSVGIcon>
    );
  },
);

export default Icon;
