import React, { Fragment, useState } from 'react';
import { DEFAULT_DOCS_URL } from '@constants';

import { usePatchIncompleteDataSource } from '@api/dataSources';
import Alert from '@components/Alert';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import NextStepButton from '@components/DataSourceSetup/components/Buttons/NextStepButton';
import ErrorCredentialAlert from '@components/DataSourceSetup/components/ErrorCredentialAlert/ErrorCredentialAlert';
import {
  ErrorText,
  StyledFormHorizontalLabelGrid,
  StyledLabel,
} from '@components/DataSourceSetup/DataSourceSetup.styles';
import Dropdown from '@components/Dropdown';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import Link from '@components/Link';
import { ModalFooter } from '@components/UI/Modal';
import { DataSourceModel } from '@models/DataSourceModel';

import ErrorDescriptionMarkdown from './ErrorDescriptionMarkdown';

const FORM_CONFIG = {
  aurora: [
    { key: 'instance', text: 'AWS cluster name' },
    { key: 'region', text: 'AWS region' },
    { key: 'role_arn', text: 'Role ARN' },
  ],
  cloudwatch: [
    { key: 'log_region', text: 'AWS region' },
    { key: 'log_role_arn', text: 'Role ARN' },
    { key: 'log_group_name_prefix', text: 'Log group name prefix' },
  ],
  none: [],
  rds: [
    { key: 'instance', text: 'AWS instance name' },
    { key: 'region', text: 'AWS region' },
    { key: 'role_arn', text: 'Role ARN' },
  ],
  s3: [
    { key: 'log_bucket', text: 'S3 bucket' },
    { key: 'log_prefix', text: 'S3 object prefix' },
    { key: 'log_region', text: 'AWS region' },
    { key: 'log_role_arn', text: 'Role ARN' },
  ],
  system: [],
} as const;

type FormConfig = typeof FORM_CONFIG;
type LogType = keyof FormConfig;
type Field = FormConfig[LogType][number];
type Payload = Record<Field['key'], string>;

const INFO_CONFIG: PartialRecord<LogType, { link: string; text: string }> = {
  aurora: {
    link: `${DEFAULT_DOCS_URL}/integrations/aws_aurora`,
    text: 'AWS Aurora for PostgreSQL instance',
  },
  rds: {
    link: `${DEFAULT_DOCS_URL}/integrations/postgres`,
    text: 'AWS RDS for PostgreSQL instance',
  },
};

interface FormatOption {
  text: string;
  value: keyof typeof FORM_CONFIG;
}

const FORM_OPTIONS: {
  [key: string]: FormatOption[];
} = {
  mssql: [
    {
      text: 'RDS Database Activity Stream',
      value: 's3',
    },
    {
      text: 'System Table/View',
      value: 'system',
    },
  ],
  mysql: [
    {
      text: 'CloudWatch Logs',
      value: 'cloudwatch',
    },
    {
      text: 'None',
      value: 'none',
    },
  ],
  oracle: [
    {
      text: 'RDS Database Activity Stream',
      value: 's3',
    },
    {
      text: 'System Table/View',
      value: 'system',
    },
  ],
  postgres: [
    {
      text: 'AWS RDS for PostgreSQL',
      value: 'rds',
    },
    {
      text: 'AWS Aurora PostgreSQL',
      value: 'aurora',
    },
    {
      text: 'None',
      value: 'none',
    },
  ],
};

const INITIAL_VALUES: Payload = {
  instance: '',
  log_bucket: '',
  log_group_name_prefix: '',
  log_prefix: '',
  log_region: '',
  log_role_arn: '',
  region: '',
  role_arn: '',
};

interface ConnectQueryLogFormProps {
  dataSource?: DataSourceModel;
  onSuccess: () => void;
}

const ConnectQueryLogForm: React.FC<ConnectQueryLogFormProps> = ({
  dataSource = new DataSourceModel(),
  onSuccess,
}) => {
  const [logType, setLogType] = useState<LogType>('none');
  const { error, isLoading, mutate } = usePatchIncompleteDataSource(dataSource.guid, {
    onSuccess,
  });

  const cloudformationUrl = dataSource?.cloudFormationUrl?.[logType];

  const redirectToAWS = () => {
    window.open(cloudformationUrl, '_blank');
  };

  const activeInfoConfig = INFO_CONFIG[logType];
  const activeFormConfig = FORM_CONFIG[logType] as unknown as Field[];

  const { handleChange, handleSubmit, setValues, values } = useForm<Payload>({
    initialValues: INITIAL_VALUES,
    onSubmit: (payload: Payload) => {
      // send only the fields that are present in the form config for specific log type
      const credentials = activeFormConfig.reduce<Partial<Payload>>(
        (acc: Partial<Payload>, field: Field) => {
          const { key } = field;
          acc[key] = payload[key];
          return acc;
        },
        {},
      );
      mutate({
        credentials: {
          log_type: logType,
          ...credentials,
        },
        guid: dataSource.guid,
        type: dataSource.type,
      });
    },
  });

  return (
    <Form isLoading={isLoading} onSubmit={handleSubmit}>
      <StyledFormHorizontalLabelGrid>
        {activeInfoConfig && (
          <Box gridColumn="1/3">
            <Alert type="info">
              To authorize our access to {activeInfoConfig.text} please deploy CloudFormation stack
              in the AWS account where the {activeInfoConfig.text} exists. The output of the stack
              indicates the AWS IAM role ARN for Select Star access. See the instruction in the{' '}
              <Link
                fontSize="inherit"
                href={activeInfoConfig.link}
                rel="noopener noreferrer"
                target="_blank"
                underline
              >
                documentation
              </Link>
              .
            </Alert>
          </Box>
        )}
        <StyledLabel as="div">
          Source Type
          <Dropdown
            onChange={(_, { value }) => {
              setLogType(value as LogType);
              setValues(INITIAL_VALUES);
            }}
            options={FORM_OPTIONS[dataSource.type]}
            selection
            value={logType}
          />
        </StyledLabel>
        {cloudformationUrl && (
          <Button disabled={isLoading} fluid gridColumn="1/3" onClick={redirectToAWS} type="button">
            Open CloudFormation
          </Button>
        )}
        {error?.data?.log_type && <ErrorText gridColumn="1/3">{error?.data.log_type}</ErrorText>}
        {activeFormConfig.map(({ key, text }) => {
          const ApiError = error?.data[key];
          return (
            <Fragment key={key}>
              <StyledLabel>
                {text}
                <Input
                  error={ApiError}
                  helperText={ApiError}
                  name={key}
                  onChange={handleChange}
                  placeholder={text}
                  value={values?.[key]}
                />
              </StyledLabel>
            </Fragment>
          );
        })}
        <Box gridColumn="1/3">
          {error && error.status === 500 && (
            <Alert title="Couldn't store credentials." type="error">
              Please try again later.
            </Alert>
          )}
          <ErrorCredentialAlert error={error?.data.credentials} />
          <ErrorDescriptionMarkdown error={error} />
        </Box>
      </StyledFormHorizontalLabelGrid>
      <ModalFooter>
        <NextStepButton disabled={isLoading} text="Save" />
      </ModalFooter>
    </Form>
  );
};

export default ConnectQueryLogForm;
