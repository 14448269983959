import React, { useCallback, useState } from 'react';
import { RecoilState } from 'recoil';

import { FetchLineageParams, useFetchLineage, useFetchLineageCsv } from '@api/lineage';
import Box from '@components/Box';
import useMergeLineageData from '@components/Explore/useMergeLineageData';
import SidebarTree from '@components/ExploreSidebar/SidebarTree';
import { SidebarTreeProps } from '@components/ExploreSidebar/SidebarTree/SidebarTree';
import { LoadLineageOptions } from '@components/ExploreSidebar/types';
import type { SearchOptions } from '@components/ExploreTree/atoms';
import TabError from '@components/TabContent/TabError';
import type { TabContentProps } from '@components/Tabs/types';
import Text from '@components/Text';
import { renderErrorToast } from '@components/Toast';
import Checkbox from '@components/UI/Form/Checkbox/CheckboxInput';
import InputLabel from '@components/UI/Form/InputLabel';
import { useUserContext } from '@context/User';
import flags from '@features';
import downloadCSV from '@utils/downloadCSV';

export interface UpstreamDownstreamTabProps extends TabContentProps {
  assetName: string;
  counts: SidebarTreeProps['counts'];
  customSearchOptions?: RecoilState<SearchOptions>;
  direction: 'left' | 'right';
  enableExportCsv?: boolean;
  mode?: 'table' | 'column';
  nodeKey: string;
  requestParamsExtraProps?: FetchLineageParams;
  showDescriptions?: boolean;
  showUsage?: boolean;
  tableOrColumnId: string;
}

const MAX_LINEAGE_CSV_OBJECTS = 10000;

export const checkCsvLimit = (csvData: string) => {
  const csvLinesCount = csvData.split('\n').length - 1;
  if (csvLinesCount > MAX_LINEAGE_CSV_OBJECTS) {
    renderErrorToast(
      `CSV limit of ${MAX_LINEAGE_CSV_OBJECTS.toLocaleString()} rows has been applied. Please use the API to fetch additional data.`,
    );
  }
};

const UpstreamDownstreamTab: React.FC<UpstreamDownstreamTabProps> = ({
  assetName,
  counts,
  customSearchOptions,
  direction,
  enableExportCsv = false,
  mode = 'table',
  nodeKey,
  requestParamsExtraProps = {},
  showDescriptions = true,
  showUsage = false,
  tableOrColumnId,
}) => {
  const { organization } = useUserContext();
  const [guid, setGuid] = useState(tableOrColumnId);
  const [isRelevantLineage, setIsRelevantLineage] = useState(
    Boolean(organization?.settings?.useRelevantLineage && flags.relevant_lineage_checkbox),
  );
  const [requestParams, setRequestParams] = useState<FetchLineageParams>({
    dbt_links: true,
    direction,
    enable_tags: false,
    group_by_data_source: true,
    include_borderline_edges: true,
    looker_db_lineage: true,
    looker_view_lineage: flags.looker_view_lineage,
    max_depth: 1,
    mode,
    mode_lineage: false,
    tableau_table_lineage: true,
    ...requestParamsExtraProps,
  });

  const { data, isError, isFetching, isLoading } = useFetchLineage(guid, {
    enabled: Boolean(tableOrColumnId),
    keepPreviousData: true,
    params: {
      ...requestParams,
    },
  });

  const { isLoading: isLoadingExportCsv, refetch: fetchExportLineage } = useFetchLineageCsv(
    tableOrColumnId,
    {
      enabled: false,
      onError: (error) => {
        const errorMsg = error?.data?.[0] ?? 'Sorry, something went wrong';
        renderErrorToast(errorMsg);
      },
      onSuccess: (csvData: string) => {
        const parsedDirection = direction === 'left' ? 'upstream' : 'downstream';
        checkCsvLimit(csvData);
        downloadCSV(csvData, `${assetName}_${parsedDirection}_lineage.csv`);
      },
      params: {
        dbt_links: requestParams.dbt_links,
        direction: direction === 'left' ? 'upstream' : 'downstream',
      },
      refetchOnWindowFocus: false,
    },
  );

  const lineageDataMerged = useMergeLineageData(data);

  const loadLineage = useCallback(
    (id: string, dir?: string, options?: LoadLineageOptions) => {
      setGuid(id);

      setRequestParams((prev) => ({
        ...prev,
        column_level_traversal:
          organization?.settings?.useRelevantLineage &&
          flags.relevant_lineage_checkbox &&
          options?.maxDepth === 'max'
            ? isRelevantLineage
            : undefined,
        direction: dir ?? 'all',
        include_borderline_edges: options?.maxDepth !== 'max',
        max_depth: options?.maxDepth ?? 1,
      }));
    },
    [isRelevantLineage, organization?.settings?.useRelevantLineage],
  );

  if (isError) return <TabError />;

  if (!nodeKey || (lineageDataMerged?.[`${mode}s`].length === 0 && !isLoading)) {
    return (
      <Text color="inherit" mt={2}>
        No lineage detected.
      </Text>
    );
  }

  const handleExportCsvClick = () => {
    fetchExportLineage();
  };

  return (
    <Box compDisplay="flex" compWidth="100%" flexDirection="column" gap={2}>
      {organization?.settings?.useRelevantLineage && flags.relevant_lineage_checkbox && (
        <InputLabel mt={3}>
          Relevant Lineage{' '}
          <Checkbox
            checked={isRelevantLineage}
            onChange={(e) => {
              setIsRelevantLineage(e.target.checked);
              lineageDataMerged.reset();
            }}
          />
        </InputLabel>
      )}
      <SidebarTree
        columns={lineageDataMerged.columns}
        counts={counts}
        customSearchOptions={customSearchOptions}
        direction={direction}
        isExpandingAll={requestParams.max_depth === 'max'}
        isLoading={isFetching}
        isLoadingExportCsv={isLoadingExportCsv}
        loadLineage={loadLineage}
        nodeKey={nodeKey}
        onExportCsvClick={enableExportCsv ? handleExportCsvClick : undefined}
        showDescriptions={showDescriptions}
        showExpandAll={lineageDataMerged?.tables.length > 0}
        showUsage={showUsage}
        startingGuid={tableOrColumnId}
        tables={lineageDataMerged.tables}
        type={mode}
        zoomOnItemClick={false}
      />
    </Box>
  );
};

export default UpstreamDownstreamTab;
