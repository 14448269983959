import React from 'react';

import type { ModalProps } from '@components/UI/Modal';
import Modal from '@components/UI/Modal';

interface ModalWithSidebarProps
  extends Pick<ModalProps, 'size' | 'onClose' | 'compHeight' | 'compWidth'> {}

const ModalWithSidebar: React.FC<ModalWithSidebarProps> = ({
  children,
  compHeight = '680px',
  compWidth,
  onClose,
  size = 'large',
}) => {
  return (
    <Modal
      compDisplay="flex"
      compHeight={compHeight}
      compWidth={compWidth}
      flexDirection="row"
      onClose={onClose}
      size={size}
    >
      {children}
    </Modal>
  );
};

export default ModalWithSidebar;
