import React from 'react';

import Text from '@components/Text';
import theme from '@styles/theme';

const ItemsCount: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <Text
      as="span"
      color={theme.colors.gray[600]}
      display="inline"
      fontSize="16px"
      verticalAlign="baseline"
    >
      {children}
    </Text>
  );
};

export default React.memo(ItemsCount);
