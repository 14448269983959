import React, { useMemo } from 'react';

import { useFetchMentions } from '@api/mentions';
import { MentionModel } from '@api/mentions/MentionModel';
import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import SearchResult, { SearchResultIcon, SearchTopIcon } from '@components/SearchBar/SearchResult';
import Skeleton from '@components/Skeleton';
import Text, { defaultParagraphStyles } from '@components/Text';
import Modal, { ModalContent, ModalHeader } from '@components/UI/Modal';
import { DeletedModel } from '@models/DeletedModel';
import theme from '@styles/theme';
import { urlFor } from '@utils/routing';

import IconButton from '../IconButton';

import { MentionedByModalLink } from './MentionedByModal.styles';

export interface MentionedByModalProps {
  filterByMetrics?: boolean;
  onClose?: () => void;
  targetId?: string;
}

const MentionedByModal: React.FC<MentionedByModalProps> = ({
  filterByMetrics,
  onClose,
  targetId,
}) => {
  const { data, isLoading } = useFetchMentions({
    params: {
      page_size: 50,
      query: '{guid,source,target}',
      source_type: filterByMetrics ? 'represented_as' : undefined,
      targets: targetId,
    },
  });

  const activeMentions = useMemo(
    () => data?.results.filter(({ source }) => source.obj.name !== DeletedModel.deletedName) ?? [],
    [data],
  );

  const target = useMemo(() => data?.results[0]?.target, [data]);
  const text = filterByMetrics ? 'is used in these metrics' : 'is mentioned by';
  const obj = target?.obj;

  const handleNewTabClick = (e: React.MouseEvent<HTMLDivElement>, mention: MentionModel) => {
    e.preventDefault();
    window.open(urlFor(mention.source.obj), '_blank');
  };

  return (
    <Modal
      closeOnEscape
      data-testid="mentionedBy"
      onClose={onClose}
      overflow="hidden"
      size="medium"
    >
      <ModalHeader
        onClose={onClose}
        title={
          isLoading ? (
            <Box compWidth={theme.space(32)} flex={1}>
              <Skeleton compWidth="100%" grayShade={200} variant="text" />
            </Box>
          ) : (
            <Box alignItems="center" compDisplay="flex" gap={0.5}>
              {obj?.dataTypes && (
                <>
                  <SearchTopIcon dataTypes={obj?.dataTypes} size="20px" />
                  <SearchResultIcon
                    dataTypes={obj?.dataTypes}
                    guid={obj?.guid}
                    name={obj?.name}
                    size="20px"
                  />
                </>
              )}
              <Text as="span" fontSize={theme.typography.fontSizes.h2} fontWeight="medium" mb={0}>
                {target?.obj.name}
              </Text>
              <Box alignItems="end" alignSelf="end" compDisplay="flex">
                <Text {...defaultParagraphStyles} as="span" lineHeight={1.65}>
                  {text}
                </Text>
              </Box>
            </Box>
          )
        }
      />
      <ModalContent
        compDisplay="flex"
        compHeight={theme.space(44)}
        flexDirection="column"
        maxHeight="unset"
        py={2.5}
      >
        {isLoading && <CircularLoader cover />}
        {activeMentions.map((mention) => (
          <MentionedByModalLink
            key={`mention-modal-link-${mention.guid}`}
            data-testid={`mention-modal-link-${mention.guid}`}
            to={urlFor(mention.source.obj)}
          >
            <Box position="absolute" right={theme.space(1.25)} top={theme.space(1.25)}>
              <IconButton iconName="open-new-tab" onClick={(e) => handleNewTabClick(e, mention)} />
            </Box>
            <SearchResult isMentionItem searchItem={mention.source.obj} searchText="" />
          </MentionedByModalLink>
        ))}
      </ModalContent>
    </Modal>
  );
};

export default MentionedByModal;
