import styled from '@emotion/styled';

import Box from '@components/Box';
import { FormHorizontalLabelGrid } from '@components/Form/Form.styles';
import { HelperText } from '@components/Input/Input.v1.styles';
import InputLabel from '@components/UI/Form/InputLabel';
import { StyledTextarea } from '@components/UI/Form/Textarea/Textarea.styles';

export const StyledDataSourceSetupContent = styled(Box)`
  .ui.selection.dropdown .menu {
    max-height: 12rem;
  }
  .StyledDropzoneFormElement {
    display: block;
  }

  ${StyledTextarea} {
    border-width: 1px;
  }
`;

StyledDataSourceSetupContent.defaultProps = {
  compDisplay: 'flex',
};

export const StyledFormHorizontalLabelGrid = styled(FormHorizontalLabelGrid)``;

StyledFormHorizontalLabelGrid.defaultProps = {
  ...FormHorizontalLabelGrid.defaultProps,
  alignItems: 'baseline',
  gap: 1.5,
  maxHeight: '70vh',
  minHeight: '350px',
  overflow: 'auto',
};

export const StyledLabel = styled(InputLabel)``;

StyledLabel.defaultProps = {
  as: 'label',
  compDisplay: 'contents',
  fontSize: 'body2',
  fontWeight: 'medium',
  gap: 1,
  noDefault: true,
};

export const ErrorText = styled(HelperText)``;

ErrorText.defaultProps = {
  as: 'span',
  state: 'error',
};
