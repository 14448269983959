import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import type { DescriptionSource } from '@api/description/description.types';
import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import { MetadataObjectType } from '@atoms';
import { breadcrumbsToLabelList, breadcrumbsToList } from '@components/Breadcrumbs';
import isEmptyRichText from '@components/RichTextEditor/helpers/isEmptyRichText';
import DataTypesModel from '@models/DataTypesModel';
import { urlFor } from '@utils/routing';

import { ObjectType } from '../types';

export class DashboardElementModel {
  static objectType: ObjectType = 'dashboardelement';

  objectType: ObjectType = DashboardElementModel.objectType;

  objectTypeV1: MetadataObjectType = 'dashboardelements';

  static typeDisplay: string = 'DashboardElement';

  typeDisplay: string = DashboardElementModel.typeDisplay;

  guid!: string;

  @Expose({ name: 'external_id' })
  externalId!: string;

  name!: string;

  description?: string;

  url?: string;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel = new DataTypesModel();

  // TODO: Remove transform https://app.shortcut.com/select-star/story/55620
  @Expose({ name: 'richtext_description' })
  @Transform((richtextDescription) =>
    isEmptyRichText(richtextDescription) ? '' : richtextDescription,
  )
  richtextDescription?: string;

  @Expose({ name: 'data_type' })
  dataType?: string;

  @Expose({ name: 'external_type' })
  externalType!: string;

  @Expose({ name: 'parent_guid' })
  parentGuid!: string;

  @Expose({ name: 'search_name' })
  fullName?: string;

  @Expose({ name: 'tagged_items' })
  @Type(() => TaggedItemModel)
  taggedItems?: TaggedItemModel[];

  @Expose({ name: 'deactivation_scheduled_on' })
  @Transform((value) => (value ? moment(value) : value))
  deactivationScheduledOn?: moment.Moment;

  @Expose({ name: 'ai_description' })
  aiDescription?: string;

  @Expose({ name: 'ingested_description' })
  ingestedDescription?: string;

  @Expose({ name: 'description_source' })
  descriptionSource?: DescriptionSource;

  @Expose({ name: 'user_description' })
  userDescription?: string;

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  get breadcrumbList() {
    return breadcrumbsToList(this.breadcrumbs);
  }

  get breadcrumbLabelList() {
    const url = urlFor(this, false, '');
    return breadcrumbsToLabelList(this.name, url, this.breadcrumbs);
  }
}
