import React from 'react';

import { useFetchRbacPolicies } from '@api/rbacPolicy';
import Box from '@components/Box';
import { GridContainer } from '@components/Grid';
import DeletePolicyConfirmationModal from '@components/Modal/DeletePolicyConfirmationModal';
import RBACPolicyModal from '@components/Modal/RBACPolicyModal';
import AdminRBACPolicyTable from '@components/Table/AdminRBACPolicyTable/AdminRBACPolicyTable';
import TitleHeader from '@components/Title/TitleHeader';
import TitleView from '@components/UI/TitleView';
import { useModal } from '@context/Modal';
import { UseFetchOptions } from '@hooks/useFetch';
import MetadataDecorator from '@utils/MetadataDecorator';
import stripSpaces from '@utils/stripSpaces';

import AddButton from './AddButton';

const query = stripSpaces(`{
	guid,
  name,
  role,
  created_by{
    -team,
  },
  status,
  access_to{
    is_wildcard,
    wildcard_type,
    target{
      object_type,
      obj
    }
  },
  assigned_to,
  except_for{
    except_for{
      object_type,
      obj{
        -breadcrumbs
      }
    }
  }
}`);

const config: UseFetchOptions = {
  params: {
    query,
  },
};

const AdminRBACPage: React.FC = () => {
  const { data, refetch } = useFetchRbacPolicies(config);
  const { MODAL_IDS, checkModalOpened, closeModal, getModalContent, openModal } = useModal();

  const addPolicyModalProps = getModalContent(MODAL_IDS.addPolicy);
  const deletePolicyModalProps = getModalContent(MODAL_IDS.deletePolicy);

  return (
    <>
      <MetadataDecorator title="Access Policy Admin" />
      <GridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <Box>
          <Box mb={10}>
            <TitleHeader>
              <span className="title big">Access Control</span>
            </TitleHeader>
          </Box>
          <Box maxWidth="100%" mb={5}>
            <TitleView
              action={
                <AddButton
                  onClick={() => {
                    openModal(MODAL_IDS.addPolicy, undefined);
                  }}
                />
              }
              mb={2.5}
              pt={1}
              title="All Policies"
            />
            <AdminRBACPolicyTable itemCount={data?.results.length} items={data?.results} />
          </Box>
        </Box>
      </GridContainer>
      {checkModalOpened(MODAL_IDS.addPolicy) && (
        <RBACPolicyModal
          onClose={() => closeModal(MODAL_IDS.addPolicy)}
          onSuccess={refetch}
          policy={addPolicyModalProps?.policy}
        />
      )}
      {checkModalOpened(MODAL_IDS.deletePolicy) && deletePolicyModalProps && (
        <DeletePolicyConfirmationModal
          onClose={() => closeModal(MODAL_IDS.deletePolicy)}
          onSuccess={refetch}
          policy={deletePolicyModalProps.policy}
        />
      )}
    </>
  );
};

export default AdminRBACPage;
