import styled from '@emotion/styled';

import Box from '@components/Box';
import rootTheme from '@styles/theme';

export const SECTION_GRID_TEMPLATE = `${rootTheme.space(20)} max-content`;

export const DSUserHeader = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.5rem;
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
`;

export const SectionRows = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.space(1.75)};
  gap: ${({ theme }) => theme.space(1.75)};
  max-width: 500px;
`;

export const Section = styled(Box)`
  display: grid;
  align-items: center;
  min-height: 36px;
  font-weight: ${({ theme }) => theme.typography.fontWeights.semibold};
  gap: ${({ theme }) => theme.space(1)};
  grid-template-columns: ${SECTION_GRID_TEMPLATE};
`;

export const SectionInfo = styled(Box)`
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
`;
