import { URL_CONFIG } from '@configs/urls/config';
import getUrl from '@configs/urls/getUrl';
import * as Sentry from '@sentry/react';

import DataTypesModel from '@models/DataTypesModel';
import InvalidDataException from '@utils/sentry/errors/invalidDataException';

import replaceAll from './replaceAll';

const NO_LINK = '#';

export const guidToObjectMap: { [guidPrefix: string]: string } = {
  cl: 'schema',
  cm: 'comment',
  co: 'column',
  da: 'dashboard',
  db: 'database',
  de: 'dashboardelement',
  dn: 'notebook',
  do: 'document',
  ds: 'datasource',
  dt: 'dbttest',
  du: 'dsuser',
  ef: 'explorefield',
  fl: 'space',
  le: 'explore',
  lm: 'lookmlmodel',
  lv: 'lookmlview',
  me: 'metric',
  st: 'bitable',
  ta: 'table',
  te: 'team',
  tf: 'tableaufield',
  tg: 'tag',
  ts: 'tableaudatasource',
  tv: 'tableauview',
  us: 'user',
  zd: 'dataset',
};

/**
 * reportquery in the following config is a general type for multiple object.
 * In that regard we can't select a proper internal router.
 * Providing merged keys based on objectType + dataType solves that issue.
 * Besides that there are an extra cases when it is better decide on both types, e.g.:
 * -  Tableau chart could be a dashboard, but we can't use that route,
 *    because dashboard is a different view.
 */
export const urlMap = {
  allDashboards: '/looker/{guid}/dashboards',
  allExplores: '/looker/{guid}/explores',
  allMetrics: '/metrics',
  allSpaces: '/mode/{guid}/spaces',
  allTableauDatasources: '/tableau/{guid}/datasources/',
  allViews: '/looker/{guid}/views',
  analyticsuser: '/profiles/{guid}',
  bitable: URL_CONFIG.thoughtspot?.thoughtspottable?.default!, // discussions redirect
  card: '/dashboards/{parentGuid}/cards/{guid}',
  categoryTag: '/tags/{guid}',
  column: '/tables/{parentGuid}/columns/{guid}',
  comment: '/link/{parentGuid}/{guid}',
  dashboard: '/dashboards/{guid}',
  dashboardelement: '/dashboards/{parentGuid}/tiles/{guid}',
  dashboardelementchart: '/dashboards/{parentGuid}/tiles/{guid}',
  data_studio: '/data-studio/{guid}/pages',
  data_studio_bi_folder: '/data-studio/{guid}/folder',
  database: '/databases/{guid}',
  dataset: URL_CONFIG.quicksight?.bidataset?.default!, // discussions redirect
  dbttest: '/tables/{parentGuid}/tests/',
  dbttests: '/dbt-tests/{guid}/tests',
  docs: '/docs/{guid}',
  document: '/docs/{guid}',
  dsuser: '/dsusers/{guid}',
  explore: '/explores/{guid}',
  explorefield: '/explores/{parentGuid}/fields/{guid}',
  folders: '/folders/{guid}',
  /**
   * @todo check if is possible to deprecate one of these "group"
   * according to Rafael, the correct data type is "group"
   * reference: https://app.shortcut.com/select-star/story/48667/be-add-group-object-to-explore-model-endpoint-results-bi-explores-endpoint#activity-57108
   */
  group: '/groups/{guid}',
  groups: '/groups/{guid}',
  look: '/dashboards/{guid}',
  looker_dashboard: '/dashboards/{guid}',
  looker_look: '/dashboards/{guid}',
  lookmlfield: '/views/{parentGuid}/fields/{guid}',
  lookmlmodel: '/models/{guid}',
  lookmlproject: '/lookmlprojects/{guid}',
  lookmlview: '/views/{guid}',
  metabase: '/metabase/{guid}/datasource',
  metabase_bi_folder: '/metabase/{guid}/folder',
  metabase_card: '/dashboards/{parentGuid}/cards/{guid}',
  metabase_dashboard: '/dashboards/{guid}',
  metric: '/metrics/{guid}',
  mode_report: '/dashboards/{guid}',
  notebook: URL_CONFIG.databricks?.notebook?.default!, // discussions redirect
  periscope_dashboard: '/dashboards/{guid}',
  periscope_dashboards: '/periscope/{guid}',
  periscope_folder: '/periscope/{guid}/dashboards',
  power_bi: '/power-bi/{guid}/datasource',
  power_bi_dashboards: '/power-bi/{guid}/dashboards',
  power_bi_folder: '/power-bi/{guid}/folder',
  power_bi_page: '/dashboards/{parentGuid}/pages/{guid}',
  power_bi_reports: '/power-bi/{guid}/reports',
  power_bi_tile: '/dashboards/{parentGuid}/tile/{guid}',
  project: '/projects/{guid}',
  projects: '/tableau/{guid}/projects',
  reportquery: '/dashboards/{parentGuid}/queries/{guid}', // read about reportquery issue above
  reportquerycard: '/dashboards/{parentGuid}/cards/{guid}', // read about reportquery issue above
  reportquerychart: '/dashboards/{parentGuid}/charts/{guid}', // read about reportquery issue above
  reportqueryelement: '/dashboards/{parentGuid}/elements/{guid}', // read about reportquery issue above
  reportquerypage: '/dashboards/{parentGuid}/pages/{guid}', // read about reportquery issue above
  reportqueryquery: '/dashboards/{parentGuid}/queries/{guid}', // TODO remove when all BI tools are supported with data_type
  reportquerytile: '/dashboards/{parentGuid}/tiles/{guid}', // TODO remove when all BI tools are supported with data_type
  reportqueryvisual: '/dashboards/{parentGuid}/pages/{guid}', // TODO remove when all BI tools are supported with data_type
  schema: '/schemas/{guid}',
  sigma: '/sigma/{guid}/folder',
  sigma_dashboards: '/sigma/{guid}',
  space: '/spaces/{guid}',
  spaces: '/spaces/{guid}',
  statusTag: '/tags/{guid}',
  table: '/tables/{guid}',
  tableau_workbook: '/dashboards/{guid}',
  tableaudatasource: '/tableaudatasources/{guid}',
  tableaufield: '/tableaudatasources/{parentGuid}/fields/{guid}',
  tableauview: '/workbooks/{parentGuid}/views/{guid}',
  tableauviewdashboard: '/workbooks/{parentGuid}/views/{guid}', // TODO remove when all BI tools are supported with data_type
  tableauviewsheet: '/workbooks/{parentGuid}/views/{guid}', // TODO remove when all BI tools are supported with data_type
  tablequery: '/tables/{guid}',
  tag: '/tags/{guid}',
  tags: '/tags/{guid}',
  team: '/teams/{guid}',
  term: '/terms/{guid}',
  thoughtspot: '/thoughtspot/{guid}/datasource',
  thoughtspot_bi_folder: '/thoughtspot/{guid}/folder',
  user: '/profiles/{guid}',
  workbook: '/workbooks/{guid}',
};

export const getObjectTypeFromGuid = (guid?: string): string | undefined => {
  if (!guid) return undefined;

  const prefix = guid?.slice(0, 2);

  if (prefix && prefix in guidToObjectMap) {
    return guidToObjectMap[prefix];
  }

  Sentry.captureException(new InvalidDataException('Invalid getObjectTypeFromGuid argument'), {
    extra: {
      guid,
      prefix,
    },
  });

  return undefined;
};

/*
 * this function is here to keep all urls parsing related to the
 * sidebar on the same place
 */
export const getUrlForDataSource = (type: string, guid: string) => {
  return `/admin/${type}/${guid}`;
};

export const getDiscussionUrl = (baseURL?: string, commentGuid?: string) => {
  if (!baseURL) return '#';
  if (!commentGuid) return `${baseURL}/discussion/`;
  if (baseURL.includes('/discussion')) return baseURL;
  return `${baseURL}/discussion/${commentGuid}`;
};

export const makeUrl = (
  type: keyof typeof urlMap | string,
  guid: string,
  parentGuid?: string,
  temp?: string,
  dataTypes?: DataTypesModel,
): string => {
  const template = temp || urlMap[type as keyof typeof urlMap];

  if (!template || !guid || (template.includes('{parentGuid}') && !parentGuid)) {
    Sentry.captureException(
      new InvalidDataException(`Cannot create url for (template: ${template}; guid: ${guid};)`),
      {
        extra: {
          guid,
          parentGuid,
          template,
          type,
        },
        tags: {
          guid,
          parentGuid,
          template,
          type,
        },
      },
    );

    return NO_LINK;
  }

  const dataTypesParams = dataTypes
    ? new URLSearchParams({
        dataSourceType: dataTypes.dataSourceType,
        objectType: dataTypes.objectType,
        ...(dataTypes.dataType && { dataType: dataTypes.dataType }),
      }).toString()
    : '';

  return replaceAll(template, { dataTypesParams, guid, parentGuid });
};

interface UrlForArgs {
  dataSourceGuid?: string; // from table model
  dataType?: string;
  dataTypes?: DataTypesModel;
  guid?: string;
  objectType?: string;
  parentGuid?: string;
  /**
   * @todo Update hierarchy to stop mutating the dataSourceId.
   */
  rawDataSourceGuid?: string;
  // from hierarchy
  type?: string;
  url?: string | null;
}

export const urlFor = (
  args?: UrlForArgs,
  excludeExternalUrls?: boolean,
  defaultUrl: string = NO_LINK,
): string => {
  const {
    dataSourceGuid,
    dataType,
    dataTypes,
    guid,
    objectType,
    parentGuid,
    rawDataSourceGuid,
    type,
    url,
  } = args ?? {};

  if (guid?.includes('mt_')) {
    return NO_LINK;
  }

  if (guid) {
    if (dataTypes?.dataSourceType! in URL_CONFIG) {
      const template = getUrl({
        dataTypes,
        guid: guid.split('.')[0], // workaround for mode, metabase and looker container
        parentGuid: rawDataSourceGuid || dataSourceGuid || parentGuid,
      });

      if (template && template !== NO_LINK) {
        return template;
      }
    }
    if (dataType && dataType in urlMap) {
      return makeUrl(dataType, guid, parentGuid);
    }

    if (objectType && objectType in urlMap) {
      return makeUrl(objectType, guid, parentGuid);
    }

    if (type && type in urlMap) {
      return makeUrl(type, guid, parentGuid);
    }
  }

  if (!excludeExternalUrls && url && url.indexOf('//') > 0) {
    return url;
  }

  return defaultUrl;
};
