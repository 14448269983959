import React, { useCallback, useMemo, useState } from 'react';

import { useFetchConnectionAlerts } from '@api/connectionAlerts';
import { useFetchDataSources } from '@api/dataSources';
import { useFetchDbtProjects } from '@api/dbt';
import { DbtTypes } from '@api/dbt/types';
import invalidateCache from '@api/invalidateCache';
import Box from '@components/Box';
import { DataSourceIngestModal, DataSourceSetupModal } from '@components/DataSourceSetup';
import { DBT_PROJECT_QUERY } from '@components/DataSourceSetup/components/DataSourceAddStep/forms/DbtForm';
import DataSourceConnectionStatusModal from '@components/Modal/DataSourceConnectionStatusModal';
import DataSourceIngestionLogModal from '@components/Modal/DataSourceIngestionLogModal';
import DeleteDataSourceConfirmationModal from '@components/Modal/DeleteDataSourceConfirmationModal';
import useEditableDataSources from '@components/SettingsSidebar/useEditableDataSources';
import AdminDatasourcesTable from '@components/Table/AdminDatasourcesTable';
import TitleView from '@components/UI/TitleView';
import { useModal } from '@context/Modal';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import { useUserContext } from '@context/User';
import { DataSourceModel } from '@models/DataSourceModel';
import stripSpaces from '@utils/stripSpaces';

import AddButton from '../../AddButton';

const query = stripSpaces(`{
  guid,
  iam_principal,
  is_credentials_setup_incomplete,
  is_ingesting,
  is_mode_discovery_db_added,
  last_fully_ingested_on,
  is_syncing_paused,
  last_ingested_on,
  name,
  processed_through,
  type,
  cloudformation_url,
  connection_error_message,
  connection_status,
  data_types,
  external_uuid
}`);

const AdminDataSources: React.FC = () => {
  const segment = useSegmentContext();
  const { isOrgAdmin } = useUserContext();
  const { MODAL_IDS, checkModalOpened, closeModal, getModalContent, openModal } = useModal();

  const [dbtDatasource, setDbtDatasource] = useState<DataSourceModel | undefined>(undefined);
  const [setupDataSource, setSetupDataSource] = useState<DataSourceModel | undefined>(undefined);
  const [ingestDataSource, setIngestDataSource] = useState<DataSourceModel | undefined>(undefined);
  const { data, isLoading: isLoadingDataSources } = useFetchDataSources({
    params: {
      meta_types: 'bi,dwh',
      query,
    },
  });

  const { data: connectionAlerts, isLoading: isLoadingConnectionAlerts } =
    useFetchConnectionAlerts();

  const { dataSources: editableDataSources, isLoading: isLoadingEditableDataSources } =
    useEditableDataSources({
      data: data?.results ?? [],
    });

  const handleDataSourceConfigure = useCallback(
    (dataSource: DataSourceModel) => {
      setSetupDataSource(dataSource);
      openModal(MODAL_IDS.createDatasource);
    },
    [MODAL_IDS.createDatasource, openModal],
  );

  useFetchDbtProjects({
    enabled: Boolean(dbtDatasource?.guid),
    onSuccess: (dbtData) => {
      const hasMultipleProjects = Boolean((dbtData?.count ?? 0) > 1);
      const dbtProject = dbtData?.results?.[0];

      if (!hasMultipleProjects && dbtProject?.dbtType === DbtTypes.core && dbtDatasource) {
        handleDataSourceConfigure(dbtDatasource);
      } else {
        setIngestDataSource(dbtDatasource);
        openModal(MODAL_IDS.ingestDataSource);
      }
    },
    params: { data_source: dbtDatasource?.guid, page_size: 1, query: DBT_PROJECT_QUERY },
    staleTime: 0,
  });

  const dataSources: DataSourceModel[] | undefined = useMemo(
    () => editableDataSources.filter((ds: DataSourceModel) => !!ds.lastIngestedOn),
    [editableDataSources],
  );
  const incompleteDataSources: DataSourceModel[] | undefined = useMemo(
    () => editableDataSources.filter((ds: DataSourceModel) => !ds.lastIngestedOn),
    [editableDataSources],
  );

  const removeDataSource = useCallback(() => {
    invalidateCache((keys) => [keys.organizations.defaultOrg]);
  }, []);

  const handleAddNewDataSource = useCallback(() => {
    setSetupDataSource(undefined);
    openModal(MODAL_IDS.createDatasource);
  }, [MODAL_IDS.createDatasource, openModal]);

  const handleDataSourceUpdate = useCallback((dataSource: DataSourceModel) => {
    if (dataSource.type === 'dbt') {
      setDbtDatasource(dataSource);
    } else {
      setIngestDataSource(dataSource);
      openModal(MODAL_IDS.ingestDataSource);
    }
  }, []);

  const handleSetupModalClose = useCallback(() => {
    segment?.track(SegmentTrackEventName.AddDSCloseButtonClicked);
    invalidateCache((keys) => [keys.dataSources.all, keys.ingestion.all]);
    setDbtDatasource(undefined);
    setSetupDataSource(undefined);
    closeModal(MODAL_IDS.createDatasource);
  }, [MODAL_IDS.createDatasource, closeModal, segment]);

  const handleIngestModalClose = useCallback(() => {
    setDbtDatasource(undefined);
    closeModal(MODAL_IDS.ingestDataSource);
    setIngestDataSource(undefined);
    invalidateCache((keys) => [keys.dataSources.all, keys.ingestion.all]);
  }, []);

  const isLoading = isLoadingDataSources || isLoadingEditableDataSources;

  const deleteDataSourceConfirmationModalProps = getModalContent(MODAL_IDS.deleteDataSource);
  const dataSourceIngestionLogModalProps = getModalContent(MODAL_IDS.ingestionLog);
  const dataSourceConnectionStatusModalProps = getModalContent(MODAL_IDS.connectionStatus);

  return (
    <>
      <Box maxWidth="100%" mb={5}>
        <TitleView
          action={isOrgAdmin ? <AddButton onClick={handleAddNewDataSource} /> : undefined}
          mb={2.5}
          pt={1}
          title="Data Sources"
        />
        <AdminDatasourcesTable
          connectionAlerts={connectionAlerts}
          data={dataSources}
          data-testid="completeDataSourcesTable"
          isLoading={isLoading}
          isLoadingConnectionAlerts={isLoadingConnectionAlerts}
          onDataSourceConfigure={handleDataSourceConfigure}
          onDataSourceUpdate={handleDataSourceUpdate}
        />
      </Box>
      {incompleteDataSources && incompleteDataSources.length > 0 && (
        <Box maxWidth="100%" mb={5}>
          <TitleView mb={2.5} pt={1} title="Incomplete" />
          <AdminDatasourcesTable
            connectionAlerts={connectionAlerts}
            data={incompleteDataSources}
            data-testid="incompleteDataSourcesTable"
            isIncompleteConnection
            onDataSourceConfigure={handleDataSourceConfigure}
          />
        </Box>
      )}
      {checkModalOpened(MODAL_IDS.createDatasource) && (
        <DataSourceSetupModal
          dataSource={setupDataSource}
          onClose={handleSetupModalClose}
          onSuccess={handleSetupModalClose}
          type={setupDataSource?.type}
        />
      )}
      {checkModalOpened(MODAL_IDS.ingestDataSource) && ingestDataSource && (
        <DataSourceIngestModal dataSource={ingestDataSource} onClose={handleIngestModalClose} />
      )}
      {checkModalOpened(MODAL_IDS.ingestionLog) && dataSourceIngestionLogModalProps && (
        <DataSourceIngestionLogModal
          dataSource={dataSourceIngestionLogModalProps.dataSource}
          onClose={() => closeModal(MODAL_IDS.ingestionLog)}
        />
      )}
      {checkModalOpened(MODAL_IDS.connectionStatus) && dataSourceConnectionStatusModalProps && (
        <DataSourceConnectionStatusModal
          connectionAlerts={dataSourceConnectionStatusModalProps.connectionAlerts}
          dataSource={dataSourceConnectionStatusModalProps.dataSource}
          onClose={() => closeModal(MODAL_IDS.connectionStatus)}
        />
      )}
      {checkModalOpened(MODAL_IDS.deleteDataSource) && deleteDataSourceConfirmationModalProps && (
        <DeleteDataSourceConfirmationModal
          item={deleteDataSourceConfirmationModalProps.item}
          onClose={() => closeModal(MODAL_IDS.deleteDataSource)}
          onRemove={removeDataSource}
        />
      )}
    </>
  );
};

export default AdminDataSources;
