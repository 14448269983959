import React from 'react';
import { useParams } from 'react-router-dom';

import { ColumnModel } from '@api/columns/ColumnModel';
import Box from '@components/Box';
import LinkedCell from '@components/Table/Cells/LinkedCell';
import { TypedCell } from '@components/Table/Cells/types';
import Text from '@components/Text';

interface MonteCarloColumnCellProps {
  columnData?: ColumnModel;
  openColumnsPage?: () => void;
}

const MonitorColumnCell: React.FC<Partial<TypedCell> & MonteCarloColumnCellProps> = (props) => {
  const { columnData, openColumnsPage } = props;
  const { guid } = useParams<{ guid: string; itemId: string }>();

  return (
    <Box alignItems="center" compDisplay="flex" flexWrap="wrap" gap={0.5}>
      {columnData ? (
        <LinkedCell
          {...props}
          customUrl={`/tables/${guid}/data-quality/monte-carlo/${columnData.guid}`}
          item={columnData}
          itemName={columnData.name}
          onClick={openColumnsPage}
          showIcon
          showNameTooltip
        />
      ) : (
        <Text color="gray.500" fontSize="body1" fontStyle="italic">
          Table level
        </Text>
      )}
    </Box>
  );
};

export default MonitorColumnCell;
