import React from 'react';

import { usePatchDataSource } from '@api/dataSources';
import Alert from '@components/Alert';
import Box from '@components/Box';
import NextStepButton from '@components/DataSourceSetup/components/Buttons/NextStepButton';
import PreviousStepButton from '@components/DataSourceSetup/components/Buttons/PreviousStepButton';
import ErrorCredentialAlert from '@components/DataSourceSetup/components/ErrorCredentialAlert/ErrorCredentialAlert';
import {
  StyledFormHorizontalLabelGrid,
  StyledLabel,
} from '@components/DataSourceSetup/DataSourceSetup.styles';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import { ModalFooter } from '@components/UI/Modal';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';

import ErrorDescriptionMarkdown from '../../ErrorDescriptionMarkdown';

import type { DataSourceAddActivityDataStepProps } from './types';

enum FieldKey {
  eventLogsBucket = 'event_logs_bucket',
  eventLogsBucketPrefix = 'event_logs_bucket_prefix',
}

const REQUIRED_FIELDS = [FieldKey.eventLogsBucket, FieldKey.eventLogsBucketPrefix];

interface FormValues {
  [FieldKey.eventLogsBucket]?: string;
  [FieldKey.eventLogsBucketPrefix]?: string;
}

const QuickSightAddActivityDataStepForm: React.FC<DataSourceAddActivityDataStepProps> = ({
  dataSource,
  onDataSourceAdded,
  prevStep,
}) => {
  const segment = useSegmentContext();
  const { error, isLoading, mutate } = usePatchDataSource(dataSource.guid, {
    onSuccess: onDataSourceAdded,
  });

  const { handleChange, handleSubmit, values } = useForm<FormValues>({
    initialValues: {
      [FieldKey.eventLogsBucket]: '',
      [FieldKey.eventLogsBucketPrefix]: '',
    },
    onSubmit: (credentials) => {
      mutate({
        credentials,
        guid: dataSource?.guid,
        type: dataSource?.type,
      });
    },
  });

  const INPUT_CONFIG = {
    [FieldKey.eventLogsBucket]: {
      Component: Input,
      key: FieldKey.eventLogsBucket,
      label: 'Event Logs Bucket',
      onChange: handleChange,
    },
    [FieldKey.eventLogsBucketPrefix]: {
      Component: Input,
      key: FieldKey.eventLogsBucketPrefix,
      label: 'Event Logs Bucket Prefix',
      onChange: handleChange,
    },
  };

  const isEmpty = !Object.values(values).some((value) => Boolean(value) === true);
  const isInvalid = isEmpty ? false : REQUIRED_FIELDS.some((key) => Boolean(values[key]) === false);

  const handleCustomSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault?.();
    segment?.track(SegmentTrackEventName.ImportData_QuickSightActivityDBNextButtonClicked);

    if (isEmpty) {
      onDataSourceAdded(dataSource);
    } else {
      handleSubmit(e);
    }
  };

  return (
    <Form isLoading={isLoading} onSubmit={handleCustomSubmit}>
      <StyledFormHorizontalLabelGrid>
        <Box gridColumn="1/3">
          <Alert type="info">
            This step is required to calculate popularity. If you are not interested in this, you
            can continue to the next step.
          </Alert>
        </Box>
        {Object.values(INPUT_CONFIG).map(({ Component, key, label, ...other }) => (
          <StyledLabel key={key}>
            {label}
            <Component
              error={error?.data?.[key]}
              helperText={error?.data?.[key]}
              name={key}
              placeholder={label}
              value={values[key]}
              {...other}
            />
          </StyledLabel>
        ))}
        <Box gridColumn="1/3">
          {error && error.status === 500 && (
            <Alert title="Couldn't store credentials." type="error">
              Please try again later.
            </Alert>
          )}
          <ErrorCredentialAlert error={error?.data.credentials} />
          <ErrorDescriptionMarkdown error={error} />
        </Box>
      </StyledFormHorizontalLabelGrid>
      <ModalFooter>
        <PreviousStepButton
          disabled={isLoading}
          onClick={() => {
            segment?.track(SegmentTrackEventName.ImportData_QuickSightActivityDBPrevButtonClicked);
            prevStep();
          }}
        />
        <NextStepButton disabled={isLoading || isInvalid} text="Save" />
      </ModalFooter>
    </Form>
  );
};

export default QuickSightAddActivityDataStepForm;
