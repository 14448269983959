import React from 'react';

import invalidateCache from '@api/invalidateCache';
import { useFetchBiViews } from '@api/views';
import type { BiViewModel } from '@api/views/BiViewModel';
import Box from '@components/Box';
import BulkButtons from '@components/BulkButtons/BulkButtons';
import { FiltersSidebar } from '@components/FiltersSidebar';
import { GridContainer } from '@components/Grid';
import { ToggleHideItemsModal } from '@components/Modal';
import PageHeaderBar from '@components/PageHeaderBar';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import useBulkEditSelected from '@hooks/useBulkEditSelected';
import { StyledTablesPage } from '@pages/TablesPage/TablesPage.styles';
import type { FilterOptions } from '@utils/filters';
import { setParams, useUpdateFilters } from '@utils/filters';
import getItemsCount from '@utils/getItemsCount';
import MetadataDecorator from '@utils/MetadataDecorator';
import stripSpaces from '@utils/stripSpaces';

import { DATA_TYPE_OPTIONS } from './constants';
import type { ProjectPageViewProps } from './types';
import ViewTable, { VIEW_TABLE_SEARCH_CONFIG, VIEW_TABLE_SORT_CONFIG } from './ViewTable';

const COUNT_ITEMS = [{ key: 'count', text: 'View' }];

const VIEW_REQUEST_CONFIG: FilterOptions = {
  order: '-popularity',
  page: 1,
  page_size: 100,
  query: stripSpaces(`{
    guid,
    workbook,
    external_id,
    name,
    description,
    popularity,
    tagged_items,
    is_hidden,
    view_type,
    data_types,
  }`),
};

interface ViewPageProps extends ProjectPageViewProps {}

const ViewPage: React.FC<ViewPageProps> = ({
  dataTypes,
  dsGuid,
  guid,
  onSelectDataType,
  subtitle,
  title,
}) => {
  const { MODAL_IDS, checkModalOpened } = useModal();
  const { reset, selected, tagsCounts, toggleAll, toggleItem } = useBulkEditSelected<BiViewModel>({
    key: `${dsGuid} ${guid}`,
  });
  const filterService = useUpdateFilters(
    VIEW_REQUEST_CONFIG,
    VIEW_TABLE_SEARCH_CONFIG,
    VIEW_TABLE_SORT_CONFIG,
    VIEW_REQUEST_CONFIG.order,
  );
  const { filter } = filterService;
  const { data, isLoading } = useFetchBiViews({
    params: { ...setParams(filter), bifolders: guid, datasources: dsGuid },
  });

  const { isPbac, permissions } = useObjectPermissionsContext({ id: dsGuid });
  const selectedEditableItems = selected.items.filter((item) =>
    isPbac ? permissions[item.guid]?.isEditable : true,
  );

  const refetch = () => {
    invalidateCache((keys) => [keys.views.all]);
  };

  const titleSuffix = !isLoading && getItemsCount({ countItems: COUNT_ITEMS, ref: data });

  return (
    <Box compDisplay="flex">
      <MetadataDecorator title={title} />
      <GridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <StyledTablesPage>
          <PageHeaderBar
            icon={dataTypes && <Icon name={dataTypes.icons.dataType} />}
            sticky
            supIcon={dataTypes && <Icon name={dataTypes.icons.dataSource} />}
            supTitle={subtitle}
            title={title}
            titleSuffix={titleSuffix}
          />
          <BulkButtons
            canEditTags
            canRemove={!filter.is_hidden}
            canUnHide={filter.is_hidden}
            isDataSourceEditable
            selectedEditableItems={selectedEditableItems}
            selectedItems={selected.items}
            showText
            tagsCounts={tagsCounts}
          />
          <Box mb={0.5}>
            <ViewTable
              data={data}
              filterService={filterService}
              isDataSourceEditable
              isLoading={isLoading}
              selectedRowIds={selected.ids}
              toggleAll={(checked) => {
                toggleAll(data?.results!, checked);
              }}
              toggleItem={toggleItem}
            />
          </Box>
        </StyledTablesPage>
      </GridContainer>
      {checkModalOpened(MODAL_IDS.hideItems) && (
        <ToggleHideItemsModal
          isShowItemsModal={filter.is_hidden}
          reloadData={() => {
            refetch();
            reset();
          }}
          selectedItems={selectedEditableItems}
        />
      )}
      <FiltersSidebar
        dataSourceType={dataTypes?.dataSourceType}
        dataTypeInitialSelected={DATA_TYPE_OPTIONS[1].value}
        dataTypeOptions={DATA_TYPE_OPTIONS}
        filterService={filterService}
        isCategoryTags
        isPopularity
        isSelectStarDetected
        isStatusTags
        isViewDataType
        onSelectDataType={onSelectDataType}
        showHidden
      />
    </Box>
  );
};
export default React.memo<ViewPageProps>(ViewPage);
