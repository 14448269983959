import React, { memo, useMemo } from 'react';

import invalidateCache from '@api/invalidateCache';
import { usePatchOrganizationUser } from '@api/organizationUser';
import { renderErrorToast, renderInfoToast } from '@components/Toast';
import Select, { Option, SelectValue } from '@components/UI/Select';

type OperationType = 'team' | 'role';

interface UserUpdateSelectProps {
  options: Option[];
  type: OperationType;
  userGuid: string;
  value: string;
}

const UserUpdateSelect = ({ options, type, userGuid, value }: UserUpdateSelectProps) => {
  const { isLoading: isUpdatingUser, mutate: updateUser } = usePatchOrganizationUser('', {
    onError: () => {
      renderErrorToast("Couldn't change user settings.");
    },
    onSuccess: () => {
      renderInfoToast('User settings has been changed.');
      invalidateCache((keys) => [
        keys.organizationUser.all,
        keys.organizationUser.organizationUser('me'),
        keys.teams.all,
        keys.user.me,
      ]);
    },
  });

  const handleUpdate = (newValue: SelectValue) => {
    const [option] = newValue as Option[];

    updateUser({
      httpClientUrl: `/users/${userGuid}/`,
      [type]: option.value as string,
    });
  };

  const selectedValue = useMemo(
    () => options.find((option) => option.value === value),
    [options, value],
  );

  return (
    <Select
      isLoading={isUpdatingUser}
      onChange={handleUpdate}
      options={options}
      value={selectedValue ? [selectedValue] : undefined}
    />
  );
};

export default memo(UserUpdateSelect);
