import React from 'react';

import { StatusType } from '@api/types';
import { tagColorMap } from '@components/AppTag/helpers';
import NumberBadge from '@components/NumberBadge';
import Icon from '@components/UI/Icon';

import { statusMap } from '../Status/Status';

export type StatusCountsType = Record<StatusType, number> | null;

interface StatusBadgeProps {
  counts?: StatusCountsType;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ counts }) => {
  if (!counts) {
    return null;
  }
  if (counts.error === 0 && counts.warning === 0 && counts.success === 0) {
    return null;
  }

  if (counts?.error === 0 && counts.warning === 0) {
    return <Icon color={tagColorMap.endorsed} name="endorsed" size="20px" />;
  }

  const status = counts?.error > 0 ? 'error' : 'warning';
  const count = counts?.error > 0 ? counts?.error : counts?.warning;

  return (
    <NumberBadge
      aria-label={statusMap[status].text}
      backgroundColor={statusMap[status].color}
      color="white"
      role="status"
      value={count}
    />
  );
};

export default StatusBadge;
