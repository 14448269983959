import React from 'react';

import invalidateCache from '@api/invalidateCache';
import { piiRulesCacheKeys, useDeletePiiRule } from '@api/piiRules';
import { PiiRuleModel } from '@api/piiRules/PiiRuleModel';
import Box from '@components/Box';
import { renderInfoToast } from '@components/Toast';
import fetchClient from '@lib/fetchClient';

import DeleteConfirmationModal from '../DeleteConfirmationModal';

interface DeletePiiRuleModalProps {
  onClose: () => void;
  pii?: PiiRuleModel;
}

const DeletePiiRuleModal: React.FC<DeletePiiRuleModalProps> = ({ onClose, pii }) => {
  const { error, isLoading, mutate } = useDeletePiiRule(pii?.guid ?? '', {
    onSuccess: () => {
      renderInfoToast('PII Rule Deleted');
      fetchClient.invalidateQueries(piiRulesCacheKeys.piiRules);
      invalidateCache((keys) => [keys.activity.all]);
      onClose();
    },
  });

  if (!pii) return null;

  return (
    <DeleteConfirmationModal
      error={error?.data?.detail || error?.data?.non_field_errors || error?.message}
      loading={isLoading}
      onClose={onClose}
      onRemove={() => mutate(null)}
      title="Delete PII Rule"
    >
      <Box as="span">
        Are you sure you want to delete{' '}
        <Box as="span" fontWeight="bold">
          {pii.name}
        </Box>
        ?
      </Box>
    </DeleteConfirmationModal>
  );
};

export default DeletePiiRuleModal;
