import styled from '@emotion/styled';
import * as CSS from 'csstype';

import Avatar from '@components/UI/Avatar';

interface DropdownStyleProps {
  compWidth?: CSS.Property.Width;
  flexGrow?: CSS.Property.FlexGrow;
  minWidth?: CSS.Property.MinWidth;
}

const DropdownStyle = styled.div<DropdownStyleProps>`
  position: relative;
  flex-grow: ${({ flexGrow = 'unset' }) => flexGrow};

  .ui.selection.dropdown {
    border: 0;
    font-size: 0.8125rem;
    line-height: 0.9375rem;
    font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
    font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
    color: #0c3476;
    background-color: #f2f5f9;
    min-width: ${({ minWidth = '8rem' }) => minWidth};
    width: ${({ compWidth = '100%' }) => compWidth};

    .icon {
      visibility: hidden;
    }

    .visible {
      border: none;
    }
  }

  .ui.active.visible .active,
  .ui.active.visible .selected {
    font-size: 0.8125rem;
    background-color: #f2f5f9;
    border: none;
  }

  .ui.active.visible .selected {
    font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  }

  .dropdownArrow {
    position: absolute;
    top: 50%;
    right: 0.625rem;
    transform: translateY(-50%);
    z-index: 1;
  }

  .ui.selection.dropdown .menu > .item {
    font-size: 0.8125rem;
  }

  .ui.default.dropdown:not(.button) > .text,
  .ui.dropdown:not(.button) > .default.text,
  .ui.selection.visible.dropdown > .text:not(.default) {
    color: #0c3476;
    justify-content: flex-start;
  }

  .ui.dropdown,
  .ui.dropdown .menu > .item {
    background-color: #f2f5f9;
    border: none;
    color: #0c3476;
    font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
    font-size: 0.8125rem;
    font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
  }

  .ui.selection.dropdown.active,
  .ui.selection.dropdown.visible {
    z-index: ${({ theme }) => theme.zIndex.floatingElement};
  }

  .ui.dropdown {
    &.active,
    .menu {
      border-radius: 0.28571429rem !important;
    }
    .menu {
      margin-top: 0.25rem;
      border: none;
      > .item {
        padding: 0.75rem 1rem !important;
        &:hover {
          color: #ffffff;
          background-color: #375fa0;
        }
      }
    }
  }

  &.dataTypeOptions {
    display: inline-flex;
    float: right;
  }
`;

export default DropdownStyle;

export const FlexDropDownStyle = styled(DropdownStyle)`
  .ui.selection.dropdown {
    min-width: 2rem;
    width: 100%;
  }

  .ui.dropdown > .text {
    display: flex;
    transition: none;
    width: 100%;
    justify-content: end;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .ui.selection.dropdown .menu > .item {
    font-size: 0.8125rem;
    display: flex;
    justify-content: end;
    align-items: center;

    span:not(.user-avatar):not(.datasource-icon) {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

export const StyledOption = styled.span`
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  vertical-align: middle;
  .name {
    font-size: 0.875rem;
    color: #2c2e36;
  }
  .team {
    margin-left: 0.5rem;
    font-size: 0.875rem;
    font-weight: ${({ theme }) => theme.typography.fontWeights.regular};

    color: #375fa0;
  }

  .user-avatar {
    display: inline-flex !important;
    border-radius: 50%;
    margin-right: 0.5rem;
  }

  .type {
    margin-left: 0.5rem;
    font-size: 0.875rem;
    font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
    color: #aeb4bb;
  }
`;

export const StyledAvatar = styled(Avatar)`
  display: inline-flex !important;
  margin-right: 0.5rem;
  vertical-align: middle;
`;
