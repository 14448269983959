import React, { useState } from 'react';

import Icon from '@components/UI/Icon';
import Select from '@components/UI/Select';
import { Option, SelectValue } from '@components/UI/Select/types';

import { AccordionItemContent, AccordionItemTitle } from './StyledAccordion';

interface DropDownProps {
  initialSelected?: string | null;
  options: {
    key: string;
    text: string;
    value: string | null;
  }[];
  placeholder?: string;
}

export interface AccordionDropdownItemProps {
  accordionItems: DropDownProps;
  title: string;
  update: (item: string, option: Option<string>) => void;
}
const AccordionDropdownItem: React.FC<AccordionDropdownItemProps> = ({
  accordionItems,
  title,
  update,
}) => {
  const [active, setActive] = useState(true);

  const options = accordionItems.options as Option[];
  const selectedOption = options.find((option) => option.value === accordionItems.initialSelected);

  const handleOptionChange = (newValue: SelectValue) => {
    const [selected] = newValue as Option<string>[];
    update(selected.value as string, selected);
  };

  return (
    <>
      <AccordionItemTitle active={active} onClick={() => setActive((prev) => !prev)}>
        {title}
        <Icon ml={0.5} name={active ? 'caret-down' : 'caret-right'} size="10px" />
      </AccordionItemTitle>
      <AccordionItemContent active={active}>
        <Select
          label={`Select ${title}`}
          onChange={handleOptionChange}
          options={options}
          placeholder={accordionItems.placeholder}
          value={selectedOption ? [selectedOption] : []}
        />
      </AccordionItemContent>
    </>
  );
};

export default React.memo(AccordionDropdownItem);
