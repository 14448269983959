import React from 'react';

import invalidateCache from '@api/invalidateCache';
import { useDeleteMetrics } from '@api/metrics';
import Box from '@components/Box';
import { renderInfoToast } from '@components/Toast';
import { MetadataData } from '@models/MetadataModel';

import TableList from '../../TableList';
import DeleteConfirmationModal from '../DeleteConfirmationModal';

interface Props {
  onClose: () => void;
  onSuccess?: () => void;
  reloadData?: () => void;
  selectedItems: MetadataData[];
}

const DeleteMetricsModal = (props: Props) => {
  const { onClose, onSuccess, reloadData, selectedItems } = props;

  const { error, isLoading, mutate } = useDeleteMetrics({
    onSuccess: () => {
      renderInfoToast('Metrics deleted');
      invalidateCache((keys) => [keys.metrics.all, keys.documents.hierarchy]);
      reloadData?.();
      onSuccess?.();
      onClose();
    },
  });

  const handleSubmit = () => {
    const guids = selectedItems.map((item) => item.guid);
    const data = { metrics: guids };
    mutate(data);
  };

  return (
    <DeleteConfirmationModal
      error={
        error?.data?.metrics ||
        error?.data?.detail ||
        error?.data?.non_field_errors ||
        error?.message
      }
      loading={isLoading}
      onClose={onClose}
      onRemove={handleSubmit}
      title="Remove Metric"
    >
      <Box compDisplay="flex" compWidth="100%" flexDirection="column">
        Are you sure you want to remove the following from Select Star? All the documentation on the
        following metrics will be deleted.
        <TableList items={selectedItems} />
      </Box>
    </DeleteConfirmationModal>
  );
};

export default DeleteMetricsModal;
