import React from 'react';

import { useDeleteDataSource } from '@api/dataSources/dataSources';
import invalidateCache from '@api/invalidateCache';
import Box from '@components/Box';
import DeleteConfirmationModal from '@components/Modal/DeleteConfirmationModal';
import { renderInfoToast } from '@components/Toast';

interface Props {
  guid?: string;
  onClose: () => void;
  onSuccess?: () => void;
}

const DisconnectMonteCarloModal = ({ guid = '', onClose, onSuccess }: Props) => {
  const { error, isLoading, mutate } = useDeleteDataSource(guid, {
    onSuccess: () => {
      renderInfoToast('Monte Carlo disconnected. It will be full deleted in the next 24 hours.');
      invalidateCache((keys) => [keys.dataSources.dataSourcesInfo]);
      onSuccess?.();
    },
    params: {
      meta_types: 'data_quality',
    },
  });

  const handleSubmit = () => {
    mutate(null);
  };

  return (
    <DeleteConfirmationModal
      deleteButtonText="Disconnect"
      error={error?.message}
      loading={isLoading}
      onClose={onClose}
      onRemove={handleSubmit}
      title="Disconnect Monte Carlo"
    >
      <Box compDisplay="flex" compWidth="100%" flexDirection="column">
        Are you sure you want to disconnect Monte Carlo?
      </Box>
    </DeleteConfirmationModal>
  );
};

export default DisconnectMonteCarloModal;
