import React from 'react';

import Box from '@components/Box';
import Text from '@components/Text';
import Image from '@components/UI/Image';

interface EmptyContentProps {
  message: React.ReactNode;
}
const EmptyContent: React.FC<EmptyContentProps> = ({ message }) => {
  return (
    <Box
      alignItems="center"
      compDisplay="flex"
      flexDirection="column"
      gap={1}
      justifyContent="start"
      mb={2}
      mt={3}
    >
      <Image alt="No content" height="auto" src="/images/empty-content.png" width="120px" />
      {message && (
        <Text color="gray.500" fontSize="body2">
          {message}
        </Text>
      )}
    </Box>
  );
};

export default EmptyContent;
