import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import { LookerExploreFieldModel } from '@api/explores/LookerExploreFieldModel';
import { LookerExploreModel } from '@api/explores/LookerExploreModel';
import invalidateCache from '@api/invalidateCache';
import { useFetchMetadataComments } from '@api/metadata';
import { useFetchMetric, usePatchMetric } from '@api/metrics';
import rawTransform from '@api/rawTransform';
import { searchCacheKeys } from '@api/search';
import { TableauDataSourceModel } from '@api/tableau/TableauDataSourceModel';
import { TableModel } from '@api/tables/TableModel';
import Breadcrumbs from '@components/Breadcrumbs';
import MetadataObjectButtonsToolbar from '@components/MetadataObjectButtonsToolbar';
import MetadataObjectTemplate from '@components/MetadataObjectTemplate';
import {
  representedAsFilters,
  representedAsTabs,
} from '@components/Modal/CreateMetricModal/CreateMetricModal';
import OpenInDjango from '@components/OpenInDjango';
import RichTextDescriptionEditor from '@components/RichTextDescriptionEditor';
import SidebarMetadataObjectDetails from '@components/SidebarMetadataObjectDetails';
import DiscussionTab from '@components/TabContent/DiscussionTab';
import OverviewTab from '@components/TabContent/OverviewTab';
import TabsRouter from '@components/TabsRouter';
import { renderInfoToast } from '@components/Toast';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import { useSessionContext } from '@context/Session';
import { useUserContext } from '@context/User';
import fetchClient from '@lib/fetchClient';
import DataTypesModel from '@models/DataTypesModel';
import EditableHeader from '@pages/DocumentsPage/EditableHeader';
import stripSpaces from '@utils/stripSpaces';
import wrapString from '@utils/wrapString';

import DimensionsTab from './Tabs/DimensionsTab';
import RelatedTabs from './Tabs/RelatedTabs';

const externalLinkMap: Record<string, 'looker' | 'tableau'> = {
  explorefield: 'looker',
  tableaufield: 'tableau',
};

const requestParams = {
  query: stripSpaces(`{
    business_owner,
    created_by,
    description,
    full_path,
    richtext_description,
    represented_as,
    richtext_represented_as,
    represented_as_fields,
    guid,
    name,
    sql,
    tagged_items,
    technical_owner,
    updated_on,
    breadcrumbs,
    data_types
  }`),
};

const getParentGuid = (parentObj?: TableModel | LookerExploreModel | TableauDataSourceModel) => {
  if (parentObj?.objectType === 'table' && parentObj?.breadcrumbs) {
    const parent = parentObj.breadcrumbs.filter((obj) => obj.ordinal === 0)[0];
    return parent?.targetGuid ?? null;
  }
  return parentObj?.guid;
};

const MetricPage: React.FC = () => {
  const { guid } = useParams<{ guid: string }>();
  const { sidebarItems } = useSessionContext();
  const { isEditable } = useObjectPermissionsContext({ id: guid });
  const miniMetric = guid && sidebarItems?.[guid];
  const { organization } = useUserContext();
  const discussionsEnabled = organization?.settings?.useDiscussions;
  const history = useHistory();

  const { data, error, isError, isLoading, refetch } = useFetchMetric(guid, {
    enabled: Boolean(guid),
    keepPreviousData: true,
    onSuccess: (d) => {
      fetchClient.setQueryData(searchCacheKeys.searchItem(d.guid), d);
    },
    params: requestParams,
  });

  const { data: commentsData } = useFetchMetadataComments(guid);
  const { name } = {
    name: '',
    ...(miniMetric || {}),
    ...(data || {}),
  };

  const {
    isError: patchMetricError,
    isLoading: patchMetricLoading,
    mutate: mutateMetric,
  } = usePatchMetric(guid, {
    onSuccess: () => {
      invalidateCache((keys) => [keys.metrics.all, keys.documents.all]);
      refetch();
      renderInfoToast(`${name} definition has been updated.`);
    },
  });

  const firstMeasure = data?.representedAsFields ? data?.representedAsFields[0] : undefined;
  const parentObj = firstMeasure?.parent?.obj;
  const measureFieldObj = firstMeasure?.item?.obj;
  const externalUrl = (measureFieldObj as LookerExploreFieldModel)?.externalUrl;
  const parentGuid = getParentGuid(parentObj);
  const externalLinkType = measureFieldObj?.objectType
    ? externalLinkMap[measureFieldObj?.objectType]
    : undefined;

  const [metricName, setMetricName] = useState(name);

  useEffect(() => {
    setMetricName(name);
  }, [name]);

  const handleNameSave = () => {
    if (name !== metricName) {
      mutateMetric({
        name: metricName ?? 'Untitled',
      });
    }
  };

  /** Redirect to old metric page format to the correct page. */
  if (guid.startsWith('me_')) {
    return <Redirect to={`/metrics/${guid.replace('me_', 'do_')}`} />;
  }

  const tabsRouterConfig = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        <OverviewTab
          canEditDescription={isEditable}
          description={data?.description}
          guid={guid}
          isEditable={false}
          isError={patchMetricError}
          isLoading={isLoading || patchMetricLoading}
          onDescriptionSave={(richtextDesc, plainTextDesc) =>
            mutateMetric({ description: plainTextDesc, richtext_description: richtextDesc })
          }
          renderBelowEditor={() => (
            <RichTextDescriptionEditor
              key="representedAs"
              customMentionFilters={representedAsFilters}
              customSearchTabs={representedAsTabs}
              descriptions={{
                description: data?.representedAs,
                richTextDescription: data?.richtextRepresentedAs,
              }}
              guid={guid}
              isEditable={isEditable}
              isError={patchMetricError}
              isLoading={patchMetricLoading}
              markdownEnabled={false}
              onDescriptionSave={(richtextRepresentedAs, plainTextRepresentedAs) =>
                mutateMetric({
                  represented_as: plainTextRepresentedAs,
                  richtext_represented_as: richtextRepresentedAs,
                })
              }
              placeholder="Enter represented as..."
              title="Represented As"
              variant="block"
            />
          )}
          richtextDescription={data?.richtextDescription}
        >
          <SidebarMetadataObjectDetails
            businessOwner={data?.businessOwner === null ? null : data?.businessOwner?.obj}
            data={data}
            editOwnersItems={data ? [data] : undefined}
            guid={guid}
            isEditable={isEditable}
            itemsType="metrics"
            lastUpdated={data?.lastUpdated}
            popularity={measureFieldObj?.popularity}
            reloadData={() => refetch()}
            showMentionedBy
            technicalOwner={data?.technicalOwner === null ? null : data?.technicalOwner?.obj}
          />
        </OverviewTab>
      ),
      default: true,
      label: 'Overview',
      path: '/overview',
      route: '/overview',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <DimensionsTab guid={guid} />,
      contentOffset: 1,
      label: 'Dimensions',
      route: '/dimensions',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <RelatedTabs fullTableName={name} guid={guid} parentGuid={parentGuid} />,
      contentOffset: 1.6,
      label: 'Related',
      route: '/related',
    },
    ...(discussionsEnabled
      ? [
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            component: () => (
              <DiscussionTab
                businessOwner={data?.businessOwner?.obj}
                guid={guid}
                technicalOwner={data?.technicalOwner?.obj}
              />
            ),
            contentOffset: 2,
            label: `Discussion ${wrapString(commentsData?.length)}`,
            path: '/discussion/:itemId?',
            route: '/discussion',
          },
        ]
      : []),
  ];

  return (
    <MetadataObjectTemplate
      data={data}
      deleteAllowed={isEditable}
      guid={guid}
      headerProps={{
        icon: (
          <Tooltip content={data?.dataTypes?.tooltips.objectType}>
            <Icon name="metric" size="30px" />
          </Tooltip>
        ),
        rightEdgeEl: (
          <MetadataObjectButtonsToolbar
            buttons={['external', 'sql', 'share']}
            // TODO: To be fixed after sc-52576
            dataTypes={rawTransform(DataTypesModel)({ data_source_type: externalLinkType })}
            externalUrl={externalUrl}
            guid={guid}
            sql={data?.sql}
            sqlEditable={isEditable}
          />
        ),
        supIcon: <Icon color="#6f747c" name="all-docs" />,
        supTitle: <Breadcrumbs items={data?.breadcrumbList} />,
        title: (
          <EditableHeader
            disabled={!isEditable}
            onChange={setMetricName}
            onSave={handleNameSave}
            value={metricName || ''}
          />
        ),
        titleToolBox: <OpenInDjango guid={data?.guid} size="14px" />,
      }}
      isAuthorized={!(error && error?.status === 403)}
      isError={isError || !guid || !organization?.settings?.useMetrics}
      metaTitle={name}
      objectName={name}
      objectType="metrics"
      onDeleteSuccess={() => history.push('/docs/tabs')}
      onTagsEdit={() => refetch()}
      tagsEditAllowed={isEditable}
    >
      <TabsRouter config={tabsRouterConfig} sticky />
    </MetadataObjectTemplate>
  );
};

export default MetricPage;
