import styled from '@emotion/styled';

export const StyledFormSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 0.5rem;
`;
