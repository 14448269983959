import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import type { ColumnModel } from '@api/columns/ColumnModel';
import invalidateCache from '@api/invalidateCache';
import { MetricModel } from '@api/metrics/MetricModel';
import { usePatchMetricsDimensions, usePostMetrics } from '@api/metrics/metrics';
import { SearchModel } from '@api/search/SearchModel';
import Alert from '@components/Alert';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import Form from '@components/Form';
import Input from '@components/Input';
import QuickSearchInput from '@components/QuickSearch/QuickSearchInput';
import type { DatasourceTabV1 } from '@components/SearchBar/DatasourceTabs/config';
import { tabConfig } from '@components/SearchBar/DatasourceTabs/config';
import InputLabel from '@components/UI/Form/InputLabel';
import Modal, { ModalContent, ModalFooter, ModalHeader } from '@components/UI/Modal';

import BiColumnSelect from './BiColumnSelect';
import BiColumnSelectV1 from './BiColumnSelectV1';
import ColumnSelect from './ColumnSelect';
import { StyledFormSection } from './CreateMetricModal.styles';
import DimensionSelect from './DimensionSelect';
import {
  defaultMetricDescription,
  defaultMetricRepresentedAs,
  defaultPlainTextMetricDescription,
  defaultPlainTextRepresentedAs,
} from './getDefaultDescriptions';
import SelectedSearchItemPill from './SelectedSearchItemPill';

const metricCustomTabs: Record<string, DatasourceTabV1> = {
  database: {
    filters: [{ indexes: ['columns'] }],
    icon: 'database',
    name: 'Data',
    subtabs: [{ indexes: ['columns'], name: 'Column' }],
  },
  looker: {
    filters: [{ indexes: ['explore_fields', 'lookml_views'] }],
    icon: 'looker',
    name: 'Looker',
    subtabs: [
      { indexes: ['lookml_views'], name: 'View Field' },
      { indexes: ['explore_fields'], name: 'Explore Field' },
    ],
  },
  quicksight: {
    filters: [{ indexes: ['quicksight_bicolumns'] }],
    icon: tabConfig.quicksight.icon,
    name: tabConfig.quicksight.name,
    subtabs: [{ indexes: ['quicksight_bicolumns'], name: 'Field' }],
  },
  tableau: {
    filters: [
      {
        filters: [{ field: 'field_type', values: ['measure'] }],
        indexes: ['tableau_fields'],
      },
    ],
    icon: 'tableau',
    name: 'Tableau',
    subtabs: [{ indexes: ['tableau_fields'], name: 'Tableau Field' }],
  },
  thoughtspot: {
    filters: [{ indexes: ['thoughtspot_columns'] }],
    icon: 'thoughtspot',
    name: 'ThoughtSpot',
    subtabs: [{ indexes: ['thoughtspot_columns'], name: 'ThoughtSpot Field' }],
  },
};

export const representedAsTabs: DatasourceTabV1[] = [
  {
    filters: [
      metricCustomTabs.database.filters,
      metricCustomTabs.looker.filters,
      metricCustomTabs.tableau.filters,
      metricCustomTabs.thoughtspot.filters,
      metricCustomTabs.quicksight.filters,
    ].flat(),
    name: 'All',
  },
  metricCustomTabs.database,
  metricCustomTabs.looker,
  metricCustomTabs.tableau,
  metricCustomTabs.thoughtspot,
  metricCustomTabs.quicksight,
];

export const representedAsFilters = representedAsTabs[0].filters;

interface CreateMetricModalProps {
  metric?: MetricModel;
  onClose: () => void;
}

const CreateMetricModal: React.FC<CreateMetricModalProps> = ({ metric, onClose }) => {
  const history = useHistory();
  const patchQuery = usePatchMetricsDimensions();
  const postQuery = usePostMetrics();

  const [name, setName] = useState(metric?.name ?? 'Untitled');
  const [selectedDimensions, setSelectedDimensions] = useState<string[]>(
    metric?.formattedDimensions?.map(({ guid }) => guid) ?? [],
  );
  const [selectedMeasure, setSelectedMeasure] = useState<SearchModel | undefined>(
    metric?.formattedDimensions?.[0] as unknown as SearchModel,
  );
  const isPatch = Boolean(metric);
  const { error, isLoading, mutate } = isPatch ? patchQuery : postQuery;

  const onSave = () => {
    if (isPatch) {
      mutate(
        { field: selectedDimensions, metric: metric?.guid! },
        {
          onSuccess: () => {
            invalidateCache((keys) => [keys.metrics.all]);
            onClose();
          },
        },
      );
    } else {
      mutate(
        {
          description: defaultPlainTextMetricDescription(selectedMeasure),
          dimension_fields: selectedDimensions,
          name,
          represented_as: defaultPlainTextRepresentedAs(selectedMeasure),
          richtext_description: defaultMetricDescription(selectedMeasure),
          richtext_represented_as: defaultMetricRepresentedAs(selectedMeasure),
        },
        {
          onSuccess: (data) => {
            invalidateCache((keys) => [keys.documents.all]);
            history.push(`/metrics/${data.guid}`);
            onClose();
          },
        },
      );
    }
  };

  const onResultSelect = (result: SearchModel) => {
    setSelectedMeasure(result);
    setSelectedDimensions([]);
  };

  const onRemoveMeasure = () => {
    setSelectedMeasure(undefined);
    setSelectedDimensions([]);
  };

  const objectType = selectedMeasure?.dataTypes?.objectType;

  return (
    <Modal onClose={onClose}>
      <ModalHeader onClose={onClose} title={isPatch ? 'Edit Dimensions' : 'Create New Metric'} />
      <ModalContent maxHeight="unset" overflow="visible" py={2}>
        <Form isLoading={isLoading}>
          {!isPatch && (
            <StyledFormSection>
              <InputLabel htmlFor="name" my={1} variantColor="modalForm">
                Name
              </InputLabel>
              <Input
                error={error?.data.name}
                fluid
                id="name"
                maxLength="50"
                onChange={(e) => setName(e.target.value)}
                placeholder="Metric name..."
                value={name}
              />
            </StyledFormSection>
          )}
          <StyledFormSection>
            <InputLabel htmlFor="measure" my={1} variantColor="modalForm">
              Measure field that represents this metric:
            </InputLabel>
            {!selectedMeasure && (
              <QuickSearchInput onResultSelect={onResultSelect} tabs={representedAsTabs} />
            )}
            {selectedMeasure && (
              <SelectedSearchItemPill onRemove={onRemoveMeasure} selectedItem={selectedMeasure} />
            )}
          </StyledFormSection>
          {selectedMeasure && (
            <StyledFormSection style={{ marginBottom: 0 }}>
              <Box
                alignItems="center"
                compDisplay="flex"
                compWidth="100%"
                justifyContent="space-between"
              >
                <InputLabel htmlFor="dimension" my={1} variantColor="modalForm">
                  {objectType === 'column'
                    ? 'Search for dimension field(s) to group/pivot on:'
                    : 'Recommended dimension field(s) to group/pivot on:'}
                </InputLabel>
                {selectedDimensions.length > 0 && (
                  <Box
                    as="span"
                    color="#375fa0"
                    fontSize="0.873rem"
                  >{`${selectedDimensions.length} Selected`}</Box>
                )}
              </Box>
              {objectType === 'column' && (
                <ColumnSelect
                  initialDimensionObjects={metric?.formattedDimensions as ColumnModel[]}
                  parentGuid={selectedMeasure.parentGuid}
                  setSelectedDimensions={setSelectedDimensions}
                />
              )}
              {objectType === 'thoughtspotcolumn' && (
                <BiColumnSelect
                  dataTypes={selectedMeasure.dataTypes}
                  initialDimensions={selectedDimensions}
                  parentGuid={selectedMeasure.parentGuid}
                  setSelectedDimensions={setSelectedDimensions}
                />
              )}
              {objectType === 'bicolumn' && (
                <BiColumnSelectV1
                  initialDimensions={selectedDimensions}
                  parentGuid={selectedMeasure.parentGuid}
                  setSelectedDimensions={setSelectedDimensions}
                />
              )}
              {['explorefield', 'tableaufield'].includes(objectType!) && (
                <DimensionSelect
                  initialDimensions={selectedDimensions}
                  objectType={selectedMeasure.objectType}
                  parentGuid={selectedMeasure.parentGuid}
                  setSelectedDimensions={setSelectedDimensions}
                />
              )}
            </StyledFormSection>
          )}
          {error && (
            <Alert title="Something went wrong." type="error">
              {error?.data?.detail || error?.message}
            </Alert>
          )}
        </Form>
      </ModalContent>
      <ModalFooter>
        <Button onClick={onClose} type="button" variant="outlined">
          Cancel
        </Button>
        <Button onClick={onSave} type="button">
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateMetricModal;
