import styled from '@emotion/styled';
import { Resizable, ResizableProps } from 're-resizable';

interface StyledSidebarProps extends ResizableProps {
  height?: string;
  top?: number;
  visible?: boolean;
  width?: string;
}

export const StyledSidebar = styled(Resizable as React.ComponentClass)<StyledSidebarProps>`
  position: fixed !important;
  top: ${({ top }) => `${top}px`};
  width: ${({ visible, width = 'auto' }) => (visible ? width : 'auto')};
  height: ${({ top }) => `calc(100vh - ${top}px)`} !important;
  background-color: #f2f5f9;
  min-width: ${({ visible }) => (visible ? '18.75rem' : '2rem')};
  z-index: ${({ theme }) => theme.zIndex.sidebar};

  ${({ theme }) => theme.media.up('lg')`
    position: sticky !important;
  `};

  .left-nav-container {
    position: relative;
    left: 0;
    height: 100%;
    box-sizing: border-box;
    padding: ${({ theme }) => theme.space(2.5, 0, 2, 0)};
    min-width: ${({ visible }) => (visible ? '300px' : '32px')};
    display: flex;
    flex-flow: column;
    overflow: auto;
  }

  .left-nav-list1,
  .left-nav-list2,
  .left-nav-title1,
  .left-nav-title2,
  .left-nav-tag {
    margin: 0 1.25rem;
  }

  .left-nav-list1,
  .left-nav-list2,
  .left-nav-tag {
    margin-bottom: 1.5rem;
  }

  .left-nav-list2 .database {
    margin-bottom: -2px;
  }

  .left-nav-title1 {
    display: flex;
    align-items: center;
    font-size: 0.9375rem;
    color: #44464a;
    margin-bottom: 0;
  }

  .left-nav-title1 .icon {
    position: relative;
    top: -0.125rem;
    font-size: 1rem;
  }

  .left-nav-title2 {
    font-size: 1rem;
    font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
    color: #2c2e36;
    margin-bottom: 0.375rem;
  }

  .left-nav-button {
    position: absolute;
    top: 3rem;
    right: -6%;
  }

  .left-nav-button .icon {
    width: 1.75rem;
    height: 1.75rem;
    background: #fff;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 3px 0 #bbb;
  }
  .left-nav-button .icon:after {
    content: '';
    width: 0.375rem;
    height: 0.375rem;
    border-width: 0 0 1px 1px;
    border-style: solid;
    transform: rotate(45deg);
    border-color: #aeb4bb;
    position: relative;
    left: 0.125rem;
  }

  .left-nav-tag1 {
    font-size: 0.8125rem;
    font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
    color: #44464a;
    padding: 1px 0.375rem;
    border-radius: 4px;
    border: solid 1px #aeb4bb;
    margin-left: 0.5em;
    position: relative;
  }

  .left-nav-search-container {
    flex-direction: column;
    flex: 0 1 auto;
  }

  .left-nav-counter {
    float: right;
    font-size: 0.8125rem;
    padding-top: 5px;
    color: #6f747c;
  }
`;

export const ToggleIconContainer = styled.div<{ visible: boolean }>(({ visible }) => ({
  '&:hover': {
    '& i': {
      left: !visible ? '1.125rem' : undefined,
      transition: 'left 0.5s',
    },
  },
  width: 0,
}));

export const ToggleIcon = styled.i<{ visible: boolean }>(
  {
    alignItems: 'center',
    background: '#fff',
    borderRadius: '50%',
    boxShadow: '0 0 3px 0 #bbb',
    color: '#0c3476',
    cursor: 'pointer',
    display: 'flex',
    float: 'right',
    height: '1.75rem',
    justifyContent: 'center',
    left: '0.875rem',
    margin: 0,
    position: 'relative',
    top: '1.875rem',
    transition: 'left 0.5s',
    width: '1.75rem',
    zIndex: 1,
  },
  ({ visible }) => ({
    '&:after': {
      borderColor: '#0c3476',
      borderStyle: 'solid',
      borderWidth: visible ? '0 0 2px 2px' : '0 2px 2px 0',
      content: "''",
      height: '0.5rem',
      left: visible ? '0.125rem' : '-0.125rem',
      position: 'relative',
      transform: visible ? 'rotate(45deg)' : 'rotate(-45deg)',
      width: '0.5rem',
    },
  }),
);

interface ToggleContainerProps {
  isActive?: boolean;
  position?: 'left' | 'right';
  top?: number;
}

export const ToggleContainer = styled.div<ToggleContainerProps>`
  position: absolute;
  top: 0;
  right: 0;
  height: ${({ top }) => `calc(100vh - ${top}px)`};
  transform: rotate(${({ position = 'right' }) => (position === 'left' ? '180deg' : 0)});
  z-index: ${({ theme }) => theme.zIndex.sidebar};
`;
