import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { OrganizationTeamModel } from '@models/OrganizationTeamModel';
import { OrganizationUserRole } from '@models/OrganizationUserRole';

export type OrganizationInvitationStatus = 'pending' | 'accepted';

export class OrganizationInvitationModel {
  static objectType: string = 'organizationinvitation';

  objectType: string = OrganizationInvitationModel.objectType;

  static typeDisplay: string = 'OrganizationInvitation';

  typeDisplay: string = OrganizationInvitationModel.typeDisplay;

  guid!: string;

  @Type(() => OrganizationTeamModel)
  team?: OrganizationTeamModel;

  email?: string;

  role?: OrganizationUserRole;

  status?: OrganizationInvitationStatus;

  @Expose({ name: 'last_invitation_email_sent_on' })
  @Transform((value) => moment(value))
  lastInvitationEmailSentOn!: moment.Moment;
}
