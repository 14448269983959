import React, { useCallback, useMemo, useRef, useState } from 'react';

import Box from '@components/Box';
import Button from '@components/Button/Button';
import CircularLoader from '@components/CircularLoader';
import { Column } from '@components/Grid';
import Icon from '@components/UI/Icon';
import Modal, { ModalFooter, ModalHeader } from '@components/UI/Modal';

import { IndicatorState, NextActionFunction, WizardButtonState } from './types';
import { StyledWizard, StyledWizardStepIndicator, StyledWizardStepText } from './Wizard.styles';
import WizardContext from './WizardContext';

export interface WizardProps {
  initialStep?: number;
  onClose: () => void;
  onDone: () => void;
  steps: {
    Content: React.ElementType;
    id: string;
    title: string;
  }[];
  title: string;
}

interface ButtonsState {
  back: WizardButtonState;
  next: WizardButtonState;
}

const Wizard = ({ initialStep = 0, onClose, onDone, steps, title }: WizardProps) => {
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonsState, setButtonsState] = useState<ButtonsState>({
    back: 'normal',
    next: 'normal',
  });
  const customNextButtonAction = useRef<NextActionFunction | undefined>();
  const isLastStep = currentStep === steps.length - 1;

  const triggerCustomAction = (func: () => void) => {
    if (customNextButtonAction.current) {
      customNextButtonAction.current?.(func);
    } else {
      func();
    }
  };

  const triggerNextPage = useCallback(() => {
    customNextButtonAction.current = undefined;
    setCurrentStep((step) => step + 1);
  }, []);

  const handleNextStep = () => {
    if (isLastStep) {
      triggerCustomAction(onDone);
    } else {
      triggerCustomAction(triggerNextPage);
    }
  };

  const handlePreviousStep = () => {
    setCurrentStep((step) => (step === 0 ? step : step - 1));
    customNextButtonAction.current = undefined;
  };

  const getStepState = (id: number, step: number): IndicatorState => {
    if (id === step) return 'current';

    if (id < step) return 'passed';

    return 'incomplete';
  };

  const setNextButtonAction = useCallback((customFunction?: NextActionFunction) => {
    customNextButtonAction.current = customFunction;
  }, []);

  const setBackButtonState = useCallback((state: WizardButtonState) => {
    setButtonsState((current) => ({ ...current, back: state }));
  }, []);

  const setNextButtonState = useCallback((state: WizardButtonState) => {
    setButtonsState((current) => ({ ...current, next: state }));
  }, []);

  const setLoadingState = useCallback((state: boolean) => {
    setIsLoading(state);
  }, []);

  const CurrentContent = useMemo(() => steps[currentStep].Content, [currentStep, steps]);

  const contextValue = useMemo(
    () => ({
      goNextStep: triggerNextPage,
      setBackButtonState,
      setLoadingState,
      setNextButtonAction,
      setNextButtonState,
    }),
    [setBackButtonState, setNextButtonAction, setNextButtonState, setLoadingState, triggerNextPage],
  );

  return (
    <WizardContext.Provider value={contextValue}>
      <Modal aria-labelledby="dialog-header" m="auto" onClose={onClose} size="medium">
        <StyledWizard compDisplay="flex" compHeight="100%" maxWidth="unset" minHeight="300px">
          {isLoading && <CircularLoader borderRadius="inherit" cover />}
          <Column
            backgroundColor="rgb(242, 245, 249)"
            borderRadius="8px 0 0 8px"
            compDisplay="flex"
            flexDirection="column"
            hideDown={['md']}
            md={4}
            noDefault
          >
            <ModalHeader justifyContent="center" title={title} />
            <Box
              compDisplay="grid"
              gap={1}
              gridTemplateRows={`repeat(${steps.length}, min-content)`}
              px={3}
              py={3}
            >
              {steps.map((step, id) => {
                const stepState = getStepState(id, currentStep);
                return (
                  <Box key={step.id} alignItems="center" compDisplay="flex">
                    <StyledWizardStepIndicator
                      $state={stepState}
                      alignItems="center"
                      compDisplay="flex"
                      justifyContent="center"
                      mr={1}
                    >
                      {currentStep > id ? (
                        <Icon color="currentColor" name="check" size="14px" />
                      ) : (
                        id + 1
                      )}
                    </StyledWizardStepIndicator>
                    <StyledWizardStepText $state={stepState}>{step.title}</StyledWizardStepText>
                  </Box>
                );
              })}
            </Box>
            <ModalFooter mt="auto" />
          </Column>
          <Column compDisplay="flex" flexDirection="column" md={8} xs={12}>
            <ModalHeader onClose={onClose} />
            <Box flex={1} p={3}>
              <CurrentContent />
            </Box>
            <ModalFooter>
              {currentStep !== 0 && buttonsState.back !== 'hidden' && (
                <Button onClick={handlePreviousStep} variant="outlined">
                  Back
                </Button>
              )}
              {buttonsState.next !== 'hidden' && (
                <Button ml={1.5} onClick={handleNextStep}>
                  {isLastStep ? 'Done' : 'Next'}
                </Button>
              )}
            </ModalFooter>
          </Column>
        </StyledWizard>
      </Modal>
    </WizardContext.Provider>
  );
};

export default Wizard;
