/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import { usePatchRbacPolicy, usePostRbacPolicy } from '@api/rbacPolicy';
import { AccessToModel } from '@api/rbacPolicy/accessToModel';
import { SearchModel } from '@api/search/SearchModel';
import { TagModel } from '@api/tags/TagModel';
import { TeamModel } from '@api/teams/TeamModel';
import { UserModel } from '@api/user/UserModel';
import Alert from '@components/Alert';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import SelectedSearchItemPill from '@components/Modal/CreateMetricModal/SelectedSearchItemPill';
import {
  accessToAllDocs,
  accessToTabs,
  accessToWildcard,
  assignToFilters,
  assignToWildcard,
  exceptForFilters,
  permissionRoleOptions,
  RBACPolicyModalProps,
} from '@components/Modal/RBACPolicyModal/constants';
import QuickSearchInput from '@components/QuickSearch/QuickSearchInput';
import { DatasourceTabV1 } from '@components/SearchBar/DatasourceTabs';
import { tabConfig } from '@components/SearchBar/DatasourceTabs/config';
import { renderInfoToast } from '@components/Toast';
import Modal, { ModalContent, ModalFooter, ModalHeader } from '@components/UI/Modal';
import Select, { Option, SelectValue } from '@components/UI/Select';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import { useUserContext } from '@context/User';
import flags from '@features';
import { MetadataModel } from '@models/MetadataModel';

import {
  StyledRBACPolicyModalFieldLabel,
  StyledRBACPolicyModalForm,
  StyledRBACPolicyModalPillContainer,
} from './RBACPolicyModal.styles';

const findPolicyRoleOption = (role: string) => {
  return permissionRoleOptions.find((option) => option.value === role) as Option;
};

const getAccessToObject = (item: AccessToModel) => {
  switch (item.wildcardType) {
    case '*':
      return accessToWildcard;
    case 'all-docs':
      return accessToAllDocs;
    default:
      return item.target?.obj;
  }
};

const isSearchingForDocuments = (searchText: string) => {
  const formattedAllDocsName = accessToAllDocs.searchName.toLowerCase().replaceAll(/\s/g, '');
  const formattedSearchText = searchText.toLowerCase().replaceAll(/\s/g, '');

  return formattedAllDocsName.includes(formattedSearchText);
};

const RBACPolicyModal: React.FC<RBACPolicyModalProps> = ({ onClose, onSuccess, policy }) => {
  const { cleanPermissionsCache } = useObjectPermissionsContext();
  const { organization } = useUserContext();
  const useRbac = organization?.settings?.useRbac;
  const {
    error: errorCreate,
    isLoading: isLoadingCreate,
    mutate: create,
  } = usePostRbacPolicy({
    onSuccess: () => {
      renderInfoToast('New Policy Created');
      onSuccess?.();
      onClose?.();
      if (useRbac) {
        cleanPermissionsCache();
      }
    },
  });

  const {
    error: errorUpdate,
    isLoading: isLoadingUpdate,
    mutate: update,
  } = usePatchRbacPolicy(policy?.guid || '', {
    onSuccess: () => {
      renderInfoToast('Policy Updated');
      onSuccess?.();
      onClose?.();
      if (useRbac) {
        cleanPermissionsCache();
      }
    },
  });

  const { handleChange, handleSubmit, setValues, values } = useForm({
    initialValues: {
      accessTo: (policy?.accessTo.map(getAccessToObject) || []) as (MetadataModel | SearchModel)[],
      assignTo: (policy?.assignedTo.map((item) =>
        item.isWildcard ? assignToWildcard : item.assignee?.obj,
      ) || []) as (UserModel | TeamModel | SearchModel)[],
      exceptFor: (policy?.exceptFor.map((item) => item.exceptFor?.obj) || []) as (
        | TagModel
        | SearchModel
      )[],
      name: policy?.name || '',
      role: policy?.role ? [findPolicyRoleOption(policy.role)] : undefined,
    },
    onSubmit: (data) => {
      const dataParams = {
        access_to: data.accessTo.map((item) => item?.guid),
        assigned_to: data.assignTo.map((item) => item?.guid),
        except_for: data.exceptFor.map((item) => item?.guid),
        name: policy && data.name === policy.name ? undefined : data.name,
        role: data.role?.[0]?.value as String,
      };
      if (policy) {
        update({ ...dataParams });
      } else {
        create({ ...dataParams });
      }
    },
  });

  const error = errorCreate || errorUpdate;
  const isLoading = isLoadingCreate || isLoadingUpdate;

  const filterAssignToResults = (results: SearchModel[]) => {
    const itemGuids = values.assignTo.map((i) => i?.guid);
    return [...results, assignToWildcard].filter((item) => !itemGuids.includes(item.id));
  };

  const filterAccessToResults = (
    results: SearchModel[],
    searchText: string,
    datasourceTab: DatasourceTabV1,
  ) => {
    const itemGuids = values.accessTo.map((i) => i?.guid);

    const shouldShowAllDocs =
      isSearchingForDocuments(searchText) &&
      [tabConfig.document.name, 'All'].includes(datasourceTab?.name);
    return [...(shouldShowAllDocs ? [accessToAllDocs] : []), ...results, accessToWildcard].filter(
      (item) => !itemGuids.includes(item.id),
    );
  };

  const filterExceptForResults = (results: SearchModel[]) => {
    const itemGuids = values.exceptFor.map((i) => i?.guid);
    return results.filter((item) => !itemGuids.includes(item.id));
  };

  const handlePolicyRoleChange = (newValue: SelectValue) => {
    setValues((prevValues) => ({
      ...prevValues,
      role: newValue as Option[],
    }));
  };

  return (
    <Modal onClose={onClose}>
      <ModalHeader onClose={onClose} title={policy ? 'Edit the Policy' : 'Create Policy'} />
      <StyledRBACPolicyModalForm isLoading={isLoading} onSubmit={handleSubmit}>
        <Box compDisplay="flex" compWidth="100%" flexDirection="column" px={3} py={2.5}>
          <Box compDisplay="flex" flexDirection="column" gap={2}>
            {policy?.isDefaultPolicy && (
              <Alert type="info">
                You cannot change the name, roles and status for default policies
              </Alert>
            )}
            <Box compDisplay="flex" flexDirection="column" gap={1}>
              <StyledRBACPolicyModalFieldLabel>Policy Name</StyledRBACPolicyModalFieldLabel>
              <Input
                disabled={policy?.isDefaultPolicy}
                error={Boolean(error?.data?.name)}
                name="name"
                onChange={handleChange}
                placeholder="Policy Name"
                type="text"
                value={values.name}
              />
            </Box>
            {error?.data?.name && <Alert type="error">{error?.data?.name}</Alert>}
            <Box compDisplay="flex" compWidth="100%" flexDirection="column" gap={1}>
              <StyledRBACPolicyModalFieldLabel>Applies to</StyledRBACPolicyModalFieldLabel>
              <QuickSearchInput
                filters={assignToFilters}
                onBeforeResult={filterAssignToResults}
                onResultSelect={(item: SearchModel) =>
                  setValues((prev) => ({ ...prev, assignTo: [...prev?.assignTo, item] }))
                }
                placeholder="Jane Doe, Sales, Engineering"
              />
              {error?.data?.assigned_to && <Alert type="error">{error?.data?.assigned_to}</Alert>}
              {values.assignTo?.length > 0 && (
                <StyledRBACPolicyModalPillContainer>
                  {values.assignTo?.map(
                    (item) =>
                      item && (
                        <Box key={item.guid}>
                          <SelectedSearchItemPill
                            onRemove={() =>
                              setValues((prev) => ({
                                ...prev,
                                assignTo: prev?.assignTo?.filter((i) => i !== item),
                              }))
                            }
                            selectedItem={item}
                          />
                        </Box>
                      ),
                  )}
                </StyledRBACPolicyModalPillContainer>
              )}
            </Box>
            <Box compDisplay="flex" flexDirection="column" gap={1}>
              <StyledRBACPolicyModalFieldLabel>Permission Role</StyledRBACPolicyModalFieldLabel>
              <Select
                compWidth="100%"
                error={Boolean(error?.data?.role)}
                isDisabled={policy?.isDefaultPolicy}
                onChange={handlePolicyRoleChange}
                options={permissionRoleOptions}
                value={values.role}
              />
            </Box>
            {error?.data?.role && <Alert type="error">{error?.data?.role}</Alert>}
            <Box compDisplay="flex" compWidth="100%" flexDirection="column" gap={1}>
              <StyledRBACPolicyModalFieldLabel>Access to</StyledRBACPolicyModalFieldLabel>
              <QuickSearchInput
                onBeforeResult={filterAccessToResults}
                onResultSelect={(item: SearchModel) =>
                  setValues((prev) => ({ ...prev, accessTo: [...prev?.accessTo, item] }))
                }
                tabs={accessToTabs}
              />
              {error?.data?.access_to && <Alert type="error">{error?.data?.access_to}</Alert>}
              {values.accessTo?.length > 0 && (
                <StyledRBACPolicyModalPillContainer>
                  {values.accessTo?.map((item) => (
                    <Box key={item.guid}>
                      <SelectedSearchItemPill
                        onRemove={() =>
                          setValues((prev) => ({
                            ...prev,
                            accessTo: prev?.accessTo?.filter((i) => i !== item),
                          }))
                        }
                        selectedItem={item}
                        useDefaultName
                      />
                    </Box>
                  ))}
                </StyledRBACPolicyModalPillContainer>
              )}
            </Box>
            {flags.show_pbac_except_for_tags && (
              <Box compDisplay="flex" compWidth="100%" flexDirection="column" gap={1}>
                <StyledRBACPolicyModalFieldLabel>Except for</StyledRBACPolicyModalFieldLabel>
                <QuickSearchInput
                  filters={exceptForFilters}
                  noExternalTags
                  onBeforeResult={filterExceptForResults}
                  onResultSelect={(item: SearchModel) =>
                    setValues((prev) => ({ ...prev, exceptFor: [...prev?.exceptFor, item] }))
                  }
                />
                {error?.data?.except_for && <Alert type="error">{error?.data?.except_for}</Alert>}
                {values.exceptFor?.map((item) => (
                  <Box key={item.guid} my={1}>
                    <SelectedSearchItemPill
                      onRemove={() =>
                        setValues((prev) => ({
                          ...prev,
                          exceptFor: prev?.exceptFor?.filter((i) => i !== item),
                        }))
                      }
                      selectedItem={item}
                      useDefaultName
                    />
                  </Box>
                ))}
              </Box>
            )}
          </Box>
          {error && error.status === 500 && (
            <Alert title="Something went wrong." type="error">
              Server couldn't process the request (${error.data})
            </Alert>
          )}
        </Box>
        <ModalFooter>
          <Button disabled={isLoading} onClick={onClose} type="button" variant="outlined">
            Cancel
          </Button>
          <Button disabled={isLoading} type="submit">
            Save
          </Button>
        </ModalFooter>
      </StyledRBACPolicyModalForm>
    </Modal>
  );
};

export default RBACPolicyModal;
