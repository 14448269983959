import React from 'react';
import { dataSources } from '@configs/dataSources/config';
import iconsConfigMap, { IconDataConfig } from '@configs/icons/config';

import Box from '@components/Box';
import Highlighter from '@components/Highlighter';
import { TypedCell } from '@components/Table/Cells/types';
import Text from '@components/Text';
import Icon from '@components/UI/Icon';
import DataTypesModel from '@models/DataTypesModel';

interface DataSourceTypeCellProps {
  dataSourceType?: DataTypesModel['dataSourceType'];
}

const DataSourceTypeCell: React.FC<Partial<TypedCell> & DataSourceTypeCellProps> = ({
  dataSourceType = '',
  globalFilter,
}) => {
  return (
    <Box alignItems="center" compDisplay="flex" gap={0.5}>
      <Icon
        name={iconsConfigMap[dataSourceType as keyof IconDataConfig]?.default ?? 'question'}
        size="16px"
      />
      <Text fontSize="body1">
        <Highlighter
          autoEscape
          searchWords={globalFilter?.split(/[.| ]+/) ?? []}
          textToHighlight={dataSources[dataSourceType as keyof typeof dataSources]?.text}
        />
      </Text>
    </Box>
  );
};

export default DataSourceTypeCell;
