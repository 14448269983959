import React from 'react';

import Box from '@components/Box';
import Highlighter from '@components/Highlighter';
import { TypedCell } from '@components/Table/Cells/types';
import Text from '@components/Text';

interface LineageDistanceCellProps {
  distance: number;
}

const LineageDistanceCell: React.FC<Partial<TypedCell> & LineageDistanceCellProps> = ({
  column,
  distance = '',
  globalFilter,
}) => {
  return (
    <Box compWidth="100%" textAlign="end">
      <Highlighter
        autoEscape
        searchWords={[globalFilter ?? column?.filterValue]}
        textToHighlight={String(distance)}
      >
        <Text textAlign="end">{distance}</Text>
      </Highlighter>
    </Box>
  );
};

export default LineageDistanceCell;
