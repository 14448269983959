import { dataSources } from '@configs/dataSources/config';

import RelatedObjectsCountsModel from '@models/RelatedObjectsCountsModel';

export type DataTypeLabelKeys = keyof RelatedObjectsCountsModel;

const dataTypeLabels: Record<DataTypeLabelKeys, string> = {
  bicolumn: 'BI columns',
  bidashboardelement: 'BI dashboard elements',
  bidataset: 'BI datasets',
  bitable: 'BI tables',
  chart: 'Charts',
  cloudobject: 'cloudobject',
  column: 'Columns',
  dashboard: 'Dashboards',
  dashboardelement: 'Dashboard elements',
  datastudiochart: `${dataSources.data_studio.text} charts`,
  datastudiopage: `${dataSources.data_studio.text} pages`,
  explore: 'Explores',
  explorefield: 'Explore fields',
  lookmlfield: 'LookML fields',
  lookmlview: 'LookML views',
  metabasecolumn: `${dataSources.metabase.text} columns`,
  metabasetable: `${dataSources.metabase.text} tables`,
  notebook: 'Notebooks',
  page: 'Pages',
  powerbicolumn: `${dataSources.power_bi.text} columns`,
  powerbidataset: `${dataSources.power_bi.text} datasets`,
  powerbitable: `${dataSources.power_bi.text} tables`,
  reportquery: 'Report queries',
  sourceTable: 'Source tables',
  table: 'Tables',
  tableaucolumn: `${dataSources.tableau.text} columns`,
  tableaudatasource: `${dataSources.tableau.text} data sources`,
  tableaufield: `${dataSources.tableau.text} fields`,
  tableautable: `${dataSources.tableau.text} tables`,
  tableauview: `${dataSources.tableau.text} views`,
  thoughtspotcolumn: `${dataSources.thoughtspot.text} columns`,
  thoughtspottable: `${dataSources.thoughtspot.text} tables`,
  total: 'Total',
  totalAllDepths: 'Total',
};

export default dataTypeLabels;
