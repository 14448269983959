import React from 'react';

import { useIngestDataSource } from '@api/ingestion';
import Alert from '@components/Alert';
import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import { renderInfoToast } from '@components/Toast';
import Modal, { ModalContent, ModalHeader } from '@components/UI/Modal';
import { DataSourceModel } from '@models/DataSourceModel';
import theme from '@styles/theme';

import ErrorDescriptionMarkdown from './components/ErrorDescriptionMarkdown';

export interface DataSourceIngestModalProps {
  dataSource: DataSourceModel;
  onClose?: () => void;
}

const DataSourceIngestModal: React.FC<DataSourceIngestModalProps> = ({ dataSource, onClose }) => {
  const { error, isFetching } = useIngestDataSource(dataSource.guid, {
    keepPreviousData: false,
    onSuccess: () => {
      renderInfoToast('Data Source sync finished.');
      onClose?.();
    },
    staleTime: 0,
  });

  const isError = Boolean(!isFetching && error);

  return (
    <Modal onClose={onClose} size="medium">
      <ModalHeader onClose={onClose} title={`Update ${dataSource.name}`} />
      <ModalContent minHeight="unset" py={2.5}>
        {isFetching && (
          <Box
            alignItems="center"
            compDisplay="flex"
            compWidth="100%"
            flexDirection="column"
            fontSize="body2"
            gap={2}
            justifyContent="center"
            minHeight={theme.space(20)}
          >
            <CircularLoader />
            Sync in progress - you can close this window.
          </Box>
        )}
        {isError && (
          <Alert title={error?.statusText || 'Something went wrong'} type="error">
            Server couldn't process the data.
          </Alert>
        )}
        {isError && <ErrorDescriptionMarkdown error={error} />}
      </ModalContent>
    </Modal>
  );
};

export default DataSourceIngestModal;
