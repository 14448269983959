import React, { useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { CommentModel } from '@api/comments/CommentModel';
import { usePostQuickSearch } from '@api/search';
import Alert from '@components/Alert';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import { CommentPayload } from '@components/Discussion/CommentTextBox/CommentTextBox.types';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import RichTextDescriptionEditor from '@components/RichTextDescriptionEditor';
import InputLabel from '@components/UI/Form/InputLabel/InputLabel';
import Modal, { ModalContent, ModalFooter, ModalHeader } from '@components/UI/Modal';
import type { Option, SelectValue } from '@components/UI/Select';
import Select from '@components/UI/Select';
import { removeDuplicatedOptions } from '@components/UI/Select/Select.utils';
import { useModal } from '@context/Modal';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import theme from '@styles/theme';

import { RECIPIENTS_SEARCH_FILTERS } from './constants';
import mapRecipientsToOptions from './mapRecipientsToOptions';
import mapSearchToRecipients from './mapSearchToRecipients';
import { NotificationForm } from './types';
import useUserLists from './useUserLists';
import { validatePayload } from './utils';

export interface NotifyUsersModalProps {
  comment?: CommentModel;
  isSaving?: boolean;
  onClose: () => void;
  onSaveComment: (payload: CommentPayload) => void;
  onUpdateComment: (payload: CommentPayload) => void;
  parentGuid: string;
}

const NotifyUsersModal = ({
  comment,
  isSaving = false,
  onClose,
  onSaveComment,
  onUpdateComment,
  parentGuid,
}: NotifyUsersModalProps) => {
  const { MODAL_IDS, closeModal, openModal } = useModal();
  const [searchRecipients, setSearchRecipients] = useState<Option[]>([]);
  const [formError, setFormError] = useState('');
  const isEditing = comment?.guid;
  const segment = useSegmentContext();

  const currentCommentRecipients = useMemo(() => {
    return comment?.commentRecipients ? mapRecipientsToOptions(comment.commentRecipients) : [];
  }, [comment?.commentRecipients]);

  const { isLoading: isSearchingOptions, mutate: search } = usePostQuickSearch({
    onSuccess: (result) => {
      const mappedRecipients = mapSearchToRecipients(result.data, searchRecipients);
      setSearchRecipients((prevValues) => [...prevValues, ...mappedRecipients]);
    },
  });

  const { isLoading: isLoadingUserLists, userLists } = useUserLists(parentGuid);

  const recipients = removeDuplicatedOptions([
    ...currentCommentRecipients,
    ...userLists,
    ...searchRecipients,
  ]);

  const debouncedSearch = useDebouncedCallback(
    (term: string) =>
      search({
        all_buckets_search: true,
        index_filters: RECIPIENTS_SEARCH_FILTERS.all,
        no_external_tags: false,
        query: term,
      }),
    500,
  );

  const { handleSubmit, setValues, values } = useForm<NotificationForm>({
    initialValues: {
      message: comment?.message ?? '',
      recipients: isEditing ? currentCommentRecipients : [],
      richTextMessage: comment?.richtextMessage ?? '',
      search: '',
    },
    onSubmit: (payload) => {
      const { errorMessage, isValid } = validatePayload(payload);
      if (isValid) {
        setFormError('');
        const { message, recipients: selectedRecipients, richTextMessage } = payload;

        const mutationMethod = isEditing ? onUpdateComment : onSaveComment;

        mutationMethod({
          comment_recipients: selectedRecipients.map((recipient) => String(recipient.value)),
          message,
          richtext_message: richTextMessage,
          target: parentGuid,
        });
      } else {
        setFormError(errorMessage as string);
      }
    },
  });

  const handleMessageChange = (richTextMessage: string, message?: string) => {
    setValues((prevValues) => ({
      ...prevValues,
      message: message ?? '',
      richTextMessage: richTextMessage ?? '',
    }));
  };

  const handleSearchChange = (value = '') => {
    setValues((prevValues) => ({
      ...prevValues,
      search: value,
    }));

    if (value) {
      debouncedSearch(value);
    }
  };

  const trackSelectedValue = (value: SelectValue) => {
    if (value && value?.length > values.recipients.length) {
      const [last] = (value as Option[]).slice(-1);
      if (last?.props?.rank !== undefined) {
        segment?.track(SegmentTrackEventName.QuickSearchResultClicked, {
          guid: last.value,
          rank: last.props?.rank,
        });
      }
    }
  };

  const handleRecipientsChange = (value: SelectValue) => {
    trackSelectedValue(value);

    setValues((prevValues) => ({
      ...prevValues,
      recipients: value as Option[],
    }));
  };

  const handleClose = () => {
    onClose();
    closeModal(MODAL_IDS.notify);
  };

  useEffect(() => {
    openModal(MODAL_IDS.notify);
  }, [MODAL_IDS, closeModal, openModal]);

  return (
    <Modal
      aria-labelledby="dialog-header"
      closeOnDimmerClick={false}
      m="auto"
      onClose={handleClose}
    >
      <ModalHeader onClose={handleClose} title="Send Notification" />
      <Form isLoading={isSaving} onSubmit={handleSubmit}>
        <ModalContent maxHeight={theme.space(80)} py={2}>
          {formError && (
            <Box compWidth="100%" mb={2.25}>
              <Alert type="warning">{formError}</Alert>
            </Box>
          )}
          <Box compDisplay="flex" flexDirection="column" gap={2.25}>
            <Box compDisplay="flex" flexDirection="column" gap={1}>
              <InputLabel
                as="div"
                color="gray.700"
                compDisplay="grid"
                cursor="default"
                fontWeight="medium"
              >
                Recipients
              </InputLabel>
              <Select
                isLoading={isSearchingOptions || isLoadingUserLists}
                isMulti
                onChange={handleRecipientsChange}
                onSearchValueChange={handleSearchChange}
                options={recipients}
                placeholder="Search for a user or team"
                searchValue={values.search}
                shouldBlockOnLoading={false}
                value={values.recipients}
              />
            </Box>
            <Box compDisplay="flex" flexDirection="column" gap={1}>
              <InputLabel
                as="div"
                color="gray.700"
                compDisplay="grid"
                cursor="default"
                fontWeight="medium"
              >
                Message
              </InputLabel>
              <RichTextDescriptionEditor
                allowedElements={['bold', 'italic', 'underline', 'mention']}
                descriptions={{
                  description: values.message,
                  richTextDescription: values.richTextMessage,
                }}
                disableSaveOnEnter
                editIconVariant="always"
                editMode
                fontSize="body2"
                isEditable
                markdownEnabled={false}
                maxHeight={theme.space(50)}
                onDescriptionChange={handleMessageChange}
                shouldFocusOnEdit={false}
                shouldStartFocused={false}
              />
            </Box>
          </Box>
        </ModalContent>
        <ModalFooter>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button type="submit">Send</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default NotifyUsersModal;
