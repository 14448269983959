import React from 'react';
import { dataSources } from '@configs/dataSources/config';

import {
  StyledDataSourceSetupContent,
  StyledFormHorizontalLabelGrid,
  StyledLabel,
} from '@components/DataSourceSetup/DataSourceSetup.styles';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';

import useDataSourceMutation from '../useDataSourceMutation';

import { DataSourceFormProps } from './types';

enum FieldKey {
  database = 'database',
  host = 'host',
  name = 'name',
  password = 'password',
  port = 'port',
  user = 'user',
}

interface FormValues {
  [FieldKey.database]?: string;
  [FieldKey.host]?: string;
  [FieldKey.name]?: string;
  [FieldKey.password]?: string;
  [FieldKey.port]?: string;
  [FieldKey.user]?: string;
}

const REQUIRED_FIELDS = [
  FieldKey.database,
  FieldKey.host,
  FieldKey.password,
  FieldKey.port,
  FieldKey.user,
];

const MssqlForm: React.FC<DataSourceFormProps> = ({
  children,
  dataSource,
  name: dsName,
  onSuccess,
  renderBefore,
}) => {
  const segment = useSegmentContext();
  const { error, isLoading, mutate } = useDataSourceMutation({ dataSource, onSuccess });
  const { handleChange, handleSubmit, values } = useForm<FormValues>({
    initialValues: {
      database: '',
      host: '',
      name: dataSource?.name ?? dsName,
      password: '',
      port: '',
      user: '',
    },
    onSubmit: ({ name, ...credentials }) => {
      mutate({
        credentials,
        name,
        type: dataSources.mssql.value,
      } as any);
      segment?.track(SegmentTrackEventName.CreateServiceAccountConnectButtonClicked, {
        dataType: dataSources.mssql.value,
      });
    },
    syncInitialValues: true,
  });

  const INPUT_CONFIG = {
    [FieldKey.name]: {
      Component: Input,
      key: FieldKey.name,
      label: 'Display Name',
      maxLength: 50,
      onChange: handleChange,
      placeholder: dsName,
    },
    [FieldKey.host]: {
      Component: Input,
      key: FieldKey.host,
      label: 'Hostname',
      onChange: handleChange,
    },
    [FieldKey.port]: {
      Component: Input,
      key: FieldKey.port,
      label: 'Port',
      onChange: handleChange,
    },
    [FieldKey.user]: {
      Component: Input,
      key: FieldKey.user,
      label: 'Username',
      onChange: handleChange,
    },
    [FieldKey.password]: {
      Component: Input,
      key: FieldKey.password,
      label: 'Password',
      onChange: handleChange,
      type: 'password',
    },
    [FieldKey.database]: {
      Component: Input,
      key: FieldKey.database,
      label: 'Database',
      onChange: handleChange,
    },
  };

  const isInvalid = REQUIRED_FIELDS.some((key) => Boolean(values[key]) === false);

  return (
    <StyledDataSourceSetupContent borderRadius="inherit">
      <Form isLoading={isLoading} onSubmit={handleSubmit}>
        <StyledFormHorizontalLabelGrid>
          {renderBefore?.({ error, loading: isLoading })}
          {Object.values(INPUT_CONFIG).map(({ Component, key, label, ...other }) => (
            <StyledLabel key={key}>
              {label}
              <Component
                error={error?.data?.[key]}
                helperText={error?.data?.[key]}
                name={key}
                placeholder={label}
                value={values[key]}
                {...other}
              />
            </StyledLabel>
          ))}
        </StyledFormHorizontalLabelGrid>
        {children?.({ error, loading: isLoading || isInvalid })}
      </Form>
    </StyledDataSourceSetupContent>
  );
};

export default MssqlForm;
