import type { DbtProjectModelV1 } from '@api/dbt/DbtProjectModel.v1';
import type { DeleteChatBotHierarchyItemModalProps } from '@components/AppMainSidebar/Hierarchy/ChatbotHierarchy/DeleteChatBotHierarchyItemModal';
import type { CalendarModalProps } from '@components/DateRangePicker/CalendarModal';
import type { MentionedByModalProps } from '@components/MentionedBy/MentionedByModal';
import type { CreateTagModalProps } from '@components/Modal/CreateTagModal';
import type { DataSourceConnectionStatusModalProps } from '@components/Modal/DataSourceConnectionStatusModal';
import type { DataSourceIngestionLogModalProps } from '@components/Modal/DataSourceIngestionLogModal';
import type { DeleteCommentModalProps } from '@components/Modal/DeleteCommentModal';
import type { DeleteConfirmationModalProps } from '@components/Modal/DeleteConfirmationModal';
import type { DeleteDataSourceConfirmationModalProps } from '@components/Modal/DeleteDataSourceConfirmationModal';
import type { DeletePolicyConfirmationModalProps } from '@components/Modal/DeletePolicyConfirmationModal';
import type { DeleteTagModalProps } from '@components/Modal/DeleteTagModal';
import type { EditPkFkModalProps } from '@components/Modal/EditPkFkModal';
import type { InviteUserModalProps } from '@components/Modal/InviteUserModal';
import type { QueryModalProps } from '@components/Modal/QueryModal';
import type { RBACPolicyModalProps } from '@components/Modal/RBACPolicyModal/constants';
import type { RevokeInvitationModalProps } from '@components/Modal/RevokeInvitationModal';
import type { TeamOwnershipMigrationModalProps } from '@components/Modal/TeamOwnershipMigrationModal';
import type { UserOwnershipMigrationModalProps } from '@components/Modal/UserOwnershipMigrationModal';
import type { GithubConfigurationWizardProps } from '@components/Wizard/GithubConfigurationWizard';
import { Subscription } from '@pages/Admin/AdminBillingPage/AdminBillingPage.types';
import type { DeleteRepositoryConfirmationModalProps } from '@pages/Admin/AdminGithubPage/DeleteRepositoryConfirmationModal';

import { TagModel } from './api/tags/TagModel';

export const DEFAULT_DOCS_URL = 'https://docs.selectstar.com';
export const INTERCOM_APP_ID = 'pkh9vlcx';
export const LOADING_TEXT = 'Loading...';
export const SERVER_PROCESSING_ERROR = 'The server was unable to process the request.';
export const UNKNOWN_SERVER_ERROR = 'Unknown Server Error.';
export const SMTH_WENT_WRONG = 'Something went wrong.';
export const NO_DATA_TEXT = 'No data';
export const DBT_DOCS_SYNC_DOCS_URL = `${DEFAULT_DOCS_URL}/integrations/dbt/dbt-docs-sync/github-dbt-docs-sync`;
export const MONTE_CARLO_DOCS_URL = `${DEFAULT_DOCS_URL}/integrations/monte-carlo`;
export const UNTITLED_GUID = 'untitled';
export const UNTITLED_TITLE = 'Untitled';

export const STRIPE_TEST_SUBSCRIPTION_OPTIONS: Subscription[] = [
  {
    dataSources: [1, 'Data source'],
    features: [
      'Automated Metadata Catalog',
      'Automated Popularity Ranking',
      'Tags & Ownership Assigment',
      'Discussion & Comments',
      'Docs & Metrics',
      'User & Team Analytics',
    ],
    featuresTagline: 'Data Discovery Essentials:',
    name: 'Pro',
    price: '$300 /mo',
    priceId: 'price_1JwGJiDZo0jgZw71h8ZxKvOz',
    tables: '250',
    users: '10',
    yearlyPrice: '$3,240 /yr',
    yearlyPriceId: 'price_1KHgk4DZo0jgZw71CJFILSny',
  },
  {
    dataSources: [2, 'Data sources'],
    features: ['Slack Integration', 'Chrome Extension', 'dbt Doc Sync', 'AI Documentation'],
    featuresTagline: 'Everything in Pro, plus:',
    name: 'Growth',
    price: '$900 /mo',
    priceId: 'price_1K4EyIDZo0jgZw7155Y8Hqmv',
    tables: '1,000',
    users: '25',
    yearlyPrice: '$9,720 /yr',
    yearlyPriceId: 'price_1KHgp7DZo0jgZw717z0vhRVH',
  },
  {
    buttonText: 'Contact Us',
    dataSources: ['Unlimited', 'Data sources'],
    features: [
      'SSO',
      'API Access',
      'Metadata PII Detection',
      'Fine-grained Access Control',
      'Snowflake Cost Analysis',
      'Enterprise Support & User Onboarding',
    ],
    featuresTagline: 'Everything in Growth, plus:',
    name: 'Enterprise',
    price: 'Custom Pricing',
    tables: '4,000+',
    users: '100+',
    yearlyPrice: 'Custom Pricing',
  },
];

export const STRIPE_SUBSCRIPTION_OPTIONS: Subscription[] = [
  {
    ...STRIPE_TEST_SUBSCRIPTION_OPTIONS[0],
    priceId: 'price_1JebyzDZo0jgZw71Jb6emIIR',
    yearlyPriceId: 'price_1KKURYDZo0jgZw71PVmVgUSu',
  },
  {
    ...STRIPE_TEST_SUBSCRIPTION_OPTIONS[1],
    priceId: 'price_1JepYxDZo0jgZw71KyWOZxxt',
    yearlyPriceId: 'price_1KKUSGDZo0jgZw71fjm3iGtR',
  },
  {
    ...STRIPE_TEST_SUBSCRIPTION_OPTIONS[2],
  },
];

export const DEFAULT_BILLING_USAGE_TOTALS = {
  datasources: 1,
  tables: 250,
  users: 10,
};

export const MODAL_IDS = {
  addCustomAttributeModal: 'add-custom-attribute-modal',
  addLinkRichTextEditorControls: 'add-link-rich-text-editor-controls',
  addLinkRichTextEditorMiniToolbar: 'add-link-rich-text-editor-mini-toolbar',
  addPolicy: 'add-policy',
  aiBulkAssistDelete: 'ai-bulk-assist-delete',
  calendar: 'calendar',
  changePassword: 'change-password',
  changeProfilePicture: 'change-profile-picture',
  clientApi: 'client-api',
  columnPage: 'column-page',
  confirmLoadFilterUsage: 'confirm-load-filter-usage',
  connectGithub: 'connect-github',
  connectMonteCarlo: 'connect-monte-carlo',
  connectionStatus: 'connection-status',
  createAnotherTag: 'create-another-tag',
  createDatasource: 'create-datasource',
  createDbtProject: 'create-dbt-project',
  createGithubRepository: 'create-github-repository',
  createPII: 'create-pii',
  createSSO: 'create-sso',
  createTag: 'create-tag',
  createTeam: 'create-team',
  createTerm: 'create-term',
  deleteChatbotConversation: 'delete-chatbot-conversation',
  deleteChatbotConversations: 'delete-chatbot-conversations',
  deleteComment: 'delete-comment',
  deleteConfirmation: 'delete-confirmation',
  deleteCustomAttributeModal: 'delete-custom-attribute-modal',
  deleteDataSource: 'delete-data-source',
  deleteDocumentsBaseDocsTab: 'delete-documents-base-docs-tab',
  deleteDocumentsMetadataObjectTemplate: 'delete-documents-metadata-object-template',
  deleteGithubRepository: 'delete-github-repository',
  deleteMetrics: 'delete-metrics',
  deletePII: 'delete-pii',
  deletePolicy: 'delete-policy',
  deleteTag: 'delete-tag',
  deleteTerm: 'delete-term',
  disconnectMontecarlo: 'disconnect-montecarlo',
  disconnectSlack: 'disconnect-slack',
  editOwnersBulk: 'edit-owners-bulk',
  editPkFk: 'edit-pk-fk',
  explore: 'explore',
  exploreErd: 'explore-erd',
  exploreLegacy: 'explore-legacy',
  glossary: 'glossary',
  hideItems: 'hide-items',
  ingestDataSource: 'ingest-data-source',
  ingestionLog: 'ingestion-log',
  inviteUserAdminUsersPage: 'invite-user-admin-users-page',
  inviteUserTeamsMenu: 'invite-user-teams-menu',
  leavePageConfirmationEditorNavigationBlocker: 'leave-page-confirmation-editor-navigation-blocker',
  leavePageConfirmationNavigationBlocker: 'leave-page-confirmation-navigation-blocker',
  matchPII: 'match-pii',
  mentionedBy: 'mentioned-by',
  metric: 'metric',
  metricDimensionsTab: 'metric-dimensions-tab',
  notify: 'notify',
  pageHistory: 'page-history',
  query: 'query',
  queryErdColumnJoinQueryModal: 'query-erd-column-join-query-modal',
  removeModeServiceAccountModal: 'remove-mode-service-account-modal',
  revokeInvitation: 'revoke-invitation',
  snowflakeTagSync: 'snowflake-tag-sync',
  teamOwnershipMigration: 'team-ownership-migration',
  uploadCSV: 'upload-csv',
  uploadGlossaryCSV: 'upload-glossary-csv',
  uploadTooLarge: 'upload-too-large',
  userOwnershipMigration: 'user-ownership-migration',
} as const;

export type ModalContent = {
  [MODAL_IDS.createAnotherTag]: TagModel;
  [MODAL_IDS.addCustomAttributeModal]: null;
  [MODAL_IDS.addLinkRichTextEditorControls]: null;
  [MODAL_IDS.addLinkRichTextEditorMiniToolbar]: null;
  [MODAL_IDS.addPolicy]: RBACPolicyModalProps;
  [MODAL_IDS.aiBulkAssistDelete]: null;
  [MODAL_IDS.calendar]: CalendarModalProps;
  [MODAL_IDS.changePassword]: null;
  [MODAL_IDS.changeProfilePicture]: null;
  [MODAL_IDS.clientApi]: null;
  [MODAL_IDS.confirmLoadFilterUsage]: null;
  [MODAL_IDS.columnPage]: null;
  [MODAL_IDS.connectGithub]: null;
  [MODAL_IDS.connectMonteCarlo]: null;
  [MODAL_IDS.connectMonteCarlo]: null;
  [MODAL_IDS.connectionStatus]: DataSourceConnectionStatusModalProps;
  [MODAL_IDS.createDatasource]: null;
  [MODAL_IDS.createDbtProject]: DbtProjectModelV1;
  [MODAL_IDS.createGithubRepository]: GithubConfigurationWizardProps;
  [MODAL_IDS.createPII]: null;
  [MODAL_IDS.createSSO]: null;
  [MODAL_IDS.createTag]: CreateTagModalProps;
  [MODAL_IDS.createTeam]: null;
  [MODAL_IDS.createTerm]: null;
  [MODAL_IDS.deleteChatbotConversation]: DeleteChatBotHierarchyItemModalProps;
  [MODAL_IDS.deleteChatbotConversations]: null;
  [MODAL_IDS.deleteComment]: DeleteCommentModalProps;
  [MODAL_IDS.deleteConfirmation]: DeleteConfirmationModalProps;
  [MODAL_IDS.deleteCustomAttributeModal]: null;
  [MODAL_IDS.deleteDataSource]: DeleteDataSourceConfirmationModalProps;
  [MODAL_IDS.deleteDocumentsBaseDocsTab]: null;
  [MODAL_IDS.deleteDocumentsMetadataObjectTemplate]: null;
  [MODAL_IDS.deleteGithubRepository]: DeleteRepositoryConfirmationModalProps;
  [MODAL_IDS.deleteMetrics]: null;
  [MODAL_IDS.deletePII]: null;
  [MODAL_IDS.deletePolicy]: DeletePolicyConfirmationModalProps;
  [MODAL_IDS.deleteTag]: DeleteTagModalProps;
  [MODAL_IDS.deleteTerm]: null;
  [MODAL_IDS.disconnectMontecarlo]: null;
  [MODAL_IDS.disconnectSlack]: null;
  [MODAL_IDS.editOwnersBulk]: null;
  [MODAL_IDS.editPkFk]: EditPkFkModalProps;
  [MODAL_IDS.explore]: null;
  [MODAL_IDS.exploreErd]: null;
  [MODAL_IDS.exploreLegacy]: null;
  [MODAL_IDS.glossary]: null;
  [MODAL_IDS.hideItems]: null;
  [MODAL_IDS.ingestDataSource]: null;
  [MODAL_IDS.ingestionLog]: DataSourceIngestionLogModalProps;
  [MODAL_IDS.inviteUserAdminUsersPage]: InviteUserModalProps;
  [MODAL_IDS.inviteUserTeamsMenu]: InviteUserModalProps;
  [MODAL_IDS.leavePageConfirmationEditorNavigationBlocker]: null;
  [MODAL_IDS.leavePageConfirmationNavigationBlocker]: null;
  [MODAL_IDS.matchPII]: null;
  [MODAL_IDS.mentionedBy]: MentionedByModalProps;
  [MODAL_IDS.metric]: null;
  [MODAL_IDS.metricDimensionsTab]: null;
  [MODAL_IDS.notify]: null;
  [MODAL_IDS.pageHistory]: null;
  [MODAL_IDS.query]: QueryModalProps;
  [MODAL_IDS.queryErdColumnJoinQueryModal]: QueryModalProps;
  [MODAL_IDS.removeModeServiceAccountModal]: null;
  [MODAL_IDS.revokeInvitation]: RevokeInvitationModalProps;
  [MODAL_IDS.snowflakeTagSync]: null;
  [MODAL_IDS.teamOwnershipMigration]: TeamOwnershipMigrationModalProps;
  [MODAL_IDS.uploadCSV]: null;
  [MODAL_IDS.uploadGlossaryCSV]: null;
  [MODAL_IDS.uploadTooLarge]: null;
  [MODAL_IDS.userOwnershipMigration]: UserOwnershipMigrationModalProps;
};

export type ModalId = (typeof MODAL_IDS)[keyof typeof MODAL_IDS];
