import React, { useMemo } from 'react';

import { useFetchCostAnalysisWarehouses } from '@api/costAnalysis';
import { useDateRangePickerContext } from '@components/DateRangePicker/DateRangePicker.context';
import { useMultiSelectContext } from '@components/UI/Select/MultiSelect.context';

import WarehouseSelectLabel from './WarehouseSelectLabel';

const formatWarehouseOptionText = (name: string, size?: string) => {
  const formattedSize = size ? ` (${size})` : '';

  return `${name}${formattedSize}`;
};

const useWarehouseSelect = () => {
  const { dateRange } = useDateRangePickerContext();
  const { selectedItems } = useMultiSelectContext();
  const { data, isLoading } = useFetchCostAnalysisWarehouses({
    keepPreviousData: true,
    params: {
      end_date: dateRange[1],
      order: 'name',
      page_size: 9999,
      query: '{name,guid,size}',
      start_date: dateRange[0],
    },
  });

  const warehouseOptions = useMemo(
    () =>
      data?.results.map(({ guid, name, size }) => ({
        breakText: true,
        customLabel: <WarehouseSelectLabel name={name} size={size} />,
        text: formatWarehouseOptionText(name, size),
        value: guid,
      })),
    [data?.results],
  );

  const selectedWarehouses = useMemo(
    () => selectedItems.map(({ value }) => value).join(','),
    [selectedItems],
  );

  const warehouseFilterPlaceholder =
    warehouseOptions && warehouseOptions.length > 0
      ? `Select Warehouse (${warehouseOptions.length})`
      : 'Select Warehouse';

  const value = {
    isLoading,
    selectedItems,
    selectedWarehouses,
    warehouseFilterPlaceholder,
    warehouseOptions,
  };

  return value;
};

export default useWarehouseSelect;
