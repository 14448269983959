import React from 'react';
import { DEFAULT_DOCS_URL } from '@constants';
import * as CSS from 'csstype';

import Link from '@components/Link';
import Text from '@components/Text';
import { StyledTextProps } from '@components/Text/Text';
import Image from '@components/UI/Image';
import Modal from '@components/UI/Modal';

const textStyles: StyledTextProps = {
  fontSize: '18px',
  lineHeight: '1.5',
  textAlign: 'center',
};

interface OnboardingFinishedProps {
  compHeight?: CSS.Property.Height;
}

const OnboardingFinished: React.FC<OnboardingFinishedProps> = ({ compHeight }) => {
  return (
    <Modal
      alignItems="center"
      closeOnDimmerClick={false}
      closeOnEscape={false}
      compDisplay="flex"
      compHeight={compHeight}
      flexDirection="column"
      justifyContent="center"
      p={3}
      pb={5}
      size="medium"
      transparentOverlay
    >
      <Image height="40px" src="/icons/logo-ss-sign.svg" width="40px" />
      <Text fontSize="24px" fontWeight="bold" lineHeight={1.5}>
        Great job! 🙌
      </Text>
      <Text {...textStyles}>
        We’re loading all the metadata and calculating the insights for you.
      </Text>
      <Text {...textStyles}>We’ll email you when it’s ready!</Text>
      <Text {...textStyles} display="inline">
        Meanwhile, check out{' '}
        <Link
          fontSize="inherit"
          href={`${DEFAULT_DOCS_URL}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          our docs
        </Link>{' '}
        to learn more about Select Star.
      </Text>
    </Modal>
  );
};

export default OnboardingFinished;
