import React from 'react';

import Box from '@components/Box';
import StatusBadge from '@components/StatusBadge';
import { StatusCountsType } from '@components/StatusBadge/StatusBadge';
import TabsRouter, { TabRoutesConfig } from '@components/TabsRouter';
import Icon from '@components/UI/Icon';

import MonitorsDbtTestsTable from './MonitorsDbtTestsTable';
import MonitorsTable from './MonitorsTable';
import UpstreamIssuesTable from './UpstreamIssuesTable';

interface DataQualityTabContentProps {
  dbtModelGuid?: string;
  guid: string;
  initialCounts: {
    dbt?: StatusCountsType;
    monteCarlo?: StatusCountsType;
    upstreamIssues?: StatusCountsType;
  };
  tabs?: { dbt?: boolean; monteCarlo?: boolean; upstreamIssues?: boolean };
}

const DataQualityTabContent: React.FC<DataQualityTabContentProps> = ({
  dbtModelGuid,
  guid,
  initialCounts,
  tabs,
}) => {
  const tabsRouterConfig: TabRoutesConfig[] = [];
  const { dbt, monteCarlo, upstreamIssues } = tabs ?? {};

  if (monteCarlo) {
    tabsRouterConfig.push({
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <MonitorsTable tableGuid={guid} />,
      contentOffset: 2,
      default: true,
      label: (
        <Box alignItems="center" compDisplay="flex" gap={0.5}>
          <Icon name="monte-carlo" size="16px" />
          Monte Carlo
          <StatusBadge counts={initialCounts.monteCarlo} />
        </Box>
      ),
      path: '/monte-carlo/:itemId?',
      route: '/monte-carlo',
    });
  }

  if (dbt) {
    tabsRouterConfig.push({
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <MonitorsDbtTestsTable tableGuid={dbtModelGuid ?? ''} />,
      contentOffset: 2,
      default: !monteCarlo,
      label: (
        <Box alignItems="center" compDisplay="flex" gap={0.5}>
          <Icon name="dbt" size="16px" />
          dbt Test
          <StatusBadge counts={initialCounts.dbt} />
        </Box>
      ),
      path: '/dbt-test/:itemId?',
      route: '/dbt-test',
    });
  }

  if (upstreamIssues) {
    tabsRouterConfig.push({
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <UpstreamIssuesTable guid={guid} />,
      contentOffset: 2,
      default: !monteCarlo && !dbt,
      label: (
        <Box alignItems="center" compDisplay="flex" gap={0.5}>
          Upstream Issues
          <StatusBadge counts={initialCounts.upstreamIssues} />
        </Box>
      ),
      route: '/upstream-issues',
    });
  }

  return <TabsRouter config={tabsRouterConfig} sticky subTabs />;
};

export default DataQualityTabContent;
