export enum SegmentTrackEventName {
  // Fired when user click on DS Modal Close button
  AddDSCloseButtonClicked = 'Add DS Close Button Clicked',

  // Fired when user click on Connect button on Data Source configuration screen
  CreateServiceAccountConnectButtonClicked = 'Create Service Account Connect Button Clicked',

  // Fired when user click on link to documentation on third onboarding screen
  CreateServiceAccountDocsLinkClicked = 'Create Service Account Docs Link Clicked',

  // Fired when user click on Next button on Data Source selection screen
  DataSourceSelectionNextButtonClicked = 'Data Source Selection Next Button Clicked',
  // Fired when user click on "Email verification succeeded" message (redirection to home)
  EmailVerificationGetStartedButtonClicked = 'Email Verification Get Started Button Clicked',

  // Extension events
  ExtensionPageView = 'Extension Page View',
  ExtensionPopUpOpened = 'Extension Pop Up Opened',
  ExtensionSignedIn = 'Extension Signed In',

  // Fired when user hits enter in the search bar and opens the full search page
  FullSearchPageOpened = 'Full Search Page Opened',

  // Fired when user click on Sign in button on /login page
  HomepageSignInButtonClicked = 'Homepage Sign In Button Clicked',

  // Fired when user click Next button on LookMLProjectSetup screen
  ImportData_LookmlConnectNextButtonClicked = 'Import Data-Lookml Connect Next Button Clicked',

  // Fired when user click Prev button on LookMLProjectSetup screen
  ImportData_LookmlConnectPrevButtonClicked = 'Import Data-Lookml Connect Prev Button Clicked',

  // Fired when user click Copy SSH button on LookMLProjectSetup screen
  ImportData_LookmlConnectSSHKeyCopyButtonClicked = 'Import Data-Lookml Connect SSH Key Copy Button Clicked',

  // Fired when user click Next button on AddDiscoveryDB screen
  ImportData_ModeDiscoveryConnectNextButtonClicked = 'Import Data-Mode Discovery Connect Next Button Clicked',

  // Fired when user click Prev button on AddDiscoveryDB screen
  ImportData_ModeDiscoveryConnectPrevButtonClicked = 'Import Data-Mode Discovery Connect Prev Button Clicked',

  // Fired when user click Next button on QuickSight screen
  ImportData_QuickSightActivityDBNextButtonClicked = 'Import QuickSight Activity DB Next Button Clicked',

  // Fired when user click Prev button on QuickSight screen
  ImportData_QuickSightActivityDBPrevButtonClicked = 'Import QuickSight Activity DB Prev Button Clicked',

  // Fired when user click Next button on database selection screen
  ImportData_SelectDatabasesNextButtonClicked = 'Import Data-Select Databases Next Button Clicked',

  // Fired when user click Prev button on database selection screen
  ImportData_SelectDatabasesPrevButtonClicked = 'Import Data-Select Databases Prev Button Clicked',

  // Fired when user click Next button on schema selection screen
  ImportData_SelectSchemasNextButtonClicked = 'Import Data-Select Schemas Next Button Clicked',

  // Fired when user click Prev button on database selection screen
  ImportData_SelectSchemasPrevButtonClicked = 'Import Data-Select Schemas Prev Button Clicked',

  // Fired when user click Next button on TableauActivityDB screen
  ImportData_TableauActivityDBNextButtonClicked = 'Import Data-Tableau Activity DB Next Button Clicked',

  // Fired when user click Prev button on TableauActivityDB screen
  ImportData_TableauActivityDBPrevButtonClicked = 'Import Data-Tableau Activity DB Prev Button Clicked',

  // Lineage events
  LineageColumnLevelLoaded = 'Lineage Column Level Loaded',
  LineageColumnNodeClicked = 'Lineage Column Node Clicked',
  LineageColumnOptionMenuToggled = 'Lineage Column Menu Option Toggled',
  LineageExpansionLoaded = 'Lineage Expansion Loaded',
  LineageExploreLoaded = 'Lineage Explore Loaded',
  LineageFitViewClicked = 'Lineage Fit View Clicked',
  LineageSQLToggleClicked = 'Lineage SQL Toggle Clicked',
  LineageSearchToggleClicked = 'Lineage Search Toggle Clicked',
  LineageSidebarClosed = 'Lineage Sidebar Closed',
  LineageSidebarOpened = 'Lineage Sidebar Opened',
  LineageTableLevelLoaded = 'Lineage Table Level Loaded',
  LineageTableNodeClicked = 'Lineage Table Node Clicked',
  LineageTableOptionMenuToggled = 'Lineage Table Menu Option Toggled',
  LineageZoomInClicked = 'Lineage Zoom In Clicked',
  LineageZoomOutClicked = 'Lineage Zoom Out Clicked',

  // Fired when user click on Sign in with Google
  LoginGoogleSignInButtonClicked = 'Login Google Sign In Button Clicked',

  // Fired when user click on Sign up link on /login page
  LoginSignUpLinkClicked = 'Login Sign Up Link Clicked',

  // Fired when user perform some search using quicksearch API
  QuickSearchPerformed = 'Quick Search Performed',

  // Fired when user clicks on a quick search result
  QuickSearchResultClicked = 'Quick Search Result Clicked',

  // Fired when user perform some search using regular search API
  RegularSearchPerformed = 'Regular Search Performed',

  // Fired when user clicks on a reguar search result
  RegularSearchResultClicked = 'Regular Search Result Clicked',

  // Fired when user click on Next button on first onboarding screen
  SetupAccountNextButtonClicked = 'Setup Account Next Button Clicked',

  // Fired when user click on Sign up with email on /signup
  SignUpEmailSignUpLinkClicked = 'Sign Up Email Sign Up Link Clicked',

  // Fired when user click on Sign up with Google
  SignUpGoogleSignUpButtonClicked = 'Sign Up Google Sign Up Button Clicked',

  // Fired when user click on resed verification link
  SignUpResendVerificationLinkClicked = 'Sign Up Resend Verification Link Clicked',

  // Fired when user signed into the app
  SignedIn = 'Signed In',

  // Fired when user signed out from the app
  SignedOut = 'Signed Out',

  // Fired when user is created with success response
  SignedUp = 'Signed Up',

  // Fired when User enter the sign in page from selectstar.com Free Trial button
  utm_sourceFreeTrialButtonClicked = 'utm_source Free Trial Button Clicked',

  // Fired when User enter the sign in page from selectstar.com Sign in button
  utm_sourceSignInButtonClicked = 'utm_source Sign In Button Clicked',
}

export enum SegmentTrackPageName {
  // Fired when user see first screen of import data process
  ImportData = 'Import Data',

  // Fired when user see SetupCompleteStep
  ImportDataCompleted = 'Import Data Completed',

  // Fired when user see IngestDataStep
  ImportData_LoadData = 'Import Data-Load Data',

  // Fired when user see LookMLProjectSetup error message
  ImportData_LookmlConnectErrorMessage = 'Import Data-Lookml Connect Error Message',

  // Fired when user see onboarding process
  SetupAccount = 'Setup Account',

  // Fired when user see sign in page
  SignIn = 'Sign In',

  // Fired when user see sign up
  SignUp = 'Sign Up',

  // Fired when user sees the unexpected error page
  UnexpectedError = 'Unexpected Error',

  // Fired when user see email veryfication error
  VerificationFailed = 'Verification Failed',

  // Fired when user see email veryfication success
  VerificationSuccess = 'Verification Success',
}

export interface SegmentProps {
  identify: (id: string, properties?: object) => void;
  page: (page: SegmentTrackPageName, properties?: object) => void;
  reload: () => void;
  track: (event: SegmentTrackEventName, properties?: object) => void;
}
