import React from 'react';

import HeaderWithInfo from '../HeaderWithInfo';

const LINEAGE_DISTANCE_HEADER_DESCRIPTION =
  'Number of edges from this table to the dashboard: 1 means it directly impacts the dashboard. More than 1 indicates influence through downstream tables.';

const LineageDistanceHeader = () => {
  return (
    <HeaderWithInfo description={LINEAGE_DISTANCE_HEADER_DESCRIPTION} name="Lineage Distance" />
  );
};

export default LineageDistanceHeader;
