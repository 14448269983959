import styled from '@emotion/styled';

import Box from '@components/Box';
import { sizeVariants } from '@components/Input/Input.v1.styles.variants';
import InputLabel from '@components/UI/Form/InputLabel';

export const StyledCreateTagContentFieldLabel = styled(InputLabel)``;

StyledCreateTagContentFieldLabel.defaultProps = {
  alignItems: 'flex-start',
  as: 'div',
  color: 'gray.700',
  compDisplay: 'grid',
  cursor: 'default',
  fontWeight: 'medium',
  gap: 2,
  gridTemplateColumns: '80px 1fr',
};

export const StyledCreateTagContentNameColorFieldLabel = styled(StyledCreateTagContentFieldLabel)`
  grid-template-columns: 80px 38px 2fr;
  row-gap: 0;
`;

export const StyledCreateTagContentNameColorFieldLabelText = styled(Box)`
  ${sizeVariants.default};
`;

StyledCreateTagContentNameColorFieldLabelText.defaultProps = {
  alignItems: 'center',
  as: 'span',
  compDisplay: 'flex',
};
