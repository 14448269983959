import React from 'react';

import invalidateCache from '@api/invalidateCache';
import { usePatchMetadataDescriptionsAndOwners } from '@api/metadata';
import Alert from '@components/Alert';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import BaseOwnerSelect from '@components/OwnerSelect/BaseOwnerSelect';
import type { OwnerSelectItem } from '@components/OwnerSelect/types';
import { renderErrorToast, renderInfoToast } from '@components/Toast';
import InputLabel from '@components/UI/Form/InputLabel/InputLabel';
import Modal, { ModalContent, ModalFooter, ModalHeader } from '@components/UI/Modal';
import type { Option, SelectValue } from '@components/UI/Select';

import { getCommonOwner } from './BulkEditOwnersModal.utils';
import useDefaultOptions from './useDefaultOptions';

interface BulkEditOwnersForm {
  businessOwner?: SelectValue;
  technicalOwner?: SelectValue;
}

export interface BulkEditOwnersModalProps<ItemType extends OwnerSelectItem = OwnerSelectItem> {
  editableItems: ItemType[];
  onClose?: () => void;
  reloadData?: () => void;
  selectedItems: ItemType[];
}

const BulkEditOwnersModal = <ItemType extends OwnerSelectItem>({
  editableItems,
  onClose,
  reloadData,
  selectedItems,
}: BulkEditOwnersModalProps<ItemType>) => {
  const nonEditableItems = selectedItems.length - editableItems.length;

  const {
    businessOwnerConfig: {
      currentOwnerOption: commonBusinessOwner,
      options: businessOwnerDefaultOptions,
    },
    isLoading: isLoadingDefaultOptions,
    technicalOwnerConfig: {
      currentOwnerOption: commonTechnicalOwner,
      options: technicalOwnerDefaultOptions,
    },
  } = useDefaultOptions({
    currentBusinessOwner: getCommonOwner(editableItems, 'businessOwner'),
    currentTechnicalOwner: getCommonOwner(editableItems, 'technicalOwner'),
  });

  const { isLoading: isUpdatingOwners, mutate: updateOwners } =
    usePatchMetadataDescriptionsAndOwners({
      onError: () => {
        renderErrorToast("Couldn't update the item's owners");
      },
      onSuccess: () => {
        renderInfoToast('Changes saved.');
        reloadData?.();
        invalidateCache((keys) => [keys.metadata.all]);
        onClose?.();
      },
    });

  const { handleSubmit, setValues, values } = useForm<BulkEditOwnersForm>({
    initialValues: {
      businessOwner: commonBusinessOwner ? [commonBusinessOwner] : undefined,
      technicalOwner: commonTechnicalOwner ? [commonTechnicalOwner] : undefined,
    },
    onSubmit: (payload) => {
      const { businessOwner, technicalOwner } = payload;
      const [businessOwnerSelected] = businessOwner ?? [];
      const [technicalOwnerSelected] = technicalOwner ?? [];

      const itemsGuids = editableItems.map((item) => item.guid);

      const businessOwnerValue = businessOwnerSelected?.value;
      const technicalOwnerValue = technicalOwnerSelected?.value;

      updateOwners({
        item_set: [
          {
            business_owner: businessOwnerValue,
            items: itemsGuids,
            technical_owner: technicalOwnerValue,
          },
        ],
      });
    },
  });

  const handleFieldChange = (name: keyof BulkEditOwnersForm, value: SelectValue) => {
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value as Option[],
    }));
  };

  const isLoading = isLoadingDefaultOptions || isUpdatingOwners;

  return (
    <Modal aria-labelledby="dialog-header" closeOnDimmerClick={false} onClose={onClose}>
      <ModalHeader onClose={onClose} title="Edit Owners" />
      <Form isLoading={isLoading} onSubmit={handleSubmit}>
        <ModalContent
          compDisplay="flex"
          compWidth="100%"
          flexDirection="column"
          gap={3.5}
          minHeight="auto"
          py={2.5}
        >
          {nonEditableItems > 0 && (
            <Box compWidth="100%">
              <Alert type="warning">
                You only have only edit access to some objects. Only {editableItems.length} will be
                updated.
              </Alert>
            </Box>
          )}
          <Box
            compDisplay="grid"
            compWidth="100%"
            gap={2}
            gridTemplateColumns="repeat(2, 1fr)"
            minWidth={0}
          >
            <Box compDisplay="flex" flexDirection="column" gap={1} minWidth={0}>
              <InputLabel
                as="div"
                color="gray.700"
                compDisplay="grid"
                cursor="default"
                fontWeight="medium"
              >
                Business Owner
              </InputLabel>
              <BaseOwnerSelect
                defaultOptions={businessOwnerDefaultOptions}
                fitAnchorWidth
                onChange={(newValue) => handleFieldChange('businessOwner', newValue)}
                placeholder="Select Business Owner"
                showNoOwnerOption
                showSearchOnOptions
                value={values.businessOwner}
              />
            </Box>
            <Box compDisplay="flex" flexDirection="column" gap={1} minWidth={0}>
              <InputLabel
                as="div"
                color="gray.700"
                compDisplay="grid"
                cursor="default"
                fontWeight="medium"
              >
                Technical Owner
              </InputLabel>
              <BaseOwnerSelect
                defaultOptions={technicalOwnerDefaultOptions}
                fitAnchorWidth
                onChange={(newValue) => handleFieldChange('technicalOwner', newValue)}
                placeholder="Select Technical Owner"
                showNoOwnerOption
                showSearchOnOptions
                value={values.technicalOwner}
              />
            </Box>
          </Box>
        </ModalContent>
        <ModalFooter>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default BulkEditOwnersModal;
