import styled from '@emotion/styled';

import Box from '@components/Box';

export const StyledBadge = styled(Box)`
  border-radius: ${({ theme }) => theme.radius.lg};
  padding: ${({ theme }) => theme.space(0.25, 0.75)};
  text-align: center;
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  line-height: normal;
`;
